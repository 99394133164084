import Modal from "react-bootstrap/Modal";
import SearchIcon from "../assets/icons/icon-po-arrow.svg";
import Button from "react-bootstrap/Button";
import React, { useEffect, useState } from "react";
// import {AikkiyamServices} from "../Services/Services";
import "./ReqNewQrModal.scss";
import CloseIcon from "../assets/icons/IconClose.svg";
import closeIcon from "../assets/icons/IconClose.svg";

function ReqNewQrModal({
  isRequestModalOpen,
  handleCloseModal,
  handleRequestClick,
}: any) {
  const [loader, setLoader] = useState(false);
  const [isPoDetailsVisible, setIsPoDetailsVisible] = useState(false);
  const [responseDataVal, setResponseDataVal] = useState("");
  const [poNumber, setPoNumber] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(
    !(poNumber.length > 0 && isPoDetailsVisible)
  );

  const handleSearchClick = () => {
    if (isPoDetailsVisible) {
      setPoNumber("");
    }
    fetchData().then((r) => {});
    setIsPoDetailsVisible(!isPoDetailsVisible);
  };

  const responseData = JSON.parse(
    `{
            "Date": "23 January 2023",
            "Organisation": "IOCL",
            "Plant": "1231 - Madanpur Khadar Plant, Delhi",
            "QRTagsCount": "10,00,000"
        }`
  );

  // const services = new AikkiyamServices()

  async function fetchData() {
    setLoader(true);
    // setResponseDataVal(await services.getPoDetails())
    setResponseDataVal(responseData);
    setIsPoDetailsVisible(true);
    setLoader(false);
  }

  // useEffect(() => {
  //     if (isRequestModalOpen) {
  //         fetchData().then(r => {
  //         })
  //     }
  // }, [isRequestModalOpen])

  const handleChange = (event: any) => {
    setPoNumber(event.target.value);
  };
  const handlePoSearch = (event: any) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  const closeModal = () => {
    handleCloseModal();
    setPoNumber("");
    setIsPoDetailsVisible(false);
  };

  useEffect(() => {
    setIsButtonDisabled(!(poNumber.length > 0 && isPoDetailsVisible));
  }, [poNumber, isPoDetailsVisible]);

  return (
    <Modal
      show={isRequestModalOpen}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header className="noBorderModalItem">
        <div className="reqRangeQuotaHeader">
          <p>Request QR Range Quota</p>

          <div className="popUpCloseButton" onClick={closeModal}>
            <img className="closeIcon" src={closeIcon} alt="close" />
          </div>
        </div>
      </Modal.Header>

      <Modal.Body>
        <div className={loader ? "inputContainer row" : ""}>
          {loader && (
            // <GenerateQrLoader fromNew={fromNew}/>
            <>Loading . . . </>
          )}
        </div>

        {!loader && (
          <div className="inputContainer row">
            {/*{!isPoDetailsVisible &&*/}
            <div className="row">
              <label className="inputText col-2">PO#</label>
              <div className="inputCont col-10">
                <input
                  type="text"
                  className="modalInput"
                  onChange={handleChange}
                  value={poNumber}
                  autoFocus={true}
                  onKeyDown={handlePoSearch}
                />

                <img
                  className="icon1"
                  width="15"
                  src={!isPoDetailsVisible ? SearchIcon : CloseIcon}
                  onClick={handleSearchClick}
                ></img>
              </div>
            </div>
            {/*}*/}
          </div>
        )}

        {isPoDetailsVisible && (
          <div className="animated-div">
            <div className="poDetailsTable">
              <div>
                {Object.keys(responseDataVal).map((key) => (
                  <div className="poDetailsRow" key={key}>
                    <div className="poDetailsFor">{key}</div>
                    <div className="poDetailsData">{responseData[key]}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer className="noBorderModalItem marginRight25px marginBottom20px">
        <button className="cancleBtn" onClick={closeModal}>
          Cancel
        </button>
        <button
          className="requestPoButton"
          // disabled={fromNew ? isButtonDisabled : loader}
          onClick={handleRequestClick}
        >
          Request
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ReqNewQrModal;
