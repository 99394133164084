import React, { useEffect } from "react";
import useState from "react-usestateref";
import "./DownloadPopup.scss";
import downloadicon from "../../../assets/icons/download-icon.svg";
import moment from "moment";
import { useStateValue } from "../../../redux/StateProvider";
import { API_BASE_URL_DOWNLOAD } from "../../../Constants/constants";
import DateTimeRangeInputComponent from "../DateTimeRangeInputComponent/DateTimeRangeInputComponent";
import { convertEpochiToDateTime } from "../DateTimeRangeInputComponent/Utils";
import {useQuery, useQueryClient} from "react-query";
import {scheduleReportGenerationApi} from "./service/DownloadPopupService";
import LocalStorageService from "../../../Utils/LocalStorageService";
import {cancelExistingQuery} from "../../../Utils/utils";

const closeTicketOption = [
  {
    id: 1,
    value: "Raw Data",
  },
  // {
  //   id: 2,
  //   value: "Temperature compliance report",
  // },
  // {
  //   id: 3,
  //   value: "Non-compliance log",
  // },
  // {
  //   id: 4,
  //   value: "Activity report",
  // },
];

function DownloadPopup({
  show,
  close,
  assetID,
  assetName, handleDownloadToastMessage
}: any) {
  const queryClient = useQueryClient();
  const downloadOption = [{ id: 1, value: "PDF" }, { id: 2, value: "EXCEL" }];
  const [selectedVal, setSelectedVal] = useState("Raw Data");
  const [selectedValFormate, setSelectedValFormate] = useState("PDF");
  const [assetNameVariable, setAssetNameVariableVariable] = useState<any>(assetName);

  //for the exel and PDF data
  const [setUrlDownload, setSetUrlDownload] = useState<string>();

  const onclose = () => close();

  useEffect(() => setAssetNameVariableVariable(() => assetName), [assetName]);

  const submitTicketStatus = () => close();

  const [duration, setDuration] = useState(0);
  const [{ dateRange, dateChip }] = useStateValue();
  const [deviceData, setDeviceData] = useState({
    id: '',
    name: '',
  });

  let fromDateStr = moment(dateRange[0]).startOf("day").toISOString();
  let toDateStr = moment(dateRange[1]).endOf("day").toISOString();

  const convertIntoDateObject = (dateString: any, state: any) => {
    // Convert the string to a Date object
    if (dateString === undefined) {
      let selectedDate = state ? new Date(moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")) : new Date(moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"));
      selectedDate.setHours(selectedDate.getHours() + 5);
      selectedDate.setMinutes(selectedDate.getMinutes() + 30);
      return new Date(selectedDate);
    } else {
      const selectedDate = new Date(dateString);
      selectedDate.setHours(selectedDate.getHours() + 5);
      selectedDate.setMinutes(selectedDate.getMinutes() + 30);
      return new Date(selectedDate);
    }
  }

  const [dateTimeRangeValue, setDateTimeRangeValue] = useState<any>({
    startDate: convertIntoDateObject(dateRange[0], true),
    endDate: convertIntoDateObject(dateRange[1], false),
  });


  const [dateRangeValue, setDateRangeValue] = useState<any>({
    startDate: dateRange[0] === undefined ? moment().startOf("day").valueOf() : moment(fromDateStr).valueOf(),
    endDate: dateRange[1] === undefined ? moment().endOf("day").valueOf() : moment(toDateStr).valueOf(),
  });


  const onChangeDownloadRadioOption = (e: any) => {
    let start_Date = dateRangeValue.startDate;
    let end_Date = dateRangeValue.endDate;
    const userName = localStorage.getItem("userName");
    const password = localStorage.getItem("password");
    const deviceId = localStorage.getItem("assetId");
    let PDFUrl;
    let Excel;
    if (assetID === "TEMP_ENERGY") {
      PDFUrl = `${API_BASE_URL_DOWNLOAD}services/control/RawDataTsTeReport.pdf?username=${userName}&password=${password}&startTime=${start_Date}&endTime=${end_Date}&fixedAssetId=${deviceId}&freqFlag=${toggleButtonState ? "TRUE" : "FALSE"}&freqValue=${toggleButtonState ? selectedFequency : 0}&birtOutputFileName=${"RawDataReport_" + assetNameVariable}_${convertEpochiToDateTime(start_Date, end_Date)}`;
      Excel = `${API_BASE_URL_DOWNLOAD}services/control/RawDataTsTeReport.xlsx?username=${userName}&password=${password}&startTime=${start_Date}&endTime=${end_Date}&fixedAssetId=${deviceId}&freqFlag=${toggleButtonState ? "TRUE" : "FALSE"}&freqValue=${toggleButtonState ? selectedFequency : 0}&birtOutputFileName=${"RawDataReport_" + assetNameVariable}_${convertEpochiToDateTime(start_Date, end_Date)}`;
    } else if (assetID === "WATER") {
      PDFUrl = `${API_BASE_URL_DOWNLOAD}services/control/RawDataWaterReport.pdf?username=${userName}&password=${password}&fromDate=${start_Date}&thruDate=${end_Date}&fixedAssetId=${deviceId}&birtOutputFileName=${"RawDataReport_" + assetNameVariable}_${convertEpochiToDateTime(start_Date, end_Date)}&mode=DD`;
      Excel = `${API_BASE_URL_DOWNLOAD}services/control/RawDataWaterReport.xlsx?username=${userName}&password=${password}&fromDate=${start_Date}&thruDate=${end_Date}&fixedAssetId=${deviceId}&birtOutputFileName=${"RawDataReport_" + assetNameVariable}_${convertEpochiToDateTime(start_Date, end_Date)}&mode=DD`;
    } else if (assetID === "AIR") {
      PDFUrl = `${API_BASE_URL_DOWNLOAD}services/control/RawDataAirReport.pdf?username=${userName}&password=${password}&fromDate=${start_Date}&thruDate=${end_Date}&fixedAssetId=${deviceId}&birtOutputFileName=${"RawDataReport_" + assetNameVariable}_${convertEpochiToDateTime(start_Date, end_Date)}&mode=DD`;
      Excel = `${API_BASE_URL_DOWNLOAD}services/control/RawDataAirReport.xlsx?username=${userName}&password=${password}&fromDate=${start_Date}&thruDate=${end_Date}&fixedAssetId=${deviceId}&birtOutputFileName=${"RawDataReport_" + assetNameVariable}_${convertEpochiToDateTime(start_Date, end_Date)}&mode=DD`;
    } else if (assetID === "ENERGY") {
      PDFUrl = `${API_BASE_URL_DOWNLOAD}services/control/RawDataEnergyReport.pdf?username=${userName}&password=${password}&fromDate=${start_Date}&thruDate=${end_Date}&fixedAssetId=${deviceId}&birtOutputFileName=${"RawDataReport_" + assetNameVariable}_${convertEpochiToDateTime(start_Date, end_Date)}&mode=DD`;
      Excel = `${API_BASE_URL_DOWNLOAD}services/control/RawDataEnergyReport.xlsx?username=${userName}&password=${password}&fromDate=${start_Date}&thruDate=${end_Date}&fixedAssetId=${deviceId}&birtOutputFileName=${"RawDataReport_" + assetNameVariable}_${convertEpochiToDateTime(start_Date, end_Date)}&mode=DD`;
    } else if (assetID === "IAQ") {
      PDFUrl = `${API_BASE_URL_DOWNLOAD}services/control/RawDataAirQualityReport.pdf?username=${userName}&password=${password}&fromDate=${start_Date}&thruDate=${end_Date}&fixedAssetId=${deviceId}&birtOutputFileName=${"RawDataReport_" + assetNameVariable}_${convertEpochiToDateTime(start_Date, end_Date)}`;
      Excel = `${API_BASE_URL_DOWNLOAD}services/control/RawDataAirQualityReport.xlsx?username=${userName}&password=${password}&fromDate=${start_Date}&thruDate=${end_Date}&fixedAssetId=${deviceId}&birtOutputFileName=${"RawDataReport_" + assetNameVariable}_${convertEpochiToDateTime(start_Date, end_Date)}`;
    } else if (assetID === "PRESSURE") {
      PDFUrl = `${API_BASE_URL_DOWNLOAD}services/control/RawDataAirPressureReport.pdf?username=${userName}&password=${password}&startTime=${start_Date}&endTime=${end_Date}&fixedAssetId=${deviceId}&birtOutputFileName=${"RawDataReport_" + assetNameVariable}_${convertEpochiToDateTime(start_Date, end_Date)}&freqFlag=FALSE&freqValue=0`;
      Excel = `${API_BASE_URL_DOWNLOAD}services/control/RawDataAirPressureReport.xlsx?username=${userName}&password=${password}&startTime=${start_Date}&endTime=${end_Date}&fixedAssetId=${deviceId}&birtOutputFileName=${"RawDataReport_" + assetNameVariable}_${convertEpochiToDateTime(start_Date, end_Date)}&freqFlag=FALSE&freqValue=0`;
    }
    else if (assetID === "WATER_PULSE") {
      PDFUrl = `${API_BASE_URL_DOWNLOAD}services/control/WaterPulse.pdf?username=${userName}&password=${password}&fromDate=${start_Date}&thruDate=${end_Date}&fixedAssetId=${deviceId}&birtOutputFileName=${"RawDataReport_" + assetNameVariable}_${convertEpochiToDateTime(start_Date, end_Date)}&mode=DD`;
      Excel = `${API_BASE_URL_DOWNLOAD}services/control/WaterPulse.xlsx?username=${userName}&password=${password}&fromDate=${start_Date}&thruDate=${end_Date}&fixedAssetId=${deviceId}&birtOutputFileName=${"RawDataReport_" + assetNameVariable}_${convertEpochiToDateTime(start_Date, end_Date)}&mode=DD`;
    }
    else if (assetID === "AIR_FILTER") {
      PDFUrl = `${API_BASE_URL_DOWNLOAD}services/control/AirFilter.pdf?username=${userName}&password=${password}&fromDate=${start_Date}&thruDate=${end_Date}&fixedAssetId=${deviceId}&birtOutputFileName=${"RawDataReport_" + assetNameVariable}_${convertEpochiToDateTime(start_Date, end_Date)}&mode=DD`;
      Excel = `${API_BASE_URL_DOWNLOAD}services/control/AirFilter.xlsx?username=${userName}&password=${password}&fromDate=${start_Date}&thruDate=${end_Date}&fixedAssetId=${deviceId}&birtOutputFileName=${"RawDataReport_" + assetNameVariable}_${convertEpochiToDateTime(start_Date, end_Date)}&mode=DD`;
    }
    else if (assetID === "AC_CONTROLLER") {
      PDFUrl = `${API_BASE_URL_DOWNLOAD}services/control/AcController.pdf?username=${userName}&password=${password}&fromDate=${start_Date}&thruDate=${end_Date}&fixedAssetId=${deviceId}&birtOutputFileName=${"RawDataReport_" + assetNameVariable}_${convertEpochiToDateTime(start_Date, end_Date)}&mode=DD`;
      Excel = `${API_BASE_URL_DOWNLOAD}services/control/AcController.xlsx?username=${userName}&password=${password}&fromDate=${start_Date}&thruDate=${end_Date}&fixedAssetId=${deviceId}&birtOutputFileName=${"RawDataReport_" + assetNameVariable}_${convertEpochiToDateTime(start_Date, end_Date)}&mode=DD`;
    }
    else {
      // (assetID.fixedAssetAssocWithTypeId === "TEMP")
      PDFUrl = `${API_BASE_URL_DOWNLOAD}services/control/RawDataTsTempReport.pdf?username=${userName}&password=${password}&startTime=${start_Date}&endTime=${end_Date}&fixedAssetId=${deviceId}&freqFlag=${toggleButtonState ? "TRUE" : "FALSE"}&freqValue=${toggleButtonState ? selectedFequency : 0}&birtOutputFileName=${"RawDataReport_" + assetNameVariable}_${convertEpochiToDateTime(start_Date, end_Date)}`;
      Excel = `${API_BASE_URL_DOWNLOAD}services/control/RawDataTsTempReport.xlsx?username=${userName}&password=${password}&startTime=${start_Date}&endTime=${end_Date}&fixedAssetId=${deviceId}&freqFlag=${toggleButtonState ? "TRUE" : "FALSE"}&freqValue=${toggleButtonState ? selectedFequency : 0}&birtOutputFileName=${"RawDataReport_" + assetNameVariable}_${convertEpochiToDateTime(start_Date, end_Date)}`;
    }
    if (e === "PDF") {
      setSelectedValFormate("PDF");
      setSetUrlDownload(PDFUrl);
    } else {
      setSelectedValFormate("EXCEL");
      setSetUrlDownload(Excel);
    }
  };

  useEffect(() => {
    const assetDatas = LocalStorageService.getSelectedAssetData();
    setDeviceData({
      id: assetDatas?.assetId,
      name: assetDatas?.assetName,
    });
    return () => cancelExistingQuery("scheduleReportGeneration", queryClient);
  }, []);

  const {data: dataResponse, error, isLoading, isFetching, refetch, dataUpdatedAt} = useQuery(
      "scheduleReportGeneration",
      () =>
          scheduleReportGenerationApi(
              {
                "fromDate": dateRangeValue.startDate,
                "thruDate": dateRangeValue.endDate,
                "contentId": "AST_RAW_DATA",
                "reportFormat": selectedValFormate === "PDF" ? "pdf" : "xlsx",
                "fixedAssetId": deviceData.id,
                "reportName": `RawDataReport_${deviceData.name}_${convertEpochiToDateTime(dateRangeValue.startDate, dateRangeValue.endDate)}`,
              }
          ),
      {
        enabled: false,
        keepPreviousData: false,
        onSuccess: (fetchedData) => {
          handleScheduleReportGeneration(fetchedData);
        }
      }
  )

  useEffect(() => {
    const startDate = moment(dateRange[0]);
    const endDate = moment(dateRange[1]);
    const duration = endDate.diff(startDate, "days") + 1;
    setDuration(duration);
  }, [dateRange]);

  //Toggle on change functionality
  const [toggleButtonState, setToggleButtonState] = useState(false);
  const [selectedFequency, setSelectedFequency] = useState(1);
  const logState = () => setToggleButtonState(!toggleButtonState);

  useEffect(() => onChangeDownloadRadioOption(selectedValFormate), [dateRangeValue, toggleButtonState, selectedFequency]);

  const [btnStatus, setBtnStatus] = useState(false);

  const updateDateTimeRanges = (key: string, value: any) => {
    if (key === "startDate") {
      setDateRangeValue(() => ({
        startDate: value,
        endDate: ""
      }));
      setDateTimeRangeValue((prev: any) => ({
        ...prev,
        endDate: ""
      }))
    } else {
      setDateRangeValue((prev: any) => ({
        ...prev,
        [key]: value
      }));
    }
  }

  const scheduleRawReport = async () => {
    try {
      await refetch();
    } catch (error) {
      console.error("Error re-fetching data:", error);
    }
  }

  const handleScheduleReportGeneration = async (data: any) => {
    let dataFetch = data;
    let message;
    if (dataFetch === "error") message = "Failed Try Again";
    else {
      switch (dataFetch.status) {
        case 200:
          message = "Report download initiated successfully";
          setDateRangeValue({ startDate: "", endDate: "" });
          setDateRangeValue({
            startDate: "",
            endDate: "",
          });
          break;

        case 419:
          let data_412 = await dataFetch.json();
          message = await data_412.errorMessage;
          break;

        case 204:
          message = "Report content not available";
          break;

        case 503:
          message = "error";
          break;

        case 512:
          message = "error";
          break;

        case 500:
          message = "Internal server error";
          break;

        case 400:
          message = "Bad Request Error";
          break;

        default:
          message = "error";
      }
    }
    cancelExistingQuery("scheduleReportGeneration", queryClient);
    handleDownloadToastMessage(message);
  }

  useEffect(() => {
    let checkBtnStatus = dateRangeValue.startDate !== "" && dateRangeValue.endDate !== "";
    setBtnStatus(() => checkBtnStatus);
  }, [dateRangeValue, dateRange])



  return (
    <div
      className="cardWrapperOutlay"
      style={{
        transform: show ? "translateY(0vh)" : "translateY(-100vh)",
        opacity: show ? "1" : "0",
      }}
    >
      <div
        className="modal-wrappers"
        style={{
          transform: show ? "translateY(0vh)" : "translateY(-100vh)",
          opacity: show ? "1" : "0",
        }}
      >
        <div className="modal-header-style">
          <div className="details">
            <img src={downloadicon} alt="icon" loading="lazy" />
            <h3>Download Asset Report</h3>
          </div>
          <span className="close-modal-btn" onClick={onclose}>×</span>
        </div>

        <div className="modal-bodyD">
          <div className="leftCnt">
            <div className="inputBox">
              <p><b>Report Type</b></p>
              {closeTicketOption &&
                closeTicketOption.map((ele: any, id: any) => {
                  return (
                    <label key={id} className="inputBox-label">
                      <input
                        type="radio"
                        id={id}
                        name="ticketClosing"
                        checked={selectedVal === ele.value}
                        value={ele.value}
                        onChange={(e) => setSelectedVal(e.target.value)}
                      />
                      {ele.value}
                    </label>
                  );
                })}
            </div>
          </div>
          <div className="rightCnt">
            <div className="inputBox">
              <p><b>Data for the period</b></p>
              <DateTimeRangeInputComponent dateRangeValue={dateTimeRangeValue} callBackDateRange={updateDateTimeRanges} />
              {duration > 31 ? (
                <p className="warningText">Cannot be greater than 31 days.</p>
              ) : null}
              <div className="typeDownload">
                {downloadOption.map((ele: any, id: any) => {
                  return (
                    <label style={{ opacity: !btnStatus || duration > 31 ? 0.4 : 1 }}
                      key={id} className="inputBox-label" >
                      <input
                        style={{ marginRight: "10px" }}
                        type="radio"
                        id={id}
                        name="csv"
                        checked={selectedValFormate === ele.value}
                        value={ele.value}
                        onClick={() => duration > 31 || !btnStatus ? null : onChangeDownloadRadioOption(ele.value)}
                      />
                      {ele.value}
                    </label>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer-asset">
          <button type="button" className="btn-Submit btn-dark" onClick={submitTicketStatus}>
            Cancel
          </button>
          {31 < duration || !btnStatus ? (
            <button type="button" style={{ opacity: 0.3, cursor: "not-allowed" }} className="btn-Action btn-primary">
              <img src={downloadicon} alt="icon" loading="lazy" /> Download
            </button>
          ) : (
              <>
                {
                  isLoading || isFetching ? (
                      <div className="loader-container">
                        <div className="spinner"></div>
                      </div>
                  ): (
                      <button type="button" className=" btn-Action btn-primary"
                              onClick={scheduleRawReport} style={{opacity: isLoading || isFetching ? 0.3 : 1}}
                              disabled={isLoading || isFetching}>
                        <img src={downloadicon} alt="icon" loading="lazy"/> Download
                      </button>
                  )
                }
              </>
          )}
        </div>
      </div>
    </div>
  );
}

export default DownloadPopup;
