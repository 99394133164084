import { element } from "prop-types";
import { getKeyForSelectedFilter } from "../../../Constants/constants";
import { DropdownType } from "../../../Utils/enumData";
import { FilterDataObj, FilterDataType, SearchListType } from "../types";

export class FilterUtils {
  searchSingleTypeFilterData = (
    value: string,
    index: number,
    data: FilterDataType[]
  ) => {
    const newData = data.map((item, idx) => {
      if (idx !== index) return item; // Keep other items unchanged

      const filteredData = item.data.map((element) => ({
        ...element,
        isVisible:
          value === "" ||
          element.name.toLowerCase().includes(value.toLowerCase()),
      }));

      return {
        ...item,
        searchValue: value,
        data: filteredData,
      };
    });

    return newData;
  };

  searchMultipleTypeFilterData = (
    value: string,
    index: number,
    data: FilterDataType[]
  ) => {
    const newData: FilterDataType[] = data.map(
      (item: FilterDataType, idx: number) => {
        if (idx !== index) {
          return item; // Keep the item unchanged if it's not the one being updated
        }

        const filteredData: FilterDataObj[] = item.data.map(
          (element: FilterDataObj) => {
            const isVisible =
              value === "" ||
              (element.name.toLowerCase().includes(value.toLowerCase()) &&
                element.isVisible);

            return {
              ...element,
              isVisible: isVisible,
            };
          }
        );

        return {
          ...item,
          searchValue: value,
          data: filteredData,
        };
      }
    );

    return newData;
  };

  checkAllUnselectedParentData = (
    index: number,
    group: string,
    hierarchyList: string[],
    filterDataList: FilterDataType[]
  ) => {
    const hierarchyMap: { [key: string]: number } = {};
    hierarchyList.forEach((item, index) => {
      hierarchyMap[item] = index;
    });

    const hierarchyIndex = hierarchyMap[group];
    if (hierarchyIndex === hierarchyList.length - 1) {
      return filterDataList;
    }

    const newFilterData = [...filterDataList];
    const parentIds: { [key: string]: boolean } = {};
    newFilterData[index].data.forEach((ele: FilterDataObj) => {
      if (ele.group === group && ele.isSelected) {
        parentIds[ele.id] = true;
      }
    });

    for (let i = hierarchyIndex + 1; i < hierarchyList.length; i++) {
      const newGroupName = hierarchyList[i];
      parentIds[group] = true;

      newFilterData[index].data.forEach((ele: FilterDataObj) => {
        if (ele.group === newGroupName) {
          ele.isVisible = true;
          ele.isSelected = false;
          if (ele.isVisible) {
            parentIds[ele.id] = true;
          }
        }
      });
      newFilterData[index].selectedCount = newFilterData[index].data.filter(
        (item: FilterDataObj) => item.isSelected === true
      ).length;
    }

    return newFilterData;
  };

  filterHierarchyData = (index: number, group: string, hierarchyList: string[], filterDataList: FilterDataType[]) => {
    const newFilterData = [...filterDataList];

    const temp: FilterDataType = newFilterData[index];

    let isSelectedModeEnabled: boolean = true;
    let groupName = "";

    const uniqueGroups = Array.from(
      new Set(temp.data.map((item) => item.group))
    );
    groupName = uniqueGroups[0];

    let parentIDs = temp.data
      .filter(
        (item: FilterDataObj) => item.group === groupName && item.isSelected
      )
      .map((item: FilterDataObj) => item.id);

    if (parentIDs.length === 0) {
      isSelectedModeEnabled = false;
      parentIDs = temp.data
        .filter(
          (item: FilterDataObj) => item.group === groupName && item.isVisible
        )
        .map((item: FilterDataObj) => item.id);
    }

    const tempIndex = temp.data.findIndex(
      (item: FilterDataObj) => item.group === uniqueGroups[1]
    );

    let modifiedData: FilterDataObj[] = temp.data.slice(0, tempIndex);

    for (let i = 1; i < uniqueGroups.length; i++) {
      const tempGroupName = uniqueGroups[i];
      const tempData = temp.data.filter(
        (item: FilterDataObj) => item.group === tempGroupName
      );

      let tempModifiedData: FilterDataObj[] = [];
      tempData.forEach((item: FilterDataObj) => {
        const tempFilteredData: FilterDataObj = {
          ...item,
          isSelected: parentIDs.includes(item.parentId) && item.isSelected,
          isVisible: parentIDs.includes(item.parentId),
        };
        tempModifiedData.push(tempFilteredData);
      });

      if (isSelectedModeEnabled) {
        parentIDs = tempModifiedData
          .filter((item: FilterDataObj) => item.isSelected)
          .map((item: FilterDataObj) => item.id);
        if (parentIDs.length === 0) {
          isSelectedModeEnabled = false;
          parentIDs = tempModifiedData
            .filter((item: FilterDataObj) => item.isVisible)
            .map((item: FilterDataObj) => item.id);
        }
      } else {
        parentIDs = tempModifiedData
        .filter((item: FilterDataObj) => item.isSelected)
        .map((item: FilterDataObj) => item.id);
        if (parentIDs.length !== 0) {
          isSelectedModeEnabled = true;
        } else {
          parentIDs = tempModifiedData
            .filter((item: FilterDataObj) => item.isVisible)
            .map((item: FilterDataObj) => item.id);
        }
      }

      modifiedData = [...modifiedData, ...tempModifiedData];
    }

    temp.data = modifiedData;
    newFilterData[index] = temp;
    return newFilterData;
  };

  checkFilterButtonDisable = (filterDataList: FilterDataType[]) => {
    let disableButtonStatus = false;
    for (let i = 0; i < filterDataList.length; i++) {
      const selectedList = filterDataList[i].data.filter(
        (item: FilterDataObj) => item.isSelected === true
      );
      if (selectedList.length > 0) {
        disableButtonStatus = true;
        break;
      }
    }
    return disableButtonStatus;
  };

  clearSelectedFilter = (data: FilterDataType[]): FilterDataType[] => {
    return data.map((item: FilterDataType) => {
      const unselectedData: FilterDataObj[] = item.data.map(
        (obj: FilterDataObj) => ({
          ...obj,
          isSelected: false,
          isVisible: true,
        })
      );

      return {
        ...item,
        data: unselectedData,
        searchValue: "",
        selectedCount: 0,
      };
    });
  };

  // This function will be triggred when the user click on the APPLY button.
  updateSearchList = (filterDataList: FilterDataType[], searchText: any) => {
    const temp = filterDataList;
    const newSearchList: SearchListType[] = [];

    temp.forEach((ele: FilterDataType) => {
      const selectedData = ele.data.filter(
        (data: FilterDataObj) => data.isSelected === true
      );

      if (selectedData.length !== 0) {
        if (ele.dropdown === DropdownType.SINGLE) {
          const selectedIDList = selectedData.map(
            (obj: FilterDataObj) => obj.id
          );
          const distinctGroups = [
            ...new Set(ele.data.map((item) => item.group)),
          ];
          newSearchList.push({
            isSearch: true,
            searchKey:
              distinctGroups.length !== 1
                ? getKeyForSelectedFilter[ele.key]
                : getKeyForSelectedFilter[ele.data[0].group],
            searchValue: selectedIDList,
          });
        } else {
          const groupedSelectedFilter = selectedData.reduce(
            (acc: { [key: string]: string[] }, element: FilterDataObj) => {
              acc[element.group] = [...(acc[element.group] || []), element.id];
              return acc;
            },
            {}
          );

          Object.entries(groupedSelectedFilter).forEach(([key, value]) => {
            newSearchList.push({
              isSearch: true,
              searchKey: getKeyForSelectedFilter[key],
              searchValue: value,
            });
          });
        }
      }
    });

    if (searchText && searchText.length > 0 && searchText[0].serchVal !== "") {
      const firstSearchText = searchText[0];
      const { serchVal, targetVal } = firstSearchText;
      if (serchVal) {
        newSearchList.push({
          isSearch: true,
          searchKey: getKeyForSelectedFilter[targetVal],
          searchValue: [serchVal],
        });
      }
    }

    return newSearchList;
  };

  // This function will be triggrered when the user try to search some data.
  updateSearchKey = (searchText: any, searchByList: SearchListType[]) => {
    const firstSearchText = searchText[0];
    const { serchVal, targetVal } = firstSearchText;
    const searchKeyList = [
      getKeyForSelectedFilter["Asset Name"],
      getKeyForSelectedFilter["Asset Id"],
    ];
    let newSearchList = searchByList.filter(
      (item: SearchListType) => !searchKeyList.includes(item.searchKey)
    );

    if (serchVal) {
      newSearchList.push({
        isSearch: true,
        searchKey: getKeyForSelectedFilter[targetVal],
        searchValue: [serchVal],
      });
    }

    return newSearchList;
  };
}
