import { API_BASE_URL } from "../../../Constants/constants";

let controller = new AbortController();
let signal = controller.signal;

export const getEventDuration = async (
  filterByType: string,
  filterById: string,
  areaId: string,
  startDate: number,
  endDate: number,
  viewIndex: number,
  viewSize: number
) => {
  const url = `${API_BASE_URL}fixedAsset/eventDuration?filterByType=${filterByType}&filterById=${filterById}&areaId=${areaId}&viewIndex=${viewIndex}&viewSize=${viewSize}&startDate=${startDate}&endDate=${endDate}`;
  const tokenID = localStorage.getItem("@tokenID");

  try {
    controller.abort(); // Cancel the previous request
    controller = new AbortController();
    signal = controller.signal;

    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenID}`,
      },
      signal: signal, // Pass the signal to the fetch request
    });

    if (!resp.ok) {
      return resp.status;
    }

    return await resp.json();
  } catch (error) {
    return error;
  }
};
