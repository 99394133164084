import moment from "moment";
import { add } from "ramda";
import React, { useEffect, useState } from "react";
import DisplayNote from "../../../a1Components/DisplayNote/DisplayNote";
import LoaderStyle from "../../../a1Components/LoaderStyle/LoaderStyle";
import FaultTicketsCard from "../faultTicketsCard/FaultTicketsCard";
import "./FaultTickets.scss";

const dummyFaultTicketJson = {
  groupdata: [
    {
      type: "bearing_fault",
      name: "Bearing fault",
      count: 6,
      delta: -2.5,
    },
    {
      type: "broken_rotor_bar_fault",
      name: "Broken rotor bar fault",
      count: 6,
      delta: -2.5,
    },
    {
      type: "inter_turn_fault",
      name: "Inter-turn fault",
      count: 6,
      delta: -2.5,
    },
  ],
  data: [
    {
      id: 28793412,
      time: 36172863123,
      type: "bearing_fault",
      name: "Bering",
      severity: "medium",
      isRepeated: true,
      location: {
        name: "so and so agency",
        full: "so and so agency, adyar, chennai",
        address: "adyar",
        city: "chennai",
      },
      relative_url: {
        image: "imges/12345",
        thumbnail: "thumbnails/12345",
      },
    },
    {
      id: 28793412,
      time: 36172863123,
      type: "broken_rotor_bar_fault_detected",
      name: "Broken rotor bar fault",
      severity: "medium",
      isRepeated: true,
      location: {
        name: "so and so agency",
        full: "so and so agency, adyar, chennai",
        address: "adyar",
        city: "chennai",
      },
      relative_url: {
        image: "imges/12345",
        thumbnail: "thumbnails/12345",
      },
    },
    {
      id: 28793412,
      time: 36172863123,
      type: "inter_turn_fault_detected",
      name: "Inter-turn fault",
      severity: "medium",
      isRepeated: true,
      location: {
        name: "so and so agency",
        full: "so and so agency, adyar, chennai",
        address: "adyar",
        city: "chennai",
      },
      relative_url: {
        image: "imges/12345",
        thumbnail: "thumbnails/12345",
      },
    },
  ],
};

function FaultTickets(props: any) {
  const { dateRange } = props;
  const [ticketsresponseData, setTicketsResponseData] =
    useState(dummyFaultTicketJson);
  const [loading, setLoading] = useState(false);

  // const ticketsCount=

  useEffect(() => {
    const faultTicketsAPI = async () => {
      setLoading(true);
      if (dateRange.length !== 0) {
        const startDate = moment(dateRange[0]).utc().valueOf();
        const endDate = moment(dateRange[1]).utc().valueOf();
        const url = `https://hawkeye.aone.ai/analytics/motorCondition/ticket/summary?start=${startDate}&end=${endDate}`;
        const response = await fetch(url);
        const jsonData = await response.json();
        // console.log(jsonData, "faultTickets");
        setTicketsResponseData(jsonData.data);
      }
      setLoading(false);
    };
    faultTicketsAPI();
  }, [dateRange]);

  return loading ? (
    <LoaderStyle />
  ) : (
    <div>
      {ticketsresponseData.groupdata[0].count === 0 &&
      ticketsresponseData.groupdata[1].count === 0 &&
      ticketsresponseData.groupdata[2].count === 0 ? (
        <DisplayNote />
      ) : (
        <div className="faultTicketsContainer">
          <div className="faultTicketsNotificationCont">
            <div className="faultTicketsNotification">
              <p className="faultTicketsNotificationTxt">
                {ticketsresponseData.groupdata[0].name}
              </p>
              <p className="faultTicketsNotificationCount">
                {ticketsresponseData.groupdata[0].count}
              </p>
            </div>
            <div className="faultTicketsNotification">
              <p className="faultTicketsNotificationTxt">
                {ticketsresponseData.groupdata[1].name}
              </p>
              <p className="faultTicketsNotificationCount">
                {ticketsresponseData.groupdata[1].count}
              </p>
            </div>
            <div className="faultTicketsNotification">
              <p className="faultTicketsNotificationTxt">
                {ticketsresponseData.groupdata[2].name}
              </p>
              <p className="faultTicketsNotificationCount">
                {ticketsresponseData.groupdata[2].count}
              </p>
            </div>
          </div>
          <div>
            {ticketsresponseData.data.map((obj) => (
              <FaultTicketsCard data={obj} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default FaultTickets;
