/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import sortIcon from "../../assets/image/sort-icon.svg";
import "./index.scss";
import StackedHrBarChart from "../StackedHrBarChart";

const tabs = ["Consumption", "Run Hours"];

const Sortable = ({ collection }) => {
  const [data, setData] = useState(collection);
  const [dataLength, seDataLength] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [assetAsc, setAssetAsc] = useState(true);
  const [utilAsc, setUtilAsc] = useState(true);
  const [savingsAsc, setSavingsAsc] = useState(true);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [yAxisData, setYAxisData] = useState([]);
  const [col1Data, setCol1Data] = useState([]);
  const [col2Data, setCol2Data] = useState([]);
  const [col3Data, setCol3Data] = useState([]);
  useEffect(() => {
    if (collection) {
      let processed = [];
      seDataLength(collection.length);
      let trimmedData = collection.slice(startIndex, startIndex + 5);
      for (let i = 0; i < trimmedData.length; i++) {
        processed.push({
          asset: trimmedData[i].asset,
          utilization: trimmedData[i].utilization,
          savings: trimmedData[i].savings,
          stackData: trimmedData[i].stackData,
        });
      }
      setYAxisData(processed.map((el) => el.asset));
      setCol1Data(processed.map((el) => el.stackData[0]));
      setCol2Data(processed.map((el) => el.stackData[1]));
      setCol3Data(processed.map((el) => el.stackData[2]));
      setData(processed);
    }
  }, [startIndex, collection]);

  const pageNext = () => {
    if (startIndex !== dataLength - 5) {
      setStartIndex(startIndex + 5);
    }
  };

  const pagePrev = () => {
    if (startIndex !== 0) {
      setStartIndex(startIndex - 5);
    }
  };

  const sortAssetComparison = (a, b) => {
    if (assetAsc) {
      if (a.asset < b.asset) return -1;
      if (a.asset > b.asset) return 1;
      return 0;
    } else {
      if (a.asset > b.asset) return -1;
      if (a.asset < b.asset) return 1;
      return 0;
    }
  };

  const sortUtilizationComparison = (a, b) => {
    if (utilAsc) {
      return (
        parseInt(a.utilization.replace(/[A-z]/g, "")) -
        parseInt(b.utilization.replace(/[A-z]/g, ""))
      );
    } else {
      return (
        parseInt(b.utilization.replace(/[A-z]/g, "")) -
        parseInt(a.utilization.replace(/[A-z]/g, ""))
      );
    }
  };

  const sortSavingsComparison = (a, b) => {
    if (savingsAsc) {
      return (
        parseInt(a.savings.replace(/[A-z]/g, "")) -
        parseInt(b.savings.replace(/[A-z]/g, ""))
      );
    } else {
      return (
        parseInt(b.savings.replace(/[A-z]/g, "")) -
        parseInt(a.savings.replace(/[A-z]/g, ""))
      );
    }
  };

  const colClick = (colNo) => {
    switch (colNo) {
      case 1:
        setAssetAsc(!assetAsc);
        data.sort(sortAssetComparison);
        break;
      case 2:
        setUtilAsc(!utilAsc);
        data.sort(sortUtilizationComparison);
        break;
      case 3:
        setSavingsAsc(!savingsAsc);
        data.sort(sortSavingsComparison);
        break;
    }
    setYAxisData(data.map((d) => d.asset));
    setCol1Data(data.map((d) => d.stackData[0]));
    setCol2Data(data.map((d) => d.stackData[1]));
    setCol3Data(data.map((d) => d.stackData[2]));
  };
  function handleValues(val) {
    let checked = val.target.value;
    let hrArr = data.map((d) => d.stackData);
    let arr = [];
    let arrFix = [];
    for (let i = 0; i < hrArr.length; i++) {
      arr.push(hrArr[i].map((el) => hrArr[i].reduce((a, b) => a + b) / el));
      arrFix.push(arr[i].map((el) => el.toFixed(2)));
    }
    if (checked === "percentage") {
      setCol1Data(arrFix.map((d) => d[0]));
      setCol2Data(arrFix.map((d) => d[1]));
      setCol3Data(arrFix.map((d) => d[2]));
    } else {
      setCol1Data(data.map((d) => d.stackData[0]));
      setCol2Data(data.map((d) => d.stackData[1]));
      setCol3Data(data.map((d) => d.stackData[2]));
    }
  }
  return (
    <div className="horizontalData">
      <div className="radio-input-warpper">
        <input
          className="form-check-input"
          type="radio"
          id="values"
          name="show"
          value="values"
          onClick={handleValues}
        />
        <label htmlFor="values">Show absolute values</label>
        <input
          className="form-check-input"
          type="radio"
          id="percentage"
          name="show"
          value="percentage"
          onClick={handleValues}
        />
        <label htmlFor="percentage">Show percentage</label>
      </div>
      <div className="xsScroll-wrapper">
        <table className="table table-responsive">
          <thead>
            <tr>
              <th scope="col">
                <div className="first-th">
                  <h6 onClick={(e) => colClick(1)}>
                    Assets <img src={sortIcon} className="cursPointer" />
                  </h6>
                  <div className="tabTitle">
                    {tabs.map((tab, i) => (
                      <h6
                        className={activeTab === tab ? "active" : ""}
                        key={i}
                        onClick={() => setActiveTab(tab)}
                      >
                        {tab}
                      </h6>
                    ))}
                  </div>
                </div>
              </th>
              <th scope="col">
                <h6 onClick={(e) => colClick(2)}>
                  Utilization <img src={sortIcon} className="cursPointer" />
                </h6>
              </th>
              <th scope="col">
                <h6 onClick={(e) => colClick(3)}>
                  Savings <img src={sortIcon} className="cursPointer" />
                </h6>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <StackedHrBarChart
                  yAxisData={yAxisData}
                  col1Data={col1Data}
                  col2Data={col2Data}
                  col3Data={col3Data}
                  data={["EB", "DG", "Solar"]}
                  legendBottom={0}
                  legendLeft="17%"
                />
              </td>
              <td>
                <ul>
                  {data.map((val, id) => {
                    return <li key={id}>{val.utilization}</li>;
                  })}
                </ul>
              </td>
              <td>
                <ul>
                  {data.map((val, id) => {
                    return <li key={id}>{val.savings}</li>;
                  })}
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="dataPagination"></div>
    </div>
  );
};

export default Sortable;
