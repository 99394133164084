import React from "react";

import "./SortDataComponent.scss";

import IncUnselectedDataSort from "../../assets/icons/IncUnselectedDataSortIcon.svg";
import IncSelectedDataSort from "../../assets/icons/IncSelectedDataSortIcon.svg";
import DecUnSelectedDataSort from "../../assets/icons/DecUnselectedDataSortIcon.svg";
import DecSelectedDataSort from "../../assets/icons/DecSelectedDataSortIcon.svg";


type DataHeadingComponentType = {
  className: string;
  data: string;
};

function SortDataComponent({ selectedDataSort,setSelectedDataSort,heading,categoryId}) {
  return (
    <div className="headingRow">
    <div className="heading">{heading}</div>
    <div className="dataSortIconDiv">
      <img
        src={selectedDataSort?.category === categoryId && selectedDataSort.sort === "inc" ? IncSelectedDataSort : IncUnselectedDataSort}
        onClick={() => {
          setSelectedDataSort({
            category:categoryId,
            sort:'inc'
          })
        }}
        className="sortIcon"
      />

      <img  src={selectedDataSort?.category === categoryId && selectedDataSort.sort === "dec" ? DecSelectedDataSort : DecUnSelectedDataSort}
        onClick={() => {
          setSelectedDataSort({
            category:categoryId,
            sort:'dec'
          })
        }} className="sortIcon" />
    </div>
  </div>
  );
}

export default SortDataComponent;
