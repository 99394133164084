import React from "react";
import "./SummaryCards.scss";
import * as R from "ramda";
import { formatNumberData } from "../../Utils/utils";
function SummaryCards({
  title,
  value,
  abbreviation,
  v_context,
  type,
  average,
  maximumTemperature,
  minimumTemperature,
  averageTemperature
}:any){
  console.log("SummaryCards",type, value);
  return (
    <div className="summaryCards">
      <div className="summaryCards__faDownload">
        <div className="tooltip-content"></div>
      </div>

      <div className="cardTitle">
        {(!R.isEmpty(type) && !R.isNil(type) && type === "TEMP") ||
        type === "KW" ||
        type === "POWER_FACTOR" ? (
          <h4>{!R.isEmpty(title) && title}</h4>
        ) : (
          <h4>{!R.isEmpty(title) && title}</h4>
        )}
      </div>

      <div className="cardValue">
        <h3 className={v_context === "POSITIVE" ? "positive" : "negative"}>
          {!R.isEmpty(value) && !R.isNil(value) ? formatNumberData(value, 2) : "--"}  {!R.isEmpty(abbreviation)  && !R.isNil(abbreviation) && abbreviation !== "null" ? abbreviation : ""}
        </h3>
      </div>

      {/* <div className="rateScore">
        <p>{spend}</p>
      </div> */}
    </div>
  );
}

export default SummaryCards;
