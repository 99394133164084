import React from 'react'
import LoaderStyle from '../LoaderStyle/LoaderStyle';
import "../../a1Pages/dashBoard/dashboardNew.scss";

const LoaderStyleComponent = () => {
  return (
    <div className='loaderStyleClass' > <LoaderStyle /> </div>
  )
}

export default LoaderStyleComponent