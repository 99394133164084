/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/alt-text */
import moment from "moment";
import * as R from "ramda";
import { useState, useEffect } from "react";
import temperatureIcon from "../../assets/image/temperature-icon.svg";
import "./style.scss";

function TempController({
  data,
  abbre,
  minTemp,
  maxTemp,
  dailyMeters,
  energyUtil,
  lastCommTime,
}: any) {
  const [latestTime, setLatestTime] = useState();
  const [compPet, setcompPet] = useState();
  useEffect(() => {
    !R.isEmpty(lastCommTime) &&
      !R.isNil(lastCommTime) &&
      lastCommTime !== null &&
      setLatestTime(lastCommTime);
  }, [lastCommTime]);

  const [supplyTemp1, setSupplyTemp1] = useState();
  const [supplyTemp2, setSupplyTemp2] = useState();
  const [reg, setReg] = useState();
  const [retTemp1, setRetTemp1] = useState();
  const [defrostTime, setDefrostTime] = useState();
  useEffect(() => {
    console.log("data", data);
    let supply1 = data.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "SUPPLY_TEMP_1"
    );
    setSupplyTemp1(supply1);
    let reg = data.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "SET_REG"
    );
    setReg(reg);
    let supply2 = data.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "SUPPLY_TEMP_2"
    );
    setSupplyTemp2(supply2);

    let retTemp = data.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "RET_TEMP_1"
    );
    setRetTemp1(retTemp);

    let defrost = data.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "DEFROST_TIME"
    );
    setDefrostTime(defrost);
  }, [data]);

  console.log("data_", supplyTemp2);

  return (
    <>
      <div className="lastUpdated">
        {!R.isEmpty(latestTime) && !R.isNil(latestTime) ? (
          <h3>Updated {moment(latestTime).fromNow()}</h3>
        ) : (
          <h3>-</h3>
        )}
      </div>
      <div className="assetCard-colum">
        <div className="assetCard-col">
          <p>Supply Temp 1</p>
          {!R.isEmpty(supplyTemp1?.meterValue) &&
          !R.isNil(supplyTemp1?.meterValue) &&
          supplyTemp1?.meterValue !== undefined ? (
            <h2 className="tempControllerH2">
              {supplyTemp1?.meterValue.toFixed(2)}°{supplyTemp1?.readingUomDesc}{" "}
            </h2>
          ) : (
            "--"
          )}

          <h6 className="tempControllerH6">
            <b>Set Point</b>{" "}
            {!R.isEmpty(reg) && !R.isNil(reg) && reg.meterValue?.toFixed(2)}
            <span>
              °{!R.isEmpty(reg) && !R.isNil(reg) && reg.readingUomDesc}
            </span>
          </h6>
        </div>
        {console.log("supplyTemp2",supplyTemp2)}
        <div className="assetCard-col">
          <p>Supply Temp 2</p>
          {!R.isEmpty(supplyTemp2?.meterValue) &&
          !R.isNil(supplyTemp2?.meterValue) &&
          supplyTemp2?.meterValue !== undefined ? (
            <h2 className="tempControllerH2">
              {supplyTemp2?.meterValue.toFixed(2)}°{supplyTemp2?.readingUomDesc}{" "}
            </h2>
          ) : (
            "--"
          )}

          <h6 className="tempControllerH6">
            <b>Set Point</b>{" "}
            {!R.isEmpty(reg) && !R.isNil(reg) && reg.meterValue?.toFixed(2)}
            <span>
              °{!R.isEmpty(reg) && !R.isNil(reg) && reg.readingUomDesc}
            </span>
          </h6>
        </div>
        <div className="assetCard-col">
          <p>Return Air Temp 1</p>
          {!R.isEmpty(retTemp1?.meterValue) &&
          !R.isNil(retTemp1?.meterValue) &&
          retTemp1?.meterValue !== undefined ? (
            <h2 className="tempControllerH2">
              {retTemp1?.meterValue.toFixed(2)}°{retTemp1?.readingUomDesc}{" "}
            </h2>
          ) : (
            "--"
          )}

          <h6 className="tempControllerH6">
            <b>Set Point</b>{" "}
            {!R.isEmpty(reg) && !R.isNil(reg) && reg.meterValue?.toFixed(2)}
            <span>
              °{!R.isEmpty(reg) && !R.isNil(reg) && reg.readingUomDesc}
            </span>
          </h6>
        </div>
        <div className="assetCard-col">
          <p>Time to Defrost</p>
          {!R.isEmpty(defrostTime?.meterValue) &&
          !R.isNil(defrostTime?.meterValue) &&
          defrostTime?.meterValue !== undefined ? (
            <h2 className="tempControllerH2">
              {defrostTime?.meterValue} {defrostTime?.readingUomDesc}{" "}
            </h2>
          ) : (
            "--"
          )}
        </div>
      </div>
    </>
  );
}

export default TempController;
