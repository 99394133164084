import LocalStorageService from "../../../Utils/LocalStorageService";
import { AreaDataType, FilterDataItem, FilterDataObj } from "../types";

export default class EventFilterUtils {
  getFacilityFilterData = () => {
    let localData: any = localStorage.getItem("facilityIds");
    const facilityList = JSON.parse(localData);

    const result: FilterDataItem[] = facilityList.map((element: any) => ({
      id: element.facilityId,
      name: element.facilityName,
      parentId: element.parentFacilityGroupId,
      isSelected: false,
      isView: true,
    }));

    // Sort the result array directly during mapping
    result.sort((a, b) => a.name.localeCompare(b.name));

    return result;
  };

  getFilterDataFromLocalStorage = (localStorageKey: string) => {
    let localData: any = localStorage.getItem(localStorageKey);
    const list = JSON.parse(localData);

    const result: FilterDataItem[] = list.map((element: any) => ({
      id: element.facilityGroupId,
      name: element.facilityGroupName,
      parentId: element.parentFacilityGroupId,
      isSelected: false,
      isView: true,
    }));

    // Sort the result array directly during mapping
    result.sort((a, b) => a.name.localeCompare(b.name));

    return result;
  };

  getAreaFilterData = () => {
    const localData = LocalStorageService.getAreaData();
    const list: AreaDataType[] = localData;

    const result: FilterDataItem[] = list.map((element) => ({
      id: element.locationSeqId,
      name: element.locationName,
      parentId: element.facilityId,
      isSelected: false,
      isView: true,
    }));

    // Sort the result array directly during mapping
    result.sort((a, b) => a.name.localeCompare(b.name));

    return result;
  };

  getFilterData() {
    const regionList = this.getFilterDataFromLocalStorage("regionIds");
    const cityList = this.getFilterDataFromLocalStorage("cityIds");
    const clusterList = this.getFilterDataFromLocalStorage("clusterIds");
    const facilityList = this.getFacilityFilterData();
    const arealist = this.getAreaFilterData();

    let result: FilterDataObj[] = [];
    if (regionList.length !== 0) {
      result.push({
        key: "REGION",
        data: regionList,
        placeholder: "Select Region",
        parent: "",
        searchValue: "",
        isSelected: false,
      });
    }

    if (cityList.length !== 0) {
      result.push({
        key: "CITY",
        data: cityList,
        placeholder: "Select City",
        parent: "REGION",
        searchValue: "",
        isSelected: false,
      });
    }

    if (clusterList.length !== 0) {
      result.push({
        key: "CLUSTER",
        data: clusterList,
        placeholder: "Select Cluster",
        parent: "CITY",
        searchValue: "",
        isSelected: false,
      });
    }

    if (facilityList.length !== 0) {
      result.push({
        key: "FACILITY",
        data: facilityList,
        placeholder: "Select Facility",
        parent: "CLUSTER",
        searchValue: "",
        isSelected: false,
      });
    }

    if (arealist.length !== 0) {
      result.push({
        key: "AREA",
        data: arealist,
        placeholder: "Select Area",
        parent: "FACILITY",
        searchValue: "",
        isSelected: false,
      });
    }

    return result;
  }
}
