import axios from "axios";
import React, { useEffect, useState } from "react";
import "./AnoviBarchart.scss";
import * as R from "ramda";
import StackedHorizontalBar from "../E-Charts/stackedHorizontalBar/StackedHorizontalBar";

function AnoviBarchart({ data }: any) {
  const [firstData, setFirstData] = useState<any>([]);
  const [finalData, setFinaltData] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<any>("");

  const handleOptionChange = async (event: any) => {
    const getValue = (obj: any) => obj.compliancePercent;
    if (
      event === "Top Compliant Assets" &&
      !R.isEmpty(firstData) &&
      !R.isNil(firstData)
    ) {
      const dataSet: any =
        !R.isEmpty(firstData) && !R.isNil(firstData) && firstData;
      const sortedData = await dataSet.sort(
        (a: any, b: any) => getValue(b) - getValue(a)
      );
      const top5 = sortedData.slice(0, 5);
      setFinaltData(top5);
    } else if (
      event === "Bottom Compliant Assets" &&
      !R.isEmpty(firstData) &&
      !R.isNil(firstData)
    ) {
      const dataSet: any =
        !R.isEmpty(firstData) && !R.isNil(firstData) && firstData;
      const sortedData = await dataSet.sort(
        (a: any, b: any) => getValue(a) - getValue(b)
      );
      const top5 = sortedData.slice(0, 5);
      setFinaltData(top5);
    }
    setSelectedOption(event);
  };

  // const fetchApiData = async() =>{
  //   const url =`https://aone-qa.app.aone.ai/api/1.0/assets/assetsComplianceSummary?username=superadmin@si&password=si@superadmin&fromDate=1680373799000&thruDate=1680546599000`
  //     // const url = `https://aone-qa.app.aone.ai/api/1.0/assets/complianceSummary?username=superadmin@si&password=si@superadmin&fromDate=1680373799000&thruDate=1680546599000`;
  //     const resp = await axios.get(url, {
  //       method: "GET",
  //       headers: { "Content-Type": "application/json" },
  //     })

  // }

  useEffect(() => {
    setFirstData(data);
    handleOptionChange("Top Compliant Assets");
  }, [data, firstData]);

  return (
    <div className="bar-chart">
      <div className="labels">
        <label>
          <input
            type="radio"
            value="Top Compliant Assets"
            checked={selectedOption === "Top Compliant Assets"}
            onChange={() => handleOptionChange("Top Compliant Assets")}
          />
          Top Compliant Assets
        </label>

        <label>
          <input
            type="radio"
            value="Bottom Compliant Assets"
            checked={selectedOption === "Bottom Compliant Assets"}
            onChange={() => handleOptionChange("Bottom Compliant Assets")}
          />
          Bottom Compliant Assets
        </label>
      </div>
      {!R.isEmpty(finalData) &&
        !R.isNil(finalData) &&
        finalData.map((item: any, index: any) => (
          <div className="bar" key={index}>
            <div className="title">
              <p>{item.fixedAssetName}</p>
              <p>{item.facilityName}</p>
            </div>
            <div className="bar-wrapper">
              <StackedHorizontalBar percentage={item.compliancePercent} />
              {/* <div
                className="bar-value"
                style={{
                  width: `${item.compliancePercent}%`,
                  backgroundColor:"#6461E3",
                }}
              >
                <span>{item.compliancePercent}%</span>
              </div> */}
            </div>
          </div>
        ))}
    </div>
  );
}

export default AnoviBarchart;