import React from "react";
// import the core library.
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { TitleComponent, TooltipComponent } from "echarts/components";
import { PieChart } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import "./style.scss";

echarts.use([
  TitleComponent,
  TooltipComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
]);

function GaugeChart({ data, rank }) {
  echarts.use([TooltipComponent, PieChart, CanvasRenderer, LabelLayout]);
  var option = {
    height: 200,
    series: [
      {
        type: "gauge",
        center: ["50%", "60%"],
        startAngle: 200,
        endAngle: -20,
        min: 0,
        max: 100,
        splitNumber: 12,
        itemStyle: {
          color:
            parseInt(data.value) > 0 && parseInt(data.value) < 60
              ? "#F44336"
              : parseInt(data.value) > 60 && parseInt(data.value) < 80
              ? "#FFC107"
              : "#4CAF50",
        },
        progress: {
          show: true,
          width: 10,
        },
        pointer: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            width: 10,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        anchor: {
          show: false,
        },
        title: {
          show: false,
        },
        detail: {
          show: false,
        },
        data: [
          {
            value: data.value,
          },
        ],
      },
    ],
  };

  return (
    <div className="gaugechart">
      <ReactEChartsCore
        echarts={echarts}
        option={option}
        notMerge={true}
        lazyUpdate={true}
        theme={"theme_name"}
      />
      <div className="gaugechart-text">
        <h2>
          {data?.value}
          {data?.abbreviation}
        </h2>
        <p>{data?.label}</p>
      </div>
      {rank && (
        <div className="gaugechart-bottom">
          <div className="gaugechart-rank">
            <span>{data?.rank}</span>
            <div>{data?.rankLabel}</div>
          </div>
          <div className="gaugechart-desc">
            <div>0-60</div>
            <div>60-80</div>
            <div>80-100</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default GaugeChart;
