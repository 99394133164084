import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import Modal from "react-bootstrap/Modal";
import logoIcon from "../../assets/icons/Asset-Blue.png";
import closeIcon from "../../assets/icons/IconClose.svg";
import deleteIcon from "../../assets/icons/button-icon-delete.svg";
import editIcon from "../../assets/icons/button-icon-edit.svg";
import AddEditOrganisationModal from "./AddOrEditOrganisation/AddEditOrganisationModal";
import { PartyGroup } from "./Types/PartyGroupResponse";

function OrgDetailsModal({
  valueObject,
  showOrgDetailsModal,
  setOrgDetailsModal,
  setDeleteOrgPopup,
  refreshList,
}: {
  valueObject: PartyGroup;
  showOrgDetailsModal: boolean;
  setOrgDetailsModal: (value: boolean) => void;
  setDeleteOrgPopup: (value: boolean) => void;
  refreshList: () => void;
}) {
  const [addEditModal, setAddEditModal] = useState(false);

  function handleCloseModal() {
    setAddEditModal(false);
  }

  function handleConfirm(inputValue: any) {
    // Add data using inputValue
    handleCloseModal();
  }

  return (
    <Modal
      show={showOrgDetailsModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      style={{ zIndex: 1050 }}
      className="viewOrgModalPop"

      // onHide={setOrgDetailsModal(false)}
    >
      <Modal.Header className="noBorder">
        <div className="orgCardMiddle orgCardHeader">
          <div className="imageCard">
            <img className="logoImage" src={logoIcon} alt="logo" />
          </div>

          <div className="orgMiddleContent">
            <h6 className="orgNameDetails">
              {valueObject.PartyGroupDetails[0].groupName}
            </h6>
            <h6>
              <span className=" partnerDiv">COMPANY</span>
            </h6>
          </div>

          <div
            className="popUpCloseButton"
            onClick={() => {
              setOrgDetailsModal(false);
            }}
          >
            <img src={closeIcon} alt="close" className="closeIcon" />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="viewOrgDetailsBody">
          <div className="viewOrgDetailsParentRow">
            <div className="viewOrgDetailsRow">
              <p className="viewOrgDetailsKey">Name</p>
              <p className="viewOrgDetailsValue">
                {valueObject.PartyGroupDetails[0].groupName}
              </p>
            </div>
            <div className="viewOrgDetailsRow">
              <p className="viewOrgDetailsKey">Email</p>
              <p className="viewOrgDetailsValue">
                {valueObject.emailVM[0]?.contactMech?.infoString}
              </p>
            </div>
          </div>
          <div className="viewOrgDetailsParentRow">
            <div className="viewOrgDetailsRow">
              <p className="viewOrgDetailsKey">Phone Number</p>
              <p className="viewOrgDetailsValue">
                {valueObject.phoneVM[0]?.telecomNumber.countryCode}-
                {valueObject.phoneVM[0]?.telecomNumber.contactNumber}
              </p>
            </div>
            <div className="viewOrgDetailsRow">
              <p className="viewOrgDetailsKey">Address</p>
              <p className="viewOrgDetailsValue">
                {valueObject.addressVM[0]?.postalAddress?.address1}{" "}
                {valueObject.addressVM[0]?.postalAddress?.address2}{" "}
                {valueObject.addressVM[0]?.postalAddress?.city} -{" "}
                {valueObject.addressVM[0]?.postalAddress?.postalCode}{" "}
              </p>
            </div>
          </div>

          <div className="viewOrgDetailsParentRow shrinkMarginTop10">
            <div className="infoHeaderBorder">
              <h6 className="adminInfoHeader marginBottom0px">
                Admin Information
              </h6>
              <div className="dividerLineBlack marginBottom20px adminInfoDivider"></div>
            </div>
          </div>

          <div className="viewOrgDetailsParentRow">
            <div className="viewOrgDetailsRow">
              <p className="viewOrgDetailsKey">Username</p>
              <p className="viewOrgDetailsValue">-</p>
            </div>
            <div className="viewOrgDetailsRow">
              <p className="viewOrgDetailsKey">Email</p>
              <p className="viewOrgDetailsValue">-</p>
            </div>
          </div>
          <div className="viewOrgDetailsParentRow">
            <div className="viewOrgDetailsRow">
              <p className="viewOrgDetailsKey">Phone Number</p>
              <p className="viewOrgDetailsValue">-</p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="noBorder shrinkMarginTop30">
        <button className="editBtnOrg" onClick={() => setAddEditModal(true)}>
          <img src={editIcon} alt="icon" />
          Edit
        </button>

        <Button
          className="orgDeleteButton"
          onClick={() => {
            setDeleteOrgPopup(true);
          }}
        >
          <img src={deleteIcon} alt="icon" />
          Delete
        </Button>
      </Modal.Footer>

      <AddEditOrganisationModal
        type="edit"
        addEditModal={addEditModal}
        editPayloadData={valueObject}
        onConfirm={handleConfirm}
        onClose={handleCloseModal}
        refreshList={refreshList}
      />
    </Modal>
  );
}

export default OrgDetailsModal;
