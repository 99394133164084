/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import "./AssetInfo.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import * as R from "ramda";
import MapIcon from './icon-button-arrow-up-right.svg';

function AssetInfo({ data, faData, assetLocation}:any) {
  const [isActive, setIsActive] = useState(true);
  const [activeDate, setActiveDate] = useState(true);
  const [activeFacility, setActiveFacility] = useState(true);
  const [assetDetails, setAssetDetails] = useState();
  const [addressData, setAddressData] = useState();

  const handleOpenMap = (latitude: number, longitude: number) => {
    if (latitude && longitude) {
      const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
      window.open(googleMapsUrl, '_blank');
    } else {
      console.log("Invalid coordinates for opening the map");
    }
  };

  useEffect(() => {
    // console.log("the assetDetails", data);
    setAssetDetails(data);
    let json = localStorage.getItem("facilityIds");
    const facilityIds = JSON.parse(json);
    facilityIds.map((ele:any, id:any) => {
      if (data?.locatedAtFacility?.facilityId === ele?.facilityId) {
        return setAddressData(ele);
      }
    });
  }, [data]);

  return !R.isEmpty(assetDetails) ? (
    <div className="assetInfoDetails">
      <div className="accordion-item">
        <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
          <div>
            <FontAwesomeIcon icon={isActive ? faAngleUp : faAngleDown} />
          </div>
          <div className="asset-table-name">Asset Details</div>
        </div>
        {isActive && (
          <div className="accordion-content infoContent">
            <div>
              <table>
                <tr>
                  <td className="Asset-Name">Asset Id:</td>
                  <td className="Asset-Details">{assetDetails?.assetId}</td>
                </tr>
                <tr>
                  <td className="Asset-Name">Asset Name:</td>
                  <td className="Asset-Details">{assetDetails?.assetName}</td>
                </tr>
                <tr>
                  <td className="Asset-Name">Device ID:</td>
                  <td className="Asset-Details">
                    {assetDetails?.fixedAssetAssocWithName}
                  </td>
                </tr>
                <tr>
                  <td className="Asset-Name">Asset Age:</td>
                  <td className="Asset-Details"></td>
                </tr>
                <tr>
                  <td className="Asset-Name">Make & Model:</td>
                  <td className="Asset-Details">
                    {assetDetails?.productBrand} {assetDetails?.productModel}
                  </td>
                </tr>
                <tr>
                  <td className="Asset-Name">Battery Level:</td>
                  {/* <td className="Asset-Details">{assetDetails?.meters[3]?.meterValue}{assetDetails?.meters[3]?.readingUomDesc}</td> */}
                  <div className="battery-percentage">
                    {/* { assetDetails?.meters[0]?.meterValue > 80 ? 
                  <> <img src={fullBattery} className="battery-img" /><span> </span> 
                  </>
                  : 
                  assetDetails?.meters[0]?.meterValue > 60 ? 
                  <><img src={sixtyPercent} className="battery-img" /><span> </span></>
                  :
                  assetDetails?.meters[0]?.meterValue > 40 ?
                  <><img src={fourtyPercent} className="battery-img" /><span> </span></>  
                  :
                  // <> <img src={twentyPercent} className="battery-img" /><span> {assetDetails?.meters[0]?.meterValue}% </span> </> 
                   <><img src={twentyPercent} className="battery-img" /><span>  </span> </> 
                  } */}
                    {/* <div className="battery-chip">
                     <p className="chip-content">Running on battery</p> 
                  </div> */}
                  </div>
                </tr>
              </table>
            </div>
          </div>
        )}
      </div>
      <div className="accordion-item">
        <div
          className="accordion-title"
          onClick={() => setActiveDate(!activeDate)}
        >
          <div>
            <FontAwesomeIcon icon={activeDate ? faAngleUp : faAngleDown} />
          </div>
          <div className="asset-table-name">Date</div>
        </div>
        {activeDate && (
          <div className="accordion-content infoContent">
            <div>
              <table>
                <tr>
                  <td className="Asset-Name">Date of purchase:</td>
                  <td className="Asset-Details">
                    {assetDetails?.purchaseDate}
                  </td>
                </tr>
                <tr>
                  <td className="Asset-Name">Activated Date:</td>
                  <td className="Asset-Details">
                    {assetDetails?.activatedDate}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        )}
      </div>
      <div className="accordion-item">
        <div
          className="accordion-title"
          onClick={() => setActiveFacility(!activeFacility)}
        >
          <div>
            <FontAwesomeIcon icon={activeFacility ? faAngleUp : faAngleDown} />
          </div>
          <div className="asset-table-name">Facility Details</div>
        </div>
        {activeFacility && (
          <div className="accordion-content infoContent">
            <div>
              <table>
                <tr className="">
                  <td className="Asset-Name">Located at Facility:</td>
                  <td className="Asset-Details">
                    {assetDetails?.locatedAtFacility?.facilityName}
                  </td>
                </tr>
                <tr>
                  <td className="Asset-Name">Address:</td>
                  <td className="Asset-Details">
                    {addressData?.address?.address1}
                    {/* {assetDetails?.locatedAtFacility?.address?.address1}
                    {assetDetails?.locatedAtFacility?.address?.city}
                    {assetDetails?.locatedAtFacility?.address?.postalCode} */}
                    {
                      assetLocation?.latitude === null && assetLocation?.longitude === null ? (
                          <p>-</p>
                      ): (
                          <div onClick={()=> handleOpenMap(assetLocation?.latitude, assetLocation?.longitude )} className="viewInMapMainDiv"><p className="viewInMapMainText">View in Maps</p><img
                              src={MapIcon}/></div>
                      )
                    }

                    {/*<a*/}
                    {/*    target="_blank"*/}
                    {/*    href={`https://maps.google.com/?q=${addressData?.facilityGeoPoint?.latitude},${addressData?.facilityGeoPoint?.longitude}`}*/}
                    {/*>*/}
                    {/*  <FontAwesomeIcon icon={faLocationDot} />*/}
                    {/*</a>*/}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="assetInfoErroMsg">
      <h2>No data available to display. </h2>
    </div>
  );
}

export default AssetInfo;
