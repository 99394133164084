import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./DownloadEvent.scss";
import DateTimeRangeInputComponent from "../../../a1Components/ModelPopupcomp/DateTimeRangeInputComponent/DateTimeRangeInputComponent";
import moment from "moment";
import { useStateValue } from "../../../redux/StateProvider";

import DownloadIcon from "../assets/download_icon.svg";
import CrossIcon from "../assets/ic_cross_icon.svg";
import EventListState from "../state/EventListState";
import { FileFormat } from "../../../Utils/enumData";
import { createDownloadUrl } from "../utils";

type PropTypes = {
  show: boolean;
  onHide: () => void;
};

function DownloadEvent({ show, onHide }: PropTypes) {
  const [{ dateRange }] = useStateValue();
  const fileFormat = [FileFormat.Excel, FileFormat.PDF];
  const { selectedFileFormat, setSelectedFileFormat, duration, setDuration } =
    EventListState();

  let fromDateStr = moment(dateRange[0]).startOf("day").toISOString();
  let toDateStr = moment(dateRange[1]).endOf("day").toISOString();

  const convertIntoDateObject = (dateString: any, state: any) => {
    // Convert the string to a Date object
    if (dateString === undefined) {
      let selectedDate = state
        ? new Date(moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"))
        : new Date(moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"));
      selectedDate.setHours(selectedDate.getHours() + 5);
      selectedDate.setMinutes(selectedDate.getMinutes() + 30);
      return new Date(selectedDate);
    } else {
      const selectedDate = new Date(dateString);
      selectedDate.setHours(selectedDate.getHours() + 5);
      selectedDate.setMinutes(selectedDate.getMinutes() + 30);
      return new Date(selectedDate);
    }
  };

  const [dateTimeRangeValue, setDateTimeRangeValue] = useState<any>({
    startDate: convertIntoDateObject(dateRange[0], true),
    endDate: convertIntoDateObject(dateRange[1], false),
  });

  const [dateRangeValue, setDateRangeValue] = useState<any>({
    startDate:
      dateRange[0] === undefined
        ? moment().startOf("day").valueOf()
        : moment(fromDateStr).valueOf(),
    endDate:
      dateRange[1] === undefined
        ? moment().endOf("day").valueOf()
        : moment(toDateStr).valueOf(),
  });

  const updateDateTimeRanges = (key: string, value: any) => {
    if (key === "startDate") {
      setDateRangeValue(() => ({
        startDate: value,
        endDate: "",
      }));
      setDateTimeRangeValue((prev: any) => ({
        ...prev,
        endDate: "",
      }));
    } else {
      setDateRangeValue((prev: any) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const checkDownloadButtonDisable = () => {
    return dateRangeValue.startDate === "" || dateRangeValue.endDate === "";
  };

  useEffect(() => {
    const startDate = moment(dateRange[0]);
    const endDate = moment(dateRange[1]);
    const duration = endDate.diff(startDate, "days") + 1;
    setDuration(duration);
  }, [dateRange]);

  const downloadEventLog = () => {
    if (duration > 31 || duration <= 0) {
      return;
    }
    let startDate = moment(dateRangeValue.startDate).valueOf();
    let endDate = moment(dateRangeValue.endDate).valueOf();
    const url = createDownloadUrl(selectedFileFormat, startDate, endDate);
    window.open(url, "_blank");
  };

  return (
<div >
    <Modal
      // size="sm"
      show={show}
      onHide={onHide}
      centered
      backdrop="static"
      className="downloadEventPopUp"
      // style={{width:'70%', minWidth: '0vw'}}
    >
    <div>
      <Modal.Header className="downloadEventPopUpHeader">
        <div className="downloadEventHeaderMainDiv">
          <p className="eventHeaderTitle">Download Event Log</p>
          <div className="closeIconDiv" onClick={onHide}>
            <img className="closeIcon" src={CrossIcon} />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="downloadEventPopUpBody">
        <div className="downloadEventBodyMainDiv">
          <div className="eventDateTimeRangePickerDiv">
            <p className="dateTimeRangePickerTitle">Data for the period</p>
            <DateTimeRangeInputComponent
              dateRangeValue={dateTimeRangeValue}
              callBackDateRange={updateDateTimeRanges}
            />
            <p
              className="dateRangeWarningText"
              style={{ opacity: duration > 31 || duration <= 0 ? 1 : 0 }}
            >
              {duration <= 0
                ? "Please check date time inputs"
                : "Can't be select more than 31 day's"}
            </p>
          </div>
          <div className="eventFileFormatDiv">
            <p className="eventFormatHeading">File Format</p>
            <div className="fileFormatDiv">
              {fileFormat.map((item, index) => (
                <div className="fileFormatCard" key={index}>
                  <div
                    className="radioButtonOuterDiv"
                    onClick={() => setSelectedFileFormat(item)}
                  >
                    {selectedFileFormat === item && (
                      <div className="radioButtonInnerDiv" />
                    )}
                  </div>
                  <p className="fileFormatText">{item}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="downloadEventPopUpFooter">
        <div className="downloadEventFooterMainDiv">
          <div className="cancelButton" onClick={onHide}>
            <p className="cancelButtonText">Cancel</p>
          </div>
          <button
            className="downloadButtonDiv"
            onClick={downloadEventLog}
            disabled={checkDownloadButtonDisable()}
            style={{
              cursor: checkDownloadButtonDisable() ? "default" : "pointer",
              opacity: checkDownloadButtonDisable() ? 0.3 : 1,
            }}
          >
            <img className="downloadButtonImage" src={DownloadIcon} />
            <p className="downloadButtonText">Download</p>
          </button>
        </div>
        </Modal.Footer>
        </div>
      </Modal>
      </div>
  );
}

export default DownloadEvent;
