import React from "react";
import FaultDetailsCard from "../faultDetailsCard/FaultDetailsCard";
import "./FaultDetails.scss";

function FaultDetails() {
  return (
    <div className="faultDetailsContainer">
      <div className="faultDetailsHeader">
        <p className="faultDetailsAssetId">ASSET ID</p>
        <p className="faultDetailsFaultType">FAULT TYPE</p>
        <p className="faultDetailsCondition">CONDITION</p>
        <p className="faultDetailsLocation">LOCATION</p>
        <p className="faultDetailsRecommendations">RECOMMENDATIONS</p>
      </div>
      <div>
        <FaultDetailsCard />
        <FaultDetailsCard />
        <FaultDetailsCard />
        <FaultDetailsCard />
      </div>
    </div>
  );
}

export default FaultDetails;
