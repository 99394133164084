/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { DashBoardServices } from "../../services/DasboardServices/DashboardService";
import { useNavigate } from "react-router-dom";
import CardWrapper from "../../a1Components/cardWrapper";
import "./dashboardNew.scss";
import * as R from "ramda";
import { useStateValue } from "../../redux/StateProvider";
import useGetPortletByPageId from "../../Hooks/useGetPortletByPageId";
import useGetinfoTip from "../../Hooks/useGetinfoTip";
import useState from "react-usestateref";
import moment from "moment";
import DashFilterUtil from "../../Utils/DashFilterUtil";
import axios from "axios";
import PeakTimeAndFootfall from "../VideoAnalytic-Components/peakTimeAndFootfall/PeakTimeAndFootfall";
import VideoAnalyticTickets from "../VideoAnalytic-Components/videoAnalyticTickets/VideoAnalyticTickets";
import SopViolations from "../VideoAnalytic-Components/sopViolations/SopViolations";
import IncidentSummary from "../VideoAnalytic-Components/incidentSummary/IncidentSummary";
import IncidentLoadMoreCard from "../VideoAnalytic-Components/incidentLoadMoreCard/IncidentLoadMoreCard";

function VideoAnalyticsPage(props: any) {
  const { isDark, theme, rightOpen, toggleBtn, themeColors, displayIncident } =
    props;

  const [portlets] = useGetPortletByPageId("AONE_BASIC_DB");
  const [infoTipData] = useGetinfoTip("AONE_BASIC_DB");
  const [routePath, setRoutePath] = useState(window.location.pathname);
  const [loader, setLoader] = useState(true);
  const [jsonData, setJsonData] = useState({});
  // you will get date range data from here dateRange
  const [{ dashboardFilters, dateRange, dateChip }, dispatch] = useStateValue();
  const navigate = useNavigate();

  /* Local Dashboard Filter */
  const [dashFilterLoc, setDashFilterLoc, dashFilterLocRef] = useState({
    cardname: "",
    filters: [],
  });

  // const [onloadCount, setOnloadCount] = useState(0);

  // const [defData, setDefData] = useState({
  //   defective_cylinders: { distributors: [], defective_count: 0 },
  //   defective_cylinders_damageType: {},
  // });
  // const [defectCount, setfetchEmptyData, defectCountRef] = useState(0);

  const [chartData, setChartData] = useState({
    dates: [],
    counts: [],
    sum_of_counts: 0,
  });

  ///
  const [disChartData, setDisChartData] = useState({
    dates: [],
    counts: [],
    sum_of_counts: 0,
  });

  const redirectToLoginPage = () => {
    // localStorage.removeItem("aoneLoginData");
    // localStorage.setItem("loginState", "false");
    // window.location.pathname = "/";
    // window.location.href="/";
  };

  const startTimestampCheck = () => {
    // const timestamp: any = localStorage.getItem("timestamp");
    // setInterval(() => {
    //   const currentTime = new Date().getTime();
    //   console.log("currentTime", currentTime, parseInt(timestamp));
    //   if (currentTime >= parseInt(timestamp)) {
    //     redirectToLoginPage();
    //   }
    // }, 60*1000);
  };

  window.addEventListener("beforeunload", () => {
    const timestamp: any = localStorage.getItem("timestamp");
    const currentTime = new Date().getTime();
    if(timestamp === null){
      redirectToLoginPage();
    }
    else{
      if (currentTime >= parseInt(timestamp)) {
        redirectToLoginPage();
      } else {
        startTimestampCheck();
      }
    }
  });

  window.addEventListener("load", () => {
    const timestamp: any = localStorage.getItem("timestamp");
    const currentTime = new Date().getTime();
    if(timestamp === null){
      redirectToLoginPage();
    }
    else{
      if (currentTime >= parseInt(timestamp)) {
        redirectToLoginPage();
      } else {
        startTimestampCheck();
      }
    }
  });

  // Check token expiration on page load
  window.addEventListener("unload", () => {
    const timestamp: any = localStorage.getItem("timestamp");
    const currentTime = new Date().getTime();
    if(timestamp === null){
      redirectToLoginPage();
    }
    else{
      if (currentTime >= parseInt(timestamp)) {
        redirectToLoginPage();
      } else {
        startTimestampCheck();
      }
    }
  });
  const leftCol = document.getElementById("leftCol");
  // const infotipFunction = (val: string) => {
  //   let retData;
  //   let datatoFilter = infoTipData;

  //   // !R.isEmpty(datatoFilter) &&
  //   //   !R.isNil(datatoFilter) &&
  //   //   datatoFilter?.portlets.map(
  //   //     (ele: { portalPortletId: any; description: any }) => {
  //   //       if (ele.portalPortletId === val) {
  //   //         retData = ele.description;
  //   //       }
  //   //     }
  //   //   );

  //   return retData;
  // };

  // // const domainName = "iocldata.atsuyatech.com:8050";
  // const domainName = "us-central1-cylinderdetection.cloudfunctions.net"

  // const [loadSummaryDataArr, setLoadSummaryDataArr] = useState([]);

  // async function loadSummaryData(fromDateStr: string, toDateStr: string) {
  //   // const fetchData = new DashBoardServices();
  //   // const data = await fetchData.loadSummary(fromDateStr, toDateStr);
  //   // return data;
  // }

  // Api calls
  // useEffect(() => {
  //   if (dateRange.length !== 0) {
  //     const d1 = new Date(dateRange[0]);
  //     const startDate = moment(d1).format("YYYY-MM-DD");

  //     const d2 = new Date(dateRange[1]);
  //     const endDate = moment(d2).format("YYYY-MM-DD");
  //     setLoader(true);
  //     // loadSummaryData(startDate, endDate).then((summaryData: any) => {
  //     //   console.log("loadAPI pinged");
  //     //   if (summaryData !== "err") {
  //     //     setLoadSummaryDataArr(summaryData?.load_details);
  //     //   } else {
  //     //     setLoadSummaryDataArr([]);
  //     //   }

  //     //   setLoader(false);
  //     // });
  //   }
  // }, [dateRange]);

  return (
    <>
      <div
        className={`${
          leftCol?.childElementCount === 0
            ? "row-reverse smb-100"
            : "row smb-100"
        }`}
      >
        <CardWrapper
          headerLabel="Incident Summary"
          footerLabel=""
          footerLink={""}
          infoTip={""}
          themeColors={{}}
          typeTwo={false}
        >
          <IncidentSummary displayInc={displayIncident} dateRange={dateRange} />
        </CardWrapper>

        {/* <CardWrapper headerLabel="Incident Card" footerLabel="">
          <IncidentLoadMoreCard />
        </CardWrapper> */}

        <div
          id="leftCol"
          className={
            !rightOpen
              ? "col-xl-6 col-12 col-12 col-lg-6 col-md-12"
              : toggleBtn
              ? "col-12"
              : "col-8"
          }
        >
          <CardWrapper
            headerLabel="Average Customer Wait Time"
            footerLabel=""
            footerLink={""}
            infoTip={""}
            themeColors={{}}
            typeTwo={false}
          >
            <PeakTimeAndFootfall dateRange={dateRange} />
          </CardWrapper>

          <CardWrapper
            headerLabel="SOP Violations"
            footerLabel=""
            footerLink={""}
            infoTip={""}
            themeColors={{}}
            typeTwo={false}
          >
            <SopViolations dateRange={dateRange} />
          </CardWrapper>
        </div>

        <div
          id="leftCol"
          className={
            !rightOpen
              ? "col-xl-6 col-12 col-12 col-lg-6 col-md-12"
              : toggleBtn
              ? "col-12"
              : "col-8"
          }
        >
          <CardWrapper
            headerLabel="Tickets"
            footerLabel=""
            footerLink={""}
            infoTip={""}
            themeColors={{}}
            typeTwo={false}
          >
            <VideoAnalyticTickets dateRange={dateRange} />
          </CardWrapper>
        </div>
      </div>
    </>
  );
}

export default VideoAnalyticsPage;
