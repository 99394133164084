/* eslint-disable no-const-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import IconClose from "../../../assets/icons/IconClose.svg";
import IconWarning from "../../../assets/icons/IconWarning.svg";
import RelocateIcon from "../../../assets/icons/RelocateIcon.svg";

import * as R from "ramda";
import Lottie from "react-lottie";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FieldValidatorUtil } from "../../../Utils/fieldValidation/FieldValidatorUtil";
import DisplayError from "../../../a1Components/DisplayError/DisplayError";
import LoaderStyle from "../../../a1Components/LoaderStyle/LoaderStyle";
import { useStateValue } from "../../../redux/StateProvider";
import { AssetServices } from "../../../services/AssetServices/AssetServices";
import { SettingsApiServices } from "../../../services/settingsPageServices/SettingsApiServices";
import FilterChipsLayout from "../../Filter/FilterChipsLayout";
import "./AssetSettingsCardContainer.scss";
import { AssetType } from "./AssetTypeData";
import AssetSettingsCard from "./assetSettingsCard/AssetSettingsCard";
import AssetSettingsCreateEditModal from "./assetSettingsCard/AssetSettingsCreateEditModal";
import noAssetLoader from "./no-asset.json";
import someThingWentWrongLoader from "./something-went-wrong.json";
import loader from "../../../a1Components/lotties/round-loader-white.json";


type AssetSettingsCardContainerType = {
  assetCreate: boolean;
  handleAssetCreate: () => void;
};

function AssetSettingsCardContainer({
  assetCreate,
  handleAssetCreate,
  callCreateFacilityFunction,
  searchString,
  initSearch,
  searchKey,
  setClearFilters
}: AssetSettingsCardContainerType) {
  const [{ settingsFilterPreferences }, dispatch] = useStateValue();
  const [assetsCardList, setAssetsCardList] = useState<AssetType[] | null>(
    null
  );
  const [statusData, setStatusData] = useState([]);
  const [selectedAssetStatus, setSelectedAssetStatus] = useState("");
  const [assetListApiError, setAssetListApiError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [viewSize, setViewSize] = useState(100);
  const [showBottomLoading, setShowBottomLoading] = useState(true);

  const [facilityList, setFacilityList] = useState([]);
  const [facilitySelectedForRelocate, setFacilitySelectedForRelocate] =
    useState("");
  const [invalidArr, setInvalidArr] = useState<string[]>([]);
  const [assetStatusHashMap, setAssetStatusHashMap] = useState<null | Map<
    string,
    string
  >>(null);
  const [staticsApiErrorStatus, setStaticsApiErrorStatus] = useState(false);
  const [countryListData, setCountryListData] = useState([]);

  const setAssetDetailEmpty = () => {
    setAssetsCardList([]);
    setCurrentPage(0);
  };

  const [selectedLsit, setSelectedList] = useState<AssetType[]>([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const addToSelectedList = (facilityName: any) => {
    if (!selectedLsit.includes(facilityName)) {
      let val = selectedLsit;
      val.push(facilityName);
      setSelectedList(val);
      setSelectedCount(selectedCount + 1);
    } else {
      let removedArr = selectedLsit.filter(
        (element) => element !== facilityName
      );
      setSelectedList(removedArr);
      setSelectedCount(selectedCount - 1);
    }
  };
  const selectAll = () => {
    let count = assetsCardList.length;
    setSelectedCount(count);
    setSelectedList(assetsCardList);
  };
  const deselectAll = () => {
    setSelectedCount(0);
    setSelectedList([]);
  };

  const handleAssetStatusChanged = (event: any) => {
    setSelectedAssetStatus(event);
  };

  const [totalSize, setTotalSize] = useState(0);

  function handleAssetListResponse(data) {
    if (data.responseMessage === "error") {
      setIsLoading(false);
      setAssetListApiError(true);
      setShowLoadMoreButton(false);
      dispatch({
        type: "SET_SETTINGS_FILTER_PAGINATION_DETAILS",
        settingsPaginationCount: "",
      });
    } else {
      const newList = data?.assets;
      if (newList.length < viewSize || newList.length == 0) {
        setShowLoadMoreButton(false);
        setShowBottomLoading(false);
      } else {
        setShowLoadMoreButton(true);
        setShowBottomLoading(true);
      }
      setIsLoading(false);
      setAssetListApiError(false);
      setTotalSize(data?.totalSize);
      setAssetsCardList((prevList) => {
        if (prevList && Array.isArray(prevList)) {
          // Ensure prevList is an array and not undefined
          return [...prevList, ...newList];
        } else {
          return newList;
        }
      });
    }
  }

  useEffect(() => {
    console.log(initSearch);
    if (initSearch == 0) {
      return;
    }

    if (searchString && searchString != "") {
      setCurrentPage(0);
      setAssetsCardList(undefined);
      getData(searchString, searchKey)
        .then((result) => {
          console.log("Data fetched successfully:", JSON.stringify(result));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else if (searchString == undefined) {
      setCurrentPage(0);
      setAssetsCardList(undefined);
      getData();
    }
  }, [initSearch, settingsFilterPreferences]);

  const getData = async (searchString, searchKey) => {
    try {
      setIsLoading(currentPage === 0);
      setAssetListApiError(false);
      setShowLoadMoreButton(false);
      deselectAll();
      let tokenId = localStorage.getItem("@tokenID");
      const services = new AssetServices();
      const data = await services.assetsList(
        tokenId,
        currentPage,
        viewSize,
        searchString,
        searchKey,
        settingsFilterPreferences
      );
      console.log("dataaa", data);
      handleAssetListResponse(data);
    } catch (error) {
      console.log(`connn error`);
      setIsLoading(false);
      setAssetListApiError(true);
      setShowLoadMoreButton(false);
      console.error("AssetSettingCard:", error);
    }
  };

  useEffect(() => {
    try {
      if (assetsCardList != undefined && assetsCardList.length > 0) {
        dispatch({
          type: "SET_SETTINGS_FILTER_PAGINATION_DETAILS",
          settingsPaginationCount: `Viewing ${assetsCardList?.length}/${totalSize} assets`,
        });
      } else {
        dispatch({
          type: "SET_SETTINGS_FILTER_PAGINATION_DETAILS",
          settingsPaginationCount: "",
        });
      }
    } catch (e) {}
  }, [assetsCardList, totalSize]);

  const loadMoreAssetData = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // const setAssetStatusData = async () => {
  //   try {
  //     setStaticsApiErrorStatus(false);
  //     let tokenId = localStorage.getItem("@tokenID");
  //     console.log("Asset:Token:", tokenId);
  //     const services = new SettingsApiServices();
  //     const response = await services.FacilityStatistics(tokenId);
  //     console.log("response:line42:", response);
  //     if (
  //       !R.isEmpty(response) &&
  //       !(
  //         response.statusCode == "BAD_REQUEST" ||
  //         response.responseMessage == "error"
  //       )
  //     ) {
  //       if (response.statusTypes.FXD_ASSET_STATUS !== undefined) {
  //         setStaticsApiErrorStatus(true);
  //         const data = response.statusTypes.FXD_ASSET_STATUS;
  //         const result = [];
  //         data.forEach((element: any) => {
  //           result.push({
  //             value: element.statusCode,
  //             label: element.description,
  //           });
  //         });
  //         const statusHashMap = new Map<string, string>();
  //         result.forEach((element: any) => {
  //           statusHashMap.set(element.value, element.label);
  //         });
  //         console.log("asset:", statusHashMap);
  //         setAssetStatusHashMap(statusHashMap);
  //         setStatusData(result);
  //         const countriesList = response?.countries;
  //         setCountryListData(countriesList);
  //       }
  //     }
  //   } catch (error) {
  //     setStaticsApiErrorStatus(false);
  //     console.error("Asset:staticApi:", error);
  //   }
  // };

  const handleBulkStatusUpdate = () => {
    let tokenId = localStorage.getItem("@tokenID");

    let selectedAssetIdList = [];
    selectedLsit.forEach((element) => {
      selectedAssetIdList.push(element.fixedAssetId);
    });
    const body = {
      fixedAssetIds: selectedAssetIdList,
      statusId: selectedAssetStatus.value,
    };

    const services = new SettingsApiServices();
    const response = services.updateAssetBulkStatus(tokenId, body);

    response
      .then((resp) => {
        console.log("Asset:Line63:", resp);
        showSuccessToast("Asset status changed.");
        setAssetDetailEmpty();
        setShowBulkChangeStatus(false);
        deselectAll();
        getData();
      })
      .catch((error) => {
        console.log("Asset:Line65:", error);
        showErrorToast("Request failed.");
      });
  };

  const setStaticData = (response: any) => {
    if (
      !R.isEmpty(response) &&
      !(
        response.statusCode == "BAD_REQUEST" ||
        response.responseMessage == "error"
      )
    ) {
      if (response.statusTypes.FXD_ASSET_STATUS !== undefined) {
        setStaticsApiErrorStatus(true);
        const data = response.statusTypes.FXD_ASSET_STATUS;
        const result = [];
        data.forEach((element: any) => {
          result.push({
            value: element.statusCode,
            label: element.description,
          });
        });
        const statusHashMap = new Map<string, string>();
        result.forEach((element: any) => {
          statusHashMap.set(element.value, element.label);
        });
        console.log("setAssetStatusHashMap ", statusHashMap);
        setAssetStatusHashMap(statusHashMap);
        setStatusData(result);
        const countriesList = response?.countries;
        setCountryListData(countriesList);
      }
    } else {
      setStaticsApiErrorStatus(false);
    }
  };

  const setFacilityData = (response: any) => {
    try {
      const result = [
        {
          value: "",
          label: "",
        },
      ];
      response.forEach((element: any) => {
        result.push({
          value: element.facilityId,
          label: element.facilityName,
        });
      });
      setFacilityList(result);
      setFilteredFacilityList(result);
    } catch (error) {
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let tokenID = localStorage.getItem("@tokenID");
      setStaticsApiErrorStatus(false);
      try {
        const services = new SettingsApiServices();
        const data1 = await services.FacilityStatistics(tokenID);

        // const data1 = await response1.json();

        setStaticData(data1);
        // setFacilityData(facilities);

        try {
          const localData = localStorage.getItem("facilityIds");
          const facilities = JSON.parse(localData);

          const result = facilities.map((element: any) => ({
            value: element.facilityId,
            label: element.facilityName,
          }));

          setFacilityList(result);
          setFilteredFacilityList(result);
        } catch (error) {
          console.error("Error fetching facility data:", error);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (currentPage && currentPage != 0) {
      getData();
    }
  }, [currentPage]);

  const checkName = (name: string, val: string) => {
    const validArr = ["validateFacilityId"];

    if (!validArr.includes(name)) {
      return;
    }

    let functionName =
      name === "validateFacilityId" ? "isValidAssetData" : name;

    let isValid = false;

    const validate = new FieldValidatorUtil();

    isValid = validate.isValidAssetData(val);

    setInvalidArr((prevInvalidArr) => {
      if (isValid) {
        return prevInvalidArr.filter((obj) => obj !== name);
      } else {
        if (!invalidArr.includes(name)) {
          return [...prevInvalidArr, name];
        } else {
          return [...prevInvalidArr];
        }
      }
    });
  };

  //Bulk Attributes Popup
  const [showSpecificAttributes, setShowSpecificAttributes] = useState(false);

  //Bulk Delete Popup
  const [showBulkDelete, setShowBulkDelete] = useState(false);

  //Bulk Relocate Popup
  const [showBulkRelocate, setShowBulkRelocate] = useState(false);
  const [enableBulkRelocateBtn, setEnableBulkRelocateBtn] = useState(true);
  const [toastEnabled, setToastEnabled] = useState(true);
  const [selectedFacility, setSelectedFacility] = useState(true);
  const [loading, setLoading] = useState(false);

  const numberOfClicks = useRef(0);

  const collapseToast =() =>{
    setToastEnabled(true);
    setLoading(false);
    numberOfClicks.current = 0
  };

  const toastOpen = () =>{
    setToastEnabled(false);
    setLoading(false);
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
  };

  const bulkRelocate = async () => {

    if(numberOfClicks.current !== 1){
      return null;
    }
    
    if (typedValue.id == "") {
      setLoading(true);
      toast.error("Please Select a Facility",{
        onOpen: () => toastOpen(),
        onClose: () =>  collapseToast()
      });
      return;
    }
    setLoading(false)
    setEnableBulkRelocateBtn(false);
    const token = localStorage.getItem("@tokenID");
    setLoading(true);
    toast.dismiss(); 

    const body = {
      fixedAssetIds: selectedLsit.map((element) => element.fixedAssetId),
      relocateTo: typedValue.id,
    };
    const service = new SettingsApiServices();
    const bulkRelocateResp = await service.bulkRelocateAsset(token, body);

    console.log({ bulkRelocateResp });
    if (bulkRelocateResp.status == 200) {
      setEnableBulkRelocateBtn(true);
      toast.success("Assets relocate to new facility.",{
        onOpen: () => setToastEnabled(false),
        onClose: () =>  collapseToast()
      });
      setAssetDetailEmpty();
      setShowBulkRelocate(false);
      deselectAll();
      getData();
      return;
    }
    setEnableBulkRelocateBtn(true);
    setLoading(false);
    toast.error("Request failed.",{
      onOpen: () => setToastEnabled(false),
      onClose: () =>  collapseToast()
    });
  };

  const showSuccessToast = (message: string) => {
    toast.success(message);
  };

  const showErrorToast = (message: string) => {
    toast.error(message);
  };

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: noAssetLoader,
  };

  const someThingWentWrongOption = {
    loop: true,
    autoplay: true,
    animationData: someThingWentWrongLoader,
  };

  const handleFacilityChange = (event: any) => {
    setFacilitySelectedForRelocate(event);
  };
  const [typedValue, setTypedValue] = useState({
    id: "",
    value: "",
  });
  const [filteredFacilityList, setFilteredFacilityList] =
    useState(facilityList);
  const [showDropDownList, setShowDropDownList] = useState(false);
  // Bulk Change Status
  const [showBulkChangeStatus, setShowBulkChangeStatus] = useState(false);
  return (
    <>
      {assetCreate && (
        <AssetSettingsCreateEditModal
          //  data={emptyData}
          type={"Create"}
          callCreateFacilityFunction={callCreateFacilityFunction}
          closePopUp={handleAssetCreate}
          callAssetListApi={getData}
          setAssetDetailEmpty={setAssetDetailEmpty}
          showSuccessToast={showSuccessToast}
          showErrorToast={showErrorToast}
        />
      )}

      <div className="assetSettingsCardContainer">
        {selectedCount > 0 ? (
          <div className="assetSettingsCardsNav">
            <p>{selectedCount + " "} selected</p>
            <div className="assetSettingsNavBtnsCont">
              <button className="assetsSelectAllBtn" onClick={selectAll}>
                Select all
              </button>
              <button className="assetsDeselectAllBtn" onClick={deselectAll}>
                Deselect all
              </button>
              {/* <button
                className="assetsUpdateAttrributesBtn"
                onClick={() => setShowSpecificAttributes(true)}
              >
                Update Attributes
              </button> */}
              <button
                className="assetsRelocateBtn"
                onClick={() => setShowBulkRelocate(true)}
              >
                <img src={RelocateIcon} alt="icon" />
                Relocate Asset
              </button>
              {/* <button
                className="assetsChangeStatusBtn"
                onClick={() => setShowBulkChangeStatus(true)}
              >
                <img src={ChangeStatusIcon} alt="icon" />
                Change Status
              </button>
              <button
                className="assetsDeleteBtn"
                onClick={() => setShowBulkDelete(true)}
              >
                <img src={IconDelete} alt="icon" />
                Delete
              </button> */}
            </div>
          </div>
        ) : (
          ""
        )}
        <FilterChipsLayout clearFilters={setClearFilters} />

        <div className="assetSettingsCardsCont">
          {isLoading ? (
            <div className="height100_asset displayGrid">
              <LoaderStyle />
            </div>
          ) : assetListApiError ? (
            // || assetStatusHashMap === null
            <Lottie
              isClickToPauseDisabled={false}
              options={someThingWentWrongOption}
            />
          ) : assetsCardList && assetsCardList.length != 0 ? (
            assetsCardList.map((el, index) => (
              <AssetSettingsCard
                callCreateFacilityFunction={callCreateFacilityFunction}
                selected={selectedLsit.includes(el)}
                key={index}
                data={el}
                addToSelectedList={addToSelectedList}
                statusId={el.statusId}
                statusDiscription={assetStatusHashMap?.get(el.statusId)}
                callAssetListApi={getData}
                setAssetDetailEmpty={setAssetDetailEmpty}
                showSuccessToast={showSuccessToast}
                showErrorToast={showErrorToast}
                countryId={el?.facility?.address?.countryGeoId}
                stateId={el?.facility?.address?.stateProvinceGeoId}
                countryList={countryListData}
              />
            ))
          ) : // <Lottie isClickToPauseDisabled={true} options={lottieOptions} />

          searchString || settingsFilterPreferences?.length > 0 ? (
            <div className="assetListSearchEmpty">
              <DisplayError type={"err-empty"} />
            </div>
          ) : (
            <Lottie isClickToPauseDisabled={false} options={lottieOptions} />
          )}

          <div className="assetSettingsLoadBtnCont">
            {showLoadMoreButton && staticsApiErrorStatus ? (
              <button className="loadMoreBtn" onClick={loadMoreAssetData}>
                Load more
              </button>
            ) : (
              currentPage != 0 &&
              showBottomLoading && (
                <button className="loadingBtn">
                  Loading
                  <div className="loaderAnimation"></div>
                </button>
              )
            )}
            {/* <button onClick={notify}>Load</button>
        <ToastContainer position="bottom-left"/> */}
          </div>
        </div>
      </div>

      {/*Update Attributes Specific Popup*/}
      <Modal
        show={showSpecificAttributes}
        onHide={() => setShowSpecificAttributes(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className="specificAttributePopupModal"
      >
        <Modal.Header className="specificAttributePopupHeader">
          <div className="specificAttributeHeaderCont">
            <div className="specificAttributeHeader">
              <h4 className="specificAttributeHeaderTxt">
                Specific asset attributes
              </h4>
              <div
                className="closeIconDiv"
                onClick={() => setShowSpecificAttributes(false)}
              >
                <img src={IconClose} alt="icon" />
              </div>
            </div>
            <div className="headerSelectCont">
              <p className="headerSelectTxt">Setting attributes for</p>
              <select className="specificAttributeHeaderSelect">
                <option></option>
              </select>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="specificAttributePopupBody">
          <div className="specificAttributeBody">
            <div className="inputTextCont">
              <label>Min Temperature</label>
              <input type="text" />
            </div>

            <div className="inputAndSelectCont">
              <label>Max Temperature</label>
              <div className="inputSelect">
                <input type="text" />
                <select>
                  <option>°C</option>
                </select>
              </div>
            </div>

            <div className="selectInputCont">
              <label>Inventory Category</label>
              <select></select>
            </div>

            <div className="inputTextCont">
              <label>Tube Volume</label>
              <input type="text" />
            </div>

            <div className="inputTextCont">
              <label>Tons of Refrigeration</label>
              <input type="text" />
            </div>

            <div className="selectInputCont">
              <label>Phase</label>
              <select></select>
            </div>

            <div className="inputAndSelectCont">
              <label>Rated Power</label>
              <div className="inputSelect">
                <input type="text" />
                <select>
                  <option>kW</option>
                </select>
              </div>
            </div>

            <div className="inputAndSelectCont">
              <label>Rated Current</label>
              <div className="inputSelect">
                <input type="text" />
                <select>
                  <option>A</option>
                </select>
              </div>
            </div>

            <div className="inputAndSelectCont">
              <label>Rated Voltage</label>
              <div className="inputSelect">
                <input type="text" />
                <select>
                  <option>V</option>
                </select>
              </div>
            </div>

            <div className="inputAndSelectCont">
              <label>Rated Frequency</label>
              <div className="inputSelect">
                <input type="text" />
                <select>
                  <option>Hz</option>
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="specificAttributePopupFooter">
          <button
            className="specificAttributeCancelBtn"
            onClick={() => setShowSpecificAttributes(false)}
          >
            Cancel
          </button>
          <button
            className="specificAttributeUpdateBtn"
            onClick={() => setShowSpecificAttributes(false)}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>

      {/*Bulk Delete Popup */}
      <Modal
        show={showBulkDelete}
        onHide={() => setShowBulkDelete(false)}
        centered
        backdrop="static"
        className="bulkDeleteAssetPopupModal"
      >
        <Modal.Header className="bulkDeleteAssetPopHeaderCont">
          <h4 className="bulkDeleteAssetPopHeader">Delete Assets?</h4>
          <div
            className="closeIconDiv"
            onClick={() => setShowBulkDelete(false)}
          >
            <img src={IconClose} alt="icon" />
          </div>
        </Modal.Header>
        <Modal.Body className="bulkDeleteAssetPopBody">
          <div className="bulkDeleteAssetBodyCont">
            <p className="bulkDeleteAssetContentTxt">
              This action will permanently delete selected assets. Do you want
              to proceed?
            </p>
            <div className="bulkDeleteAssetWarningCont">
              <div className="warningIconCont">
                <img src={IconWarning} alt="icon" />
                <p>
                  <b>Warning</b>
                </p>
              </div>
              <p>
                This action will also permanently delete the associations
                between assets.
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="bulkDeleteAssetFooter">
          <button
            className="bulkDeleteAssetCancelBtn"
            onClick={() => setShowBulkDelete(false)}
          >
            Cancel
          </button>
          <button
            className="bulkDeleteAssetDeleteBtn"
            onClick={() => setShowBulkDelete(false)}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>

      {/* Bulk Relocate Popup */}
      <Modal
        show={showBulkRelocate}
        onHide={() => setShowBulkRelocate(false)}
        centered
        className="relocatePopupModal"
        size="md"
      >
        <div>
          <div className="relocateAssetHeaderCont">
            <h4 className="relocateAssetHeader">Relocate Asset</h4>
            <div
              className="closeIconDiv"
              onClick={() => {
                setShowBulkRelocate(false);
                setShowDropDownList(false);
              }}
            >
              <img src={IconClose} className="closeIcon" />
            </div>
          </div>
          <div className="selectorTagContainer">
            <p className="countOfAssets">
              {selectedLsit.length} assets selected
            </p>

            <input
              className="selectSearchInput"
              type="search"
              value={typedValue.value}
              onChange={(e) => {
                setFilteredFacilityList(
                  facilityList.filter((obj) =>
                    obj.label
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                  )
                );
                setTypedValue((obj) => {
                  return { ...obj, value: e.target.value };
                });
              }}
              placeholder="Select Facility"
              onClick={() => {
                setShowDropDownList(!showDropDownList);
                setTypedValue((obj) => {
                  return { ...obj, value: "" };
                });
                setSelectedFacility(false);
              }}
            />

            {showDropDownList && filteredFacilityList.length !== 0 && (
              <ul className="bulkSelectSearchListCont">
                {filteredFacilityList.map((el) => (
                  <li
                    key={el.value}
                    className={
                      el.label === typedValue.value
                        ? "selectSearchList selectedList"
                        : "selectSearchList"
                    }
                    onClick={() => {
                      setTypedValue({ id: el.value, value: el.label });
                      setShowDropDownList(false);
                      setSelectedFacility(true);
                    }}
                  >
                    {el.label}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="relocateAssetFooterCont">
            <button
              className="assetCancelBtn"
              onClick={() => {
                setShowBulkRelocate(false);
                setShowDropDownList(false);
              }}
            >
              Cancel
            </button>
            <button className="assetRelocateBtn" 
            style={{ opacity: selectedFacility && toastEnabled ? 1 : 0.3 }}
            disabled={ !enableBulkRelocateBtn || !toastEnabled || !selectedFacility} 
            onClick={()=>{ numberOfClicks.current = numberOfClicks.current + 1;  bulkRelocate();}}>
              {loading ? <Lottie options={defaultOptions} height="29px"/> : "Relocate" }
            </button>
          </div>
        </div>
      </Modal>

      {/*Bulk Change Status */}
      <Modal
        show={showBulkChangeStatus}
        onHide={() => setShowBulkChangeStatus(false)}
        centered
        backdrop="static"
        className="bulkChangeStatusPopupModal"
        size="sm"
      >
        <Modal.Header className="bulkChangeStatusPopupHeader">
          <h4 className="bulkChangeStatusHeader">Change Status</h4>
          <div
            className="closeIconDiv"
            onClick={() => setShowBulkChangeStatus(false)}
          >
            <img src={IconClose} alt="icon" />
          </div>
        </Modal.Header>
        <Modal.Body className="bulkChangeStatusPopupBody">
          <div className="bulkChangeStatusBodyCont">
            <p className="bulkSelectTxt">{selectedCount} assets selected</p>
            <Select
              options={statusData}
              className="changeStatusSelector"
              value={selectedAssetStatus}
              onChange={handleAssetStatusChanged}
              placeholder="Select Status"
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="bulkChangeStatusPopupFooter">
          <button
            className="bulkChangeStatusCancelBtn"
            onClick={() => setShowBulkChangeStatus(false)}
          >
            Cancel
          </button>
          <button
            className="bulkChangeStatusUpdateBtn"
            onClick={handleBulkStatusUpdate}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>
      {/* <ToastContainer position="top-left" theme="light" /> */}
    </>
  );
}

export default AssetSettingsCardContainer;
