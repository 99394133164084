import React from 'react';

type dataType = {
    tittle:string | number,
    onClickFunction:() => void,
    value:string | number,
    onClickState: boolean
}

const RadioButton = ({tittle,onClickState, onClickFunction, value} : dataType) => {
  return (
    <div className='radioInputContainer'>
        <input disabled={onClickState} onChange={onClickFunction} type='radio' checked={value === tittle}/>
        <p onClick={onClickFunction}>{tittle}</p>
    </div>
  )
}

export default RadioButton