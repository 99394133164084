import './DateTimeInput.scss';
import { InputValueDatType } from '../../DataTypes'
import { Modal } from 'react-bootstrap';
import CalenderPopUpScreen from '../../../ModelPopupcomp/DateTimeRangeInputComponent/CalenderPopUpScreen';
import { useEffect, useState } from 'react';

const DateTimeInput = ({title,maxDate, keyName, minDate,selectedDate, value, handleDateChange}:InputValueDatType) => {
  const [popUpState, setPopUpState] = useState(false);
  const closeCalenderPopUpScreen = () => setPopUpState(false);
  const updateInputValue = (e: any) => handleDateChange(keyName, e);

  return (
    <div className="dateTimeCard">
      <p>{title}</p>
      <Modal show={popUpState} centered backdrop={"static"} className='dateSpecific'>
        <CalenderPopUpScreen keyName={keyName} selectedDate={selectedDate} min={minDate} max={maxDate} updateInput={updateInputValue} closePopUp={closeCalenderPopUpScreen} />
      </Modal>
      <input onClick={() => setPopUpState(true)} placeholder={` Select ${keyName ==="startDate" ? "start date" : "end date" }`} className="current-date" value={value} />
    </div>
  );
}

export default DateTimeInput;