import React from "react";

import "./ImageSelectorParentComponent.scss";
import ImageSelectorComponent from "./ImageSelectorComponent";

type ImageComponentType = {
    image: string | null;
    setImage: (url: string | null) => void;
    heading: string | null;
};

function ImageSelectorParentComponent({
                                          image,
                                          setImage,
                                          heading,
                                      }: ImageComponentType) {
    return (
        <div className="image-container">
            <ImageSelectorComponent image={image} setImage={setImage}/>
            <div>
                <h6 className="image-heading">{heading}</h6>
                <h6 className="image-instructions">Max file size : 2MB. File format : png, jpeg</h6>
                <h6 className="image-instructions">Recommended resolution 300x300
                </h6>
            </div>
        </div>
    );
}

export default ImageSelectorParentComponent;
