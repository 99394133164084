/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, {useEffect, useState} from "react";
import "../../App_one.scss";
import packageJson from "../../../package.json";
import {epochToDateFormRelease} from "../../Utils/utils";

function FooterAone() {
    const [data, setData] = useState();
    const [count, setCount] = useState(0);
    const [showNewText, setShowNewText] = useState(false);
    useEffect(() => {
        incrementCount();
        let state = localStorage.getItem("cachestate");
        setData(state);
    }, []);
    const incrementCount = () => {
        // Update state with incremented value
        setCount(count + 1);
    };

    return (
        <div className="footer-Style">
            <div>
                <p
                    onMouseEnter={() => {
                        setShowNewText(true)
                    }}
                    onMouseLeave={() => {
                        setShowNewText(false)
                    }}
                >
                    {/*{showNewText ?*/}
                    {/*    `Build Time : ${epochToDateFormRelease(packageJson.buildDate)} | Version ${packageJson.version} Beta` :*/}
                    {/*    `Version ${packageJson.version} Beta`*/}
                    {/*}*/}

                    {`Version ${packageJson.version}`}
                </p>

                <p>Powered by AOne</p>
            </div>
        </div>
    );
}

export default FooterAone;
