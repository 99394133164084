/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBorderAll,
  faHouse,
  faBell,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import dashboardicon from "./dashboardicon.svg";
import assetsicon from "./assetsicon.svg";
import motor from "./icon-motor.svg";
import smartTrolley from "./icon-smart-trolley.svg";
import trackTrace from "./icon-trackntrace.svg";
import VideoAnalytic from "../../assets/icons/VideoAnalytic.svg"
import VideoAnalyticsNavIcon from "../../assets/icons/VideoAnalyticsNavIcon.svg"
import settingsicon from "./settings-icon.svg";
import viewReportIcon from '../../assets/icons/viewReportIcon.svg'
import qrGenerationIcon from "./qr-generation.svg";
export const SidebarData = [
  // {
  //   title: "Dashboard",
  //   path: "/",
  //   icon: <img src={dashboardicon} />,
  //   active: false,
  //   id: "AONE_BASIC_DB",
  // },
  {
    title: "Dashboard",
    path: "/",
    icon: <img src={dashboardicon} />,
    active: false,
    id: "DB_VIEW",
  },
  {
    title: "Dashboard",
    path: "/",
    icon: <img src={dashboardicon} />,
    active: false,
    id: "REALESTATE_FM_DB",
  },

  // {
  //   title: "SmartTrolley",
  //   path: "/smartTrolley",
  //   icon:  <img src={smartTrolley} />,
  //   active: false,
  //   id: "BIZ_OWNER_VIEW",
  // },
  {
    title: "cylinderTag",
    path: "/cyltag",
    icon: <img src={trackTrace} />,
    active: false,
    id: "VIDEO_ANALYTICS_VIEW",
  },
  {
    title: "VideoAnalytics",
    path: "/VideoAnalytics",
    icon: <img src={VideoAnalyticsNavIcon} />,
    active: false,
    id: "VIDEO_ANALYTICS_VIEW",
  },
  {
    title: "Condition Monitor",
    path: "/ConditionMonitor",
    icon: <img src={motor} />,
    active: false,
    id: "MCM_VIEW",
  },
  {
    title: "Assets",
    path: "/asset-list",
    icon: <img src={assetsicon} />,
    active: false,
    id: "FIXEDASSET_VIEW",
  },
  {
    title: "QR Generation",
    path: "/qrgeneration",
    icon: <img src={qrGenerationIcon}/>,
    active: true,
    id: "QR_GENERATION_VIEW",
  },
  {
    title: "Settings",
    path: "/settings",
    icon: <img src={settingsicon} />,
    active: true,
    id: "SETTINGS_VIEW",
  },
  {
    title: "Reports",
    path: "/reports",
    icon: <img src={viewReportIcon} />,
    active: true,
    id: "REPORTS_VIEW",
  }
];
