import React, { useEffect } from "react";
import useState from "react-usestateref";
import "./DownloadAnovi.scss";
import { faXmark, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iccalendar from "../../../assets/image/calendar-icon.svg";
import Email_Icon from "../../../assets/icons/email_icon.svg";
import downloadicon from "../../../assets/image/Icon_feather-download.svg";
import * as R from "ramda";
import MultiRangeSlider from "../../UserViewDashComp/MultiRangeSlider/MultiRangeSlider";
import moment from "moment";
import CustomToast from "../CustomToast/CustomToast";
import { useStateValue } from "../../../redux/StateProvider";
import { DashBoardServices } from "../../../services/DasboardServices/DashboardService";
import { ToastContainer, toast } from "react-toastify";
import LoaderStyle from "../../LoaderStyle/LoaderStyle";
import DisplayError from "../../DisplayError/DisplayError";
import loading_lottie from "../../../assets/Lotties/animation_Loading_1.json";
import Lottie from "react-lottie";
import { API_BASE_URL } from "../../../Constants/constants";
import DateTimeRangeInputComponent from "../DateTimeRangeInputComponent/DateTimeRangeInputComponent";

const closeTicketOption = [
  {
    id: 1,
    value: "Asset Temperature Compliance",
  },
  {
    id: 2,
    value: "Specific temperature range",
  },
];
const downloadOption = [
  {
    id: 1,
    value: "Excel",
  },
  // {
  //   id: 1,
  //   value: "CSV",
  // },
];

function DownloadAnovi({
  data,
  show,
  close,
  summaryData,
  assetDataDownload,
  assetType,
}: any) {
  const [openOptionDiv, setOpenOptionDiv] = useState(false);
  const [selectedVal, setSelectedVal] = useState(
    "Asset Temperature Compliance"
  );
  const [downloadSelectedVal, setDownloadSelectedVal] = useState();
  const [{ dateRange, dateChip }, dispatch] = useStateValue();
  const [submitState, setSubmitState] = useState(false);
  const [downloadSubmitState, setDownloadSubmitState] = useState(false);
  const [downloadState, setDownloadState] = useState(false);
  const [summaryDownload, setSummaryDownload, summaryDownloadRef] = useState(
    []
  );
  const [csvData, setCsvData] = useState();
  const [rawData, setRawData] = useState();

  const [filenameUpdate, setFilenameUpdate, fileNameUpdateRef] = useState("");
  const [assetSelectedData, setAssetSelectedData] = useState([]);
  const [reportType, setReportType] = useState("");

  useEffect(() => {
    if (filenameUpdate === "") setFilenameUpdate(summaryData.assetName);
  }, [filenameUpdate]);

  const [showStr, setshowStr] = useState(false);
  // const onChangeRadioOption = (e: any) => {
  //   const userName = localStorage.getItem("userName");
  //   const password = localStorage.getItem("password");
  //   setSubmitState(true);
  //   setSelectedVal(e.target.value);
  //   setDownloadState(true);
  //   if (e.target.value === "Asset Temperature Compliance") {
  //     let link1 = `https://aone.atsuyatech.com/services/control/AssetTemperatureComplianceReport.xlsx?username=${userName}&password=${password}&fromDate=${dateRangeValue.startDate}&thruDate=${dateRangeValue.endDate}`;
  //     setLink1(link1);
  //     // setCsvData(rawData);
  //   } else if (e.target.value === "Specific temperature range") {
  //     setshowStr(true);
  //     let link1 = `https://aone.atsuyatech.com/services/control/UserDefinedTemperatureComplianceReport.xlsx?username=${userName}&password=${password}&fromDate=${dateRangeValue.startDate}&thruDate=${dateRangeValue.endDate}&minVal=0&maxVal=4`;
  //     setLink1(link1);
  //     // setCsvData(summaryDownloadRef.current);
  //   }
  // };

  const onclose = () => {
    close();
    setSelectedVal("Asset Temperature Compliance");
    setOpenOptionDiv(false);
  };

  const submitTicketStatus = () => {
    close();
    setOpenOptionDiv(false);
  };

  const [assetDataSet, setAssetDataSet] = useState();
  useEffect(() => {
    setAssetDataSet(assetDataDownload);
  }, [assetDataDownload]);



  let fromDateStr = moment(dateRange[0]).startOf("day").toISOString();
  let toDateStr = moment().valueOf() <= moment(dateRange[1]).valueOf() ? moment().toISOString() : moment(dateRange[1]).endOf("day").toISOString();
  const [dateRangeValue, setDateRangeValue] = useState<any>({
    startDate: moment(fromDateStr).valueOf(),
    endDate: moment(toDateStr).valueOf(),
  });

  const convertIntoDateObject = (dateString: any) => {
    // Convert the string to a Date object
    const selectedDate = new Date(dateString);
    selectedDate.setHours(selectedDate.getHours() + 5);
    selectedDate.setMinutes(selectedDate.getMinutes() + 30);
    return new Date(selectedDate);
  }


  const [dateTimeRangeValue, setDateTimeRangeValue] = useState<any>({
    startDate: convertIntoDateObject(fromDateStr),
    endDate: convertIntoDateObject(toDateStr),
  });

  const [warningMsgState, setWarningMsgState] = useState(false);

  useEffect(() => {
    const startDate = moment(dateRange[0]);
    const endDate = moment(dateRange[1]);
    const duration = endDate.diff(startDate, "days") + 1;
    setWarningMsgState(31 < duration);
  }, [dateRange]);

  useEffect(() => {
    if (
      !R.isEmpty(assetType) &&
      !R.isNil(assetType) &&
      assetType === "TEMP_ENERGY"
    ) {
      let tempobj;
      data.forEach((ele: any) => {
        let value;
        value = {
          Date: ele?.date,
          Time: ele?.time,
          "Temperature (C)": ele?.temp,
          kW: ele?.kw,
          PF: ele?.pf,
          "Current (A)": ele?.current,
          "Frequency (Hz)": ele?.freq,
        };
        tempobj.push(value);
      });
      if (!R.isEmpty(tempobj) && !R.isNil(tempobj)) {
        setRawData(tempobj);
      }
    } else if (
      !R.isEmpty(assetType) &&
      !R.isNil(assetType) &&
      assetType === "TEMP"
    ) {
      let tempobj;
      data.forEach((ele: any) => {
        let value: any;

        value = {
          Date: ele?.date,
          Time: ele?.time,
          "Temperature (C)": ele?.temp,
        };
        tempobj.push(value);
      });
      if (!R.isEmpty(tempobj) && !R.isNil(tempobj)) {
        setRawData(tempobj);
      }
    } else if (
      !R.isEmpty(assetType) &&
      !R.isNil(assetType) &&
      assetType === "ENERGY"
    ) {
      let tempobj: any = [];
      data.forEach((ele: any) => {
        let value: any;

        value = {
          Date: ele.date,
          Time: ele.time,
          "Total Watt": ele?.tw,
          "Power Factor": ele?.pf,
          "Actual Demand (kVA)": ele?.tva,
        };
        tempobj.push(value);
      });
      if (!R.isEmpty(tempobj) && !R.isNil(tempobj)) {
        setRawData(tempobj);
      }
    } else if (
      !R.isEmpty(assetType) &&
      !R.isNil(assetType) &&
      assetType === "DG"
    ) {
      // console.log("the assetType and data ",assetType,"dddd",data)
      let tempobj;
      data.forEach((ele: any) => {
        let value: any;
        value = {
          Date: ele.date,
          Time: ele.time,
          "Fuel Level (%)": ele?.fuellvlData_Dg,
          "Run hour (Hrs)": ele?.runhr_dg,
          "Total Watt": ele?.tw,
          "Actual Demand (kVA)": ele?.tva,
          "Power Factor": ele?.pf,
        };
        tempobj.push(value);
      });
      if (!R.isEmpty(tempobj) && !R.isNil(tempobj)) {
        setRawData(tempobj);
      }
    }
    // else if (
    //   !R.isEmpty(assetType) &&
    //   !R.isNil(assetType) &&
    //   assetType === "TEMP"
    // ) {
    // } else if (assetType === "ENERGY") {
    // } else if (assetType === "DG") {
    // }
  }, [data]);

  const [closeAlertStatus, setCloseAlertStatus] = useState(false);
  const [toastState, setToastState] = useState(false);

  //Download Report
  const closeAfterDownload = async () => {
    setCloseAlertStatus(true);
    let Db_Service = new DashBoardServices();
    let dataFetch: any;
    if (selectedVal === "Asset Temperature Compliance") {
      dataFetch = await Db_Service.Schedule_Report_API(
        dateRangeValue.startDate,
        dateRangeValue.endDate
      );
    } else {
      dataFetch = await Db_Service.User_Schedule_Report_API(
        dateRangeValue.startDate,
        dateRangeValue.endDate,
        value,
        valueTwo
      );
    }

    let message;
    if (dataFetch === "error") message = "Failed Try Again";
    else {
      switch (dataFetch.status) {
        case 200:
          let data = await dataFetch.json();
          message = await data.responseMessage;
          setTostData(message);
          setShowToast(true);
          setTimeout(() => {
            close();
          }, 4000);
          break;

        case 419:
          let data_412 = await dataFetch.json();
          message = await data_412.errorMessage;
          toast.success(message, {
            position: "bottom-right",
            autoClose: 2000, // Close the toast after 5 seconds
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          break;

        case 204:
          let data_413 = await dataFetch.json();
          message = await data_413.errorMessage;
          toast.success(message, {
            position: "bottom-right",
            autoClose: 2000, // Close the toast after 5 seconds
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          break;

        case 503:
          let data_419 = await dataFetch.json();
          message = await data_419.errorMessage;
          toast.success(message, {
            position: "bottom-right",
            autoClose: 2000, // Close the toast after 5 seconds
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          break;

        case 512:
          let data_420 = await dataFetch.json();
          message = await data_420.errorMessage;
          toast.success(message, {
            position: "bottom-right",
            autoClose: 2000, // Close the toast after 5 seconds
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          break;

        // case 500:
        //   let data_500 = await dataFetch.json();
        //   message = await data_500.errorMessage;
        //   break;

        // case 400:
        //   let data_400 = await dataFetch.json();
        //   message = await data_400.errorMessage;
        //   break;

        default:
          message = "error";
          toast.success(message, {
            position: "bottom-right",
            autoClose: 2000, // Close the toast after 5 seconds
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
      }
    }
    setCloseAlertStatus(false);
    setToastState(true);
    setTimeout(() => {
      setToastState(false);
    }, 5000);
  };

  const [valuez, setValuez] = useState({ min: 0, max: 100 });


  const [degree, setDegree] = useState<any>({ minTemp: -55, maxTemp: 125 });

  const handleChangeInput = (type: any, value: any) => {
    if (type === "maxTemp") {
      if (-55 < parseInt(value) && parseInt(value) < 125) {
        setDegree({
          ...degree,
          maxTemp: value,
        });
      }
    } else {
      if (-55 < parseInt(value) && parseInt(value) < 125) {
        setDegree({
          ...degree,
          minTemp: value,
        });
      }
    }

    // if (
    //   (event.target.name === "minTemp" && event.target.value < -55) ||
    //   (event.target.name === "maxTemp" && event.target.value > 125)
    // ) {
    //   setDegree(degree);
    //   return;
    // }
    // if (
    //   (event.target.name === "minTemp" &&
    //     event.target.value + 2 > degree.maxTemp) ||
    //   (event.target.name === "maxTemp" &&
    //     event.target.value - 2 < degree.minTemp)
    // ) {
    //   setDegree(degree);
    //   return;
    // }
    // let temp = { ...degree };
    // temp[event.target.name] = event.target.value;
    // setDegree(temp);
  };

  const [showToast, setShowToast] = useState(false);
  const [tostData, setTostData] = useState("");

  //get Email Report
  const getdownloadEmailRepo = async () => {
    const token_id: any = localStorage.getItem("@tokenID");
    const url = `${API_BASE_URL}reports/sendPDTemperatureComplianceReportByMail`;
    var errText = "err-500/404";
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          token: token_id,
          fromDate: dateRangeValue.startDate,
          thruDate: dateRangeValue.endDate,
        }),
        headers: { "Content-Type": "application/json" },
      });

      if (response.status === 200) {
        let data = await response.json();
        console.log("respdata___", data);
        return await data;
      } else {
        console.log("respdata___", response);
        return errText;
      }
    } catch {
      console.log("respdata", errText);
      return errText;
    }
  };

  const getdownloadEmailUserDefinedRepo = async () => {
    const token_id: any = localStorage.getItem("@tokenID");
    const url = `${API_BASE_URL}reports/sendUDTemperatureComplianceReportByMail`;
    var errText = "err-500/404";
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          token: token_id,
          fromDate: dateRangeValue.startDate,
          thruDate: dateRangeValue.endDate,
          minVal: parseFloat(degree?.minTemp),
          maxVal: parseFloat(degree?.maxTemp),
        }),
        headers: { "Content-Type": "application/json" },
      });

      if (response.status === 200) {
        let data = await response.json();
        return await data;
      } else {
        return errText;
      }
    } catch {
      console.log("respdata", errText);
      return errText;
    }
  };

  const handleShowToast = async () => {
    setCloseAlertStatus(true);
    if (selectedVal === "Asset Temperature Compliance") {
      const Email = localStorage.getItem("email_check");
      let len = Email?.length;
      if (Email === undefined || Email === null || len === 0) {
        setTostData("error");
        setShowToast(true);
      } else {
        let value = await getdownloadEmailRepo();
        let msg = await value?.responseMessage?.responseMessage;
        setTostData(msg);
        setShowToast(true);
        setTimeout(() => {
          close();
        }, 3000);
      }
      setCloseAlertStatus(false);
    } else if (selectedVal === "Specific temperature range") {
      const Email = localStorage.getItem("email_check");
      let len = Email?.length;

      if (Email === undefined || Email === null || len === 0) {
        setTostData("error");
        setShowToast(true);
      } else {
        let value = await getdownloadEmailUserDefinedRepo();
        let msg = await value?.responseMessage?.responseMessage;
        setTostData(msg);
        setShowToast(true);
      }
      setCloseAlertStatus(false);
    }

    // console.log("the message email is",value)
    // const Email = localStorage.getItem("email_check");
    // len = Email.length;
    // console.log("helloLOcal",Email.length,Email);
    // if(Email === undefined || Email === null || len === 0){

    //   setTostData("error")
    // }
    // else {setTostData("Birt Email initiated successfully")}

    // setShowToast(true);
  };

  const handleCloseToast = () => setShowToast(false);

  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
    }
  }, [showToast]);

  const [value, setValue] = useState<any>("");
  const [valueTwo, setValueTwo] = useState<any>("");

  const [valueWarning, setValueWarning] = useState(false);
  const [valueTwoWarning, setValueTwoWarning] = useState(false);

  const formatNumber = (val: any) => {
    if (/[A-Za-z]/.test(val)) {
      setValueWarning(true);
    } else {
      setValueWarning(false);
      if (val.length >= 3 && val < -55.0) {
        setValue(`${-55}.00`);
        return;
      } else if (val.length >= 3 && val > 125) {
        setValue(`${125}.00`);
        return;
      }
      if (!isNaN(val) && val.includes(".") && val.split(".")[1].length > 2) {
        setValue(`${val.split(".")[0]}.${val.split(".")[1].slice(0, 2)}`);
      } else {
        setValue(val);
      }
    }
  };

  const validateValue = (val: any) => {
    if (val < -54.99) {
      setValue(`${-55}.00`);
    }
    if (val > 125) {
      setValue(`${125}.00`);
    }
  };

  const formatNumberOne = (val: any) => {
    if (/[A-Za-z]/.test(val)) {
      setValueTwoWarning(true);
      console.log(val, "check77");
    } else {
      setValueTwoWarning(false);
      if (val.length >= 3 && val < -54.99) {
        setValueTwo(`${-55}.00`);
        return;
      } else if (val.length >= 3 && val > 125) {
        setValueTwo(`${125}.00`);
        return;
      }
      if (!isNaN(val) && val.includes(".") && val.split(".")[1].length > 2) {
        setValueTwo(`${val.split(".")[0]}.${val.split(".")[1].slice(0, 2)}`);
      } else {
        setValueTwo(val);
      }
    }
  };

  const updateDateTimeRanges = (key: string, value: any) => {
    if (key === "startDate") {
      setDateRangeValue((prev: any) => ({
        [key]: value,
        endDate: ""
      }));
      setDateTimeRangeValue((prev: any) => ({
        ...prev,
        endDate: ""
      }));
    } else {
      setDateRangeValue((prev: any) => ({
        ...prev,
        [key]: value
      }));
    }
  }

  const [btnStatus, setBtnStaus] = useState(false);

  useEffect(() => {
    let checkBtnStatus = dateRangeValue.startDate !== "" && dateRangeValue.endDate !== "";
    setBtnStaus(() => checkBtnStatus);
  }, [dateRangeValue, dateRange])

  return (
    <>
      {showToast ? (
        <div className="pagination_value" style={{ zIndex: "999999" }}>
          <CustomToast message={tostData} onClose={handleCloseToast} />
        </div>
      ) : null}

      <div className="pagination_value" style={{ zIndex: "999999" }}>
        <ToastContainer position="bottom-right" />
      </div>
      <div
        className="cardWrapperOutlay"
        style={{
          transform: show ? "translateY(0vh)" : "translateY(-100vh)",
          opacity: show ? 1 : 0,
          display: toastState || showToast ? "none" : "block",
        }}
      >
        <div className="modal-wrapper-element">
          {closeAlertStatus ? (
            <div className="loaderStyle">
              <LoaderStyle />
            </div>
          ) : (
            <>
              {toastState ? null : (
                <>
                  <div
                    className="modal-header-style"
                    style={{ opacity: toastState ? 0 : 1 }}
                  >
                    <div className="details">
                      <h3>Download Asset Report</h3>
                    </div>
                    <span className="close-modal-btn" onClick={onclose}>
                      ×
                    </span>
                  </div>
                  <div className="modal-bodyD-comp">
                    <div className="leftCnt">
                      <div className="inputBox">
                        <p>
                          {" "}
                          <b>Report Type</b>{" "}
                        </p>
                        {closeTicketOption &&
                          closeTicketOption.map((ele: any, id: any) => {
                            return (
                              <label key={id} className="inputBox-label">
                                <input
                                  type="radio"
                                  id={ele.value}
                                  name="ticketClosing"
                                  checked={selectedVal === ele.value}
                                  value={ele.value}
                                  onChange={() => setSelectedVal(ele.value)}
                                />
                                {ele.value}
                              </label>
                            );
                          })}
                      </div>
                    </div>
                    <div className="rightCnt">
                      <div className="inputBox">
                        <p><b>Data for the period</b></p>
                        <DateTimeRangeInputComponent dateRangeValue={dateTimeRangeValue} callBackDateRange={updateDateTimeRanges} />
                        <p className="warningTextMsg" style={{ opacity: warningMsgState ? 1 : 0 }}>Cannot be greater than 31 days.</p>
                      </div>
                      <div className="typeDownload" style={{ marginTop: "5px" }}>
                        {downloadOption &&
                          downloadOption.map((ele, id) => {
                            return (
                              <label key={id} className="inputBox-label">
                                <input
                                  style={{ marginRight: "10px" }}
                                  type="radio"
                                  name="csv"
                                  checked={true}
                                  value={ele.value}
                                // onChange={onChangeDownloadRadioOption}
                                />
                                {ele.value}
                              </label>
                            );
                          })}
                      </div>
                    </div>
                  </div>

                  {selectedVal === "Specific temperature range" ? (
                    <>
                      <div className="inputStylescss">
                        <div>
                          <h4>Min Temperature</h4>
                          <div
                            className="styleInp"
                            style={{
                              borderColor: valueWarning ? "#ff0303" : "#a7b8f4",
                            }}
                          >
                            <input
                              type="text" // Use type="text" to allow flexible input format
                              id="numberInput"
                              value={value}
                              onChange={(e) => formatNumber(e.target.value)}
                              className="inputTextValue"
                              onBlur={() => setValueWarning(false)}
                              placeholder="Min value -55.00" // Add the placeholder for two decimal places
                            />
                            °C
                          </div>
                        </div>

                        <div>
                          <h4>Max Temperature</h4>
                          <div
                            className="styleInp"
                            style={{
                              borderColor: valueTwoWarning
                                ? "#ff0303"
                                : "#a7b8f4",
                            }}
                          >
                            <input
                              type="text" // Use type="text" to allow flexible input format
                              id="numberInput"
                              value={valueTwo}
                              onChange={(e) => formatNumberOne(e.target.value)}
                              className="inputTextValue"
                              placeholder="Max value 125.00" // Add the placeholder for two decimal places
                              onBlur={() => setValueTwoWarning(false)}
                            />
                            °C
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div className="modal-footer">
                    <div
                      style={{ opacity: warningMsgState || !btnStatus ? 0.4 : 1, cursor: warningMsgState || !btnStatus ? "not-allowed" : "pointer" }}
                      onClick={() => warningMsgState || !btnStatus ? null : handleShowToast} className="email-Report" >
                      <img src={Email_Icon} alt="icon" loading="lazy" />
                      <h1>Email Report</h1>
                    </div>

                    <div className="buttonState">
                      <button type="button" className="btn-Submit btn-dark" onClick={submitTicketStatus} >
                        Cancel
                      </button>
                      <button type="button" className="btn-Action" onClick={() => warningMsgState || !btnStatus ? null : closeAfterDownload()}
                        style={{ opacity: warningMsgState || !btnStatus ? 0.4 : 1, cursor: warningMsgState || !btnStatus ? "not-allowed" : "pointer" }}
                      >  <img src={downloadicon} alt="icon" loading="lazy" /> Download </button>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default DownloadAnovi;