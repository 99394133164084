/* eslint-disable no-const-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck

import { OverlayTrigger } from "react-bootstrap";
import threeVerticalDots from "../../assets/icons/three-dots-vertical.svg";
import logoIcon from "../../assets/icons/Asset-Blue.png";
import orgTypeIcon from "../../assets/icons/ic-org-type.svg";
import React, { useEffect, useState } from "react";
import Popover from "react-bootstrap/Popover";
import OrgDetailsModal from "./OrgDetailsModal";
import AddEditOrganisationModal from "./AddOrEditOrganisation/AddEditOrganisationModal";
import DeleteOrganisationModal from "./DeleteOrganisationModal";
import OrgCardLoader from "../../Utils/loadingShimmer/OrgCardLoader";

// @ts-ignore
function OrganisationCard({
  selected,
  addToSelectedList,
  valueObject,
  refreshList,
}) {
  const [showPopover, setShowPopover] = useState(false);
  const [addEditModal, setAddEditModal] = useState(false);
  const [orgDetailsModal, setOrgDetailsModal] = useState(false);
  const [deleteOrgPopup, setDeleteOrgPopup] = useState(false);

  const [cardViewShow, setCardOrghow] = useState(true);
  const setViewOrgTrue = () => {
    if (cardViewShow) {
      setOrgDetailsModal(true);
    }
  };

  function handleCloseModal() {
    setAddEditModal(false);
  }

  function handleConfirm(inputValue: any) {
    // Add data using inputValue
    handleCloseModal();
  }

  const popover = (
    <Popover
      id="popover-basic"
      className="popOver"
      show={showPopover}
      onMouseEnter={() => setShowPopover(true)}
      onMouseLeave={() => setShowPopover(false)}
    >
      <ul>
        <li
          onClick={() => {
            setOrgDetailsModal(true);
            setShowPopover(false);
          }}
        >
          View Organisation
        </li>
        <li
          onClick={() => {
            setAddEditModal(true);
            setShowPopover(false);
          }}
        >
          Edit Organisation
        </li>
        <li
          onClick={() => {
            setDeleteOrgPopup(true);
            setShowPopover(false);
          }}
        >
          Delete Organisation
        </li>
      </ul>
    </Popover>
  );

  return (
    <div className="organisationCard">
      {/*<>*/}
      {/*    <OrgCardLoader/>*/}
      {/*</>*/}
      <OrgDetailsModal
        show={orgDetailsModal}
        valueObject={valueObject}
        setDeleteOrgPopup={setDeleteOrgPopup}
        showOrgDetailsModal={orgDetailsModal}
        setOrgDetailsModal={setOrgDetailsModal}
        onHide={() => setOrgDetailsModal(false)}
        refreshList={refreshList}
      />
      <div onClick={setViewOrgTrue}>
        <div className="orgCardTop">
          <input
            type="checkbox"
            checked={selected}
            onChange={() =>
              addToSelectedList(valueObject.PartyGroupDetails[0].partyId)
            }
            onMouseOver={() => setCardOrghow(false)}
            onMouseLeave={() => setCardOrghow(true)}
            // partyId
            className="organisationsCheckbox  "
          />
          {/* form-check-input organisationsCheckbox  */}
          <div className="enabledDiv">
            <h6 className="enabledText">ENABLED</h6>

            <div>
              <div
                onMouseOver={() => setCardOrghow(false)}
                onMouseLeave={() => setCardOrghow(true)}
              >
                <OverlayTrigger
                  placement="bottom-end"
                  rootCloseEvent="click"
                  show={showPopover}
                  overlay={popover}
                >
                  <img
                    className="organisationCardsOptions"
                    src={threeVerticalDots}
                    alt="options"
                    onMouseEnter={() => setShowPopover(true)}
                    onMouseLeave={() => setShowPopover(false)}
                  />
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>

        <div className="orgCardMiddle">
          <div className="imageCard">
            <img className="logoImage" src={logoIcon} alt="logo" />
          </div>

          <div className="orgMiddleContent">
            <h6 className="orgName">
              {valueObject.PartyGroupDetails[0].groupName ?? "---"}
            </h6>
            <h6 className="orgEmail">
              {valueObject.emailVM[0]?.contactMech?.infoString}
            </h6>
          </div>
        </div>

        <div className="orgBottomContent">
          <img src={orgTypeIcon} alt="org type" />
          <h6 className="orgType">
            {valueObject.PartyGroupDetails[0].partyIdFrom}
          </h6>
        </div>

        <AddEditOrganisationModal
          type="edit"
          addEditModal={addEditModal}
          editPayloadData={valueObject}
          onConfirm={handleConfirm}
          onClose={handleCloseModal}
          refreshList={refreshList}
        />

        <DeleteOrganisationModal
          name={valueObject.name}
          deleteOrgPopup={deleteOrgPopup}
          setDeleteOrgPopup={setDeleteOrgPopup}
        />
      </div>
    </div>
  );
}

export default OrganisationCard;
