import { API_BASE_URL } from "../../../Constants/constants";

let controller = new AbortController();
let signal = controller.signal;

export const getEventSummary = async (
  filterByType: string,
  filterById: string,
  filterByDate: string,
  areaId: string,
  startDate: number,
  endDate: number,
  extraDetails: boolean,
  trendKey: string,
  previousEventRange: string
) => {
  let url = `${API_BASE_URL}fixedAsset/eventSummaryTrend?filterByType=${filterByType}&filterById=${filterById}&filterByDate=${filterByDate}&areaId=${areaId}&startDate=${startDate}&endDate=${endDate}&extraDetails=${extraDetails}&trendKey=${trendKey}`;
  if (previousEventRange !== "_NA_") {
    url = `${url}&previousEventRange=${previousEventRange}`;
  }
  const tokenID = localStorage.getItem("@tokenID");

  try {
    controller.abort(); // Cancel the previous request
    controller = new AbortController();
    signal = controller.signal;

    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenID}`,
      },
      signal: signal, // Pass the signal to the fetch request
    });

    if (!resp.ok) {
      return resp.status;
    }

    return await resp.json();
  } catch (error) {
    return error;
  }
};
