import React, { useEffect, useRef } from "react";
import "./MetricTrendSummary.scss";
import {
  TrendChartDataType,
  YAsix,
} from "../../../a1Pages/AssetDetailPage/types/TrendChartDataType";
import useState from "react-usestateref";
import { useStateValue } from "../../../redux/StateProvider";
import { DashBoardServices } from "../../../services/DasboardServices/DashboardService";
import moment from "moment";
import { getTrendChartKey } from "../../../Constants/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import ListDownIcon from "../../../assets/icons/listDownIcon.svg";
import SelectedBarChartIcon from "../assets/selectedBarChartIcon.svg";
import UnSelectedBarChartIcon from "../assets/unselectedBarChartIcon.svg";
import SelectedLineChartIcon from "../assets/selectedLineChartIcon.svg";
import UnselectedLineChartIcon from "../assets/unselectedLineChartIcon.svg";
import Switch from "../../../a1Components/FormElements/switch";
import DisplayError from "../../../a1Components/DisplayError/DisplayError";
import * as R from "ramda";
import TrendBarChart from "../charts/TrendBarChart";
import TrendLineChart from "../charts/TrendLineChart";
import TrendChartUtils from "../utils/TrendChartUtils";
import {
  meterTypeIdListData,
  metricTrendRange,
  trendAsssetTypeCheck,
  trendChartLegendData,
} from "../utils";

type PropTypes = {
  assetId: string;
  assetAssocTypeId: string;
};

function MetricTrendSummary(propTypes: PropTypes) {
  const [{ dateRange, dateChip }] = useStateValue();

  const [dateUpdate, setDateUpdate] = useState("Today");
  const [selectedTrend, setSelectedTrend] = useState<string[]>([
    "Temperature compliance trend",
  ]);
  const [selectedTrendRange, setSelectedTrendRange] = useState<string>("");
  const [trendLoad, setTrendLoad] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);

  const [trendDataLegendsScroll, setTrendDataLegendsScroll] = useState(true);
  const [meterTypeIdsList, setMeterTypeIdsList] = useState<string[]>([]);
  const [trendArrList, setTrendArrList] = useState<string[]>([]);
  const dropdownRef = useRef(null);
  const [trendRange, setTrendRange] = useState<string[]>([]);
  const [dropdownWidth, setDropdownWidth] = useState(0);
  const [btnState, setBtnState] = useState(false);
  const [lineChart, setLineChart] = useState(false);
  const [
    filterTrendChartData,
    setFilterTrendChartData,
    filterTrendChartDataRef,
  ] = useState<YAsix[]>([]);
  const [trendChartData, setTrendChartData, trendChartDataRef] = useState<
    string | number | TrendChartDataType
  >({
    xAsix: [],
    yAsix: [],
  });

  const toggleClassName = () => {
    setTrendDataLegendsScroll((prevState) => !prevState);
  };

  const chartSwitch = () => {
    setLineChart(!lineChart);
  };

  const handleDropdownClick = (event) => {
    // Check if the click is inside the dropdown or its trigger
    if (
      event.target.closest(".dropDownView") ||
      event.target.closest(".dateRagneFrequenciesMenu")
    ) {
      return;
    }
    setShowDropDown(false);
  };

  /**
   * Set Legend for the Trend Chart.
   */
  const setLegendTrendChart = () => {
    const trendLegendData = trendChartLegendData(propTypes.assetAssocTypeId);
    setTrendArrList(trendLegendData);
    setSelectedTrend([trendLegendData[0]]);

    const meterTypeIdsListData = meterTypeIdListData(
      propTypes.assetAssocTypeId
    );
    setMeterTypeIdsList(meterTypeIdsListData);
  };

  function clickTrend(item: string) {
    let selected = [];
    if (selectedTrend.includes(item)) {
      selected = [...selectedTrend.filter((el) => el !== item)];
    } else {
      selected = [...selectedTrend, item];
    }
    setSelectedTrend(selected);
  }

  const filterTrendDataSet = () => {
    if (
      trendChartData === "error" ||
      typeof trendChartData === "string" ||
      typeof trendChartData === "number"
    ) {
    } else {
      setBtnState(false);
      let result: YAsix[] = [];
      selectedTrend.forEach((item: string) => {
        const key = getTrendChartKey[item];
        const temp = trendChartData.yAsix.find((ele) => ele.key === key);
        if (temp !== undefined) {
          result.push(temp);
        }
      });
      setFilterTrendChartData(result);
      setBtnState(true);
    }
  };

  const trendDataUpdate = async (fromDate: number, toDate: number) => {
    setTrendLoad(true);
    const assetId = propTypes.assetId;
    const meterTypeIdsString: string = meterTypeIdsList.join(",");

    var body: object = {
      productMeterTypeIds: meterTypeIdsString,
      fromDate: fromDate,
      thruDate: toDate,
      fixedAssetId: `${assetId}`,
    };

    if (dateUpdate === "Custom") {
      body = { ...body };
    } else {
      const trendRangeData = selectedTrendRange.split(" ");
      const range = trendRangeData[1];
      const frequency =
        trendRangeData[2] === "days"
          ? "day"
          : trendRangeData[2] === "months"
          ? "month"
          : trendRangeData[2] === "weeks"
          ? "week"
          : "";

      body = { ...body, frequency: frequency, range: range };
    }

    setTrendChartData({
      xAsix: [],
      yAsix: [],
    });

    const service = new DashBoardServices();
    const response = await service.getTrendData(body);

    if (response === "error" || typeof response === "string") {
      setTrendChartData(response);
    } else if (typeof response === "number") {
      setTrendChartData(response);
    } else {
      const utils = new TrendChartUtils();
      const extractedData = utils.extractData(response, meterTypeIdsList);
      setTrendChartData(extractedData);
    }
    setTrendLoad(false);
  };

  /**
   *  If date range will be changed then the legend will be changed
   */
  useEffect(() => {
    const updateTrendLegendChip = () => {
      const data = metricTrendRange(dateUpdate);
      setTrendRange(data);
      setSelectedTrendRange(data[0]);
    };
    updateTrendLegendChip();
  }, [dateUpdate]);

  useEffect(() => {
    if (dateUpdate !== dateChip) {
      setDateUpdate(dateChip);
    }
  }, [dateRange]);

  /**
   * Set the required legends for the graph.
   */
  useEffect(() => {
    if (propTypes.assetId !== "") {
      setLegendTrendChart();
    }
  }, [propTypes.assetId]);

  useEffect(() => {
    filterTrendDataSet();
  }, [selectedTrend, trendChartData]);

  useEffect(() => {
    if (dropdownRef.current) {
      const width = dropdownRef.current.offsetWidth;
      setDropdownWidth(width);
    }
  }, [dropdownRef.current, trendArrList, filterTrendChartDataRef.current]);

  useEffect(() => {
    const shouldUpdate =
      (dateUpdate === "Custom" && selectedTrendRange === "") ||
      (dateUpdate !== "Custom" && selectedTrendRange !== "");

    if (
      shouldUpdate &&
      propTypes.assetId !== "" &&
      meterTypeIdsList.length !== 0
    ) {
      setFilterTrendChartData([]);
      setTrendChartData({
        xAsix: [],
        yAsix: [],
      });
      const fromDateStr = moment(dateRange[0]).startOf("day").valueOf();
      const toDateStr = moment(dateRange[1])
        .add("day", 1)
        .endOf("day")
        .valueOf();
      trendDataUpdate(fromDateStr, toDateStr);
    }
  }, [selectedTrendRange, propTypes.assetId, meterTypeIdsList]);

  React.useEffect(() => {
    document.addEventListener("mousedown", handleDropdownClick);
    return () => {
      document.removeEventListener("mousedown", handleDropdownClick);
    };
  }, []);

  return (
    <div className="trendChartMainCont">
      {/* For Legends */}
      <div className="trendChartLegendMainCont">
        <div
          className={
            trendDataLegendsScroll
              ? "trendChartLegendContScroll"
              : "trendChartLegendCont"
          }
        >
          {trendArrList.map((item, index) => (
            <div
              key={index}
              className={`trendChartLegendButton ${
                selectedTrend.includes(item)
                  ? "selectedLegendButton"
                  : "unselectedLegendButton"
              }`}
              style={{ cursor: btnState ? "pointer" : "no-drop" }}
              onClick={() => {
                if (btnState) {
                  clickTrend(item);
                }
                setShowDropDown(false);
              }}
            >
              {selectedTrend.includes(item) && (
                <FontAwesomeIcon
                  className="selectedLegendIcon"
                  icon={faCheck}
                />
              )}
              <p
                className={`trendChartLegend ${
                  selectedTrend.includes(item)
                    ? "selectedLegendText"
                    : "unselectedLegendText"
                }`}
              >
                {item}
              </p>
            </div>
          ))}
        </div>
        {trendArrList.length > 3 && (
          <div className="trendChartIconsCont" onClick={toggleClassName}>
            <img
              className={
                trendDataLegendsScroll ? "toggleIcon" : "toggleIconActive"
              }
              src={ListDownIcon}
              alt="icon"
            />
          </div>
        )}
      </div>

      {/* For Date Ragne Selector and Trend Chart Settings */}
      <div
        className="trendCartSettingMainCont"
        style={{
          justifyContent: trendRange.length > 1 ? "space-between" : "flex-end",
        }}
      >
        {trendRange.length > 1 && (
          <div className="dateRagneFrequenciesDiv">
            <div
              className="dropDownView"
              onClick={() => setShowDropDown(!showDropDown)}
              ref={dropdownRef}
            >
              <p className="dropDownText">Viewing {selectedTrendRange}</p>
              <img
                src={ListDownIcon}
                alt="icon"
                className={`arrowIcon ${
                  showDropDown ? "rotate-180" : "rotate-0"
                }`}
              />
            </div>
            {showDropDown && (
              <div
                className="dateRagneFrequenciesMenu"
                style={{ width: `${dropdownWidth}px` }}
              >
                {trendRange.map((item, index) => (
                  <div
                    className="dateRagneFrequenciesButton"
                    onClick={() => {
                      setSelectedTrendRange(item);
                      setShowDropDown(!showDropDown);
                    }}
                    style={{
                      borderBottom:
                        trendRange.length - 1 !== index
                          ? "1px solid rgba(189, 203, 251, 1)"
                          : "",
                    }}
                  >
                    <p className="dateRagneFrequenciesText">{item}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <div className="trendChartSettingCont">
          {!lineChart && trendAsssetTypeCheck(propTypes.assetAssocTypeId) ? (
            <img loading="lazy" src={SelectedBarChartIcon} />
          ) : (
            <img loading="lazy" src={UnSelectedBarChartIcon} alt="bar chart" />
          )}

          {btnState ? (
            <Switch
              label=""
              id="online"
              checked={lineChart}
              onChange={chartSwitch}
              isAlignCenter={true}
              onToggle={undefined}
            />
          ) : (
            <Switch
              label=""
              id="online"
              checked={lineChart}
              onChange={() => null}
              isAlignCenter={true}
              onToggle={undefined}
            />
          )}
          {lineChart && trendAsssetTypeCheck(propTypes.assetAssocTypeId) ? (
            <img src={SelectedLineChartIcon} loading="lazy" alt="bar chart" />
          ) : (
            <img src={UnselectedLineChartIcon} loading="lazy" alt="bar chart" />
          )}
        </div>
      </div>

      {trendAsssetTypeCheck(propTypes.assetAssocTypeId) ? (
        <>
          {trendLoad ? (
            <div
              style={{
                minHeight: 160,
                justifyContent: "center",
                alignItems: "center",
              }}
              className="d-flex justify-content-center"
            >
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : trendChartDataRef.current === "error" ? (
            <DisplayError type="err_1" />
          ) : typeof trendChartDataRef.current === "number" ? (
            <DisplayError type="err-500/404" />
          ) : R.isEmpty(filterTrendChartDataRef.current) ? (
            <DisplayError type="err-empty" />
          ) : (
            <div>
              {!lineChart ? (
                <TrendBarChart
                  xAxisData={trendChartDataRef.current.xAsix}
                  yAxisData={filterTrendChartDataRef.current}
                />
              ) : (
                <TrendLineChart
                  xAxisData={trendChartDataRef.current.xAsix}
                  yAxisData={filterTrendChartDataRef.current}
                />
              )}
            </div>
          )}
        </>
      ) : null}
    </div>
  );
}

export default MetricTrendSummary;
