import React, { useEffect, useState } from "react";
import "./AssetCardMeter.scss";
import { Attribute, CardMeter, MinAndMaxDataType } from "../types";
import AssetCardMeterData from "./AssetCardMeterData";
import NoDataAvailableIcon from "../assets/noDataAvailable.svg";

type PropTypes = {
  data: CardMeter[];
  fixedAssetAssocWithTypeId: string;
  minAndMaxData: MinAndMaxDataType;
  attributeList: Attribute[];
  name: string;
};

type MeterRow = {
  left: CardMeter;
  right: CardMeter | null;
};

function AssetCardMeter({
  data,
  fixedAssetAssocWithTypeId,
  minAndMaxData,
  attributeList,
  name,
}: PropTypes) {
  const [cardData, setCardData] = useState<MeterRow[]>([]);

  useEffect(() => {
    const organiseData = () => {
      const result: MeterRow[] = [];
      const sliceData = data.slice(0, 4);

      for (let index = 0; index < sliceData.length; index += 2) {
        const temp: MeterRow = {
          left: data[index],
          right: index + 1 < sliceData.length ? data[index + 1] : null,
        };
        result.push(temp);
      }
      setCardData(result);
    };
    organiseData();
  }, [data]);

  return (
    <div
      className="cardMeterMainDiv"
      style={{
        justifyContent:
          cardData.length === 0
            ? "center"
            : cardData.length === 1
            ? "flex-start"
            : "space-between",
      }}
    >
      {cardData.length === 0 ? (
        <div className="cardMeterNoData">
          <img src={NoDataAvailableIcon} className="noDataIcon" />
          <p className="noDataText">Data unavailable at the moment</p>
        </div>
      ) : (
        cardData.map((item, index) => (
          <div
            className="assetCardMeterRow"
            style={{
              borderBottomWidth:
                index !== cardData.length - 1 || cardData.length === 1
                  ? "1px"
                  : "0px",
            }}
          >
            <AssetCardMeterData
              description={item.left.description}
              meterValue={item.left.meterValue}
              fixedAssetAssocWithTypeId={fixedAssetAssocWithTypeId}
              minAndMaxData={minAndMaxData}
              meterTypeId={item.left.meterTypeId}
              readingUomDesc={item.left.readingUomDesc}
              cardMeterData={item.left}
              attributeList={attributeList}
              name={name}
            />
            {item.right !== null && (
              <>
                <div className="assetCardMeterVerticalLine" />
                <AssetCardMeterData
                  description={item.right.description}
                  meterValue={item.right.meterValue}
                  fixedAssetAssocWithTypeId={fixedAssetAssocWithTypeId}
                  minAndMaxData={minAndMaxData}
                  meterTypeId={item.right.meterTypeId}
                  readingUomDesc={item.right.readingUomDesc}
                  cardMeterData={item.right}
                  attributeList={attributeList}
                  name={name}
                />
              </>
            )}
          </div>
        ))
      )}
    </div>
  );
}

export default AssetCardMeter;
