import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import './FilterModal.scss';
import {FaCheckSquare, FaRegSquare} from 'react-icons/fa';
import {getKeyForSelectedFilter} from "../../Constants/constants"
import { useStateValue } from "../../redux/StateProvider";
import IconClose from "../../assets/icons/IconClose.svg";
interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    data: Map<string, { key: string; value: string }[]>;
    selectedAssetCategoryMain: string[];
    setSelectedAssetCategoryMain: (value: string[]) => void;
    clearFilters: boolean;
    setClearFilters:(value: boolean)=> void
}

function FilterModal({ setClearFilters, clearFilters,isOpen, onClose, data, selectedAssetCategoryMain, setSelectedAssetCategoryMain}: ModalProps) {
    const [{settingsFilterPreferences}, dispatch] = useStateValue();
    const [selectedKey, setSelectedKey] = useState<string | null>(null);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [filterText, setFilterText] = useState('');
    const allValues = Array.from(data.values()).flatMap((item) => item.map((entry) => entry));
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [appliedValues, setAppliedValues] = useState([]);
    const [selectedAssetCategory, setSelectedAssetCategory] = useState<string[]>([]);

    useEffect(() => {
        setSelectedValues(appliedValues);
    }, [appliedValues]);

    useEffect(() => {
        if (clearFilters) {   
        setAppliedValues([]);
        setSelectedValues([]);
        filterAssetType();
        setSelectedAssetCategory([]);
        setSelectedAssetCategoryMain([]);
        dispatch({
            type: 'SET_SETTINGS_FILTER_PREFERENCES',
            payload: [],
        });
        dispatch({
            type: 'SET_SETTINGS_FILTER_SELECTED_LIST_FOR_KEY',
            selectedFilterList: [],
        });
        onClose();
        }
    },[clearFilters])

    useEffect(() => {
        setIsButtonEnabled(selectedValues.length > 0);
    }, [selectedValues]);

    useEffect(() => {
        if (isOpen) {
            setSelectedAssetCategory(selectedAssetCategoryMain);
            setSelectedKey(data.keys().next().value);
            setFilterText('');
            filterAssetType();
        }
    }, [isOpen, data]);

    useEffect(() => {
        let selectedStuff: string[] = [];
        settingsFilterPreferences.forEach((pref: any) => {
            selectedStuff = selectedStuff.concat(pref.searchValue);
        });
        setSelectedValues(selectedStuff)


        if (settingsFilterPreferences.size == 0) {
            setSelectedAssetCategory([]);
            setSelectedAssetCategoryMain([]);
            setAppliedValues([]);
        }
        console.log(settingsFilterPreferences, "filterBug")
    }, [settingsFilterPreferences])

    const handleClose = () => {
        setSelectedValues(appliedValues);
        setSelectedKey(null);
        onClose();
    };

    const handleKeyClick = (key: string) => {
        setSelectedKey(key);
        setFilterText('');
    };

    const handleValueClick = (value: string) => {
        setSelectedValues((prevSelectedValues) => {
            if (prevSelectedValues.includes(value)) {
                return prevSelectedValues.filter((v) => v !== value);
            } else {
                return [...prevSelectedValues, value];
            }
        });
        if (selectedKey === "Asset Category") {
            setSelectedAssetCategory((prevSelectedValues) => {
                if (prevSelectedValues.includes(value)) {
                    return prevSelectedValues.filter((v) => v !== value);
                } else {
                    return [...prevSelectedValues, value];
                }
            });
        }
    };

    // Asset Type will be filtered as the asset category is selected.
    const filterAssetType = async () => {
        let responseString = localStorage.getItem("user_specific_asset_type_data");
    const response = JSON.parse(responseString);

    // For asset type list
    const result: AssetFilterDataType[] = response?.map((element: any) => ({
        key: element.fixedAssetTypeId,
        value: element.description || element.fixedAssetTypeId,
        name: element.description || element.fixedAssetTypeId,
        id: element.fixedAssetTypeId,
        group: "status",
        isSelected: true,
        isVisible: true,
        parentFixedAssetTypeId: element.parentFixedAssetTypeId
    }));
        if (selectedAssetCategory.length === 0) {
            data.set('Asset Type', result);
        } else {
            const filteredAssetTypes = result.filter((assetType) => {
                return selectedAssetCategory.some(category => assetType?.parentFixedAssetTypeId === category);
            });
            data.set('Asset Type', filteredAssetTypes);
        }
      };

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterText(e.target.value);
    };

    const handleClearAll = () => {
        setSelectedValues([]);
        setSelectedAssetCategory([]);
        setSelectedAssetCategoryMain([]);
        setAppliedValues([]);
        dispatch({
            type: 'SET_SETTINGS_FILTER_PREFERENCES',
            payload: [],
        });
        dispatch({
            type: 'SET_SETTINGS_FILTER_SELECTED_LIST_FOR_KEY',
            selectedFilterList: [],
        });
        onClose();
    };

    const handleApply = () => {
        setClearFilters(false);
        const searchItems: { isSearch: boolean; searchKey: string; searchValue: string[] }[] = [];

        // Iterate over "data"
        data.forEach((items, key) => {
            // @ts-ignore
            const searchKey = getKeyForSelectedFilter[key];
            // check the selected keys for the particular key and create an array
            const searchValues = items
                .filter(item => selectedValues.includes(item.key))
                .map(item => item.key);

            // If searchValues is not empty, create a search item
            if (searchValues.length > 0) {
                const searchItem = {
                    isSearch: true,
                    searchKey,
                    searchValue: searchValues,
                };

                searchItems.push(searchItem);
            }
        });
        dispatch({
            type: 'SET_SETTINGS_FILTER_PREFERENCES',
            payload: searchItems,
        });
        dispatch({
            type: 'SET_SETTINGS_FILTER_SELECTED_LIST_FOR_KEY',
            selectedFilterList: allValues,
        });
        setAppliedValues(selectedValues);
        setSelectedAssetCategoryMain(selectedAssetCategory);
        onClose();
    };

    const [filteredValues, setFilteredValues] = useState<any>([]);

    useEffect(() => {
    setFilteredValues(() => selectedKey ? (data.get(selectedKey) || []).filter((item) => item?.value?.toLowerCase().includes(filterText.toLowerCase())) : [])        
    }, [selectedKey, data])

    
    useEffect(() => {
        filterAssetType();
    }, [selectedAssetCategory])

    return (
        <>
        <Modal show={isOpen} size="sm" backdrop="static" centered={true} style={{zIndex: 1050}} className='filterMainModal'>
            <div className="filterHeader">
                <div className="headerTitle">Filter by</div>
                <div
                    className="closeIconDiv"
                    onClick={handleClose}
                >
                    <img src={IconClose} alt="icon" />
                </div>
            </div>

            <div className="filterBody">
                <div className="partition">
                    <div className="filterKeyDiv">
                        {Array.from(data.keys())
                            // @ts-ignore
                            .filter((key) => data.get(key) !== undefined && data.get(key).length > 0)
                            .map((key) => (
                                <div
                                    key={key}
                                    className={`filterKey ${selectedKey === key ? 'activeFilter' : ''}`}
                                    onClick={() => handleKeyClick(key)}
                                >
                                    {key}
                                </div>
                            ))}
                    </div>

                    <div className="filterValuesDiv">
                        <div className="filterValuesChildDiv">
                            <input
                                type="text"
                                placeholder="Search"
                                value={filterText}
                                className="filterSearch"
                                onChange={handleFilterChange}
                            />

                            {filteredValues.map((item) => (
                                <div
                                    key={`${item.value}_${item.key}`}
                                    className={`filterValue ${selectedValues.includes(item.key) ? 'selected' : ''}`}
                                    onClick={() => handleValueClick(item.key)}
                                >
                                    {selectedValues.includes(item.key) ? (
                                        <FaCheckSquare/>
                                    ) : (
                                        <FaRegSquare/>
                                    )}
                                    <div className="valueString">
                                        {item.value}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="footerButtons">
                            <div className="buttonGroup">
                                <button className="clearAll" onClick={handleClearAll} disabled={!isButtonEnabled}>CLEAR ALL</button>
                                <button className="apply" onClick={handleApply}  disabled={!isButtonEnabled}>APPLY</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
        </>
    );
}

export default FilterModal;