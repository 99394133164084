export class HelperFunctions {
/**
 * * Returns a string indicating the number of purposes selected.
 *  * If the list is empty or null, returns "Set purpose".
 *  * If the list has one item, returns "1 purpose selected".
 *  * If the list has multiple items, returns "{n} purposes selected", where {n} is the number of items in the list.
 *  * @param list The list of purposes to check.
 *  * @returns A string indicating the number of purposes selected.
 *  */
    public static getPurposeString(list: any[]) {
        if (!list || list.length === 0) {
            return ("Set purpose");
        } else {
            if (list.length === 1) {
                return (`${list.length} purpose selected`);
            } else {
                return (`${list.length} purposes selected`);
            }
        }
    }

    public static isTnt(s : String): boolean {
        return s.includes("iocl");
    }

    static getLoginURL(username: string, password: string) {
        if (this.isTnt(username)){
            return `https://dev-tnt.app.aone.ai/api/1.0//Login?username=${username}&password=${password}`
        } else {
            return `https://aone.atsuyatech.com//api/1.0/Login?username=${username}&password=${password}`;
        }
    }
}