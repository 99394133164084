import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";

import "./FaultsSummary.scss";
import FaultSummaryBtn from "../faultSummaryBtn/FaultSummaryBtn";
import DisplayNote from "../../../a1Components/DisplayNote/DisplayNote";
import moment from "moment";
import LoaderStyle from "../../../a1Components/LoaderStyle/LoaderStyle";

const d2ummyFaultData = {
  groupdata: [
    {
      count: 13,
      delta: -2.5,
      name: "All Faults",
      type: "All Faults",
    },
    {
      count: 13,
      delta: 2.5,
      name: "Bearing fault",
      type: "Bearing fault",
    },
    {
      count: 11,
      delta: 1.5,
      name: "Broken rotor bar fault",
      type: "Broken rotor bar fault",
    },
    {
      count: 11,
      delta: 1.5,
      name: "Inter-turn fault",
      type: "Inter-turn fault",
    },
  ],
  stack_chart: {
    data: [
      {
        heights: [3, 5, 7, 9, 12, 15, 18],
        name: "Safety Hazards Detected",
        type: "Safety Hazards Detected",
      },
      {
        heights: [9, 7, 6, 2, 4, 3, 5],
        name: "SOP Violations",
        type: "SOP Violations",
      },
      {
        heights: [4, 7, 9, 12, 6, 8, 3],
        name: "Inter-turn fault",
        type: "Inter-turn fault",
      },
    ],
    timeframe_range: [
      "01 Nov",
      "02 Nov",
      "03 Nov",
      "04 Nov",
      "05 Nov",
      "06 Nov",
      "07 Nov",
    ],
  },
};

function FaultsSummary(props: any) {
  const [responseData, setResponseData] = useState(d2ummyFaultData);

  const convertDates = (arr: any) => {
    return arr.map((obj: any) => moment(obj).format("D MMM"));
  };
  const add = (accumulator: any, a: any) => {
    return accumulator + a;
  };
  const sumCount =
    responseData.stack_chart.data[0].heights.reduce(add, 0) +
    responseData.stack_chart.data[1].heights.reduce(add, 0) +
    responseData.stack_chart.data[2].heights.reduce(add, 0);

  const option1 = {
    tooltip: {},
    grid: {
      height: "70%",
    },
    xAxis: {
      data: convertDates(responseData.stack_chart.timeframe_range),
      // responseData.stack_chart.timeframe_range.map((obj)=> moment(obj).format('D MMM')),
    },
    yAxis: {
      show: false,
    },

    series: [
      {
        name: responseData.stack_chart.data[0].name,
        type: "bar",
        stack: "one",
        color: "#0165FF",
        label: responseData.stack_chart.data[0].type,
        data: responseData.stack_chart.data[0].heights,
      },
      {
        name: responseData.stack_chart.data[1].name,
        type: "bar",
        stack: "one",
        color: "#80B2FF",
        label: responseData.stack_chart.data[1].type,
        data: responseData.stack_chart.data[1].heights,
      },
      {
        name: responseData.stack_chart.data[2].name,
        type: "bar",
        stack: "one",
        color: "#E5F0FF",
        label: responseData.stack_chart.data[2].type,
        data: responseData.stack_chart.data[2].heights,
      },
    ],
    label: {
      show: true,
      position: "top",
    },
    barWidth: "30%",
  };

  const option2 = {
    tooltip: {},
    grid: {
      height: "70%",
    },
    xAxis: {
      data: convertDates(responseData.stack_chart.timeframe_range),
      //  responseData.stack_chart.timeframe_range,
    },
    yAxis: {
      show: false,
    },

    series: [
      {
        name: responseData.stack_chart.data[0].name,
        type: "bar",
        stack: "one",
        color: "#0165FF",
        label: responseData.stack_chart.data[0].type,
        data: responseData.stack_chart.data[0].heights,
      },
    ],
    label: {
      show: true,
      position: "top",
    },
    barWidth: "30%",
  };

  const option3 = {
    tooltip: {},
    grid: {
      height: "70%",
    },
    xAxis: {
      data: convertDates(responseData.stack_chart.timeframe_range),
      //  responseData.stack_chart.timeframe_range,
    },
    yAxis: {
      show: false,
    },

    series: [
      {
        name: responseData.stack_chart.data[1].name,
        type: "bar",
        stack: "one",
        color: "#80B2FF",
        label: responseData.stack_chart.data[1].type,
        data: responseData.stack_chart.data[1].heights,
      },
    ],
    label: {
      show: true,
      position: "top",
    },
    barWidth: "30%",
  };

  const option4 = {
    tooltip: {},
    grid: {
      height: "70%",
    },
    xAxis: {
      data: convertDates(responseData.stack_chart.timeframe_range),
      //  responseData.stack_chart.timeframe_range,
    },
    yAxis: {
      show: false,
    },

    series: [
      {
        name: responseData.stack_chart.data[2].name,
        type: "bar",
        stack: "one",
        color: "#E5F0FF",
        label: responseData.stack_chart.data[2].type,
        data: responseData.stack_chart.data[2].heights,
      },
    ],
    label: {
      show: true,
      position: "top",
    },
    barWidth: "30%",
  };

  const [activeCardN, setActiveCardName] = useState("All Faults");
  const OnclickChange = (e: string) => {
    setActiveCardName(e);
  };
  const [loading, setLoading] = useState(false);
  const { dateRange } = props;

  useEffect(() => {
    const faultSummaryAPI = async () => {
      setLoading(true);
      if (dateRange.length !== 0) {
        const startDate = moment(dateRange[0]).utc().valueOf();
        const endDate = moment(dateRange[1]).utc().valueOf();
        const url = `https://hawkeye.aone.ai/analytics/motorCondition/incident/summary?start=${startDate}&end=${endDate}`;
        const response = await fetch(url);
        const jsonData = await response.json();
        // console.log(jsonData, "faultSummary");
        setResponseData(jsonData.data);
      }
      setLoading(false);
    };
    faultSummaryAPI();
  }, [dateRange]);

  return loading ? (
    <LoaderStyle />
  ) : (
    <div>
      {sumCount === 0 ? (
        <DisplayNote />
      ) : (
        <div className="faultsSummaryContainer">
          <div className="chartBtnsCont">
            {responseData.groupdata.map((value, index) => {
              return (
                <FaultSummaryBtn
                  callBack={OnclickChange}
                  data={value}
                  state={activeCardN}
                  key={index}
                />
              );
            })}
          </div>
          <div className="selectorContainer">
            <select className="faultSelector">
              <option value="chennai">Chennai</option>
            </select>
          </div>
          <div>
            {loading ? (
              <DisplayNote />
            ) : (
              <div>
                {activeCardN === responseData.groupdata[0].name && (
                  <ReactEcharts option={option1} />
                )}

                {activeCardN === responseData.groupdata[1].name && (
                  <ReactEcharts option={option2} />
                )}

                {activeCardN === responseData.groupdata[2].name && (
                  <ReactEcharts option={option3} />
                )}

                {activeCardN === responseData.groupdata[3].name && (
                  <ReactEcharts option={option4} />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default FaultsSummary;
