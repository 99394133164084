import * as R from 'ramda';
import useState from "react-usestateref";
import TemperatureComplianceBar from '../AnoviCustBar/TemperatureComplianceBar';
import TemperatureComplianceHeader from '../AnoviCustBar/TemperatureComplianceHeader';
import DisplayError from '../DisplayError/DisplayError';
import AnoviComplianceStackedBar from '../E-Charts/stackedHorizontalBar/AnoviComplianceStackedBar';
import AssetWise_Compliance from '../E-Charts/stackedHorizontalBar/AssetWise_Compliance';
import LinkWithIcon from '../FormElements/linkWithIcon';
import AnoviFilterChipDropComp from '../cardWrapper/FilterChipsDrop/AnoviFilterChipDropComp';
import { useEffect, useRef } from 'react';
import { useStateValue } from "../../redux/StateProvider";
import iconchevronleft from "../../assets/icons/iconchevronleft.svg";
import iconchevronright from "../../assets/icons/iconchevronright.svg";
import vector from "../.././assets/image/Vector.svg";
import { useNavigate } from 'react-router-dom';
import { DashBoardServices } from '../../services/DasboardServices/DashboardService';
import moment from 'moment';
import "../../a1Pages/dashBoard/dashboardNew.scss";
import LoaderStyleComponent from './LoaderStyleComponent';
import DownloadAnovi from '../ModelPopupcomp/AnoviDownload/DownloadAnovi';
import {convertUpperCaseLetter, convertTittleintoUpperCaseLetter, checkIntialState, checkPaginationVisible} from '../../Utils/utils'

interface ChildProps {
    filterState:boolean;
    clickFilterTab: () => void;
}


const TempCompilance = (props:ChildProps) => {
  //filterState
    const [tempComperatureData, setTempComperatureData, tempComperatureDataRef] = useState<any>({ all: [], data: [] });
    const [tempStatus, setTempStatus] = useState(false);
    const [nameVal, setnameVal, nameValRef] = useState<any>("");
    const [pageVariable, setPageVariable] = useState(5);
    const [acilityVariable, setFacilityVariable] = useState<any>(false);
    const [filterLoader, setFilterLoader] = useState(false);
    const [popupState, setPopupState] = useState(false);
    const [{ dateRange }, dispatch] = useStateValue();
    const navigate = useNavigate();
    const [valTemp, setValTemp] = useState<any>(null);
    const [facilityName, setFacilityName] = useState<any>("");
    const [tempDefaultFacilityType, setTempDefaultFacilityType] = useState<any>("");
    const [apiCallCount, setApiCallCount] = useState(0);

    const defaultFacilityNames = () => {
        setFacilityName(tempDefaultFacilityType);
        setnameVal(tempDefaultFacilityType);
    }

    const tempComplianceAnovi = async (fromDateStrunix: any, toDateStrunix: any) => {
        setTempStatus(true);
        const fetchData = new DashBoardServices();
        const compData = await fetchData.anoviTempCompliance(fromDateStrunix, toDateStrunix);
        if (compData === "error") {
        setTempComperatureData(compData);
        } else if (typeof compData === "number") {
        setTempComperatureData(compData);
        } else {
        let data = await compData.complianceSummary;
        if (data.all[0]?.type === "fixedAsset") {
            setFacilityVariable(true);
            setFacilityName("Asset");
        }else {
            setFacilityVariable(false);
            setFacilityName(data.all[0]?.name);
            setTempDefaultFacilityType(data.all[0]?.type);
        }
        setValTemp(data);
        setnameVal(data.all[0]?.name);
        setTempComperatureData(data);
        if(data.data.length === 1){
          setnameVal(data.all[0]?.name);
          setFacilityName(data.data[0].name);
        }
        }
        setTempStatus(false);
    };

    useEffect(() => {
      let fromDateStrunix = moment(dateRange[0]).startOf('day').valueOf();
      let toDateStrunix = moment(dateRange[1]).endOf('day').valueOf();
      tempComplianceAnovi(fromDateStrunix, toDateStrunix);
    }, [dateRange])


    useEffect(()=>{
      if(typeof tempComperatureDataRef.current !== "number" && tempComperatureDataRef.current.data.length === 1 && apiCallCount === 0){
        props.clickFilterTab();
        setApiCallCount((prev) => prev + 1);
      }
    },[tempComperatureDataRef.current]);

    useEffect(() => {
      if (valTemp !== null) {
        setFacilityVariable(false);
        setTempComperatureData(valTemp);
        setnameVal(valTemp.all[0]?.name);
      }
    }, [props.filterState])

  return (
    <>
        {popupState ? (
          <DownloadAnovi className="modal" data="" 
            show={true} 
            close={() => setPopupState(false)}
            summaryData={""}
            assetDataDownload={""}
            assetType={""}
            faData={""}
          />
        ) : null}
      {tempStatus ? <LoaderStyleComponent />
       : tempComperatureDataRef.current === "error" ?  <DisplayError type={"err_1"} />
       : typeof tempComperatureDataRef.current === "number" ? <DisplayError type={"err-500/404"} />
       : R.isEmpty(tempComperatureDataRef.current) ? <DisplayError type={"err-empty"} />
       : R.isEmpty(tempComperatureDataRef.current.all) ||  R.isEmpty(tempComperatureDataRef.current.data) ?  <DisplayError type={"err-empty"} />
        : (
          <>
            <div className='tempCardContainer'>
              { props.filterState ? (
                  <AnoviFilterChipDropComp
                  data={tempComperatureDataRef.current}
                  callbackData={(element:any) => setTempComperatureData(element)}
                  callBackname={(ele:string) => setnameVal(ele)}
                  callBacktype={(e: Boolean) => setFacilityVariable(e)}
                  callBackByFacilityType={(e:string) => setFacilityName(e)}
                  callBackDefaultNames={defaultFacilityNames}
                  apiStatusOnLoading={() => {
                    setPageVariable(5);
                    setFilterLoader(true);
                  }}
                  apiStatusOnDone={() => setFilterLoader(false)}
                  />) : null
              }

              {filterLoader ? <LoaderStyleComponent />
               : (
                  <>
                  <h5 className='textHeading'>{ tempComperatureDataRef.current.all[0].compliancePercentage } % Compliant</h5>
                  <div className='tempBarCardContainer'>
                    <div className='tempBarCardContainerOne'>
                      <TemperatureComplianceHeader color={"#97aefe"} percentage={tempComperatureDataRef.current.all[0].ncBelowThreshold} />
                      <div className='tempBarCardContainer'>
                        <h6 className='tempSubHeading'>Below Threshold</h6>
                      </div>
                      </div>
                      <div style={{ width: "45%" }}>
                        <TemperatureComplianceHeader color={"#4caf50"} percentage={tempComperatureDataRef.current.all[0].compliancePercentage} />
                        <div className='tempBarCardContainer'>
                          <h6 className='tempSubHeading'>Compliant</h6>
                        </div>
                      </div>
                      <div style={{ width: "30%" }}>
                        <TemperatureComplianceHeader color={"#f6754c"} percentage={ tempComperatureDataRef.current.all[0].ncAboveThreshold } />
                        <div className='tempBarContainer'>
                          <h6 className='tempSubHeading'>Above Threshold</h6>
                        </div>
                    </div>
                  </div>
                  <h6 className='tempheading'> {convertTittleintoUpperCaseLetter(nameValRef.current)} {checkIntialState(nameValRef.current,facilityName)} wise-compliance </h6>
                  {acilityVariable ? (
                    <>
                      {tempComperatureDataRef.current.data.sort((a: any, b: any) => parseInt(b.compliancePercentage) - parseInt(a.compliancePercentage))
                          .slice(pageVariable - 5, pageVariable)
                          .map((el: any, index: any) => {
                          return <TemperatureComplianceBar key={index} data={el} />
                      })}
                    </>
                  ) : (
                    <AnoviComplianceStackedBar  data={tempComperatureDataRef.current.data.slice(pageVariable - 5, pageVariable)} />
                  )}


                  {
                    checkPaginationVisible(tempComperatureDataRef.current.data.length) ?  
                    <div className='tempBarCardSubContainer'>
                    <p onClick={() => pageVariable <= 5 ? null : setPageVariable(pageVariable - 5)} className='previousButton'
                      style={{ opacity: pageVariable <= 5 ? 0.3 : 1, cursor: pageVariable <= 5 ? "not-allowed" : "pointer" }} >
                      <img src={iconchevronleft} loading='lazy' alt="icon"/>
                    </p>
                    <p className='paginationText'>
                       Showing {pageVariable - 5} - {pageVariable >= tempComperatureDataRef.current.data.length ? tempComperatureDataRef.current.data.length : pageVariable}
                       &nbsp; of {tempComperatureDataRef.current.data.length} {convertUpperCaseLetter(facilityName)}s
                    </p>
                    <p onClick={() => pageVariable >= tempComperatureDataRef.current.data.length ? null : setPageVariable(pageVariable + 5) } className='previousButton'
                      style={{ opacity: pageVariable >=  tempComperatureDataRef.current.data.length ? 0.3 : 1, cursor: pageVariable >=  tempComperatureDataRef.current.data.length ? "not-allowed" : "pointer" }} >
                      <img src={iconchevronright} alt='icon' loading='lazy' />
                    </p>
                </div> : null

                  }
                 

                  <h6 className='tempheading'> Asset type-wise compliance </h6>
                  <div className='tempBarContainerOne'>
                    <h3 className='tempHeadingOne'> Compliance% </h3>
                    <AssetWise_Compliance data={ tempComperatureDataRef.current.assetTypeWise } />
                  </div>
                  </>
              )}
            </div>
  
            <button className="dowCust" onClick={() => setPopupState(true)} >
             <img src={vector} alt='icon' loading='lazy' /> Download Reports
            </button>

            <LinkWithIcon onClick={() =>  navigate("/asset-list")} cardname="Asset Summary" label="View All Assets"  link="/asset-list" />
          </>
      )}
    </>
  )
}
export default TempCompilance