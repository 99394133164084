import React from "react";
// import the core library.
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { GridComponent } from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import "./SingleBarChart.scss";
// Register the required components

function SingleBarChart() {
  echarts.use([GridComponent, BarChart, CanvasRenderer]);
  var option = {
    yAxis: {
      type: "category",
      data: ["val"],
    },
    xAxis: {
      type: "value",
    },
    series: [
      {
        data: [120],
        type: "bar",
        showBackground: true,
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.2)",
        },
      },
    ],
  };

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={option}
      notMerge={true}
      lazyUpdate={true}
      theme={"theme_name"}
      className="barRingGauge"
    />
  );
}

export default SingleBarChart;
