import * as R from "ramda";
import React, { useEffect, useState } from "react";
import SummaryCards from "./SummaryCards";
import "./SummaryParentCard.scss";
import {
  roundToTwoDecimalPlaces,
  subtractWholeNumber,
  sumOfTwoDecimalPlaces,
} from "../../Utils/utils";

function SummaryParentCard({ data, faData }: any) {
  const [finalData, setFinalData] = useState<any>([]);
  const [filterVariable, setFilterVariable] = useState(false);

  const getSummaryData = (arr: any) => {
    const resultArr = arr.map((element) => {
      console.log("envv ", element);
      let event_summary_array =
        data.find((item) => item.eventSummary)?.eventSummary || [];
      const temp_parent_obj =
        element?.parent === "eventSummary" ? event_summary_array : data;
      console.log("envv ", temp_parent_obj);
      const TempObj =
        element.type === "DEFROST_CYCLE" ||
          element.type === "CMPRSR_OFF" ||
          element.type === "CMPRSR_ON" ||
          element.type === "VIR_DOOR_OPEN" ||
          element.type === "PHY_DOOR_OPEN" ||
          element.type === "PHY_DO_BUZR"
          ? temp_parent_obj.find((obj) => obj.type === element.type)
          : data.find((obj) => obj.type === element.type);

      const value =
        element.type === "CMPRSR_TOTAL"
          ? Math.max(
            temp_parent_obj.find((item) => item.type === "CMPRSR_ON")
              ?.cycleCount,
            temp_parent_obj.find((item) => item.type === "CMPRSR_OFF")
              ?.cycleCount
          )
          : element.type === "CMPRSR_AVG_DUR"
            ? sumOfTwoDecimalPlaces(
              temp_parent_obj.find((item) => item.type === "CMPRSR_ON")
                ?.averageDuration,
              temp_parent_obj.find((item) => item.type === "CMPRSR_OFF")
                ?.averageDuration
            )
            : element.type === "TEMP_COMP_PCT" || element.type === "KVA"
              ? TempObj?.average
              : element?.valueCategory === "cycleCount"
                ? TempObj?.cycleCount
                : element?.valueCategory === "averageDuration"
                  ? TempObj?.averageDuration
                  : element?.valueCategory === "avgBetweenCycles"
                    ? TempObj?.avgBetweenCycle
                    : TempObj?.value || TempObj?.averageTemperature || TempObj?.average;

      const typeList = ["CMPRSR_TOTAL", "CMPRSR_AVG_DUR"];

      return {
        type:
          typeList.includes(element.type) && !Number.isNaN(value)
            ? element.type
            : TempObj?.type,
        title:
          element.type === "TEMP_COMP_PCT"
            ? "Temperature Compliance"
            : element.type === "CMPRSR_TOTAL"
              ? "Compressor Total Count"
              : element.type === "CMPRSR_AVG_DUR"
                ? "Average Compressor Cycle Duration"
                : element.type === TempObj?.title
                  ? element.title
                  : element.type === "KWH" ||
                    element.type === "KVAH" ||
                    element.type === "KVA" ||
                    element.type === "POWER_FACTOR"
                    ? element.title
                    : TempObj?.title,
        abbreviation: element.abbreviation,
        value:
          value === undefined ? "-" : roundToTwoDecimalPlaces(value).toString(),
      };
    });
    return resultArr;
  };

  const hvacData = async () => {
    setFilterVariable(true);
    let arr = [
      {
        type: "TEMP_COMP_PCT",
        title: "Temperature Compliance",
        abbreviation: "%",
      },
      {
        type: "DEFROST_CYCLE",
        title: "Count Of Defrost Cycles",
        abbreviation: "",
        parent: "eventSummary",
        valueCategory: "cycleCount",
      },
      {
        type: "DEFROST_CYCLE",
        title: "Average Defrost Cycle Duration",
        abbreviation: "",
        parent: "eventSummary",
        valueCategory: "averageDuration",
      },
      {
        type: "DEFROST_CYCLE",
        title: "Average Time Between Defrost Cycles",
        abbreviation: "",
        parent: "eventSummary",
        valueCategory: "avgBetweenCycles",
      },
      {
        type: "VIR_DOOR_OPEN",
        title: "Count Of Door Opening Impacts",
        abbreviation: "",
        parent: "eventSummary",
        valueCategory: "cycleCount",
      },
      {
        type: "VIR_DOOR_OPEN",
        title: "Average Duration Of Door Opening Impacts",
        abbreviation: "",
        parent: "eventSummary",
        valueCategory: "averageDuration",
      },
      {
        type: "VIR_DOOR_OPEN",
        title: "Average Time Between Door Openings",
        abbreviation: "",
        parent: "eventSummary",
        valueCategory: "avgBetweenCycles",
      },
      {
        type: "PHY_DOOR_OPEN",
        title: "Average duration of door openings",
        abbreviation: "",
        parent: "eventSummary",
        valueCategory: "averageDuration",
      },
      {
        type: "PHY_DOOR_OPEN",
        title: "Average time between door openings",
        abbreviation: "",
        parent: "eventSummary",
        valueCategory: "avgBetweenCycles",
      },
      {
        type: "PHY_DOOR_OPEN",
        title: "count of door openings",
        abbreviation: "",
        parent: "eventSummary",
        valueCategory: "cycleCount",
      },
    ];

    const filterData = getSummaryData(arr);
    const tempFilterData = filterData.filter((item) => item.type !== undefined);
    setFinalData(tempFilterData);
    setFilterVariable(false);
  };

  const tempController = async () => {
    setFilterVariable(true);
    let arr = [
      {
        type: "TEMP_COMP_PCT",
        title: "Temperature Compliance",
        abbreviation: "%",
      },
    ];

    const filterData = getSummaryData(arr);
    const tempFilterData = filterData.filter((item) => item.type !== undefined);
    setFinalData(tempFilterData);
    setFilterVariable(false);
  };

  const tempEnergy = async () => {
    setFilterVariable(true);
    let arr = [
      {
        type: "TEMP_COMP_PCT",
        title: "Temperature Compliance",
        abbreviation: "%",
      },
      { type: "KVA", title: "Apparent Power", abbreviation: "kVA" },
      { type: "KWH", title: "Energy Consumed", abbreviation: "kWh" },
      {
        type: "DEFROST_CYCLE",
        title: "Count Of Defrost Cycles",
        abbreviation: "",
        parent: "eventSummary",
        valueCategory: "cycleCount",
      },
      {
        type: "DEFROST_CYCLE",
        title: "Average Defrost Cycle Duration",
        abbreviation: "",
        parent: "eventSummary",
        valueCategory: "averageDuration",
      },
      {
        type: "DEFROST_CYCLE",
        title: "Average Time Between Defrost Cycles",
        abbreviation: "",
        parent: "eventSummary",
        valueCategory: "avgBetweenCycles",
      },
      {
        type: "CMPRSR_OFF",
        title: "OFF Cycle Count",
        abbreviation: "",
        parent: "eventSummary",
        valueCategory: "cycleCount",
      },
      {
        type: "CMPRSR_OFF",
        title: "Average OFF Cycle Duration",
        abbreviation: "",
        parent: "eventSummary",
        valueCategory: "averageDuration",
      },
      {
        type: "CMPRSR_ON",
        title: "ON Cycle Count",
        abbreviation: "",
        parent: "eventSummary",
        valueCategory: "cycleCount",
      },
      {
        type: "CMPRSR_ON",
        title: "Average ON Cycle Duration",
        abbreviation: "",
        parent: "eventSummary",
        valueCategory: "averageDuration",
      },
      {
        type: "VIR_DOOR_OPEN",
        title: "Count Of Door Opening Impacts",
        abbreviation: "",
        parent: "eventSummary",
        valueCategory: "cycleCount",
      },
      {
        type: "VIR_DOOR_OPEN",
        title: "Average Duration Of Door Opening Impacts",
        abbreviation: "",
        parent: "eventSummary",
        valueCategory: "averageDuration",
      },
      {
        type: "VIR_DOOR_OPEN",
        title: "Average Time Between Door Openings",
        abbreviation: "",
        parent: "eventSummary",
        valueCategory: "avgBetweenCycles",
      },
      {
        type: "CMPRSR_TOTAL",
        title: "Compressor Total Count",
        abbreviation: "",
        parent: "eventSummary",
        valueCategory: "total",
      },
      // {
      //   type: "CMPRSR_AVG_DUR",
      //   title: "Average Compressor Cycle Duration",
      //   abbreviation: "",
      //   parent: "eventSummary",
      //   valueCategory: "average",
      // },
    ];

    const filterData = getSummaryData(arr);
    const tempFilterData = filterData.filter((item) => item.type !== undefined);
    setFinalData(tempFilterData);
    setFilterVariable(false);
  };

  const ebdata = async () => {
    setFilterVariable(true);
    let arr = [
      {
        type: "Wh_EB",
        title: "Energy Consumed (kWh)",
        value: "",
        abbreviation: "kWh",
      },
      {
        type: "POWER_FACTOR",
        title: "Average Power Factor",
        value: "",
        abbreviation: "",
      },
      {
        type: "KVA",
        title: "Actual Demand (kVA)",
        value: "",
        abbreviation: "kVA",
      },
      {
        type: "KVAH",
        title: "Energy Consumed (kVAh)",
        value: "",
        abbreviation: "kVAh",
      },
    ];

    let list = arr.map((ele) => {
      let TempObj = data.find((obj: any) => obj.type === ele.type);
      if (TempObj === undefined) {
      } else {
        return {
          type: TempObj?.type,
          title: ele?.title,
          abbreviation: ele?.abbreviation,
          value:
            TempObj?.type === "POWER_FACTOR"
              ? subtractWholeNumber(TempObj?.average, 2)
              : TempObj?.value ||
              TempObj?.averageTemperature ||
              TempObj?.average,
        };
      }
    });
    setFinalData(list);
    setFilterVariable(false);
  };

  const Dgdata = async () => {
    setFilterVariable(true);
    let arr = [
      {
        type: "NEUTRAL_AMP",
        title: "",
        value: "",
        abbreviation: "A",
      },
      {
        type: "POWER_FACTOR",
        title: "",
        value: "",
        abbreviation: "",
      },
      {
        type: "KW",
        title: "",
        value: "",
        abbreviation: "kW",
      },
      {
        type: "KVA",
        title: "",
        value: "",
        abbreviation: "kVA",
      },
      {
        type: "KVAR",
        title: "",
        value: "",
        abbreviation: "kVAR",
      },
      {
        type: "FREQUENCY",
        title: "",
        value: "",
        abbreviation: "Hz",
      },
      {
        type: "TA",
        title: "",
        value: "",
        abbreviation: "A",
      },
      {
        type: "VLN_AV",
        title: "",
        value: "",
        abbreviation: "V",
      },
      {
        type: "VBAT",
        title: "",
        value: "",
        abbreviation: "V",
      },

      {
        type: "Wh_DG",
        title: "",
        value: "",
        abbreviation: "kWh",
      },
      {
        type: "KVAH_DG",
        title: "",
        value: "",
        abbreviation: "kVAh",
      },
      {
        type: "RUNHR",
        title: "",
        value: "",
        abbreviation: "hrs",
      },
      {
        type: "ENGSPD",
        title: "",
        value: "",
        abbreviation: "RPM",
      },
      {
        type: "OILPRESS",
        title: "",
        value: "",
        abbreviation: "Bar",
      },
      {
        type: "FUEL_REMAINING",
        title: "",
        value: "",
        abbreviation: "L",
      },
      {
        type: "FUEL_LEVEL_PCT",
        title: "",
        value: "",
        abbreviation: "%",
      },
      {
        type: "COOLTEMP",
        title: "",
        value: "",
        abbreviation: "C",
      },
    ];
    let list = arr.map((ele) => {
      let TempObj = data.find((obj: any) => obj.type === ele.type);
      if (TempObj === undefined) {
      } else {
        return {
          type: TempObj?.type,
          title: TempObj?.title,
          abbreviation: ele?.abbreviation,
          value:
            TempObj?.value || TempObj?.averageTemperature || TempObj?.average,
        };
      }
    });
    setFinalData(list);
    setFilterVariable(false);
  };

  const waterData = async () => {
    setFilterVariable(true);
    let arr = [
      {
        type: "WTR_FLOW_AGR",
        title: "Water consumption",
        value: "",
        abbreviation: "",
      },
      {
        type: "F_VOLUME",
        title: "Average flow rate",
        value: "",
        abbreviation: "",
      },
    ];
    let list = arr.map((ele) => {
      let TempObj = data.find((obj: any) => obj.type === ele.type);
      if (TempObj === undefined) {
      } else {
        return {
          type: TempObj?.type,
          title: ele?.title,
          abbreviation: TempObj?.abbreviation,
          value:
            ele?.title === "Water consumption"
              ? TempObj?.value
              : TempObj?.average,
        };
      }
    });
    setFinalData(list);
    setFilterVariable(false);
  };

  const airData = async () => {
    setFilterVariable(true);
    let arr = [
      {
        type: "AIR_FLOW_AGR",
        title: "Compressed Air Consumed",
        value: "",
        abbreviation: "",
      },
      {
        type: "F_MASS",
        title: "Average flow rate",
        value: "",
        abbreviation: "",
      },
    ];
    let list = arr.map((ele) => {
      let TempObj = data.find((obj: any) => obj.type === ele.type);
      if (TempObj === undefined) {
      } else {
        return {
          type: TempObj?.type,
          title: ele?.title,
          abbreviation: TempObj?.abbreviation,
          value:
            ele?.title === "Compressed Air Consumed"
              ? TempObj?.value
              : TempObj?.average,
        };
      }
    });
    setFinalData(list);
    setFilterVariable(false);
  };

  const airQualityData = async () => {
    setFilterVariable(true);
    let arr = [
      {
        type: "CO2",
        title: "Carbon dioxide",
        value: "",
        abbreviation: "",
      },
      {
        type: "PM1_0",
        title: "PM 1.0",
        value: "",
        abbreviation: "",
      },

      {
        type: "PM2_5",
        title: "PM2_5",
        value: "",
        abbreviation: "",
      },

      {
        type: "PM10",
        title: "PM10",
        value: "",
        abbreviation: "",
      },

      {
        type: "TVOC",
        title: "Total volatile organic compound",
        value: "",
        abbreviation: "",
      },
      {
        type: "HUM",
        title: "Humidity",
        value: "",
        abbreviation: "",
      },
      {
        type: "HCHO",
        title: "Formaldehyde",
        value: "",
        abbreviation: "",
      },
      {
        type: "TEMP",
        title: "Average Temperature",
        abbreviation: "%",
      },
    ];
    let list = arr.map((ele) => {
      let TempObj = data.find((obj: any) => obj.type === ele.type);
      if (TempObj === undefined) {
      } else {
        return {
          type: TempObj?.type,
          title: TempObj?.title,
          abbreviation: TempObj?.abbreviation,
          value:
            TempObj?.title === "Temperature"
              ? TempObj?.averageTemperature
              : TempObj?.average,
        };
      }
    });
    setFinalData(list);
    setFilterVariable(false);
  };

  const pressureData = async () => {
    setFilterVariable(true);
    let arr = [
      {
        type: "PRESSURE",
        title: "Pressure",
        value: "",
        abbreviation: "",
      },
    ];
    let list = arr.map((ele) => {
      let TempObj = data.find((obj: any) => obj.type === ele.type);
      if (TempObj === undefined) {
      } else {
        return {
          type: TempObj?.type,
          title: TempObj?.title,
          abbreviation: TempObj?.abbreviation,
          value:
            TempObj?.title === "Pressure"
              ? TempObj?.average
              : "",
        };
      }
    });
    setFinalData(list);
    setFilterVariable(false);
  }

  const waterPulseData = async () => {
    setFilterVariable(true);
    let arr = [
      {
        type: "PULSE",
        title: "Water Pulse Count",
        value: "",
        abbreviation: "",
      }, {
        type: "WATER_VOLUME",
        title: "Water consumption in Litres",
        value: "",
        abbreviation: "L",
      },
    ];
    let list = arr.map((ele) => {
      let TempObj = data.find((obj: any) => obj.type === ele.type);
      if (TempObj === undefined) {
      } else {
        return {
          type: TempObj?.type,
          title: TempObj?.title,
          abbreviation: TempObj?.abbreviation,
          value: TempObj?.value,
        };
      }
    });
    setFinalData(list);
    setFilterVariable(false);
  }

  const chillerData = async () => {
    setFilterVariable(true);
    let arr = [
          {
            type: "CHW_ENT_WTR_TEMP",
            title: "",
            value: "",
            abbreviation: "",
          },
          {
            type: "CHW_EXIT_WTR_TEMP",
            title: "",
            value: "",
            abbreviation: "",
          },
          {
            type: "COND_ENT_WTR_TEMP",
            title: "",
            value: "",
            abbreviation: "",
          },
          {
            type: "COND_EXIT_WTR_TEMP",
            title: "",
            value: "",
            abbreviation: "",
          },
          {
            type: "CHW_DIS_TEMP_1",
            title: "",
            value: "",
            abbreviation: "",
          },
          {
            type: "COMP_MTR_WIND_TEMP",
            title: "",
            value: "",
            abbreviation: "",
          },
          {
            type: "OIL_PRESS_DIFF",
            title: "",
            value: "",
            abbreviation: "",
          },
          {
            type: "TA",
            title: "",
            value: "",
            abbreviation: "",
          },
          {
            type: "EXV_POS_1",
            title: "",
            value: "",
            abbreviation: "",
          },
          {
            type: "ACT_DMD_LMT",
            title: "",
            value: "",
            abbreviation: "",
          },
          {
            type: "CAP_UTIL",
            title: "",
            value: "",
            abbreviation: "",
          },
          {
            type: "COND_PRESS",
            title: "",
            value: "",
            abbreviation: "",
          },
          {
            type: "CHW_DMD_LVL",
            title: "",
            value: "",
            abbreviation: "",
          },
          {
            type: "EVAP_PRESS",
            title: "",
            value: "",
            abbreviation: "",
          },
          {
            type: "CHW_SUPPLY_TEMP_1",
            title: "",
            value: "",
            abbreviation: "",
          },
          {
            type: "EXV_POS_2",
            title: "",
            value: "",
            abbreviation: "",
          }
        ]
    ;
    let list = arr.map((ele) => {
      let TempObj = data.find((obj: any) => obj.type === ele.type);
      if (TempObj === undefined) {
      } else {
        return {
          type: TempObj?.type,
          title: TempObj?.title,
          abbreviation: TempObj?.abbreviation,
          value: TempObj?.average,
        };
      }
    });
    setFinalData(list);
    setFilterVariable(false);
  }

  const filterData = async () => {
    if (faData.fixedAssetAssocWithTypeId === "TEMP") {
      hvacData();
    } else if (faData.fixedAssetAssocWithTypeId === "TEMP_ENERGY") {
      tempEnergy();
    } else if (faData.fixedAssetAssocWithTypeId === "TEMPCONTROLLER") {
      tempController();
    } else if (faData.fixedAssetAssocWithTypeId === "ENERGY") {
      ebdata();
    } else if (faData.fixedAssetAssocWithTypeId === "DG") {
      Dgdata();
    } else if (faData.fixedAssetAssocWithTypeId === "WATER") {
      waterData();
    } else if (faData.fixedAssetAssocWithTypeId === "AIR") {
      airData();
    } else if (faData.fixedAssetAssocWithTypeId === "IAQ") {
      airQualityData();
    }
    else if (faData.fixedAssetAssocWithTypeId === "WATER_PULSE") {
      waterPulseData();
    }
    else if (faData.fixedAssetAssocWithTypeId === "PRESSURE") {
      pressureData();
    }
    else if (faData.fixedAssetAssocWithTypeId === "CHILLER") {
      chillerData();
    }
  };

  useEffect(() => {
    if (R.isEmpty(data)) {
      setFinalData([]);
    } else if (data === null) {
    } else if (data === undefined) {
    } else {
      filterData();
    }
  }, []);

  return (
    <div className="summaryParentCard">
      <div className="colOne  row">
        {finalData?.map((ele: any, index: any) => {
          return (
            <div className="summary-cards summary-cards p-0" key={index}>
              <SummaryCards
                type={ele?.type}
                value={ele?.value}
                title={ele?.title}
                abbreviation={ele?.abbreviation}
                average={ele?.average}
                averageTemperature={ele?.averageTemperature}
                maximumTemperature={ele?.maximumTemperature}
                minimumTemperature={ele?.minimumTemperature}
              />
            </div>
          );
        })}
      </div>
      <div className="colTwo"></div>
    </div>
  );
}

export default SummaryParentCard;
