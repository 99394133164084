/* eslint-disable react-hooks/rules-of-hooks */
// @ts-nocheck
import axios from "axios";
import * as R from "ramda";
import AssetFilterUtil from "./AssetFilterUtil";
import { API_BASE_URL } from ".././Constants/constants";
import {roundToTwoDecimalNumber} from '../Utils/utils'

export default class AssetDataUtil {
  FILTER_HIERARCHY = ["region", "city", "cluster", "facility"];
  NON_HIERARCHY_FILTERS = ["status", "state", "assetType"];

  transformCategData(data: [], categName: any) {
    let transformedArr: {
      name: any;
      id: any;
      groupId: any;
      isSelected: boolean;
      isVisible: boolean;
    }[] = [];

    if (data !== null) {
      if (categName === "@assetTypes") {
        // eslint-disable-next-line array-callback-return
        data.map((assetTypeObj: string) => {
          transformedArr.push({
            name: assetTypeObj.assetType,
            id: assetTypeObj.assetTypeId,
            groupId: assetTypeObj.assetType,
            isSelected: false,
            isVisible: true,
          });
        });
      } else if (categName === "facilityIds") {
        data.map((list: any) => {
          transformedArr.push({
            name: list?.facilityName,
            id: list?.facilityId,
            groupId: list?.parentFacilityGroupId,
            isSelected: false,
            isVisible: true,
          });
        });
      } else {
        data.map((list: any) => {
          transformedArr.push({
            name: list?.facilityGroupName || list?.facilityId,
            id: list?.facilityGroupId || list?.facilityId,
            groupId: list?.parentFacilityGroupId,
            isSelected: false,
            isVisible: true,
          });
        });
      }
    }
    transformedArr.unshift({
      name: "All",
      id: "All",
      groupId: "All",
      isSelected: true,
      isVisible: true,
    });

    return transformedArr;
  }

  // async getPageFilterAssets(pageSize: number, facIdArr: any[], index: any){
  //   async function fetchFilteredAssets() {
  //     const userName = localStorage.getItem("userName");
  //     const password = localStorage.getItem("password");
  //     console.log("facilityID",facIdArr)

  //     const facIdStr = "";
  //     const pageIndex = 1
  //     console.log("facIdArr::", facIdArr, index);

  //     const assetTypeList_LocalDB =
  //       "https://snakeoil-api.atsuyatech.com/AssetID_Config";
  //     const resp_LocalDB = await axios.get(assetTypeList_LocalDB, {
  //       method: "GET",
  //       headers: { "Content-Type": "application/json" },
  //     });
  //     let assetTypeList = resp_LocalDB.data[0];

  //     const url = `https://aone.atsuyatech.com//api/1.0/assets?username=${userName}&password=${password}&assetTypeList=${assetTypeList}&facilityList=${facIdStr}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
  //     const resp = await axios.get(url, {
  //       method: "GET",
  //       headers: { "Content-Type": "application/json" },
  //     });
  //     return resp;
  //   }
  //   return fetchFilteredAssets().then((result) => {
  //     if (result && result.status === 200) {
  //       const assetList = result?.data?.assets;

  //       assetList.forEach((ele: any) => {
  //         ele.isVisible = true;
  //       });

  //       return assetList;
  //     }
  //   });
  // }

  getVisibilityCount(assetList: any) {
    const visibleAssets = assetList.filter((obj: any) => obj.isVisible);
    return visibleAssets.length;
  }

  async fetchAssets(assetFilters: any, pageSize: number, pageIndex: number) {
    const respObj: {
      assets: any;
      totalAssetCount: number;
      assetVisibleCount: number;
      assetCountByType: any;
    } = {
      assets: [],
      totalAssetCount: 0,
      assetVisibleCount: 0,
      assetCountByType: {},
    };

    // console.log("assetFilters::", assetFilters);

    let assetResp: {
      assetList: any;
      totalAssetCount: any;
      assetCountByType: any;
    } = {
      assetList: [],
      totalAssetCount: 0,
      assetCountByType: {},
    };

    assetResp = await this.getFilteredAssets(assetFilters, pageSize, pageIndex);

    if (assetResp === "err") {
      return assetResp;
    }

    const visibleAssetCount = this.getVisibilityCount(assetResp?.assetList);

    respObj.assetVisibleCount = visibleAssetCount;
    respObj.assets = assetResp?.assetList;
    respObj.totalAssetCount = assetResp?.totalAssetCount;
    respObj.assetCountByType = assetResp?.assetCountByType;

    return respObj;
  }

  async getFilteredAssets(assetFilters: any, pageSize: any, pageIndex: number) {
    async function fetchFilteredAssets() {
      const token_id:any = localStorage.getItem("@tokenID");
      const assetTypesLocal:any = localStorage.getItem("assetTypes");
      console.log("assetTypesLocalassetTypesLocal", assetTypesLocal)

      let pageSizeVal = pageSize.toString();
      let pageIndexStr = pageIndex.toString();

      // const assetTypeList_LocalDB =
      //   "https://snakeoil-api.atsuyatech.com/AssetID_Config";
      // const resp_LocalDB = await axios.get(assetTypeList_LocalDB, {
      //   method: "GET",
      //   headers: { "Content-Type": "application/json" },
      // });
      // let assetTypeList = await resp_LocalDB.data[0];

      let url = `${API_BASE_URL}assets?token=${token_id}&pageSize=${pageSizeVal}&pageIndex=${pageIndexStr}&assetTypeList=${assetTypesLocal}&mode=view`;

      /* Get the relevant list of strings */

      // Checking for Facility Ids
      if (!R.isEmpty(assetFilters.facIds) && !R.isNil(assetFilters.facIds)) {
        const facIdStr = assetFilters.facIds.join(",").toString();
        url += `&facilityList=${facIdStr}`;
      }

      // Checking for Health Status Ids
      if (
        !R.isEmpty(assetFilters.healthStatusIds) &&
        !R.isNil(assetFilters.healthStatusIds)
      ) {
        const healthStatusStr = assetFilters.healthStatusIds
          .join(",")
          .toString();
        url += `&healthStatus=${healthStatusStr}`;
      }

      // Checking for compliance
      if (
        !R.isEmpty(assetFilters.complianceIds) &&
        !R.isNil(assetFilters.complianceIds)
      ) {
        const complianceStr = assetFilters.complianceIds.join(",").toString();
        url += `&complianceTypes=${complianceStr}`;
      }

      // Checking for Monitored Asset Type List
      if (
        !R.isEmpty(assetFilters.monitoredAssetTypeIds) &&
        !R.isNil(assetFilters.monitoredAssetTypeIds)
      ) {
        const monAssetTypeIdStr = assetFilters.monitoredAssetTypeIds
          .join(",")
          .toString();
        url += `&monitoredAssetTypeList=${monAssetTypeIdStr}`;
      }

      let cancelToken = axios.CancelToken.source();
      if (cancelToken) {
        cancelToken.cancel("Operations cancel due to new request");
      }
      const resp = await axios.get(url, {
        // cancelToken: cancelToken.token,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      return resp;
    }
    return fetchFilteredAssets()
      .then((result) => {
        if (result && result.status === 200) {
          let assetTypes =  result?.data?.assets.map(
            (x) => x.assetTypeDesc
          );
          let uniqueTypes = [...new Set(assetTypes)];

          this.getAssetTypes(uniqueTypes);

          const tempAssetList =  result?.data?.assets;
          const totalAssetCount =  result?.data?.assetCount;
          const assetCountByType = result?.data?.assetCountByType;
          
          const assetList = tempAssetList
            .sort(
              (a, b) => b?.assetId.localeCompare(a?.assetId)
            );

          assetList.forEach((ele: any) => {
            ele.isVisible = true;
          });

          const respObj = {
            assetList,
            totalAssetCount,
            assetCountByType,
          };

          return respObj;
        } else {
          return "err";
        }
      })
      .catch((err) => {
        return "err";
      });
  }

  getAssetTypes(assetTypes: any) {
    const currAssetTypes = localStorage.getItem("@assetTypes")!;
    // console.log("currAssetTypes::", currAssetTypes);

    // console.log("newAssetTypes::", assetTypes);
  }

  async refreshAssetList(pageSize: any, pageIndex: number) {
    async function fetchAssets() {
      const token_id:any = localStorage.getItem("@tokenID");
      const assetTypesLocal:any = localStorage.getItem("assetTypes");
      console.log("assetTypesLocalassetTypesLocal",assetTypesLocal)
      let pageSizeVal = pageSize.toString();
      const pageIndexStr = pageIndex.toString();
      // console.log("page index", pageIndexStr);
      // const assetTypeList_LocalDB =
      //   "https://snakeoil-api.atsuyatech.com/AssetID_Config";
      // const resp_LocalDB = await axios.get(assetTypeList_LocalDB, {
      //   method: "GET",
      //   headers: { "Content-Type": "application/json" },
      // });
      // let assetTypeList = await resp_LocalDB.data[0];
      // console.log("assetTypeList::", assetTypeList);
      const url = `${API_BASE_URL}assets?token=${token_id}&pageSize=${pageSizeVal}&pageIndex=${pageIndexStr}&assetTypeList=${assetTypesLocal}&mode=view`;
      const resp = await axios.get(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      return resp;
    }
    return fetchAssets().then((result) => {
      if (result && result.status === 200) {
        // console.log("676767", result);
        if (result?.data?.assetCountByType) {
          const assetTypeList = result?.data?.assetCountByType.map(
            (obj: any) => {
              return {
                assetTypeId: obj.assetTypeId,
                assetType: obj.assetType,
              };
            }
          );
          localStorage.setItem("@assetTypes", JSON.stringify(assetTypeList));
        }

        const assetList = result?.data?.assets;
        const totalAssetCount = result?.data?.assetCount;

        // console.log("assetAPI::", result?.data);

        assetList.forEach((ele: any) => {
          ele.isVisible = true;
        });

        const respObj = {
          assetList,
          totalAssetCount,
        };

        return respObj;
      } else {
        return "err";
      }
    });
  }

  clearAssetFilters(assetFilters: any) {
    const keys = Object.keys(assetFilters);
    const newAssetFilterObj: any = {};
    keys.forEach((ele: any) => {
      if (
        typeof assetFilters[ele] === "object" &&
        !R.isEmpty(assetFilters[ele])
      ) {
        const arrCopy = assetFilters[ele].slice();
        arrCopy.forEach((arrEle: any) => {
          if (arrEle.id === "All") arrEle.isSelected = true;
          else arrEle.isSelected = false;

          arrEle.isVisible = true;
        });
        newAssetFilterObj[ele] = arrCopy;
      } else {
        newAssetFilterObj[ele] = assetFilters[ele];
      }
    });

    assetFilters.isFilterApplied = false;

    return newAssetFilterObj;
  }

  adjustFacListVisibility(assetFilters: any) {
    const parentCateg = "cluster";
    const childCateg = "facility";

    const parentIdArr: any = [];
    const facIdArr: any = [];

    if (assetFilters[parentCateg][0].isSelected) {
      // All is selected

      assetFilters[parentCateg].forEach(
        (eleParent: any, indexParent: number) => {
          if (
            indexParent !== 0 &&
            eleParent.isVisible &&
            !parentIdArr.includes(eleParent.id)
          ) {
            parentIdArr.push(eleParent.id);
          }
        }
      );
    } else {
      assetFilters[parentCateg].forEach((eleParent: any) => {
        if (
          eleParent.isSelected &&
          eleParent.isVisible &&
          !parentIdArr.includes(eleParent.id)
        ) {
          parentIdArr.push(eleParent.id);
        }
      });
    }

    // Iterate through child and see if the group Id of child exists in the parentArr
    assetFilters[childCateg].forEach((eleChild: any) => {
      if (parentIdArr.includes(eleChild.groupId) || eleChild.id === "All") {
        if (!facIdArr.includes(eleChild.id) && eleChild.id !== "All") {
          facIdArr.push(eleChild.id);
        }
        eleChild.isVisible = true;
      } else {
        eleChild.isVisible = false;
      }
    });

    const respObj = {
      assetFilters,
      facIdArr,
    };

    return respObj;
  }

  getChosenFacList(assetFilters: any) {
    let chosenFacList;
    assetFilters = assetFilters.assetFilters;
    // console.log("assetFilters::", assetFilters);
    if (assetFilters.facility[0].isSelected) {
      chosenFacList =  assetFilters.facility.filter(
        (obj: any) => obj.isVisible && obj.name !== "All"
      );
    } else {
      chosenFacList =  assetFilters.facility.filter(
        (obj: any) => obj.isSelected
      );
    }

    return chosenFacList;
  }

  checkFilterAllUnselect(assetFilters: any) {
    const keys = Object.keys(assetFilters);
    const newAssetFilterObj: any = {};
    keys.forEach((ele: any) => {
      if (
        typeof assetFilters[ele] === "object" &&
        !R.isEmpty(assetFilters[ele])
      ) {
      }
    });
  }

  async getFacIdList(assetFilters: any) {
    let facList = [];
    if (assetFilters.facility[0].isSelected) {
      facList = assetFilters.facility.filter(
        (obj: any) => obj.isSelected && obj.isVisible && obj.name !== "All"
      );
    } else {
      facList = assetFilters.facility.filter((obj: any) => obj.isSelected);
    }
    // console.log("facList::", facList);
    return facList;
  }
}
