import React, { useState } from "react";
import "./FaultDetailsCard.scss";

import MoreIcon from "../../../assets/icons/MoreIcon.svg";
import FaultDetailsCradPopup from "./FaultDetailsCradPopup";

function FaultDetailsCard() {
  const [popupState, setPopupState] = useState(false);
  const closeModalHandler = () => {
    setPopupState(false);
  };
  const handleShow = () => {
    setPopupState(true);
  };
  // const [popupState, setPopupState] = useState(false);
  return (
    <div className="faultDetailsCardContainer">
      <p className="motorTxt">MOTOR-07</p>
      <p className="faultTxt">Broken rotor bar fault</p>
      <p className="conditionTxt">UNHEALTHY</p>
      <ul className="locationContainer">
        <li className="areaTxt">Drying Area</li>
        <li className="addressTxt">Plant 1, Delhi</li>
      </ul>
      <p className="checkTxt">Check the device.</p>
      <img
        className="moreImg"
        src={MoreIcon}
        alt="moreImg"
        onClick={handleShow}
      />
      <div>
        {
          popupState&& <FaultDetailsCradPopup
             className="modal"
             show={popupState}
             close={closeModalHandler}
           />
        }
      </div>
      
    </div>
  );
}

export default FaultDetailsCard;
