import React, { useEffect, useState } from "react";
import axios from "axios";
// import the core library.
import ReactEChartsCore from "echarts-for-react/lib/core";
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from "echarts/core";
// Import charts, all with Chart suffix
import { BarChart } from "echarts/charts";
import * as R from "ramda";
// import components, all suffixed with Component
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
} from "echarts/components";
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import { CanvasRenderer } from "echarts/renderers";

// Register the required components
function StackedBarAnovi({ data, comp }: any) {
  const [value, setValue] = useState<any>([]);
  const [label, setLabel] = useState<any>([]);

  console.log("StackedBarAnovi", data);

  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    CanvasRenderer,
  ]);

  // const fetchApiData = async () => {
  //   // const url =`https://aone-qa.app.aone.ai/api/1.0/assets/assetsComplianceSummary?username=superadmin@si&password=si@superadmin&fromDate=1680373799000&thruDate=1680546599000`
  //   const url = `https://aone-qa.app.aone.ai/api/1.0/assets/complianceSummary?username=superadmin@si&password=si@superadmin&fromDate=1680373799000&thruDate=1680546599000`;
  //   const resp = await axios.get(url, {
  //     method: "GET",
  //     headers: { "Content-Type": "application/json" },
  //   });
  //   if (!R.isEmpty(resp)) {
  //     console.log("respddtaa", resp);
  //     const compliancePercentArr = resp?.data.complianceSummary[0]?.data.map(
  //       (el: any) => el.compliancePercent
  //     );
  //     const facilityNameArr = resp?.data?.complianceSummary[0]?.data.map(
  //       (el: any) => el.facilityName
  //     );
  //     setValue(compliancePercentArr);
  //     setLabel(facilityNameArr);
  //   }
  // };

  useEffect(() => {
    if (comp === "alerts") {
    } else {
      console.log("datadatadata", data);
      const compliancePercentArr = data?.complianceSummary[0]?.data.map(
        (el: any) => el.compliancePercent
      );
      const facilityNameArr = data?.complianceSummary[0]?.data.map(
        (el: any) => el.facilityName
      );
      console.log("datadatadata", compliancePercentArr, facilityNameArr);
      setValue(compliancePercentArr);
      setLabel(facilityNameArr);
    }
  }, [data]);

  // var option = {
  //   color: ["#ff6b2d", "#4caf50"],
  //   legend: {},
  //   tooltip: {},
  //   dataset: {
  //     dimensions: ["product", "open"],
  //     source: [
  //       { product: "13 jan", open: 43.3 },
  //       { product: "14 jan", open: 83.1 },
  //       { product: "15 jan", open: 43.3 },
  //       { product: "16 jan", open: 83.1 },
  //       { product: "17 jan", open: 43.3 },
  //       { product: "18 jan", open: 83.1 },
  //     ],
  //   },
  //   xAxis: { type: "category" },
  //   yAxis: {},
  //   // Declare several bar series, each will be mapped
  //   // to a column of dataset.source by default.
  //   series: [{ type: "bar" ,barMaxWidth:"30"}],
  // };

  // var option = {
  //   color: ["#ff6b2d", "#4caf50"],
  //   legend: {},
  //   tooltip: {},
  //   dataset: {
  //     dimensions: ["compliancePercent"],
  //     source: !R.isEmpty(firstData) && !R.isNil(firstData) && firstData
  //   },
  //   xAxis: { type: "category" },
  //   yAxis: {},
  //   // Declare several bar series, each will be mapped
  //   // to a column of dataset.source by default.
  //   series: [{ type: "bar" ,barMaxWidth:"30"}],
  // };
  var option = {
    color: ["#0075FF"],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    legend: {
      show: true,
      bottom: 0,
      left: 0,
      itemWidth: 12,
      itemHeight: 12,
      textStyle: { color: "#0B0B0C", fontSize: 12 },
    },

    grid: {
      left: "1%",
      right: "1%",
      bottom: 35,
      top: "5%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      rotate: 90,
      data: label,

      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        interval: 0,
        rotate: 40,
        fontSize: 10,
        align: "right",
        verticalAlign: "middle",
        lineHeight: 8,
      },
    },
    dataset: {},
    yAxis: {
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: [
      {
        data: value,
        type: "bar",
        label: {
          show: true,
          position: "top",
        },
      },
    ],
    dataZoom: [
      {
        type: "slider",
        start: 80,
        end: 100,
        show: true,
      },
    ],
  };

  return (
    <div style={{ margin: "30px" }}>
      <ReactEChartsCore
        echarts={echarts}
        option={comp === "alerts" ? data : option}
        notMerge={true}
        lazyUpdate={true}
        theme={"theme_name"}
      />
    </div>
  );
}

export default StackedBarAnovi;
