import React, { useEffect } from "react";

function AoneTrolley() {
  useEffect(() => {
    window.open("https://aone-app.atsuyatech.com/");
  }, []);
  return <div></div>;
}

export default AoneTrolley;
