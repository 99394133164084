import React, { useState } from "react";
import "./CustomSwitch.scss";

function CustomSwitch(props:any) {
  const {callBack,check} = props;
  return (
    <label className="toggle-switch">
      <input type="checkbox" checked={!check} onChange={callBack} />
      <span className="switch" />
    </label>
  );
}

export default CustomSwitch;
