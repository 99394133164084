/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment";
import { number } from "prop-types";
import * as R from "ramda";
import { useState, useEffect } from "react";
import "./style.scss";

function Water({ data, abbre, dailyMeters,lastCommTime }:any) {
  const [wtrData, setWtrData] = useState();
  const [flowRate, setFlowRate] = useState()
  useEffect(() => {
    dailyMeters.map((ele) => {
      ele.meterTypeId.includes("WTR_FLOW_AGR") && setWtrData(ele);
    });

    // F_VOLUME
    dailyMeters.map((ele) => {
      ele.meterTypeId.includes("F_VOLUME") && setFlowRate(ele);
    });
  }, [dailyMeters]);
  const [latestTime, setLatestTime] = useState();

  useEffect(() => {
    !R.isEmpty(lastCommTime) && !R.isNil(lastCommTime) && lastCommTime !== null && setLatestTime(lastCommTime) ;
  }, [lastCommTime]);
  return (
    <>
      <div className="lastUpdated">
        {!R.isEmpty(latestTime) && !R.isNil(latestTime) ? (
          <h3>Updated {moment(latestTime).fromNow()}</h3>
        ) : (
          <h3>&nbsp;</h3>
        )}
      </div>
      <div className="assetCard-colum">
        <div className="assetCard-col">
          <p> Today Water Consumed </p>
          {!R.isEmpty(wtrData) ? (
            <h2>
              {wtrData?.sum.toFixed(2)} <span>{wtrData?.defaultUom}</span>
            </h2>
          ) : (
            <h2></h2>
          )}
        </div>

        <div className="assetCard-col">
          <p>Average Flow Rate</p>
          {!R.isEmpty(flowRate) ? (
            <h2>
              {flowRate?.average.toFixed(2)} <span>{flowRate?.defaultUom}</span>
            </h2>
          ) : (
            <h2></h2>
          )}
        </div>
      </div>
    </>
  );
}

export default Water;
