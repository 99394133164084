import moment from "moment";
import LocalStorageService from "../../Utils/LocalStorageService";
import { getAssetTypeDesc, getFacilityDetails } from "../assetList/utils";
import { AssetDetailsServices } from "./AssetDetailsServices";
import { formatNumberData } from "../../Utils/utils";

export const convertDynamicsButtonsData = (data) => {

    let buttonsArr = [];
    data.filter((obj)=> obj.liveData !== undefined && obj.liveData !== null && obj.liveData.length !== 0 ).forEach((obj)=>{
        // first I'm pushing parent to the buttonsArr.
        if(obj.parentTypeDescription === null){
        buttonsArr.push({ name:obj.description, color: obj.colorCode, options: [], sequenceNo : obj.sequenceNum, liveData : obj.liveData == undefined ? []: obj.liveData })
        }
        if(obj.parentTypeDescription !== null){
            if(buttonsArr.find((ele)=> ele.name === obj.parentTypeDescription) === undefined){
            buttonsArr.push({ name:obj.parentTypeDescription, color: obj.colorCode, options: [], sequenceNo : obj.sequenceNum, liveData : obj.liveData == undefined ? []: obj.liveData})
        }
        }
    })

    const addChildsToParents = (data, buttonsArr) => {
        data.forEach((obj)=>{
            if(obj.parentTypeDescription !== null){
                buttonsArr = buttonsArr.map((ele)=> {
                    if(ele.name === obj.parentTypeDescription){
                        ele.options.push({ name:obj.description, color: obj.colorCode, sequenceNo : obj.sequenceNum, liveData : obj.liveData == undefined ? []: obj.liveData })
                        return ele;
                    }
                    return ele;
                })}
        })
        return buttonsArr.map((obj)=> {
            if(obj.options.length !== 0){
                obj.sequenceNo =  Math.min(...obj.options.map((el)=> el.sequenceNo));
                obj.options = obj.options.sort((a,b)=> a.sequenceNo - b.sequenceNo);
            }
            return obj;
         } ).sort((a,b)=> a.sequenceNo - b.sequenceNo).filter((el)=> el.liveData !== undefined && el.liveData.length !== 0);
      }


  return addChildsToParents(data, buttonsArr);
};
export const adjustOpacity = (rgbaColor, opacity) => {
    if(rgbaColor !== null){
        const rgbaRegex = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.?\d*)?)?\)/;
        const match = rgbaColor.match(rgbaRegex) === undefined ? 'rgb(84,112,198)' : rgbaColor.match(rgbaRegex);
        
        if (match) {
          const [_, r, g, b] = match;
          return `rgba(${r}, ${g}, ${b}, ${opacity})`;
        } else {
          throw new Error('Invalid rgba color format');
        }
    }else{ return 'white' }
   
};
export const groupLiveMetersData = (data) =>{
  let findParentArr = [];
  const respArr = data;
  respArr.forEach((obj)=>{
      if(obj.parentTypeId === null){
          findParentArr.push({parentTypeId: obj.parentTypeId, threshColor: obj.thresholdColorCode, lowerThresholdAttr : obj.lowerThresholdAttr, upperThresholdAttr :obj.upperThresholdAttr, name: obj.description, color: obj.colorCode, readingUomDesc: obj.readingUomDesc, value : obj.meterValue, imgUrl: obj.contentUrl, decimalControl: obj.decimalPrecision, options :[], sequenceNo: obj.sequenceNum });
      }else if(findParentArr.find((el)=> el.parentTypeId === obj.parentTypeId) === undefined ){
          findParentArr.push({parentTypeId: obj.parentTypeId, threshColor: obj.thresholdColorCode, lowerThresholdAttr : obj.lowerThresholdAttr, upperThresholdAttr :obj.upperThresholdAttr, name: obj.parentTypeDescription, readingUomDesc: obj.readingUomDesc, color: obj.colorCode, value: obj.meterValue, imgUrl: obj.contentUrl, decimalControl: obj.decimalPrecision, options :[], sequenceNo: obj.sequenceNum })
      }
  })
  // grouping childrens to parent ARR
  respArr.forEach((obj)=>{
      if(obj.parentTypeId !== null){
          findParentArr = findParentArr.map((ele)=> {
              if(ele.parentTypeId === obj.parentTypeId){
                  ele.options.push({ threshColor: obj.thresholdColorCode, lowerThresholdAttr : obj.lowerThresholdAttr, upperThresholdAttr :obj.upperThresholdAttr, name: obj.description,readingUomDesc : obj.readingUomDesc, value: obj.meterValue, color: obj.colorCode, imgUrl: obj.contentUrl, decimalControl: obj.decimalPrecision, sequenceNo: obj.sequenceNum })
                  return ele;
              }
              return ele;
          })}
  })
  // sorting based on the sequence....
  findParentArr = findParentArr.map((obj)=> {
      if(obj.options.length !== 0){
          obj.sequenceNo =  Math.min(...obj.options.map((el)=> el.sequenceNo));
      }
      return obj;
   } )

  return findParentArr.sort((a,b)=> a.sequenceNo - b.sequenceNo);
}
export const validatePowerFactor = (num,uom, decimal) => {
  num = num == null ? '-': num; 
  uom = uom == null ? '' : uom;
    decimal = decimal == null ? 2 : decimal;

  if(num == null){
    return '-'
  }
  if(num === 0){
    return 0 + " " + uom;
  }

  if (num <= 1) {
    return num.toFixed(decimal) + " " + uom;
  }
  if ((parseInt(num) - num).toFixed(2) == 0) {
    return 0 + " " + uom;
  }
  return (parseInt(num) - num).toFixed(2) + " " + uom;
}
export const validateNullValues = (value, uom, decimal) =>{
  uom = uom == null ? '' : uom; 
    decimal = decimal == null ? 2 : decimal;

  if(value === null){
    return '-'
  }
  else if(typeof value == "string"){ return value + " " + uom; }
  else{
    console.log('String::',value);
return formatNumberData(value, decimal) + " " + uom;
  }

}
export const distributeElemtsToThreeArrays = (data) =>{
  data = data.filter((el)=> el.options.length !== 0 )
  const arr1 = [];
  const arr2 = [];
  const arr3 = [];
  for (let i = 0; i < data.length; i++) {
      if (i % 3 === 0) {
          arr1.push(data[i]);
      } else if (i % 3 === 1) {
          arr2.push(data[i]);
      } else if (i % 3 === 2) {
          arr3.push(data[i]);
      }
  }
  return [arr1, arr2, arr3];
}
export const formattDatesForLiveData = (dates) =>{
  let previousDate = null;

  const formattedDates = dates.map(dateString => {
      const date = moment(dateString);
      let formattedDate;
  
      if (previousDate && previousDate.isSame(date, 'day')) {
          formattedDate = date.format('hh:mm A');
      } else {
          formattedDate = date.format('MMM DD, hh:mm A');
      }
  
      previousDate = date;
      return formattedDate;
  });
  return formattedDates;
}
export function getTimeAgo(lastCommTime) {
  if (!lastCommTime) return 'No data';

  const lastCommMoment = moment(lastCommTime);
  const now = moment();

  const duration = moment.duration(now.diff(lastCommMoment));
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  if (days > 0) {
    return `${days} day${days > 2 ? 's' : ''} ago`;
} else if (hours > 0) {
    return `${hours} hour${hours > 2 ? 's' : ''} ago`;
} else if (minutes > 0) {
    return `${minutes} minute${minutes > 2 ? 's' : ''} ago`;
} else {
    return 'a few seconds ago';
}

}
export const renderChart = (setChartEmptyErrorStatus,liveDataResponseObj,selectedOptions, setDynamicOptions, setShowChart ) => {
  setChartEmptyErrorStatus('');
  let dataZoomObj = JSON.parse(localStorage.getItem("dataZoom"));
  let start = dataZoomObj == null ? 0 : dataZoomObj.start;
  let end = dataZoomObj == null ? 100 : dataZoomObj.end;
  const shallowCopyData = [...liveDataResponseObj.current.assetRawData];
  const timeResponseObj = {...liveDataResponseObj.current.assetSpecificData};

  const findMinMax = (values) => {
    values = values.filter((el)=> el!== null);
    const min = Math.min(...values);
    const max = Math.max(...values);
    return { min, max };
  };

  if (selectedOptions.length == 1) {
      let dataObj = shallowCopyData.find((obj) => obj.description === selectedOptions[0].name);
      let valuesArr = dataObj.liveData === undefined ? [] : dataObj.liveData?.length !== 0 ? dataObj.liveData.map((obj) => { const { time, ...remValue } = obj; return Object.values(remValue)[0] }) : [];
      const { min, max } = findMinMax(valuesArr.map((el) => el !== null ? +el?.toFixed(dataObj.decimalPrecision == null ? 2 : dataObj?.decimalPrecision) : el));
    
     console.log(valuesArr);
      if (valuesArr.length === 0) {
          setChartEmptyErrorStatus('empty');
          return
      }
      let newOptions = {}
      if (dataObj.upperThresholdAttr !== null && dataObj.lowerThresholdAttr !== null && dataObj.assetAttr !== undefined &&
           dataObj.assetAttr.find((obj) => obj.attrName === dataObj.lowerThresholdAttr) !== undefined && 
           dataObj.assetAttr.find((obj) => obj.attrName === dataObj.upperThresholdAttr) !== undefined && 
           dataObj.assetAttr.find((obj) => obj.attrName === dataObj.lowerThresholdAttr).attrValue !== null &&
           dataObj.assetAttr.find((obj) => obj.attrName === dataObj.lowerThresholdAttr).attrValue !== null  )  {
          newOptions = {
              dataZoom: [
                  {
                      type: 'slider',
                      start: start,
                      end: end,
                  },
                  {
                      type: 'inside',
                      start: start,
                      end: end,
                  }
              ],
              tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                      type: 'cross',
                  },
              },
              grid: {
                left: 120,
                // right: 0,
                top: 10,
                bottom: 150,
              },
              xAxis: {
                  type: 'category',
                  boundaryGap: false,
                  data: timeResponseObj?.time?.length !== 0 ? formattDatesForLiveData(timeResponseObj?.time?.map((obj) => obj.time)) : [],
                  splitLine: {
                      show: true,
                      lineStyle: {
                          type: "dotted",
                          width: 2,
                          dashOffset: 4
                      }
                  },
                  axisLabel: {
                    rotate: 70
                  }
              },
              yAxis: {
                  type: 'value',
                  min:min,
                  max:max,
                  axisLabel: {
                    formatter: function(value) {
                        return value.toFixed(2);
                    },
                },
                  axisPointer: {
                      snap: true,
                  },
                  splitLine: {
                      show: true,
                      lineStyle: {
                          type: "dotted",
                          width: 2,
                          dashOffset: 4
                      }
                  }
              },
              visualMap: {
                  show: false,
                  dimension: 1,
                  pieces: [
                      {
                          lte: dataObj.assetAttr.find((obj) => obj.attrName === dataObj.lowerThresholdAttr) !== undefined ? +dataObj.assetAttr.find((obj) => obj.attrName === dataObj.lowerThresholdAttr).attrValue : 1,
                          color: 'red',
                      },
                      {
                          gt: dataObj.assetAttr.find((obj) => obj.attrName === dataObj.lowerThresholdAttr) !== undefined ? +dataObj.assetAttr.find((obj) => obj.attrName === dataObj.lowerThresholdAttr).attrValue : 1,
                          lte: dataObj.assetAttr.find((obj) => obj.attrName === dataObj.upperThresholdAttr) !== undefined ? +dataObj.assetAttr.find((obj) => obj.attrName === dataObj.upperThresholdAttr).attrValue : 2,
                          color: 'green',
                      },
                      {
                          gt: dataObj.assetAttr.find((obj) => obj.attrName === dataObj.upperThresholdAttr) !== undefined ? +dataObj.assetAttr.find((obj) => obj.attrName === dataObj.upperThresholdAttr).attrValue : 2,
                          color: 'red',
                      },
                  ],

              },
              series: [
                  {
                      name: dataObj.description,
                      type: 'line',
                      smooth: true,
                      data: valuesArr.map((el)=> el?.toFixed(dataObj.decimalPrecision == null ? 2 : dataObj.decimalPrecision )),
                      connectNulls: true,
                      markArea: {
                          silent: true,
                          data: [
                              [
                                  {
                                      yAxis: 0,
                                      itemStyle: {
                                          color: 'transparent',
                                      },
                                  },
                                  {
                                      yAxis: dataObj.assetAttr.find((obj) => obj.attrName === dataObj.lowerThresholdAttr) !== undefined ? +dataObj.assetAttr.find((obj) => obj.attrName === dataObj.lowerThresholdAttr).attrValue : 1,
                                  },
                              ],
                              [
                                  {
                                      yAxis: dataObj.assetAttr.find((obj) => obj.attrName === dataObj.lowerThresholdAttr) !== undefined ? +dataObj.assetAttr.find((obj) => obj.attrName === dataObj.lowerThresholdAttr).attrValue : 1,
                                      itemStyle: {
                                          color: 'lightgreen',
                                      },
                                  },
                                  {
                                      yAxis: dataObj.assetAttr.find((obj) => obj.attrName === dataObj.upperThresholdAttr) !== undefined ? +dataObj.assetAttr.find((obj) => obj.attrName === dataObj.upperThresholdAttr).attrValue : 2,
                                  },
                              ],
                              [
                                  {
                                      yAxis: dataObj.assetAttr.find((obj) => obj.attrName === dataObj.upperThresholdAttr) !== undefined ? +dataObj.assetAttr.find((obj) => obj.attrName === dataObj.upperThresholdAttr).attrValue : 2,
                                      itemStyle: {
                                          color: 'transparent',
                                      },
                                  },
                                  {
                                      yAxis: 'max',
                                  },
                              ],
                          ],
                      },
                  },
              ],
          }
      } else {
          newOptions = {
              dataZoom: [
                  {
                      type: 'slider',
                      start: start,
                      end: end,
                  },
                  {
                      type: 'inside',
                      start: start,
                      end: end,
                  }
              ],
              tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                      type: 'cross',
                  },
              },
              grid: {
                left: 120,
                  // right: 0,
                  top: 10,
                  bottom: 150,
              },
              xAxis: {
                  type: 'category',
                  boundaryGap: false,
                  data: timeResponseObj?.time?.length !== 0 ? formattDatesForLiveData(timeResponseObj?.time?.map((obj) => obj.time)) : [],
                  splitLine: {
                      show: true,
                      lineStyle: {
                          type: "dotted",
                          width: 2,
                          dashOffset: 4
                      }
                  },
                  axisLabel: {
                    rotate: 70
                  }
                  // axisLabel: {
                  //     formatter: function (value) {
                  //         return moment(value).format('hh:ss a');
                  //     }
                  // }
              },
              yAxis: {
                  type: 'value',
                  min:min,
                  max:max,
                  axisLabel: {
                    formatter: function(value) {
                        return value.toFixed(2);
                    },
                },
                  axisPointer: {
                      snap: true,
                  },
                  splitLine: {
                      show: true,
                      lineStyle: {
                          type: "dotted",
                          width: 2,
                          dashOffset: 4
                      }
                  }
              },
              series: [
                  {
                      name: dataObj.description,
                      type: 'line',
                      smooth: true,
                      data: valuesArr.map((el)=> el?.toFixed(dataObj.decimalPrecision == null ? 2 : dataObj.decimalPrecision )),
                      connectNulls: true,
                      lineStyle: {
                          color: dataObj.colorCode,
                      },
                      itemStyle: {
                          color: dataObj.colorCode,
                      },
                  },
              ],
          }
      }
      setDynamicOptions(newOptions)
      setShowChart(true);
  } else if (selectedOptions.length > 1) {
      let seriesDataArr = [];
      let allValues = [];
    //   let dataObj1 = shallowCopyData.find((obj) => obj.description === selectedOptions[0].name);
    //   if (dataObj1.liveData === undefined) {
    //       setChartEmptyErrorStatus('empty');
    //       return
    //   }
      selectedOptions.forEach((el) => {
          let dataObj = shallowCopyData.find((obj) => obj.description === el.name);
          let valuesArr = dataObj.liveData.length !== 0 ? dataObj.liveData.map((obj) => { const { devType, time, ...remValue } = obj; return Object.values(remValue)[0] }) : [];
        
          allValues = allValues.concat(valuesArr.filter(val => val !== null));

          seriesDataArr.push({
              name: dataObj.description,
              type: 'line',
              smooth: true,
              data: valuesArr.map((el)=> el?.toFixed(dataObj.decimalPrecision == null ? 2 : dataObj.decimalPrecision )),
              connectNulls: true,
              lineStyle: {
                  color: dataObj.colorCode,
              },
              itemStyle: {
                  color: dataObj.colorCode,
              },
          })
      })
      let min = Math.min(...allValues);
      let max = Math.max(...allValues);
      let newOptions = {
          dataZoom: [
              {
                  type: 'slider',
                  start: start,
                  end: end,
              },
              {
                  type: 'inside',
                  start: start,
                  end: end,
              }
          ],
          tooltip: {
              trigger: 'axis',
              axisPointer: {
                  type: 'cross',
              },
          },
          grid: {
            left: 120,
            // right: 0,
            top: 10,
            bottom: 150,
          },
          xAxis: {
              type: 'category',
              boundaryGap: false,
              data: timeResponseObj?.time?.length !== 0 ? formattDatesForLiveData(timeResponseObj?.time?.map((obj) => obj.time)) : [],
              splitLine: {
                  show: true,
                  lineStyle: {
                      type: "dotted",
                      width: 2,
                      dashOffset: 4
                  }
              },
              axisLabel: {
                rotate: 70
              }
          },
          yAxis: {
              type: 'value',
              min:min,
              max:max,
              axisLabel: {
                formatter: function(value) {
                    return value.toFixed(2);
                },
            },
              axisPointer: {
                  snap: true,
              },
              splitLine: {
                  show: true,
                  lineStyle: {
                      type: "dotted",
                      width: 2,
                      dashOffset: 4
                  }
              }
          },
          series: seriesDataArr,
      }
      setDynamicOptions(newOptions);
      setShowChart(true);
  }
}
export const getLiveMetersData = async (setMetersDataArr, setLiveMeterLoader, setMetersDataErrorResp, setFacilityData, setAssetType, setLiveMeterDataRespObj, setDistributedArr, setAssetAssocTypeId, setAssetLocation ) => {
  setMetersDataArr([]);
  setLiveMeterLoader(false);
  setMetersDataErrorResp(false);
  const id = await LocalStorageService.getAssetIdData();
  const assetListInfo = await new AssetDetailsServices().getAssetListForAssetDetails(id);
  if (assetListInfo == 'error') {
      // alert("Asset List API Error");
      setMetersDataErrorResp(true);
      setLiveMeterLoader(true)
      return
  }
  setLiveMeterLoader(true)

  if( assetListInfo.hasOwnProperty("responseMessage") &&
  assetListInfo.responseMessage === "success" &&
      assetListInfo.hasOwnProperty("assets")) {
      const facilityId = assetListInfo?.assets[0]?.locatedAtFacilityId;
      const assetTypeId = assetListInfo?.assets[0]?.assetTypeId;

      const facility = await getFacilityDetails(facilityId);
      setFacilityData(facility);

      const assetTypeData = await getAssetTypeDesc(assetTypeId);
      setAssetType(assetTypeData);
      setAssetAssocTypeId(assetListInfo?.assets[0]?.fixedAssetAssocWithTypeId);

      const assetData= assetListInfo?.assets[0];

      if(assetData?.hasOwnProperty("latitude") && assetData?.hasOwnProperty("longitude")) {
          const latitude = assetData?.latitude;
          const longitude = assetData?.longitude;

          if(typeof latitude === "number" && typeof longitude === "number") {
              setAssetLocation({
                  latitude: latitude,
                  longitude: longitude,
              })
          }
      }
  }

  setLiveMeterDataRespObj(assetListInfo)

  if (assetListInfo?.assets[0]?.meterData?.liveMeters !== undefined) {
      const metersData = groupLiveMetersData(assetListInfo?.assets[0]?.meterData?.liveMeters)
      console.log("metersData::", metersData);
      setMetersDataArr(metersData);
      if (metersData.length >= 1) {
          const distributedAr = distributeElemtsToThreeArrays(metersData);
          setDistributedArr(distributedAr)
      }
  } else {
      // alert("NO DATA")
  }
}
export const findThresHoldColor = (liveMeterDataRespObj, metersDataArr, setArrayWithThresHoldColor) =>{
    let meterDataClone = JSON.stringify(metersDataArr);
    meterDataClone = JSON.parse(meterDataClone);
    let onlyLowerThresHoldArr =  meterDataClone.filter((obj)=> obj.lowerThresholdAttr !== null || obj.upperThresholdAttr !== null ).map((obj)=>{

        let findLowerValueObj = liveMeterDataRespObj?.assets !== undefined ? liveMeterDataRespObj?.assets[0]?.attributes !== undefined ? 
        liveMeterDataRespObj?.assets[0]?.attributes?.find((el)=> el.attrName === obj.lowerThresholdAttr ) : undefined : undefined;
        let findUpperValueObj = liveMeterDataRespObj?.assets !== undefined ? liveMeterDataRespObj?.assets[0]?.attributes !== undefined ? 
        liveMeterDataRespObj?.assets[0]?.attributes?.find((el)=> el.attrName === obj.upperThresholdAttr ) : undefined : undefined;

        // UomDescription


        if(findLowerValueObj !== undefined && findLowerValueObj.attrValue !== null) {obj.lowerThresholdAttr = +findLowerValueObj.attrValue; obj.readingUomDesc = findLowerValueObj.UomDescription === null ? '' : findLowerValueObj.UomDescription  }
        if(findUpperValueObj !== undefined && findUpperValueObj.attrValue !== null) {obj.upperThresholdAttr = +findUpperValueObj.attrValue;  obj.readingUomDesc = findUpperValueObj.UomDescription === null ? '' : findUpperValueObj.UomDescription }

        if(findLowerValueObj !== undefined && findLowerValueObj.attrValue === null){obj.lowerThresholdAttr = false }
        if(findUpperValueObj !== undefined && findUpperValueObj.attrValue === null){obj.upperThresholdAttr = false }

        if(+obj.lowerThresholdAttr === NaN && findLowerValueObj === undefined) obj.lowerThresholdAttr = false;
        if(+obj.upperThresholdAttr === NaN && findUpperValueObj === undefined) obj.upperThresholdAttr = false;

        if(+obj.lowerThresholdAttr !== NaN){ obj.lowerThresholdAttr = +obj.lowerThresholdAttr }
        if(+obj.upperThresholdAttr !== NaN){ obj.upperThresholdAttr = +obj.upperThresholdAttr }

        return obj;
       })
       setArrayWithThresHoldColor(onlyLowerThresHoldArr)
}
