import React, { useState } from "react";
import "./IncidentSummaryCard.scss";
import moment from "moment";
import * as R from "ramda";
// import IncidentImg from "../../../assets/icons/IncidentImg.svg";
import Modal from "react-bootstrap/Modal";
// import Carousel from "react-bootstrap/Carousel";

function IncidentSummaryCard(props: any) {
  const { data } = props;

  let dataSet = '' ;
    // setDataSet] = useState('testDate')
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let dataCheck = data.duration ;
  let miliSec = dataCheck ;
  const seconds = Math.floor((miliSec / 1000) % 60);
if(seconds > 60 ){
  const minutes = Math.floor((miliSec / 1000 / 60) % 60);
   dataSet = minutes + " min"
  // setDataSet(addStr)
}
else {
   dataSet = seconds + " sec"
  // setDataSet(addStr2)
}

  const thumbnail_url = `https://hawkeye.aone.ai/analytics/repository/download/thumbnail/${data.id}`;
  const image_url = `https://hawkeye.aone.ai/analytics/repository/download/image/${data.id}`;

  return (
    <div className="incidentSummaryCardContainer">
      <div
        className="incidentSummaryCardImg"
        style={{ cursor: "pointer" }}
        onClick={handleShow}
      >
        <img className="incidentImg" src={thumbnail_url} alt="incidentImg" />
      </div>
      <div className="incidentSummaryDetails">
        <p className="incidentDateTime">
          {moment(data.time).format("DD MMM YYYY h:mm a")}
        </p>
        <p className="incidentTime">{!R.isEmpty(dataSet) && dataSet}</p>
      </div>
      <div className="incidentSummaryDetails">
        <p className="incidentSummaryContHead">{data.name}</p>
        <p className="incidentSummaryContSub">{data.description}</p>
      </div>
      <div className="incidentSummaryDetails">
        <p className="incidentSummaryContHead">{data.count}</p>
      </div>
      <div className="incidentSummaryDetailsAddress">
        <p className="incidentSummaryContHead">{data.location.name}</p>
        <p className="incidentSummaryContAddress">
          {data.location.name},{/* {data.location.full}  */}
          {data.location.address},
          <br />
          {data.location.city}
        </p>
      </div>

      {/* popup code */}
      <Modal show={show} backdrop="static"> 
        <div className="modalCloseBtnContainer">
          <button className="modalCloseBtn" onClick={handleClose}>
            X
          </button>
        </div>
        <Modal.Body>
          <div className="imageModalPopupContainer">
            <p className="imageModalPopupHeader">SOP Violation</p>
            <ul className="imageModalPopupIncidenTimeCont">
              <li className="imageModalPopupIncidenTimeTxt">
                {data.description}
              </li>
              <li className="imageModalPopupIncidenTime">
                {moment(data.time).format("DD MMM YYYY h:mm a")}
              </li>
            </ul>

            <div className="imageModalPopupAddressCont">
              <p className="imageModalPopupAddress">
                {data.location.name} {data.location.address},
                {data.location.city}
              </p>
            </div>
            <div className="imageModalPopupImgContainer">
              <img
                className="imageModalPopupImg"
                src={image_url}
                alt="popupImg"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default IncidentSummaryCard;
