import { AcControllerSetting } from "../types";

export class AcControllerUtils {
  extractAcControllerSetting(data: object) {
    const result: AcControllerSetting[] = [];
    data?.command.forEach((item) => {
      const key = Object.keys(item);
      const controllerItem = item[key[0]];
      const latestUpdate = item[key[1]];

      const setting =
        key[0] === "1000"
          ? controllerItem?.trigger
          : key[0] === "202"
          ? controllerItem?.timerMode
          : key[0] === "203"
          ? controllerItem?.tempMode
          : {};

      result.push({
        key: key[0],
        data: setting,
        latestUpdate: latestUpdate,
      });
    });
    return result;
  }
}
