import React, { useState } from "react";
import "./QrGenerationComponent.scss";
import AddIcon from "../assets/icons/AddIcon.svg";
import ExportIcon from "../assets/icons/ExportIcon.svg";
import ChevronIcon from "../assets/icons/icon-feather-chevron-down.svg";
import machineDetailsIcon from "../assets/icons/icon-machine-details.svg";
import PoDetailsChildComponent from "./PoDetailsChildComponent";
import ReqNewQrModal from "./ReqNewQrModal";
import RangeIDGeneratedModal from "./RangeIDGeneratedModal";
import SortDataComponent from "../Utils/sortComponent/SortDataComponent";
import FilterDataComponent from "../Utils/filterDataComponent/FilterDataComponent";
import {PoDetailsRow} from "./Types/PoDetailsRow";

type SelectedDataCategory = {
  category: string;
  sort: string;
};

function QrGenerationComponent() {
  const data: PoDetailsRow[] = JSON.parse(
    `[{
        "id": 1,
        "po": "3298110",
        "plant": "1231 - Madanpur Khadar Plant, Delhi",
        "totalQr": "10,25,500",
        "generated": "10,26,500",
        "qcRejected": "2731",
        "remaining": "2731",
        "status": "IN-PROGRESS",
        "poDetails": [{
            "qrCount": "10,25,500",
            "qcRejected": "2731",
            "allocatedTime": "12 Jan 2023 12:30pm",
            "status": "COMPLETED",
            "machineDetails": "10001"
        }, {
            "qrCount": "2731",
            "qcRejected": "-",
            "allocatedTime": "13 Jan 2023 10:30am",
            "status": "IN-PROGRESS",
            "machineDetails": "10001"
        }
        ]
    }, {    
        "id": 2,
        "po": "9383134",
        "plant": "1231 - Madanpur Khadar Plant, Delhi",
        "totalQr": "5,00,000",
        "generated": "5,06,100",
        "qcRejected": "6100",
        "remaining": "0",
        "status": "COMPLETED",
        "poDetails": [{
            "qrCount": "5,00,000",
            "qcRejected": "4520",
            "allocatedTime": "15 Jan 2023 4:30pm",
            "status": "COMPLETED",
            "machineDetails": "10001"
        }, {
            "qrCount": "4250",
            "qcRejected": "1580",
            "allocatedTime": "16 Jan 2023 12:30pm",
            "status": "COMPLETED",
            "machineDetails": "10001"
        }, {
            "qrCount": "1580",
            "qcRejected": "0",
            "allocatedTime": "17 Jan 2023 9:30am",
            "status": "COMPLETED",
            "machineDetails": "10001"
        }
        ]
    }, {    
        "id": 3,
        "po": "9398134",
        "plant": "1231 - Madanpur Khadar Plant, Delhi",
        "totalQr": "5,00,000",
        "generated": "5,06,100",
        "qcRejected": "6100",
        "remaining": "0",
        "status": "COMPLETED",
        "poDetails": [{
            "qrCount": "5,00,000",
            "qcRejected": "4520",
            "allocatedTime": "15 Jan 2023 4:30pm",
            "status": "COMPLETED",
            "machineDetails": "10001"
        }, {
            "qrCount": "4250",
            "qcRejected": "1580",
            "allocatedTime": "16 Jan 2023 12:30pm",
            "status": "COMPLETED",
            "machineDetails": "10001"
        }, {
            "qrCount": "1580",
            "qcRejected": "0",
            "allocatedTime": "17 Jan 2023 9:30am",
            "status": "COMPLETED",
            "machineDetails": "10001"
        }
        ]
    }
    ]`
  );

  const totalQrFilterData = [
    {
      id:1,
      value:"Below 1,00,000"
    },
    {
      id:2,
      value:"1,00,000 to 5,00,000"
    },
    {
      id:3,
      value:"5,00,000 to 10,00,000"
    },
    {
      id:2,
      value:"Above 10,00,000"
    }
  ]

  const generatedFilterData = [
    {
      id:1,
      value:'Below 1000'
    },
    {
      id:2,
      value:'1000 to 2000'
    },
    {
      id:3,
      value:'2000 to 3000'
    },
    {
      id:1,
      value:'Above 3000'
    }
  ]

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const [selectedDataSort, setSelectedDataSort] =
    useState<null | SelectedDataCategory>(null);

  function handleRowClick(id: number) {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  }

  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const [isRangeIdGeneratedModalOpen, setIsRangeIdGeneratedModalOpen] =
    useState(false);

  const handleButtonClick = () => {
    setIsRequestModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsRequestModalOpen(false);
    setIsRangeIdGeneratedModalOpen(false);
  };

  const handleRequestClick = () => {
    handleCloseModal();
    setIsRangeIdGeneratedModalOpen(true);
  };

  return (
    <div className="qrGenerationParent">
      <div className="qrGenerationTopDiv">
        <h5 className="qrGenerationHeader">QR Generation</h5>
        <h6 className="qrGenerationSearch">Search Bar : ToDo</h6>
      </div>

      <div className="qrGenerationMiddleDiv">
        <div className="qrGenerationHeaderButtons">
          <button className="userExportBtn marginRight10px">
            <img src={ExportIcon} alt="icon" />
            Export
          </button>

          <button
            className="primaryButtonImgAndDark"
            onClick={handleButtonClick}
          >
            <img src={AddIcon} alt="icon" />
            Request New
          </button>

          <ReqNewQrModal
            handleCloseModal={handleCloseModal}
            handleRequestClick={handleRequestClick}
            isRequestModalOpen={isRequestModalOpen}
          />

          <RangeIDGeneratedModal
            isRangeIdGeneratedModalOpen={isRangeIdGeneratedModalOpen}
            handleCloseModal={handleCloseModal}
          />
        </div>
      </div>

      <div className="qrGenerationContentDiv">
        <div className="tableRow tableHeader">
          <div className="qrChevron"></div>
          <div className="qrSNo">
            <SortDataComponent
              selectedDataSort={selectedDataSort}
              setSelectedDataSort={setSelectedDataSort}
              heading="S.No"
              categoryId="serialNo"
            />
          </div>
          <div className="qrPoNo">
            <SortDataComponent
              selectedDataSort={selectedDataSort}
              setSelectedDataSort={setSelectedDataSort}
              heading="PO #"
              categoryId="po"
            />
          </div>
          <div className="qrPlantDetail">
            <SortDataComponent
              selectedDataSort={selectedDataSort}
              setSelectedDataSort={setSelectedDataSort}
              heading="Plant"
              categoryId="plant"
            />
          </div>
          <div className="qrTotalQrCount">
            <FilterDataComponent   heading='Total QR' filterData={totalQrFilterData}  />

            </div>
          <div className="qrGenerated">
            <FilterDataComponent   heading='Generated #' filterData={generatedFilterData} />

            </div>
          <div className="qrRejected">
            <SortDataComponent
              selectedDataSort={selectedDataSort}
              setSelectedDataSort={setSelectedDataSort}
              heading="QR Rejected #"
              categoryId="qrRejected"
            />
          </div>
          <div className="qrRemaining">
            <SortDataComponent
              selectedDataSort={selectedDataSort}
              setSelectedDataSort={setSelectedDataSort}
              heading="Remaining #"
              categoryId="remaining"
            />
          </div>
          <div className="qrStatus">
            <SortDataComponent
              selectedDataSort={selectedDataSort}
              setSelectedDataSort={setSelectedDataSort}
              heading="Status"
              categoryId="status"
            />
          </div>
        </div>
        {data.map((row) => (
          <div>
            <div key={row.id} onClick={() => handleRowClick(row.id)}>
              <div className="tableRow showPointer">
                <div className="qrChevron">
                  <img
                    src={ChevronIcon}
                    className={`chevronImg ${
                      selectedRows.includes(row.id) ? "rotate-90" : "rotate-0"
                    }`}
                    alt="open"
                  />
                </div>
                <div className="qrSNo">{row.id}</div>
                <div className="qrPoNo">{row.po}</div>
                <div className="qrPlantDetail">{row.plant}</div>
                <div className="qrTotalQrCount">{row.totalQr}</div>
                <div className="qrGenerated">{row.generated}</div>
                <div className="qrRejected">{row.qcRejected}</div>
                <div className="qrRemaining">{row.remaining}</div>
                <div className="qrStatus">
                  <div
                    className={`${
                      row.status == "COMPLETED"
                        ? "statusBg completed"
                        : "statusBg inProgress"
                    }`}
                  >
                    {row.status}
                  </div>
                </div>
              </div>
            </div>
            {selectedRows.includes(row.id) && (
              <div>
                <PoDetailsChildComponent poDetails={row.poDetails} />
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="qrGenerationBottomDiv">
        <div>Showing 10 of 131 POs</div>
        <div className="paginationDiv">
          <div className="paginationPrev">Previous</div>
          <div className="paginationPageNo">1</div>
          <div className="paginationNext">Next</div>
        </div>
      </div>

      {/*<RangeIDGeneratedModal*/}
      {/*    handleCloseModal={handleCloseModal}*/}
      {/*    isRangeIdGeneratedModalOpen={isRangeIdGeneratedModalOpen}*/}
      {/*    requestResponseData={requestResponseData}*/}
      {/*    machineData={machineData}*/}
      {/*/>*/}
    </div>
  );
}

export default QrGenerationComponent;
