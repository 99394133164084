import React, { useEffect, useState } from 'react';
import Lottie from "react-lottie";
import loader from "../../a1Components/lotties/round-loader-white.json";
import "../LoginPage/LoginPageStyles.scss";
import { NavLink } from "react-router-dom";
import OtpInputScreenPassword from './OtpInputScreenPassword';
import { useStateValue } from '../../redux/StateProvider';
import { DashBoardServices } from '../../services/DasboardServices/DashboardService';

type dataType = {
  onSumbitData: () => void;
}

const OtpScreen = ({ onSumbitData }: dataType) => {
  const [buttonStatus, setButtonStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [otpInput, setOtpInput] = useState<string>("");
  const [count, setCount] = useState(60);
  const [intervalId, setIntervalId] = useState(null);
  const [otpApiLoadingStatus, setOtpApiLoadingStatus] = useState(false);
  const [{ forgotPassword }, dispatch] = useStateValue();
  const dbService = new DashBoardServices();

  const submitOtp = async () => {
    seterrorMessage("");
    setLoading(true);
    if (otpInput.length === 4) {
      let validateOtp: any = await dbService.validateOTP(forgotPassword.user, otpInput);
      if (validateOtp === "error") {
        seterrorMessage("Failed, please try again");
      } else {
        if (validateOtp.status === 200) {
          onSumbitData();
        } else {
          let data = await validateOtp.json()
          seterrorMessage(data.apiErrors[0]);
        }
      }
    } else {
      seterrorMessage("Please enter OTP");
    }
    setLoading(false);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
  };

  const startInterval = () => {
    setCount(60);
    clearInterval(intervalId); // Clear any existing interval
    const newIntervalId = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
      if (count <= 0) clearInterval(intervalId);
    }, 1000); // Interval runs every 1 second (1000 milliseconds)
    setIntervalId(newIntervalId);
  };

  const regenerateOTP = async () => {
    seterrorMessage("");
    setOtpApiLoadingStatus(true);
    let obj: {
      userLoginId: string,
      contactString: string,
      contactType: string
    } = {
      userLoginId: forgotPassword.user,
      contactString: forgotPassword.selectedOption,
      contactType: forgotPassword.type
    }
    let fetchData: any = await dbService.generateOTP(obj);
    if (fetchData === "error") {
      seterrorMessage("Failed, please try again ");
    }
    else {
      let response = await fetchData.json();
      if (fetchData.status === 200) {
        startInterval();
      } else {
        seterrorMessage(response.apiErrors[0]);
      }
    }
    setOtpApiLoadingStatus(false);
  }

  useEffect(() => {
    startInterval();
  }, [])

  const verifyFunction = (e: string) => {
    setOtpInput(e);
  }

  return (
    <div className="subContent">
      <div className="loginContent">
        <h6>Reset Password</h6>
        <p>Please enter the OTP sent to your  {forgotPassword.selectedOption}.</p>
        <div className='counterDivContainer'>
          {!otpApiLoadingStatus ?
            count <= 0 ? <p className='resendotpText' onClick={regenerateOTP}>Resend OTP</p> : <p>Resend OTP in {count} seconds</p> :
            <p>Please wait...</p>}
        </div>
        <OtpInputScreenPassword callBackFunction={verifyFunction} />
        {buttonStatus ? <button className="signInbuttonOpacity">Verify</button> :
          <button onClick={submitOtp} className="signInbutton">
            {loading ? <Lottie options={defaultOptions} /> : "Verify"}
          </button>
        }
        <div className="errorMessageContainer"><p>{errorMessage}</p></div>
        <NavLink to={"/"}>
          <div className="redirectLoginDiv"><p>Back to Login</p></div>
        </NavLink>
      </div>
    </div>
  )
}

export default OtpScreen