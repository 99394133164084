import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './TimePicker.scss';
import React, { useEffect, useState } from 'react';
import {ReportDatePickerData} from '../DataTypes';
import { convertedIntoTwoDecimalNumber } from '../../ModelPopupcomp/DateTimeRangeInputComponent/Utils';
import TimeInputField from '../TimeRangePicker/TimeInputField';

const TimePicker = ({ PlaceHolder,state, field,activeInput, title, value, updateDateRange }: ReportDatePickerData) => {
  const [calenderPopUpScreen, setCalenderPopupScreen] = useState(false);
  const [selectedTime, setSelectedTime] = useState<any>(`${convertedIntoTwoDecimalNumber(new Date().getHours())}:${convertedIntoTwoDecimalNumber(new Date().getMinutes())}`);

  const applyAction = () => {
    updateDateRange({
      startDate: selectedTime,
      endDate: ""
    });
    setCalenderPopupScreen(false);
  };

  const handleClick = () => {
    activeInput();
    setCalenderPopupScreen(true);
  }

  useEffect(() =>  updateDateRange({ startDate: selectedTime, endDate: ""}), []);

  return (
    <div className="dateTimeInputCardContainer">
    <h4 className="tempInputTitleText">{title}{field === "Y" ? " *" : ""}</h4>
    <div className="styleInp">
      <input type="text" onClick={handleClick} required={true} 
       value={ selectedTime }  className="inputTempTextValue" placeholder={PlaceHolder} />
      <div className='calenderIcon'>
        <FontAwesomeIcon onClick={handleClick} icon={faCalendar} />
      </div>    
    </div>
    {calenderPopUpScreen && state ? (
      <div className="slectDateTimeCard">
        <TimeInputField  title='Select Time' value={selectedTime} handleDateChange={(e) => setSelectedTime(e)} maxDate={''} minDate={''}/>
        <div className="bottonButtonsDiv">
          <button onClick={() => setCalenderPopupScreen(false)} className="cancelButton">Cancel</button>
          {
            selectedTime !== "" ?  <button onClick={applyAction} className="applyButton">Apply</button> :  <button className="applyButtonOpacity">Apply</button>
          }   
        </div>
      </div>
    ) : null}
  </div>
  )
}

export default TimePicker