import React, { useEffect, useRef, useState } from "react";
import "./ScrollingText.scss";
import { getCardAbbreviation } from "../../Utils/utils";

type PropTypes = {
  minValue: string;
  unit: string;
  maxValue: string;
};

function TempDataScrolling({ minValue, unit, maxValue }: PropTypes) {
  const abbre = getCardAbbreviation(unit);

  const [isHovered, setIsHovered] = useState(false);
  const textRef = useRef<HTMLParagraphElement>(null);
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      setIsTextOverflowing(
        textRef.current.scrollWidth > textRef.current.clientWidth
      );
    }
  }, [minValue, maxValue]);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <div className="scrollingMainDiv">
      <p
        className={`scrollingText minMaxData`}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <p
          ref={textRef}
          style={{
            animation:
              isTextOverflowing && isHovered
                ? "scrollLeftRight 5s linear infinite"
                : "none",
            margin: 0,
          }}
        >
          Min{" "}
          <span className="minMaxValue">
            {minValue} {unit}
          </span>{" "}
          - Max{" "}
          <span className="minMaxValue">
            {maxValue} {unit}
          </span>
        </p>
      </p>
    </div>
  );
}

export default TempDataScrolling;
