/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as R from "ramda";
import React from "react";

// export default class DownloadReportUtil {
//   ascii_A: number = 65;
//   compReportOrder: any = ["Compliance", "Non-Compliance", "Offline"];

//   // Gets you an array of different Excel Sheets
//   getSheetArr(allData: any, facilitiesObj: any, type: string) {
//     let sheetResp: any = [];

//     let sheetArr: any = [];

//     let facilityCategArr: any = [];

//     // Facility category arrays
//     facilityCategArr = Object.values(facilitiesObj).filter(
//       (obj: any) => !R.isNil(obj) && !R.isEmpty(obj)
//     );

//     console.log("facilityCategArr::", facilityCategArr);

//     // Sheet Array
//     sheetArr = Object.keys(facilitiesObj)
//       .filter(
//         (key: any) =>
//           !R.isNil(facilitiesObj[key]) && !R.isEmpty(facilitiesObj[key])
//       )
//       .map((keyStr: any) => keyStr.replaceAll("Ids", "").toUpperCase());

//     const dwnReportUtil = new DownloadReportUtil();
//     sheetArr.forEach((sheetObj: any, index: number) => {
//       /* Column Array for Current Sheet */
//       const columnArrVal = dwnReportUtil.getColumnArr(allData, type);

//       /* Row Array for Current Sheet */
//       const rowArrVal = dwnReportUtil.getRowArr(
//         allData,
//         facilityCategArr[index],
//         type
//       );

//       const sheetObjVal: any = {
//         sheetName: sheetObj,
//         rowArr: rowArrVal,
//         columnArr: columnArrVal,
//       };

//       sheetResp.push(sheetObjVal);
//     });

//     return sheetResp;
//   }

//   getRowArr(allData: any, regionData: any, type: any) {
//     const rowArrVal: any[] = [
//       {
//         A: "", //A2
//       },
//     ];

//     if (type === "Asset Summary") {
//       const assetStateArr = regionData.map((obj: any) => {
//         return {
//           regionName: obj.hasOwnProperty("facilityGroupName")
//             ? obj.facilityGroupName
//             : obj.facilityName,
//           types: obj.data.map((objType: any) => objType.name),
//           assetTypes: obj.data.map((objType: any) => objType.assetDescList),
//           values: obj.data.map((objType: any) => objType.values),
//         };
//       });

//       // assetStateArr[0].values[1] = assetStateArr[0].values[0];
//       // assetStateArr[0].values[0] = [];

//       // console.log("assetSTateArr is::", assetStateArr);

//       // The first row(A1 onwards) is already populated by the Column Array. We need to start our population from the 2nd row(A2 onwards) and down.
//       // The second row has a fixed format.

//       let rowNo = 2;
//       let asciiCurr = this.ascii_A + 1;

//       const assetStateArrAll = allData.map((obj: any) => {
//         return {
//           types: obj.name,
//           assetTypes: obj.assetDescList,
//         };
//       });

//       assetStateArrAll.forEach((objAsstType: any) => {
//         objAsstType.assetTypes.forEach((objStr: any) => {
//           rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] = objStr;
//           asciiCurr += 1;
//         });
//       });

//       //The third row and below is repetitive
//       rowNo = 3;

//       assetStateArr.forEach((objAsstStObj: any) => {
//         asciiCurr = this.ascii_A;

//         rowArrVal[rowNo - 2] = {};
//         rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
//           objAsstStObj.regionName;

//         // Clean up ObjAsstStObj
//         asciiCurr += 1;

//         objAsstStObj.values.forEach((valueObj: any) => {
//           const newValuesArr: any = [];

//           if (valueObj.length === 0) {
//             console.log("objAsstStObj::", objAsstStObj);
//             objAsstStObj.assetTypes[0].forEach((val: any) => {
//               rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] = "";
//               asciiCurr += 1;
//             });
//           } else {
//             valueObj.forEach((val: any) => {
//               rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] = val;
//               asciiCurr += 1;
//             });
//           }
//         });

//         rowNo += 1;
//       });
//     }
//     else if (type === "Energy") {
//       console.log("rowData energy::", regionData);
//       // The first row(A1 onwards) is already populated by the Column Array. We need to start our population from the 2nd row(A2 onwards) and down.
//       const dataArr = regionData.map((obj: any) => {
//         return {
//           regionName: obj.hasOwnProperty("facilityGroupName")
//             ? obj.facilityGroupName
//             : obj.facilityName,
//           assetTypes:
//             obj.data?.length > 0
//               ? obj.data.map((objType: any) => objType.fixedAssetType)
//               : [],
//           overallConsumption:
//             obj.data.length > 0
//               ? obj.data.map((objType: any) => {
//                   if (!objType.hasOwnProperty("overallConsumption")) {
//                     return;
//                   } else {
//                     return objType.overallConsumption.value.toFixed(2);
//                   }
//                 })
//               : [],
//           assetCounts:
//             obj.data.length > 0
//               ? obj.data.map((objType: any) => objType.count)
//               : [],
//           powerFactor:
//             obj.data.length > 0
//               ? obj.data.map((objType: any) =>
//                   objType.hasOwnProperty("powerFactor")
//                     ? objType.powerFactor.value
//                     : ""
//                 )
//               : [],

//               averageConsumption:
//               obj.data.length > 0
//                 ? obj.data.map((objType: any) =>
//                     objType.hasOwnProperty("averageConsumption")
//                       ? objType.averageConsumption.value
//                       : ""
//                   )
//                 : [],

//                 actualDemand:
//                 obj.data.length > 0
//                   ? obj.data.map((objType: any) =>
//                       objType.hasOwnProperty("actualDemand")
//                         ? objType.actualDemand.value
//                         : ""
//                     )
//                   : [],

//                   PredictiveCost:
//                   obj.data.length > 0
//                     ? obj.data.map((objType: any) => {
//                         if (!objType.hasOwnProperty("overallConsumption")) {
//                           return;
//                         } else {
//                           return (objType.overallConsumption.value * 10).toFixed(2);
//                         }
//                       })
//                     : [],
//         };
//       });
// console.log("dataArrdataArr::",dataArr)
//       // The second row has a fixed format

//       let rowNo = 2;
//       let asciiCurr = this.ascii_A + 1;

//       const rowSecond = {
//         A: "",
//         B: "Asset Count",
//         C: "Overall Consumption (kWh)",
//         D: "PF",
//         E:"Average Consumption",
//         F:"Actual Demand",
//         G:"PredictiveCost",
//         H: "Asset Count",
//         I: "Overall Consumption (kWh)",
//         J: "PF",
//         K:"Average Consumption",
//         L:"Actual Demand",
//         M:"PredictiveCost",
//         N: "Asset Count",
//         O: "Overall Consumption (kWh)",
//         P: "PF",
//         Q:"Average Consumption",
//         R:"Actual Demand",
//         S:"PredictiveCost",
//         T: "Asset Count",
//         U: "Overall Consumption (kWh)",
//         V: "PF",
//         W:"Average Consumption",
//         X:"Actual Demand",
//         Y:"PredictiveCost"
//       };

//       rowArrVal[rowNo - 2] = rowSecond;

//       // The third row and below is repetitive
//       rowNo += 1;

//       dataArr.forEach((objAsstType: any, index: number) => {
//         asciiCurr = this.ascii_A;

//         // Each object in this loop is a row

//         rowArrVal[rowNo - 2] = {};

//         rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
//           objAsstType.regionName;

//         asciiCurr = this.ascii_A + 1;

//         objAsstType.assetTypes.forEach((objIndi: any, index: number) => {
//           // Each object in this loop is a column

//           // Asset Count
//           rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
//             objAsstType.assetCounts[index];

//           asciiCurr += 1;

//           // Overall Consumption
//           rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
//             objAsstType.overallConsumption[index];

//           asciiCurr += 1;

//           // PF
//           rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
//             objAsstType.powerFactor[index];

//           asciiCurr += 1;

//             // averageConsumption
//             rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
//             objAsstType.averageConsumption[index];

//           asciiCurr += 1;

//             // actualDemand
//             rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
//             objAsstType.actualDemand[index];

//           asciiCurr += 1;

//             // PredictiveCost
//             rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
//             objAsstType.PredictiveCost[index];

//           asciiCurr += 1;
//         });

//         rowNo += 1;
//       });
//     }
//      else if (type === "DG") {
//       console.log("DG regionData::", regionData);
//       const dataArr = regionData.map((obj: any) => {
//         return {
//           regionName: obj.hasOwnProperty("facilityGroupName")
//             ? obj.facilityGroupName
//             : obj.facilityName,
//           assetTypes: obj.data.map((objType: any) => objType.fixedAssetType),
//           overallConsumption: obj.data.map((objType: any) => {
//             if (!objType.hasOwnProperty("overallConsumption")) {
//               return;
//             } else {
//               return objType.overallConsumption.value.toFixed(2);
//             }
//           }),
//           assetCounts: obj.data.map((objType: any) => objType.count),
//           // fuelLevel: obj.data.map((objType: any) =>
//           //   objType.hasOwnProperty("fuelLevel") ? objType.fuelLevel.value : ""
//           // ),
//           runHours: obj.data.map((objType: any) =>
//             objType.hasOwnProperty("runHours") ? objType.runHours.value : ""
//           ),

//           averageConsumption: obj.data.map((objType: any) =>
//           objType.hasOwnProperty("averageConsumption") ? objType.averageConsumption.value : ""
//         ),

//           fuelRemaining: obj.data.map((objType: any) =>
//           objType.hasOwnProperty("fuelRemaining") ? objType.fuelRemaining.value : ""
//         ),

//           fuelUsed: obj.data.map((objType: any) =>
//           objType.hasOwnProperty("fuelUsed") ? objType.fuelUsed.value : ""
//         ),

//         sfc: obj.data.map((objType: any) =>
//           objType.hasOwnProperty("sfc") ? objType.sfc.value : ""
//         ),

//         };
//       });
// console.log("dataArrdataArr",dataArr)
//       let rowNo = 2;
//       // Repetitive Data from 2nd Row onwards.

//       dataArr.forEach((obj: any) => {
//         // each iteration is a row of data

//         let asciiCurr = this.ascii_A;
//         rowArrVal[rowNo - 2] = {};

//         // Region Name
//         rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] = obj.regionName;

//         asciiCurr += 1;

//         // Asset Count
//         rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
//           obj.assetCounts[0];

//         asciiCurr += 1;

//         // Overall Consumption
//         rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
//           obj.overallConsumption[0];

//         asciiCurr += 1;

//         // // Avg Fuel Level
//         // rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] = obj.fuelLevel[0];

//         // asciiCurr += 1;

//         // Run Hours
//         rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] = obj.runHours[0];

//         asciiCurr += 1;

//         //  averageConsumption
//          rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] = obj.averageConsumption[0];

//          asciiCurr += 1;

//           // fuelRemaining
//         rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] = obj.fuelRemaining[0];

//         asciiCurr += 1;

//          // fuelUsed
//          rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] = obj.fuelUsed[0];

//          asciiCurr += 1;

//           // sfc
//         rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] = obj.sfc[0];

//         rowNo += 1;
//       });
//       console.log("dataArrdataArr",dataArr)
//     }

//     return rowArrVal;
//   }

//   getColumnArr(allData: any, type: any) {
//     console.log("allData::", allData);
//     let columnArrVal: {
//       label: string;
//       value: string;
//     }[] = [
//       {
//         label: "",
//         value: String.fromCharCode(this.ascii_A),
//       },
//     ];

//     if (type === "Asset Summary") {
//       const assetStateArr = allData.map((obj: any) => {
//         return {
//           types: obj.name,
//           assetTypes: obj.assetDescList,
//         };
//       });

//       const headerObj = {
//         types: this.compReportOrder,
//         lengths: assetStateArr.map((obj: any) => obj.assetTypes.length),
//       };

//       // Re-arrange headerObj
//       console.log("headerObj::", headerObj);

//       // Going to construct the Header Row which is the same as the column Array
//       let asciiCurr = this.ascii_A + 1;

//       headerObj.types.forEach((obj: any, index: number) => {
//         const gapNum = headerObj.lengths[index] + asciiCurr;

//         const columnObj: {
//           label: string;
//           value: string;
//         } = {
//           label: "",
//           value: "",
//         };

//         columnObj.label = obj;
//         columnObj.value = String.fromCharCode(asciiCurr);

//         columnArrVal.push(columnObj);
//         asciiCurr += 1;

//         for (asciiCurr; asciiCurr < gapNum; asciiCurr++) {
//           const columnObj: {
//             label: string;
//             value: string;
//           } = {
//             label: "",
//             value: "",
//           };

//           columnObj.label = "";
//           columnObj.value = String.fromCharCode(asciiCurr);
//           columnArrVal.push(columnObj);
//         }
//       });

//       console.log("columnArrVal::", columnArrVal);
//     } else if (type === "Energy") {
//       console.log("allData for energy is::", allData);

//       let energyTitleArr: any = [];
//       let asciiCurr = this.ascii_A + 1;

//       allData.forEach((obj: any) => {
//         energyTitleArr.push({
//           label: obj.fixedAssetTypeId,
//           value: String.fromCharCode(asciiCurr),
//         });
//         asciiCurr += 1;

//         energyTitleArr.push({
//           label: "",
//           value: String.fromCharCode(asciiCurr),
//         });
//         asciiCurr += 1;

//         energyTitleArr.push({
//           label: "",
//           value: String.fromCharCode(asciiCurr),
//         });
//         asciiCurr += 1;

//         energyTitleArr.push({
//           label: "",
//           value: String.fromCharCode(asciiCurr),
//         });

//         asciiCurr += 1;
//         energyTitleArr.push({
//           label: "",
//           value: String.fromCharCode(asciiCurr),
//         });
//         asciiCurr += 1;

//         energyTitleArr.push({
//           label: "",
//           value: String.fromCharCode(asciiCurr),
//         });
//         asciiCurr += 1;
//       });

//       columnArrVal = [...columnArrVal, ...energyTitleArr];

//       console.log("columnArrVal::", columnArrVal);
//     } else if (type === "DG") {
//       const dgTitleArr: any = [
//         {
//           label: "Asset Count",
//           value: "B",
//         },
//         {
//           label: "Overall Consumption (kWh)",
//           value: "C",
//         },
//         {
//           label: "Run Hours (Hrs)",
//           value: "D",
//         },
//         {
//           label: "Average Consumption",
//           value: "E",
//         },
//         {
//           label: "Fuel Remaining",
//           value: "F",
//         },
//         {
//           label: "Fuel Used",
//           value: "G",
//         },
//         {
//           label: "Specific Fuel Consumption",
//           value: "H",
//         },
//       ];
//       columnArrVal = [...columnArrVal, ...dgTitleArr];
//     }

//     return columnArrVal;
//   }

//   formatReport(data: any, reportType: string, portalId: string) {
//     const formattedData: any = [];

//     if (portalId === "Asset Summary") {
//       console.log(",data ", data);
//       data.forEach((dataObj: any) => {
//         const assetObj: any = {};
//       });
//     } else if (portalId === "Temperature Compliance") {
//       data.forEach((dataObj: any) => {
//         const assetObj: any = {};
//       });
//     } else if (portalId === "Energy") {
//       data.forEach((dataObj: any) => {
//         const assetObj: any = {};
//       });
//     } else if (portalId === "Tickets") {
//       console.log(",data ", data.all);
//       data?.all?.forEach((dataObj: any) => {
//         const assetObj: any = {};
//       });
//     }
//   }
// }

export default class DownloadReportUtil {
  ascii_A: number = 65;
  compReportOrder: any = ["Compliance", "Non-Compliance"];

  // Gets you an array of different Excel Sheets
  getSheetArr(allData: any, facilitiesObj: any, type: string) {
    let sheetResp: any = [];

    let sheetArr: any = [];

    let facilityCategArr: any = [];

    // Facility category arrays
    facilityCategArr = Object.values(facilitiesObj).filter(
      (obj: any) => !R.isNil(obj) && !R.isEmpty(obj)
    );

    // console.log("facilityCategArr::", facilityCategArr);

    // Sheet Array
    sheetArr = Object.keys(facilitiesObj)
      .filter(
        (key: any) =>
          !R.isNil(facilitiesObj[key]) && !R.isEmpty(facilitiesObj[key])
      )
      .map((keyStr: any) => keyStr.replace(/Ids/g, "").toUpperCase());

    const dwnReportUtil = new DownloadReportUtil();
    sheetArr.forEach((sheetObj: any, index: number) => {
      /* Column Array for Current Sheet */
      const columnArrVal = dwnReportUtil.getColumnArr(allData, type);

      /* Row Array for Current Sheet */
      const rowArrVal = dwnReportUtil.getRowArr(
        allData,
        facilityCategArr[index],
        type
      );

      const sheetObjVal: any = {
        sheetName: sheetObj,
        rowArr: rowArrVal,
        columnArr: columnArrVal,
      };

      sheetResp.push(sheetObjVal);
    });

    return sheetResp;
  }

  getRowArr(allData: any, regionData: any, type: any) {
    const rowArrVal: any[] = [
      {
        A: "", //A2
      },
    ];

    if (type === "Compliance Summary") {
      const assetStateArr = regionData.map((obj: any) => {
        return {
          regionName: obj.hasOwnProperty("facilityGroupName")
            ? obj.facilityGroupName
            : obj.facilityName,
          types: obj.data.map((objType: any) => objType.name),
          assetTypes: obj.data.map((objType: any) => objType.assetDescList),
          values: obj.data.map((objType: any) => objType.values),
        };
      });

      // assetStateArr[0].values[1] = assetStateArr[0].values[0];
      // assetStateArr[0].values[0] = [];

      // console.log("assetSTateArr is::", assetStateArr);

      // The first row(A1 onwards) is already populated by the Column Array. We need to start our population from the 2nd row(A2 onwards) and down.
      // The second row has a fixed format.

      let rowNo = 2;
      let asciiCurr = this.ascii_A + 1;

      const assetStateArrAll = allData.map((obj: any) => {
        return {
          types: obj.name,
          assetTypes: obj.assetDescList,
        };
      });

      assetStateArrAll.forEach((objAsstType: any) => {
        objAsstType.assetTypes.forEach((objStr: any) => {
          rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] = objStr;
          asciiCurr += 1;
        });
      });

      //The third row and below is repetitive
      rowNo = 3;

      assetStateArr.forEach((objAsstStObj: any) => {
        asciiCurr = this.ascii_A;

        rowArrVal[rowNo - 2] = {};
        rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
          objAsstStObj.regionName;

        // Clean up ObjAsstStObj
        asciiCurr += 1;

        objAsstStObj.values.forEach((valueObj: any) => {
          const newValuesArr: any = [];

          if (valueObj.length === 0) {
            // console.log("objAsstStObj::", objAsstStObj);
            objAsstStObj.assetTypes[0].forEach((val: any) => {
              rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] = "";
              asciiCurr += 1;
            });
          } else {
            valueObj.forEach((val: any) => {
              rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] = val;
              asciiCurr += 1;
            });
          }
        });

        rowNo += 1;
      });
    } else if (type === "Energy") {
      // console.log("rowData energy::", regionData);
      // The first row(A1 onwards) is already populated by the Column Array. We need to start our population from the 2nd row(A2 onwards) and down.

      let rowSecond;
      regionData.map((obj: any) => {
        if (
          !R.isNil(obj.data) &&
          !R.isEmpty(obj.data) &&
          obj.parentFacilityGroupType === "CLUSTER"
        ) {
          rowSecond = {
            A: "",
            B: "Asset Count",
            C: "Cumulative Consumption per facility (kWh)",
            D: "Power factor per facility",
            E: "Daily Consumption per facility ",
            F: "Demand per facility",
            G: "Predictive cost per facility",
            H: "Asset Count",
            I: "Cumulative Consumption per facility (kWh)",
            J: "Power factor per facility",
            K: "Daily Consumption per facility ",
            L: "Demand per facility",
            M: "Predictive cost per facility",
            N: "Asset Count",
            O: "Cumulative Consumption per facility (kWh)",
            P: "Power factor per facility",
            Q: "Daily Consumption per facility ",
            R: "Demand per facility",
            S: "Predictive cost per facility",
            T: "Asset Count",
            U: "Cumulative Consumption per facility (kWh)",
            V: "Power factor per facility",
            W: "Daily Consumption per facility ",
            X: "Demand per facility",
            Y: "Predictive cost per facility",
          };
        } else {
          rowSecond = {
            A: "",
            B: "Asset Count",
            C: "Average Cumulative Consumption per facility (kWh)",
            D: "Average Power factor per facility",
            E: "Average Daily Consumption per facility ",
            F: "Average Demand per facility",
            G: "Predictive cost per facility",
            H: "Asset Count",
            I: "Average Cumulative Consumption per facility (kWh)",
            J: "Average Power factor per facility",
            K: "Average Daily Consumption per facility ",
            L: "Average Demand per facility",
            M: "Predictive cost per facility",
            N: "Asset Count",
            O: "Average Cumulative Consumption per facility (kWh)",
            P: "Average Power factor per facility",
            Q: "Average Daily Consumption per facility ",
            R: "Average Demand per facility",
            S: "Predictive cost per facility",
            T: "Asset Count",
            U: "Average Cumulative Consumption per facility (kWh)",
            V: "Average Power factor per facility",
            W: "Average Daily Consumption per facility ",
            X: "Average Demand per facility",
            Y: "Predictive cost per facility",
          };
        }
      });
      const dataArr = regionData.map((obj: any) => {
        if (!R.isNil(obj.data) && !R.isEmpty(obj.data)) {
          return {
            regionName: obj.hasOwnProperty("facilityGroupName")
              ? obj.facilityGroupName
              : obj.facilityName,
            assetTypes:
              obj.data.length > 0
                ? obj.data.map((objType: any) => objType.fixedAssetType)
                : [],
            averageConsumptionPerFacility:
              obj?.data.length > 0
                ? obj.data.map((objType: any) => {
                    if (
                      !objType.hasOwnProperty("averageConsumptionPerFacility")
                    ) {
                      return;
                    } else {
                      return objType.averageConsumptionPerFacility.value.toFixed(
                        2
                      );
                    }
                  })
                : [],
            assetCounts:
              obj.data.length > 0
                ? obj.data.map((objType: any) => objType.count)
                : [],
            powerFactor:
              obj.data.length > 0
                ? obj.data.map((objType: any) =>
                    objType.hasOwnProperty("powerFactor")
                      ? objType.powerFactor.value
                      : ""
                  )
                : [],

            averageConsumption:
              obj.data.length > 0
                ? obj.data.map((objType: any) =>
                    objType.hasOwnProperty("averageConsumption")
                      ? objType.averageConsumption.value
                      : ""
                  )
                : [],

            actualDemand:
              obj.data.length > 0
                ? obj.data.map((objType: any) =>
                    objType.hasOwnProperty("actualDemand")
                      ? objType.actualDemand.value
                      : ""
                  )
                : [],

            PredictiveCost:
              obj?.data?.length > 0
                ? obj?.data.map((objType: any) => {
                    if (
                      !objType.hasOwnProperty("averageConsumptionPerFacility")
                    ) {
                      return;
                    } else {
                      return objType.averageConsumptionPerFacility.value !==
                        "NaN"
                        ? (
                            objType.averageConsumptionPerFacility.value * 10
                          ).toFixed(2)
                        : "-";
                    }
                  })
                : [],
          };
        }
      });
      // console.log("dataArrdataArr::", dataArr);
      // The second row has a fixed format

      let rowNo = 2;
      let asciiCurr = this.ascii_A + 1;

      rowArrVal[rowNo - 2] = rowSecond;

      // The third row and below is repetitive
      rowNo += 1;

      dataArr.forEach((objAsstType: any, index: number) => {
        asciiCurr = this.ascii_A;

        // Each object in this loop is a row

        rowArrVal[rowNo - 2] = {};
        if (!R.isNil(objAsstType)) {
          rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
            objAsstType.regionName;

          asciiCurr = this.ascii_A + 1;

          objAsstType.assetTypes.forEach((objIndi: any, index: number) => {
            // Each object in this loop is a column

            // Asset Count
            rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
              objAsstType.assetCounts[index];

            asciiCurr += 1;

            // Overall Consumption
            rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
              objAsstType.averageConsumptionPerFacility[index];

            asciiCurr += 1;

            // PF
            rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
              objAsstType.powerFactor[index];

            asciiCurr += 1;

            // averageConsumption
            rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
              objAsstType.averageConsumption[index];

            asciiCurr += 1;

            // actualDemand
            rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
              objAsstType.actualDemand[index];

            asciiCurr += 1;

            // PredictiveCost
            rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
              objAsstType.PredictiveCost[index];

            asciiCurr += 1;
          });
        }

        rowNo += 1;
      });
    } else if (type === "DG") {
      // console.log("DG regionData::", regionData);

      const dataArr = regionData.map((obj: any) => {
        if (!R.isNil(obj.data) && !R.isEmpty(obj.data)) {
          return {
            regionName: obj.hasOwnProperty("facilityGroupName")
              ? obj.facilityGroupName
              : obj.facilityName,
            assetTypes: obj?.data.map((objType: any) => objType.fixedAssetType),
            overallConsumption: obj?.data.map((objType: any) => {
              if (!objType.hasOwnProperty("overallConsumption")) {
                return;
              } else {
                return objType.overallConsumption.value.toFixed(2);
              }
            }),
            assetCounts: obj?.data.map((objType: any) => objType.count),
            // fuelLevel: obj.data.map((objType: any) =>
            //   objType.hasOwnProperty("fuelLevel") ? objType.fuelLevel.value : ""
            // ),

            AverageUnitsGeneratedPerFacility: obj?.data.map((objType: any) =>
              objType.hasOwnProperty("averageConsumptionPerFacility")
                ? objType.averageConsumptionPerFacility.value
                : ""
            ),

            EnergyGeneratedPerLitrePerFacility: obj?.data.map((objType: any) =>
              objType.hasOwnProperty("kwhPerLitre")
                ? objType.kwhPerLitre.value
                : ""
            ),
            SpecificFuelConsumptionPerFacility: obj?.data.map((objType: any) =>
              objType.hasOwnProperty("litresPerKwh")
                ? objType?.litresPerKwh?.value
                : ""
            ),
            FuelUsedAllFacilitie: obj?.data.map((objType: any) =>
              objType.hasOwnProperty("fuelUsed") ? objType.fuelUsed.value : ""
            ),
            FuelRemainingAllFacilities: obj?.data.map((objType: any) =>
              objType.hasOwnProperty("fuelRemaining")
                ? objType.fuelRemaining.value
                : ""
            ),
            AverageFuelUsedPerFacility: obj?.data.map((objType: any) =>
              objType.hasOwnProperty("avgFuelUsed")
                ? objType.avgFuelUsed.value
                : ""
            ),
            RunHoursAllFacilities: obj?.data.map((objType: any) =>
              objType.hasOwnProperty("runHours") ? objType.runHours.value : ""
            ),

            AverageRunHoursPerFacility: obj?.data.map((objType: any) =>
              objType.hasOwnProperty("avgRunHours")
                ? objType.avgRunHours.value
                : ""
            ),
            AverageBatteryVoltagePerFacility: obj?.data.map((objType: any) =>
              objType.hasOwnProperty("avgBatteryVoltage")
                ? objType.avgBatteryVoltage.value
                : ""
            ),
            AverageCoolantTemperaturePerFacility: obj?.data.map(
              (objType: any) =>
                objType.hasOwnProperty("avgCoolantTemp")
                  ? objType.avgCoolantTemp.value
                  : ""
            ),
            AverageLubeOilPressurePerFacility: obj?.data.map((objType: any) =>
              objType.hasOwnProperty("avgOilPressure")
                ? objType.avgOilPressure.value
                : ""
            ),
            ClosingStock: obj?.data.map((objType: any) =>
              objType.hasOwnProperty("stockClosed")
                ? objType.stockClosed.value
                : ""
            ),
            StockInHand: obj?.data.map((objType: any) =>
              objType.hasOwnProperty("stockInHand")
                ? objType.stockInHand.value
                : ""
            ),
            AverageLoadperfacility: obj?.data.map((objType: any) =>
              objType.hasOwnProperty("avgLoadPct")
                ? objType.avgLoadPct.value
                : ""
            ),

            // runHours: obj?.data.map((objType: any) =>
            //   objType.hasOwnProperty("runHours") ? objType.runHours.value : ""
            // ),

            // averageConsumption: obj?.data.map((objType: any) =>
            //   objType.hasOwnProperty("averageConsumption") ? objType.averageConsumption.value : ""
            // ),

            //   fuelRemaining: obj?.data.map((objType: any) =>
            //   objType.hasOwnProperty("fuelRemaining") ? objType.fuelRemaining.value : ""
            // ),

            //   fuelUsed: obj?.data.map((objType: any) =>
            //   objType.hasOwnProperty("fuelUsed") ? objType.fuelUsed.value : ""
            // ),

            // sfc: obj?.data.map((objType: any) =>
            //   objType.hasOwnProperty("sfc") ? objType.sfc.value : ""
            // ),
          };
        }
      });
      // console.log("dataArrdataArr", dataArr);
      let rowNo = 2;
      // Repetitive Data from 2nd Row onwards.

      dataArr.forEach((obj: any) => {
        // each iteration is a row of data
        // console.log("OBJOJB", obj);
        if (!R.isNil(obj)) {
          let asciiCurr = this.ascii_A;
          rowArrVal[rowNo - 2] = {};

          // Region Name
          rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] = obj.regionName;

          asciiCurr += 1;

          // Asset Count
          rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
            obj.assetCounts[0];

          asciiCurr += 1;

          // Overall Consumption
          rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
            obj.overallConsumption[0];

          asciiCurr += 1;

          // AverageUnitsGeneratedPerFacility
          rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
            obj.AverageUnitsGeneratedPerFacility[0];

          asciiCurr += 1;

          // EnergyGeneratedPerLitrePerFacility
          rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
            obj.EnergyGeneratedPerLitrePerFacility[0];

          asciiCurr += 1;

          // SpecificFuelConsumptionPerFacility
          rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
            obj.SpecificFuelConsumptionPerFacility[0];

          asciiCurr += 1;

          // FuelUsedAllFacilitie
          rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
            obj.FuelUsedAllFacilitie[0];

          asciiCurr += 1;

          // FuelRemainingAllFacilities
          rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
            obj.FuelRemainingAllFacilities[0];

          asciiCurr += 1;

          // AverageFuelUsedPerFacility
          rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
            obj.AverageFuelUsedPerFacility[0];

          asciiCurr += 1;

          // RunHoursAllFacilities
          rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
            obj.RunHoursAllFacilities[0];

          asciiCurr += 1;

          // AverageRunHoursPerFacility
          rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
            obj.AverageRunHoursPerFacility[0];

          asciiCurr += 1;

          // AverageBatteryVoltagePerFacility
          rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
            obj.AverageBatteryVoltagePerFacility[0];

          asciiCurr += 1;

          // AverageCoolantTemperaturePerFacility
          rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
            obj.AverageCoolantTemperaturePerFacility[0];

          asciiCurr += 1;

          // AverageLubeOilPressurePerFacility
          rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
            obj.AverageLubeOilPressurePerFacility[0];

          asciiCurr += 1;

          // ClosingStock
          rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
            obj.ClosingStock[0];

          asciiCurr += 1;

          // StockInHand
          rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
            obj.StockInHand[0];

          asciiCurr += 1;

          // AverageLoadperfacility
          rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
            obj.AverageLoadperfacility[0];

          // // Avg Fuel Level
          // rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] = obj.fuelLevel[0];

          // asciiCurr += 1;

          // // Run Hours
          // rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
          //   obj.runHours[0];

          // asciiCurr += 1;

          // //  averageConsumption
          // rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
          //   obj.averageConsumption[0];

          // asciiCurr += 1;

          // // fuelRemaining
          // rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
          //   obj.fuelRemaining[0];

          // asciiCurr += 1;

          // // fuelUsed
          // rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] =
          //   obj.fuelUsed[0];

          // asciiCurr += 1;

          // // sfc
          // rowArrVal[rowNo - 2][String.fromCharCode(asciiCurr)] = obj.sfc[0];

          rowNo += 1;
        }
      });
      // console.log("dataArrdataArr", dataArr);
    }

    return rowArrVal;
  }

  getColumnArr(allData: any, type: any) {
    // console.log("allData::", allData);
    let columnArrVal: {
      label: string;
      value: string;
    }[] = [
      {
        label: "",
        value: String.fromCharCode(this.ascii_A),
      },
    ];

    if (type === "Compliance Summary") {
      const assetStateArr = allData.map((obj: any) => {
        return {
          types: obj.name,
          assetTypes: obj.assetDescList,
        };
      });

      const headerObj = {
        types: this.compReportOrder,
        lengths: assetStateArr.map((obj: any) => obj.assetTypes.length),
      };

      // Re-arrange headerObj
      // console.log("headerObj::", headerObj);

      // Going to construct the Header Row which is the same as the column Array
      let asciiCurr = this.ascii_A + 1;

      headerObj.types.forEach((obj: any, index: number) => {
        const gapNum = headerObj.lengths[index] + asciiCurr;

        const columnObj: {
          label: string;
          value: string;
        } = {
          label: "",
          value: "",
        };

        columnObj.label = obj;
        columnObj.value = String.fromCharCode(asciiCurr);

        columnArrVal.push(columnObj);
        asciiCurr += 1;

        for (asciiCurr; asciiCurr < gapNum; asciiCurr++) {
          const columnObj: {
            label: string;
            value: string;
          } = {
            label: "",
            value: "",
          };

          columnObj.label = "";
          columnObj.value = String.fromCharCode(asciiCurr);
          columnArrVal.push(columnObj);
        }
      });

      // console.log("columnArrVal::", columnArrVal);
    } else if (type === "Energy") {
      // console.log("allData for energy is::", allData);

      let energyTitleArr: any = [];
      let asciiCurr = this.ascii_A + 1;

      allData.forEach((obj: any) => {
        energyTitleArr.push({
          label: obj.fixedAssetTypeId,
          value: String.fromCharCode(asciiCurr),
        });
        asciiCurr += 1;

        energyTitleArr.push({
          label: "",
          value: String.fromCharCode(asciiCurr),
        });
        asciiCurr += 1;

        energyTitleArr.push({
          label: "",
          value: String.fromCharCode(asciiCurr),
        });
        asciiCurr += 1;

        energyTitleArr.push({
          label: "",
          value: String.fromCharCode(asciiCurr),
        });

        asciiCurr += 1;
        energyTitleArr.push({
          label: "",
          value: String.fromCharCode(asciiCurr),
        });
        asciiCurr += 1;

        energyTitleArr.push({
          label: "",
          value: String.fromCharCode(asciiCurr),
        });
        asciiCurr += 1;
      });

      columnArrVal = [...columnArrVal, ...energyTitleArr];

      // console.log("columnArrVal::", columnArrVal);
    } else if (type === "DG") {
      // console.log("allData for DG is::", allData);
      const dgTitleArr: any = [
        {
          label: "Asset Count",
          value: "B",
        },
        {
          label: "Units Generated-All facilities (kWh)",
          value: "C",
        },
        {
          label: "Average Units Generated per facility",
          value: "D",
        },
        {
          label: "Energy Generated per litre per facility",
          value: "E",
        },
        {
          label: "Fuel Consumed per kWh Energy per facility",
          value: "F",
        },
        {
          label: "Fuel Used - All Facilities",
          value: "G",
        },
        {
          label: "Fuel Remaining - All Facilities",
          value: "H",
        },
        {
          label: "Average Fuel Used per facility",
          value: "I",
        },
        {
          label: "Run Hours - All facilities",
          value: "J",
        },
        {
          label: "Average Run Hours per facility",
          value: "K",
        },
        {
          label: "Average Battery Voltage per facility",
          value: "L",
        },
        {
          label: "Average Coolant Temperature per facility",
          value: "M",
        },
        {
          label: "Average Lube Oil Pressure per facility",
          value: "N",
        },
        {
          label: "Closing Stock",
          value: "O",
        },
        {
          label: "Stock in hand",
          value: "P",
        },
        {
          label: "Average Load % per facility",
          value: "Q",
        },
      ];
      columnArrVal = [...columnArrVal, ...dgTitleArr];
    }

    return columnArrVal;
  }

  formatReport(data: any, reportType: string, portalId: string) {
    const formattedData: any = [];

    if (portalId === "Compliance Summary") {
      // console.log(",data ", data);
      data.forEach((dataObj: any) => {
        const assetObj: any = {};
      });
    } else if (portalId === "Temperature Compliance") {
      data.forEach((dataObj: any) => {
        const assetObj: any = {};
      });
    } else if (portalId === "Energy") {
      data.forEach((dataObj: any) => {
        const assetObj: any = {};
      });
    } else if (portalId === "Tickets") {
      // console.log(",data ", data.all);
      data?.all?.forEach((dataObj: any) => {
        const assetObj: any = {};
      });
    }
  }
}
