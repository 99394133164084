import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import DisplayError from "../DisplayError/DisplayError";
import "./AnoviEventSummary.scss";
import vector from "../.././assets/icons/Vector_arleft.svg";
import HoverIcon from "../.././assets/icons/hoverIcon.svg"
import { SortingTable } from "./SortingTable";
import { millisecondsToTime } from "../../Utils/utils";


const CompressorTable = ({data}:any) => {
    const [activeFacility, setActiveFacility] = useState("");
    const [compressorInsideTable, setCompressorInsideTable] = useState(false);
    const [compressorTableData, setCompressorTableData] = useState<any>([]);
    const [compressorInsideTableData, setCompressorInsideTableData] = useState<any>([]);
    const [activeSortingKey, setActiveSortingKey] = useState("");
    const [pageIndex, setPageIndex] = useState(5);
    const [insidePageTableIndex, setInsidePageTableIndex] = useState(10);
    let Sort = new SortingTable();
    const [isHovered, setIsHovered] = useState(null);


    const dataSorting = (key:any, table:any) => {
        if(activeSortingKey === key){
            if(table === 1) setCompressorTableData(Sort.sortTableAscendingOrder(compressorTableData, key));
            else setCompressorInsideTableData(Sort.sortTableAscendingOrder(compressorInsideTableData, key));
            setActiveSortingKey("");
        }else {
            if(table === 1) setCompressorTableData(Sort.sortTableDescending(compressorTableData, key));
            else setCompressorInsideTableData(Sort.sortTableDescending(compressorInsideTableData, key));
            setActiveSortingKey(key);
        }
      }

    useEffect(() => {
        let dataList = data.map((el: any) => {
            return {
              assetList: el.assetsList,
              facility: el.facilityName,
              assets: el.assetCount,
              totalCount:
                el.eventSummary[0]?.cycleCount + el.eventSummary[1]?.cycleCount,
              avgDuration:
                +el.eventSummary[0]?.averageDuration +
                +el.eventSummary[1]?.averageDuration / 2,
              totalOnCycles: el.eventSummary?.find(
                (obj: any) => obj.title === "CMPRSR_ON"
              )?.cycleCount,
              avgOnDuration: el.eventSummary?.find(
                (obj: any) => obj.title === "CMPRSR_ON"
              )?.averageDuration,
              totalOffCycles: el.eventSummary?.find(
                (obj: any) => obj.title === "CMPRSR_OFF"
              )?.cycleCount,
              avgOffDuration: el.eventSummary?.find(
                (obj: any) => obj.title === "CMPRSR_OFF"
              )?.averageDuration,
            };
          });
          setCompressorTableData(dataList);
    }, [data]);

    const returnexceptedObj_1 = (activeData:any) => {
        let InnerDataArr = activeData?.map((obj: any) => {
            return {
              assetName: obj.assetName,
              totalCyclesCount:
                obj.eventSummary[0]?.cycleCount + obj.eventSummary[1]?.cycleCount,
              averageDuration:
                (+obj.eventSummary[0]?.averageDuration +
                  +obj.eventSummary[1]?.averageDuration) /
                2,
              totalOnCycles: obj.eventSummary?.find(
                (el: any) => el.title === "CMPRSR_ON"
              )?.cycleCount,
              averageOnDuration: obj.eventSummary?.find(
                (el: any) => el.title === "CMPRSR_ON"
              )?.averageDuration,
              totalOffCycles: obj.eventSummary?.find(
                (el: any) => el.title === "CMPRSR_OFF"
              )?.cycleCount,
              averageOffDuration: obj.eventSummary?.find(
                (el: any) => el.title === "CMPRSR_OFF"
              )?.averageDuration,
            };
          });
          setCompressorInsideTableData(InnerDataArr);
      };

    const ReturnTable = () => {
      return (
        <>
          <div className="eventBackNavContainer">
          <div className="eventBackNav" onClick={() => {
              setActiveFacility("");
              setCompressorInsideTable(false);
              setInsidePageTableIndex(10);
            }}>
          <img className="backNavIcon" src={vector}  loading="lazy" alt=""/>
          <p className="eventNavHeader">All facilities</p>
          </div>
        </div>
        {compressorInsideTableData.length === 0 ? 
        (<div><DisplayError type={"err-empty"} /></div>):
        (<>
          <p className="facilitiesText">Assets under {activeFacility}</p>
          <div className="eventSummaryScss" style={{ overflowX: "scroll" }}>
            <Table className="eventInsideTable" responsive>
              <thead>
                <tr>
                  <th onClick={() => dataSorting('assetName', 2)}> <div className="sortingIcons">
                        <p>Asset</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div></th>
                  <th onClick={() => dataSorting('totalCyclesCount', 2)}><div className="sortingIcons">
                        <p>Cycles Count</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div> </th>
                  <th onClick={() => dataSorting('averageDuration', 2)}><div className="sortingIcons">
                        <p>Average Duration</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div> </th>
                  <th onClick={() => dataSorting('totalOnCycles', 2)}><div className="sortingIcons">
                        <p>Total ON Cycles</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div> </th>
                  <th onClick={() => dataSorting('averageOnDuration', 2)}><div className="sortingIcons">
                        <p>Average ON Duration</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div> </th>
                  <th onClick={() => dataSorting('totalOffCycles', 2)}><div className="sortingIcons">
                        <p>total OFF Cycles</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div></th>
                  <th onClick={() => dataSorting('averageOffDuration', 2)}><div className="sortingIcons">
                        <p>Average OFF Duration</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div></th>
                </tr>
              </thead>
              <tbody>
                {compressorInsideTableData && compressorInsideTableData.map((el: any, index: any) => {
                  return (
                    <tr key={index}>
                      <td>{el.assetName}</td>
                      <td>{el.totalCyclesCount}</td>
                      <td>{el.averageDuration} mins</td>
                      <td>{el.totalOnCycles}</td>
                      <td>{el.averageOnDuration} mins</td>
                      <td>{el.totalOffCycles}</td>
                      <td>{el.averageOffDuration} mins</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {
              compressorInsideTableData.length > 10 ? 
            <div className="footerShowMoreContainer">
              <button disabled={insidePageTableIndex === 10} onClick={() => setInsidePageTableIndex(insidePageTableIndex-10)}> {" < "} </button><p>Showing {insidePageTableIndex-9} to {compressorInsideTableData.length > insidePageTableIndex ? insidePageTableIndex : compressorInsideTableData.length} of {compressorInsideTableData.length} {activeFacility}</p> <button disabled={insidePageTableIndex > compressorInsideTableData.length} onClick={() => setInsidePageTableIndex(insidePageTableIndex+10)}>{" > "}</button>
           </div> : null
           } 
          </div>
        </>)}
          
        </>
      );
    };

    const renderDash = (value: any, formatter?: (val: any) => any) => {
      if (value == null || value === undefined || isNaN(value)) {
        return "-";
      }
      return formatter ? formatter(value) : value;
    };

    return (
      <>
        {compressorTableData.length === 0 ? (
          <div className="centerDisplayNote">
            <DisplayError type={"err-empty"} />
          </div>
        ) : compressorInsideTable ? (
          ReturnTable()
        ) : (
          <div className="eventSummaryScss" style={{ overflowX: "scroll" }}>
            <Table className="eventTableHeader" responsive>
              <thead>
                <tr>
                  <th onClick={() => dataSorting('facility', 1)}><div className="sortingIcons">
                        <p>Facility</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div> </th>
                  <th onClick={() => dataSorting('assets', 1)}><div className="sortingIcons">
                        <p>Assets</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div> </th>
                  <th onClick={() => dataSorting('totalCount', 1)}> <div className="sortingIcons">
                        <p>Total Count</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div></th>
                  <th onClick={() => dataSorting('avgDuration', 1)}> <div className="sortingIcons">
                        <p>Average Duration</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div> </th>
                  <th onClick={() => dataSorting('totalOnCycles', 1)}><div className="sortingIcons">
                        <p>Total ON Cycles</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div> </th>
                  <th onClick={() => dataSorting('avgOnDuration', 1)}><div className="sortingIcons">
                        <p>Average ON Duration</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div> </th>
                  <th onClick={() => dataSorting('totalOffCycles', 1)}><div className="sortingIcons">
                        <p>Total OFF Cycles</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div>  </th>
                  <th onClick={() => dataSorting('avgOffDuration', 1)}><div className="sortingIcons">
                        <p>Average OFF Duration</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div> </th>
                </tr>
              </thead>
              <tbody>
                {compressorTableData.map((el: any, index: any) => {
                  return (
                    <tr key={index}>
                      <td
                        onClick={() => {
                          returnexceptedObj_1(el.assetList);
                          setActiveFacility(el.facility);
                          setCompressorInsideTable(true);
                        }}
                        style={{ cursor: "pointer" }}
                        onMouseEnter={() => setIsHovered(el.facility)}
                        onMouseLeave={() => setIsHovered(null)}
                      >
                        {el.facility}
                        {isHovered == el.facility && <img style={{height: "10px", width: "10px", marginLeft: "5px"}} src={HoverIcon} alt="icon"/>}
                      </td>
                      <td>{renderDash(el.assets)}</td>
                      <td>{renderDash(el.totalCount)}</td>
                      <td>{renderDash((el.avgDuration + "min"))} </td>
                      <td>{renderDash(el.totalOnCycles)}</td>
                      <td>{renderDash((el.avgOnDuration + "mins"))}</td>
                      <td>{renderDash(el.totalOffCycles)}</td>
                      <td>{renderDash((el.avgOffDuration + "mins"))}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {/* {
              compressorTableData.length > 5 ? 
              <div className="footerShowMoreContainer">
                <button disabled={pageIndex === 5} onClick={() => setPageIndex(pageIndex-5)}> {" < "} </button><p>Showing {pageIndex-4} to {compressorTableData.length > pageIndex ? pageIndex : compressorTableData.length} of {compressorTableData.length} {activeFacility}</p> <button disabled={pageIndex > compressorTableData.length} onClick={() => setPageIndex(pageIndex+5)}>{" > "}</button>
              </div> : null
            } */}
          </div>
        )}
      </>
    );
  };

export default CompressorTable;