/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

function RedirectComp() {
  let { fixedAssetId } = useParams();

  useEffect(() => {
    // console.log("redirectComp on load::", fixedAssetId);
    const url =
      "https://services.igotit.app/services/control/viewAsset?fixedAssetId=" +
      fixedAssetId;
    // const url = "https://www.google.com";
    window.location.href = url;
  }, []);

  return <div></div>;
}

export default RedirectComp;
