import { API_BASE_URL } from "../../../Constants/constants";
import LocalStorageService from "../../../Utils/LocalStorageService";
import { SearchListType } from "../types/EventListTypes";

let controller = new AbortController();
let signal = controller.signal;

export const getEventList = async (
  index: number,
  viewSize: number,
  startDate: number,
  endDate: number,
  searchBy:SearchListType[]
) => {
  const url = `${API_BASE_URL}fixedAsset/getEventList`;
  const tokenID = LocalStorageService.getToken();
  const body = {
    viewIndex: index,
    viewSize: viewSize,
    startDate: startDate,
    endDate: endDate,
    areaId: "_NA_",
    searchBy: searchBy,
  };

  try {
    controller.abort(); // Cancel the previous request
    controller = new AbortController();
    signal = controller.signal;

    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenID}`,
      },
      signal: signal, // Pass the signal to the fetch request
    });

    if (!resp.ok) {
      return resp.status;
    }

    return await resp.json();
  } catch (error) {
    return error;
  }
};

export const getEventDetails = async (
  eventId: string,
  startDateTime: number,
  assetId: string
) => {
  const url = `${API_BASE_URL}fixedAsset/getEventDetails?eventId=${eventId}&startDateTime=${startDateTime}&assetId=${assetId}`;
  const tokenID = localStorage.getItem("@tokenID");

  try {
    controller.abort(); // Cancel the previous request
    controller = new AbortController();
    signal = controller.signal;

    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenID}`,
      },
      signal: signal,
    });

    if (!resp.ok) {
      return resp.status;
    }

    const response = await resp.json();

    return response?.eventDetail;
  } catch (error) {
    return error;
  }
};

