import React, {useEffect} from 'react';
import {useStateValue} from '../../redux/StateProvider';
import "./FilterChipsLayout.scss"
import {getFilterValueForKey} from "../../Utils/utils";
import IconClose from "../../assets/icons/iconCloseLightBlue.svg";

function FilterChipsLayout(props:any) {
    const { clearFilters } = props;

    const [{settingsFilterPreferences, settingsFilterAllKeyValueList}, dispatch] = useStateValue();

    function removeFilterItem(valueToRemove: string) {
        const updatedFilterPreferences = settingsFilterPreferences
            .map((item: { searchValue: string[] }) => ({
                ...item,
                searchValue: item.searchValue.filter((value) => value !== valueToRemove),
            }))
            .filter((item: { searchValue: string[] }) => item.searchValue.length > 0);
        dispatch({
            type: 'SET_SETTINGS_FILTER_PREFERENCES',
            payload: updatedFilterPreferences,
        });
    }

    function clearAllFilters() {
        clearFilters(true);
        dispatch({
            type: 'SET_SETTINGS_FILTER_PREFERENCES',
            payload: [],
        });
    }

    return (
        <>
            {settingsFilterPreferences.length > 0 && <div className="filterChipRoot">
                <div className="filterChipsParent">
                    {settingsFilterPreferences.map((item: any) => (
                        item.searchValue.map((value: any) => (
                                <div className="filterChipSettings">
                                    <div className="filterChipSettingsKey">{getFilterValueForKey(item.searchKey)}:</div>
                                    <div className="filterChipSettingsValue"> {settingsFilterAllKeyValueList.find((entry: {
                                        key: string;
                                        value: string
                                    }) => entry.key === value)?.value}
                                    </div>
                                    <div
                                        className="closeIconFiltersDiv"
                                    >
                                        <img src={IconClose} className="closeIconFilter"
                                             onClick={() => removeFilterItem(value)}
                                             alt="icon"/>
                                    </div>
                                </div>
                            )
                        )))}

                </div>
                <div className="settingsFilterClearAll" onClick={clearAllFilters}>CLEAR ALL</div>
            </div>
            }
        </>
    )

}

export default FilterChipsLayout;