import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import DisplayError from "../DisplayError/DisplayError";
import "./AnoviEventSummary.scss";
import vector from "../.././assets/icons/Vector_arleft.svg";
import HoverIcon from "../.././assets/icons/hoverIcon.svg"
import { SortingTable } from "./SortingTable";
import { millisecondsToTime } from "../../Utils/utils";


const DefrostCycleTable = ({data}:any) => {
    const [dfcInsideTable, setDfcInsideTable] = useState(false);
    const [activeSortingKey, setActiveSortingKey] = useState("");
    const [activeFacility, setActiviteFacility] = useState("Facilities");
    const [defrostCycleTable, setDefrostCycleTable] = useState<any>([]);
    const [defrostCycleInsideTable, setDefrostCycleInsideTable] = useState<any>([]);
    const [pageIndex, setPageIndex] = useState(5);
    const [insidePageTableIndex, setInsidePageTableIndex] = useState(10);
    let Sort = new SortingTable();
    const [isHovered, setIsHovered] = useState(null);



    const returnexceptedObj = () => {
        let list: any = data.map((obj: any) => {
          let array = obj.eventSummary.map((el: any) => {
            return {
              facility: obj.facilityName,
              assets: obj.assetCount,
              cycleCount: el.cycleCount,
              avgDuration: el.averageDuration,
              avgTimeBtnCls: el.avgBetweenCycle,
              assetList: obj.assetsList,
            };
          });
          if (array.length === 1) {
            return array[0];
          } else {
            return {};
          }
        });
        setDefrostCycleTable(list);
      };

      useEffect(() => {
        returnexceptedObj()
      }, [data])

      const dataSorting = (key:any, table:any) => {
        if(activeSortingKey === key){
            if(table === 1) setDefrostCycleTable(Sort.sortTableAscendingOrder(defrostCycleTable, key));
            else setDefrostCycleInsideTable(Sort.sortTableAscendingOrder(defrostCycleInsideTable, key));
            setActiveSortingKey("");      
        }else {
          if(table === 1) setDefrostCycleTable(Sort.sortTableDescending(defrostCycleTable, key));
          else setDefrostCycleInsideTable(Sort.sortTableDescending(defrostCycleInsideTable, key));
          setActiveSortingKey(key);
        }
      }
    
      const returnexceptedObj_1 = (responseData: any) => {
        let list: any = responseData.map((obj: any) => {
          let array = obj.eventSummary.map((el: any, index: any) => {
            return {
              asset: obj.assetName,
              cycleCount: el.cycleCount,
              avgDuration: el.averageDuration,
              avgTimeBtnCls: el.avgBetweenCycle,
            };
          });
          if (array.length === 1) {
            return array[0];
          } else {
            return {};
          }
        });
        setDefrostCycleInsideTable(list);
      };
   
    // defrost inside/child table
    const ReturnTable = () => {
      return (
        <>
        <div className="eventBackNavContainer">
          <div className="eventBackNav" onClick={() => {
              setActiviteFacility("Facilities");
              setDfcInsideTable(false);
              setInsidePageTableIndex(10);
            }}>
          <img className="backNavIcon" src={vector}  loading="lazy" alt=""/>
          <p className="eventNavHeader">All facilities</p>
          </div>
        </div>
        {defrostCycleInsideTable.length === 0 ? 
        (<div style={{display: "flex", justifyContent: "center"}}><DisplayError type={"err-empty"} /></div>):(<>
          <p className="facilitiesText">Assets under {activeFacility}</p>
          <div className="eventSummaryInnerTable">
            <Table className="eventInsideTable" responsive>
                <thead>
                  <tr>
                    <th onClick={() => dataSorting('assets', 2)}><div className="sortingIcons">
                        <p>Assets</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div></th>
                    <th onClick={() => dataSorting('cycleCount', 2)}><div className="sortingIcons">
                        <p>Cycle Count</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div></th>
                    <th onClick={() => dataSorting('avgDuration', 2)}><div className="sortingIcons">
                        <p>Average Duration</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div></th>
                    <th onClick={() => dataSorting('avgTimeBtnCls', 2)}>
                    <div className="sortingIcons">
                        <p>Avg Time Between Cycles</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div></th>
                  </tr>
                </thead>
                <tbody>
                  {defrostCycleInsideTable && defrostCycleInsideTable.slice(0, insidePageTableIndex).map((el: any, index: any) => {
                    return (
                      <tr key={index}>
                        <td>{el.asset}</td>
                        <td>{el.cycleCount}</td>
                        <td>{millisecondsToTime(el.avgDuration)}</td>
                        <td>{millisecondsToTime(el.avgTimeBtnCls)}</td>
                      </tr>
                    );
                  })}
                </tbody>
            </Table>
            {
              defrostCycleInsideTable.length > 10 ?
              <div className="footerShowMoreContainer">
                <button disabled={insidePageTableIndex === 10} onClick={() => setInsidePageTableIndex(insidePageTableIndex-10)}> {" < "} </button><p>Showing {insidePageTableIndex-9} to {defrostCycleInsideTable.length > insidePageTableIndex ? insidePageTableIndex : defrostCycleInsideTable.length} of {defrostCycleInsideTable.length} {activeFacility}</p> <button disabled={insidePageTableIndex > defrostCycleInsideTable.length} onClick={() => setInsidePageTableIndex(insidePageTableIndex+10)}>{" > "}</button>
              </div> : null
            }
          </div>
        </>)}
        </>
      );
    };

    return (
      <>
        {(defrostCycleTable.length === 0) ? (
          <div className="centerDisplayNote">
            <DisplayError type={"err-empty"} />
          </div>
        ) : (
          <>
            {dfcInsideTable ? (
              <>{ReturnTable()}</>
            ) : (
              <div className="eventSummaryScss">
                <Table className="eventTableHeader" responsive>
                  <thead>
                    <tr>
                    <th onClick={() => dataSorting('facility', 1)}>
                    <div className="sortingIcons">
                        <p>Facility</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div></th>
                    <th onClick={() => dataSorting('assets', 1)}> <div className="sortingIcons">
                        <p>Assets</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div></th>
                    <th onClick={() => dataSorting('cycleCount', 1)}> <div className="sortingIcons">
                        <p>Cycle Count</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div></th>
                    <th onClick={() => dataSorting('avgDuration', 1)}> <div className="sortingIcons">
                        <p>Average Duration</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div></th>
                    <th onClick={() => dataSorting('avgTimeBtnCls', 1)}> <div className="sortingIcons">
                        <p>Avg Time Between Cycles</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div></th>
                    </tr>
                  </thead>
                  <tbody >
                    {defrostCycleTable.map((el: any, index: any) => {
                            if (el.facility == null || el.assets == null || el.cycleCount == null || el.avgDuration == null || el.avgTimeBtnCls == null) {
                              return null;
                            }

                            return (
                              <tr key={index}>
                                <td
                                  onClick={() => {
                                    returnexceptedObj_1(el.assetList);
                                    setActiviteFacility(el.facility);
                                    setDfcInsideTable(true);
                                  }}
                                  style={{ cursor: "pointer" }}
                                  onMouseEnter={() => setIsHovered(el.facility)}
                                  onMouseLeave={() => setIsHovered(null)}
                                >
                                  {el.facility}
                                  {isHovered == el.facility && <img style={{height: "10px", width: "10px", marginLeft: "5px"}} src={HoverIcon} alt="icon"/>}
                                </td>
                                <td>{el.assets}</td>
                                <td>{el.cycleCount}</td>
                                <td>{el.avgDuration} mins</td>
                                <td>{el.avgTimeBtnCls} mins</td>
                              </tr>
                            );
                    })}
                    
                  </tbody>
                </Table>
                {/* {
                  defrostCycleTable.length > 5 ? 
                  <div className="footerShowMoreContainer">
                    <button disabled={pageIndex === 5} onClick={() => setPageIndex(pageIndex-5)}> {" < "} </button><p>Showing {pageIndex-4} to {defrostCycleTable.length > pageIndex ? pageIndex : defrostCycleTable.length} of {defrostCycleTable.length} {activeFacility}</p> <button disabled={pageIndex > defrostCycleTable.length} onClick={() => setPageIndex(pageIndex+5)}>{" > "}</button>
                  </div>  :  null
                } */}
              </div>
            )}
          </>
        )}
      </>
    );
  };

export default DefrostCycleTable;