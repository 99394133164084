import React from "react";
import "./AlertChip.scss";
function AlertChip({ color, text, img, value }:any) {
  return (
    <div className="alertChip" style={{ backgroundColor: color,  pointerEvents: "none"}}>
      <img src={img} />
      <h3>
        {value}{"  "}{text}
      </h3>
    </div>
  );
}

export default AlertChip;
