/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Item } from "semantic-ui-react";
import { keyFormat } from "../../../Utils";
import TicketsCard from "../ticketsCard/TicketsCard";
import { VideoAnalyticsDBServices } from "../videoAnalyticsDbService/videoAnalyticsDbService";
import "./VideoAnalyticTickets.scss";
import moment from "moment";
import DisplayNote from "../../../a1Components/DisplayNote/DisplayNote";
import DisplayError from "../../../a1Components/DisplayError/DisplayError";
import axios from "axios";

function VideoAnalyticTickets(props: any) {
  const { dateRange } = props;
  const [ticketSummary, setTicketSummaryData] = useState({});
  const [ticketList, setTicketList] = useState({});
  const [loading, setLoading] = useState(false);

  const ticketSummaryData = async () => {
    setLoading(true);
    try {
      const startDate = moment(dateRange[0]).utc().valueOf();
      const endDate = moment(dateRange[1]).utc().valueOf();
      const url = `https://hawkeye.aone.ai/analytics/videoAnalytics/ticket/summary?start=${startDate}&end=${endDate}`;
      const response = await axios.get(url);
      if (response.status === 200) {
        setTicketSummaryData(response.data.data);
      } else {
        setTicketSummaryData({});
      }
      setLoading(false);
    } catch (error) {
      setTicketSummaryData({});
      setLoading(false);
    }
  };

  useEffect(() => {
    ticketSummaryData();
  }, [dateRange]);

  return (
    <>
      {loading ? (
        <DisplayNote />
      ) : (
        <>
        <DisplayNote />
          {/* {Object.getOwnPropertyNames(ticketSummary).length !== 0 ? (
            <>
              {ticketSummary.groupdata[0].count !== 0 ||
              ticketSummary.groupdata[1].count !== 0 ||
              ticketSummary.groupdata[2].count !== 0 ? (
                <>
                  <div className="videoAnalyticTicketsContainer">
                    <div className="ticketTypeNotificationCont">
                      {ticketSummary.groupdata.map((item) => {
                        return (
                          <div className="ticketTypeNotification">
                            <p className="ticketTypeNotificationTxt">
                              {item.name}
                            </p>
                            <p className="ticketTypeNotificationCount">
                              {item.count}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                    <div>
                      {ticketSummary?.data.map((obj) => (
                        <TicketsCard data={obj} />
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <DisplayNote />
              )}
            </>
          ) : (
            <DisplayError type={"err_1"} />
          )} */}
        </>
      )}
    </>
  );
}

export default VideoAnalyticTickets;
