import React, { useState, useRef, useEffect } from 'react';

type dataType = {
    callBackFunction:(e:string) => void;
}

function OtpInputScreenPassword({ callBackFunction } : dataType ) {
  const [otp, setOtp] = useState(['', '', '', '']);
  const otpInputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const handleInputChange = (index:number, value:string) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      otp[index] = value;
      setOtp([...otp]);
      if (value && index < 3) {
        otpInputRefs[index + 1].current.focus();
      }else {
        if(otp.join("").length === 4) callBackFunction(otp.join(""));
      }
    }
  };

  const handleKeyDown = (index:number, event:any) => {
    if (event.key === 'Backspace' && !otp[index] && index > 0) {
      otpInputRefs[index - 1].current.focus();
    }
  };

  return (
    <div className='otpInputContainer'>
        {otp.map((digit:string, index:number) => (
           <input  key={index} type="number" maxLength={1} minLength={1} value={digit} onChange={(e) => handleInputChange(index, e.target.value.trim())} onKeyDown={(e) => handleKeyDown(index, e)} ref={otpInputRefs[index]} />
        ))}
    </div>
  );
}


export default OtpInputScreenPassword