/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { FieldValidatorUtil } from "../../../../Utils/fieldValidation/FieldValidatorUtil";
import DisplayError from "../../../../a1Components/DisplayError/DisplayError";
import AssetImageIcon from "../../../../assets/icons/AssetImageIcon.svg";
import AssetLocationIcon from "../../../../assets/icons/AssetLocationIcon.svg";
import AssetTypeIcon from "../../../../assets/icons/AssetTypeIcon.svg";
import CardHoverIcon from "../../../../assets/icons/CardHoverIcon.svg";
import IconClose from "../../../../assets/icons/IconClose.svg";
import IconDelete from "../../../../assets/icons/IconDelete.svg";
import IconEdit from "../../../../assets/icons/IconEdit.svg";
import IconWarning from "../../../../assets/icons/IconWarning.svg";
import { SettingsApiServices } from "../../../../services/settingsPageServices/SettingsApiServices";
import AssetSettingsCreateEditModal from "../assetSettingsCard/AssetSettingsCreateEditModal";
import { AssetHelperFunction } from "./../../../../Utils/AssetHelperFunction";
import "./AssetSettingsCard.scss";
import HorizontalLine from "../../../../assets/image/horizontalLine.svg";
import FormValidator from "../../../../Utils/FormValidations/FormValidations";
import { toast } from "react-toastify";
import loader from "../../../../a1Components/lotties/round-loader-white.json";
import Lottie from "react-lottie";

interface AssetAttr {
  attrDescription: string
  attrValue: any
  attrName: string
  fieldTypeId: string
  UomId?: string
  UomDescription?: string
  defaultAttrValue?: string
}

function AssetSettingsCard(props: any) {
  const {
    addToSelectedList,
    data,
    selected,
    statusId,
    statusDiscription,
    callAssetListApi,
    setAssetDetailEmpty,
    showSuccessToast,
    showErrorToast,
    callCreateFacilityFunction,
    countryId,
    stateId,
    countryList,
  } = props;

  const securityPermissions = localStorage.getItem("securityPermissions") || "";
  const securityPermissionsArray = JSON.parse(securityPermissions);
  // const securityPermissionsArray = securityPermissionsArr.filter(permission => permission !== "FIXEDASSET_CREATE");

  // Asset Delete Popup
  const [assetDelete, setAssetDelete] = useState(false);

  // Asset View Popup
  const [showViewAsset, setShowViewAsset] = useState(false);

  // Asset Edit Popup
  const [showEditAsset, setShowEditAsset] = useState(false);

  const [showUpdateAttributes, setShowUpdateAttributes] = useState(false);

  const [cardViewAsset, setCardViewAsset] = useState(true);

  const [stateName, setStateName] = useState("");

  const [facilityList, setFacilityList] = useState([]);
  const [facilitySelectedForRelocate, setFacilitySelectedForRelocate] =
    useState({
      value: data?.facility?.facilityId,
      label: data?.facility?.facilityName,
    });
  const [invalidArr, setInvalidArr] = useState<string[]>([]);

  // Relocate Popup
  const [showRelocate, setShowRelocate] = useState(false);
  const [attributesData, setAttributesData] = useState<AssetAttr[]>([]);
  const [assetAttributeStatus, setAssetAttributeStatus] = useState({
    buttonDisabled: false,
    isLoading: false
  });
  const service = new SettingsApiServices();
  const [isValidEmail, setIsValidEmail] = useState(true);

  useEffect(() => {
    if (showViewAsset || showEditAsset || showUpdateAttributes) {
      fetchAssetAttributesData();
    }
  }, [showViewAsset, showEditAsset, showUpdateAttributes]);

  async function fetchAssetAttributesData() {
    try {
      const apiResponse = await service.getAssetAttributes(data?.fixedAssetId);
      const initialDataWithOriginalValue = apiResponse?.map((obj) => ({
        ...obj,
        originalValue: obj.attrValue,
      }));
      setAttributesData(initialDataWithOriginalValue);
      console.log("initialDataWithOriginalValue", initialDataWithOriginalValue);
    } catch (error) {
      console.error("Error fetching attributes data:", error);
    }
  }

  const handleUpdateAttributes = async () => {
    // const changedAttributes = attributesData.filter(obj => obj.attrValue !== obj.originalValue);
    const changedAttributesList = attributesData.map((obj) => ({
      fixedAssetId: data?.fixedAssetId,
      attrName: obj.attrName,
      attrValue: obj.attrValue,
    }));
    const formattedChangedAttributes = {
      assetAttributeList: changedAttributesList,
    };

    if (changedAttributesList.length > 0) {

      const isEmailFieldAvailable = attributesData.find((obj) => obj.fieldTypeId === "EMAIL");
      if (isEmailFieldAvailable === undefined) {
        setIsValidEmail(true);
      } else {
        validateEmail(isEmailFieldAvailable.attrValue);
        return;
      }

      setAssetAttributeStatus({
        ...assetAttributeStatus,
        isLoading: true
      });

      try {
        const response = await service.updateAssetAttributes(
          formattedChangedAttributes
        );
        if (response.statusCode === "OK" || response.statusCode === 200) {
          setAssetAttributeStatus({
            ...assetAttributeStatus,
            isLoading: false
          });
          showSuccessToast("Asset attributes updated successfully.");
          setShowUpdateAttributes(false);
        }
      } catch (e) {
        setAssetAttributeStatus({
          ...assetAttributeStatus,
          isLoading: false
        });
        showErrorToast("Asset attributes update failed.");
        console.log(e);
      }
      fetchAssetAttributesData();
    } else {
      showErrorToast("Error: Values have not been edited.");
    }
  };

  const handleCancelChanges = () => {
    const resetAttributesData = attributesData?.map((obj) => ({
      ...obj,
      attrValue: obj.originalValue,
    }));
    setAttributesData(resetAttributesData);
    setShowUpdateAttributes(false);
  };

  const handleInputChange = (index, newValue) => {
    const updatedAttributesData = [...attributesData];
    updatedAttributesData[index].attrValue = newValue;
    setAttributesData(updatedAttributesData);
  };

  // Update Attributes Popup
  const setViewAssetTrue = () => {
    if (cardViewAsset) {
      setShowViewAsset(true);
    }
  };

  const [filteredFacilityList, setFilteredFacilityList] = useState(facilityList);
  const [typedValue, setTypedValue] = useState({
    id: data?.facility?.facilityId,
    value: data?.facility?.facilityName
  });
  const getFacilityList = () => {
    try {
      const localData = localStorage.getItem("facilityIds");
      const facilities = JSON.parse(localData);

      const result = facilities.map((element: any) => ({
        value: element.facilityId,
        label: element.facilityName,
      }));

      setFacilityList(result);
      setFilteredFacilityList(result);
    } catch (error) {
      console.error("Error fetching facility data:", error);
    }
  };

  const [enableRelocateAssetButton, setEnableRelocateAssetButton] = useState(true);
  const [toastEnabled, setToastEnabled] = useState(true);
  const [selectedFacility, setSelectedFacility] = useState(true);
  const [loading, setLoading] = useState(false);

  const numberOfClicks = useRef(0);

  const collapseToast = () => {
    setToastEnabled(true);
    setLoading(false);
    numberOfClicks.current = 0
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
  };

  const relocateAsset = async () => {

    if (numberOfClicks.current !== 1) {
      return null;
    }
    const body = {
      "fixedAssetId": data?.fixedAssetId,
      "relocateTo": typedValue.id,
    };

    setEnableRelocateAssetButton(false);
    const token = localStorage.getItem("@tokenID");

    toast.dismiss();
    setLoading(true);

    const resp = await service.relocateAsset(token, body);
    console.log("asset:relocateAsset:", resp);
    if (resp.status == 200) {
      setEnableRelocateAssetButton(true);
      toast.success("Asset relocated to the facility.", {
        onOpen: () => setToastEnabled(false),
        onClose: () => collapseToast()
      });
      setAssetDetailEmpty();
      setShowRelocate(false);
      callAssetListApi();
    } else {
      setEnableRelocateAssetButton(true);
      setLoading(false);
      toast.error("Request failed.", {
        onOpen: () => setToastEnabled(false),
        onClose: () => collapseToast()
      });
    }
  };

  const handleFacilityChange = (event: any) => {
    setFacilitySelectedForRelocate(event);
  };

  const checkName = (name: string, val: string) => {
    const validArr = ["validateFacilityId"];

    if (!validArr.includes(name)) {
      return;
    }

    let functionName =
      name === "validateFacilityId" ? "isValidAssetData" : name;

    let isValid = false;

    const validate = new FieldValidatorUtil();

    isValid = validate.isValidAssetData(val);

    setInvalidArr((prevInvalidArr) => {
      if (isValid) {
        return prevInvalidArr.filter((obj) => obj !== name);
      } else {
        if (!invalidArr.includes(name)) {
          return [...prevInvalidArr, name];
        } else {
          return [...prevInvalidArr];
        }
      }
    });
  };

  const getStateData = () => {
    const assetHelperFunction = new AssetHelperFunction();
    const result = assetHelperFunction.getFacilityStateData(
      countryList,
      countryId,
      stateId
    );
    setStateName(result);
  };

  useEffect(() => {
    getFacilityList();
    getStateData();
  }, []);

  //ToDo match the key not the value
  const getStatusColor = (status: string) => {
    if (status == "Active") {
      return "#5AA15D4D";
    } else if (status == "Inactive") {
      return "#F447474D";
    } else if (status == "Maintenance") {
      return "#E9BE034D";
    } else if (status == "Lost") {
      return "#DF43204D";
    }
    if (status == "Active") {
      return "#5AA15D4D";
    } else if (status == "Accident/ Breakdown") {
      return "#F447474D";
    } else if (status == "Online") {
      return "#4CAF5033";
    } else if (status == "Offline") {
      return "#F4433633";
    } else if (status == "Slow") {
      return "#FFC10733";
    } else {
      return "#FF967E";
    }
  };

  const [showDropDownList, setShowDropDownList] = useState(false);

  const validateAssetAttribute = (
    value: string,
    fieldTypeId: string,
    index: number
  ) => {
    if (value === "") {
      handleInputChange(index, value);
      return;
    }
    const validate = new FieldValidatorUtil();
    const fieldValidators = {
      NUMBER_FLOAT: /^[+-]?\d*\.?\d{0,8}$/,
      NUMBER_LONG: /^[+-]?\d{1,20}$/,
      TEXTAREA: /^[a-zA-Z0-9 ]{1,255}$/,
      TEXT_LONG: /^[a-zA-Z0-9 ]{1,150}$/,
      TEXT_SHORT: /^[a-zA-Z0-9 ]{1,30}$/,
      PASSWORD: /^[a-zA-Z0-9 ]{1,30}$/,
      DEFAULT: /^[A-Za-z_]+$/,
    };

    if (fieldTypeId === undefined) {
      const regex = fieldValidators.TEXT_SHORT;
      if (validate.isAssetAttributeValidate(value, regex)) {
        handleInputChange(index, value);
      }
    } else {
      const regex = fieldValidators[fieldTypeId] || fieldValidators.DEFAULT;
      if (fieldTypeId in fieldValidators) {
        if (validate.isAssetAttributeValidate(value, regex)) {
          handleInputChange(index, value);
        }
      }
    }
  };

  const validateEmail = (value: string) => {
    const validate = new FieldValidatorUtil();
    if (value !== "" || value !== null) {
      const result = validate.validateOrgEmail(value);
      setIsValidEmail(result);
    }
  }

  const today = new Date();
  const maxDate = today.toISOString().split('T')[0]; // Today's date
  const minDate = new Date(today);
  minDate.setDate(today.getDate() - 360);
  const minDateStr = minDate.toISOString().split('T')[0]; // Date 360 days from today

  useEffect(() => {
    if (attributesData.length > 0) {
      setAssetAttributeStatus({
        buttonDisabled: false,
        isLoading: false
      });
    } else {
      setAssetAttributeStatus({
        buttonDisabled: true,
        isLoading: false
      })
    }
  }, [attributesData])

  return (
    <>
      <div
        className={selected ? "activeAssetSettingsCard" : "assetSettingsCard"}
        onClick={setViewAssetTrue}
      >
        <div className="assetSettingsCardInputCont">
          <input
            type="checkbox"
            className="settingsCardInput"
            checked={true}
            onClick={() => addToSelectedList(data)}
            onMouseOver={() => setCardViewAsset(false)}
            onMouseLeave={() => setCardViewAsset(true)}
          />
          <div className="settingsCardStatusCont">
            <p
              className="statusEnabledTxt"
              style={{ backgroundColor: getStatusColor(statusDiscription) }}
            >
              {statusDiscription}
            </p>
            <div className="settingsImgHoverCont">
              <img src={CardHoverIcon} alt="icon" />
              <ul
                className="settingsCardHoverCont"
                onMouseOver={() => setCardViewAsset(false)}
                onMouseLeave={() => setCardViewAsset(true)}
              >
                <li onClick={() => setShowViewAsset(true)}>View Asset</li>
                {securityPermissionsArray.includes("FIXEDASSET_UPDATE") && <li onClick={() => setShowUpdateAttributes(true)}>Update Attributes</li>}
                {securityPermissionsArray.includes("FIXEDASSET_UPDATE") && <li onClick={() => setShowRelocate(true)}>Relocate</li>}
                {securityPermissionsArray.includes("FIXEDASSET_UPDATE") && <li onClick={() => setShowEditAsset(true)}>Edit Asset</li>}
                {securityPermissionsArray.includes("FIXEDASSET_DELETE") && <li onClick={() => setAssetDelete(true)}>Delete Asset</li>}
              </ul>
            </div>
          </div>
        </div>
        <div className="assetSettingsCardDetailsCont">
          <div className="assetProfileCont">
            <img src={AssetImageIcon} alt="icon" />
          </div>
          <div className="assetSettingsCardDetails">
            <p className="assetIdTxt">
              {data?.serialNumber || data?.fixedAssetId}
            </p>
            <div className="settingsImgAndDetailsCont">
              <img src={AssetTypeIcon} alt="icon" />
              <div className="detailsContainer">
                <div className="assetNameTextCont">
                  <p className="assetNameTxt">{data?.fixedAssetName}</p>
                </div>
                <p className="assetPositionTxt">
                  {data?.fixedAssetTypeDescription || "-"}
                </p>
              </div>
            </div>
          </div>
        </div>

        <p className="assetAreaTxt">
          {data?.productName === null || undefined ? "-" : data?.productName}
        </p>

        <div className="assetAddressContainer">
          <img src={AssetLocationIcon} alt="icon" />
          <p className="assetAddressTxt">
            {data?.facility?.facilityName}
            {data?.facility?.address?.city
              ? `, ${data?.facility?.address?.city}`
              : ""}

            {stateName ? `, ${stateName}` : ""}
          </p>
        </div>
      </div>

      {/* View Asset Popup */}
      <Modal
        show={showViewAsset}
        onHide={() => setShowViewAsset(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className="viewAssetPopupModal"
      >
        <Modal.Header className="viewAssetPopupHeader">
          <div className="viewAssetPopupHeaderCont">
            <div className="viewAssetImgCont">
              <img src={AssetImageIcon} alt="icon" />
            </div>
            <div className="viewAssetProfileDetails">
              <p className="viewAssetIdTxt">{data?.fixedAssetId}</p>
              <div className="viewAssetImgAndDetailsCont">
                <img src={AssetTypeIcon} alt="icon" />
                <div className="detailsContainer">
                  <p className="viewAssetNameTxt">{data?.fixedAssetName}</p>
                  <p className="viewAssetPositionTxt">
                    {" "}
                    {data?.fixedAssetTypeDescription}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="closeIconDiv" onClick={() => setShowViewAsset(false)}>
            <img src={IconClose} alt="icon" />
          </div>
        </Modal.Header>
        <Modal.Body className="viewAssetPopupBody">
          <div className="viewAssetPopupBodyCont">
            <div className="AssetInfoContainer">
              <p className="AssetInfoHeader">Asset Information</p>
              <div className="AssetInfoHeaderBorder"></div>
              <div className="AssetInfoDetailsCont">
                <div className="AssetInfoDetails">
                  <div className="AssetInfoHeaderDiv">
                    <p className="AssetInfoHeader">Serial Number</p>
                  </div>
                  <div className="AssetInfoParaDiv">
                    <p className="AssetInfoPara">
                      {data?.serialNumber == null || undefined
                        ? "-"
                        : data?.serialNumber}
                    </p>
                  </div>
                </div>

                <div className="AssetInfoDetails">
                  <div className="AssetInfoHeaderDiv">
                    <p className="AssetInfoHeader">Asset Type</p>
                  </div>
                  <div className="AssetInfoParaDiv">
                    <p className="AssetInfoPara">
                      {data?.fixedAssetTypeDescription == null || undefined
                        ? "-"
                        : data?.fixedAssetTypeDescription}
                    </p>
                  </div>
                </div>

                <div className="AssetInfoDetails">
                  <div className="AssetInfoHeaderDiv">
                    <p className="AssetInfoHeader">Asset Class</p>
                  </div>
                  <div className="AssetInfoParaDiv">
                    <p className="AssetInfoPara">
                      {data?.enumerationDescription == null || undefined
                        ? "-"
                        : data?.enumerationDescription}
                    </p>
                  </div>
                </div>

                <div className="AssetInfoDetails">
                  <div className="AssetInfoHeaderDiv">
                    <p className="AssetInfoHeader">Located at Facility</p>
                  </div>
                  <div className="AssetInfoParaDiv">
                    <p className="AssetInfoPara">
                      {data?.facility?.facilityName == null || undefined
                        ? "-"
                        : data?.facility?.facilityName}
                    </p>
                  </div>
                </div>

                <div className="AssetInfoDetails">
                  <div className="AssetInfoHeaderDiv">
                    <p className="AssetInfoHeader">Product Name</p>
                  </div>
                  <div className="AssetInfoParaDiv">
                    <p className="AssetInfoPara">
                      {data?.productName == null || undefined
                        ? "-"
                        : data?.productName}
                    </p>
                  </div>
                </div>

                <div className="AssetInfoDetails">
                  <div className="AssetInfoHeaderDiv">
                    <p className="AssetInfoHeader">Brand</p>
                  </div>
                  <div className="AssetInfoParaDiv">
                    <p className="AssetInfoPara">{"-"}</p>
                  </div>
                </div>

                <div className="AssetInfoDetails">
                  <div className="AssetInfoHeaderDiv">
                    <p className="AssetInfoHeader">Model</p>
                  </div>
                  <div className="AssetInfoParaDiv">
                    <p className="AssetInfoPara">{"-"}</p>
                  </div>
                </div>

                <div className="AssetInfoDetails">
                  <div className="AssetInfoHeaderDiv">
                    <p className="AssetInfoHeader">Make</p>
                  </div>
                  <div className="AssetInfoParaDiv">
                    <p className="AssetInfoPara">{"-"}</p>
                  </div>
                </div>

                <div className="AssetInfoDetails">
                  <div className="AssetInfoHeaderDiv">
                    <p className="AssetInfoHeader">Purchase Date</p>
                  </div>
                  <div className="AssetInfoParaDiv">
                    <p className="AssetInfoPara">
                      {data?.dateAcquired == null || undefined
                        ? "-"
                        : moment(data?.dateAcquired).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="AssetAttributeCont">
              {attributesData && attributesData?.length > 0 && (
                <>
                  <p className="AssetAttributeHeader">Attributes</p>
                  <div className="AssetAttributeHeaderBorder"></div>
                  <div className="AssetAttributeDetailsCont">
                    {attributesData.map((obj) => (
                      <div
                        className="AssetAttributeDetails"
                        key={obj.attrValue}
                      >
                        <div className="AssetAttributeHeaderDiv">
                          <p className="AssetAttributeHeader">
                            {obj.attrDescription || obj.attrName}
                          </p>
                        </div>
                        <div className="AssetAttributeParaDev">
                          <p className="AssetAttributePara">
                            {obj.attrValue || "-"}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="viewAssetPopupFooter">
          {securityPermissionsArray.includes("FIXEDASSET_UPDATE") && (
            <button
              className="viewAssetEditBtn"
              onClick={() => setShowEditAsset(true)}
            >
              <img src={IconEdit} alt="icon" />
              Edit
            </button>
          )}
          {securityPermissionsArray.includes("FIXEDASSET_DELETE") && (
            <button
              className="viewAssetDeleteBtn"
              onClick={() => setAssetDelete(true)}
            >
              <img src={IconDelete} alt="icon" />
              Delete
            </button>
          )}
        </Modal.Footer>
      </Modal>

      {/* Update Attributes Popup */}
      <Modal
        show={showUpdateAttributes}
        onHide={handleCancelChanges}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="updateAttributePopupModal"
      >
        <Modal.Header className="updateAttributePopupHeader">
          <h4 className="updateAttributeHeader">Update attributes</h4>
          <div className="closeIconDiv" onClick={handleCancelChanges}>
            <img src={IconClose} alt="icon" />
          </div>
        </Modal.Header>
        <Modal.Body className="updateAttributePopupBody">
          <div className="updateAttributePopupBodyCont">
            <div className="updateAttributeProfileCont">
              <div className="updateAttributeImgCont">
                <img src={AssetImageIcon} alt="icon" />
              </div>
              <div className="updateAttributeDetails">
                <p className="updateAttributeIdTxt">{data?.fixedAssetId}</p>
                <div className="updateAttributeImgAndDetailsCont">
                  <img src={AssetTypeIcon} alt="icon" />
                  <div className="detailsContainer">
                    <p className="updateAttributeNameTxt">
                      {data?.fixedAssetName}
                    </p>
                    <p className="updateAttributePositionTxt">
                      {" "}
                      {data?.fixedAssetTypeDescription}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="updateAttributesInputsCont">
              {attributesData === undefined || attributesData?.length === 0 ? (
                <div className="updateAttributesErrorLayout">
                  <DisplayError type={"err-empty"} />
                </div>
              ) : (
                attributesData?.map((obj, index) => (
                  <>
                    {obj.fieldTypeId === "BOOLEAN" ? (
                      <div className="textInputsCont" key={obj.attrDescription}>
                        <p>{obj.attrDescription || obj.attrName}</p>
                        <div className="booleanParentCont">
                          <div
                            className="booleanLeftCont"
                            onClick={() => handleInputChange(index, "NO")}
                            style={{
                              backgroundColor:
                                obj.attrValue === "NO"
                                  ? "rgba(218, 226, 255, 1)"
                                  : "rgba(255, 255, 255, 1)",
                            }}
                          >
                            <p>No</p>
                          </div>
                          <div
                            className="booleanRightCont"
                            onClick={() => handleInputChange(index, "YES")}
                            style={{
                              backgroundColor:
                                obj.attrValue === "YES"
                                  ? "rgba(218, 226, 255, 1)"
                                  : "rgba(255, 255, 255, 1)",
                            }}
                          >
                            <p>Yes</p>
                          </div>
                        </div>
                      </div>
                    ) : obj.fieldTypeId === "DATE_TIME" ? (
                      <div className="textInputsCont" key={obj.attrDescription}>
                        <p>{obj.attrDescription || obj.attrName}</p>
                        <input
                          type="datetime-local"
                          value={obj.attrValue || ""}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                          min={minDateStr}
                          max={maxDate}
                        />
                      </div>
                    ) : obj.fieldTypeId === "DATE" ? (
                      <div className="textInputsCont" key={obj.attrDescription}>
                        <p>{obj.attrDescription || obj.attrName}</p>
                        <input
                          type="date"
                          value={obj.attrValue || ""}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                          min={minDateStr}
                          max={maxDate}
                        />
                      </div>
                    ) : obj.fieldTypeId === "TIME" ? (
                      <div className="textInputsCont" key={obj.attrDescription}>
                        <p>{obj.attrDescription || obj.attrName}</p>
                        <input
                          type="time"
                          value={obj.attrValue || ""}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                        />
                      </div>
                    ) : obj.fieldTypeId === "OPTION" ? (
                      <div className="textInputsCont" key={obj.attrDescription}>
                        <p>{obj.attrDescription || obj.attrName}</p>
                        <select
                          value={obj.attrValue}
                          className="attributeDropdown"
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                        >
                          {obj.defaultAttrValue
                            ?.split(",")
                            .map((item, optionIndex) => (
                              <option key={optionIndex} value={item}>
                                {item}
                              </option>
                            ))}
                        </select>
                      </div>
                    ) : obj.fieldTypeId === "NUMBER_FLOAT" ? (
                      <div className="textInputsCont" key={obj.attrDescription}>
                        <p>{obj.attrDescription || obj.attrName}</p>
                        <div className="textInputUnitConst">
                          <input
                            type="text"
                            step="0.1"
                            value={obj.attrValue || ""}
                            onChange={(e) => {
                              validateAssetAttribute(
                                e.target.value,
                                obj.fieldTypeId,
                                index
                              );
                            }}
                          />
                          <img
                            src={HorizontalLine}
                            alt="icon"
                            className="horizontalLine"
                          />
                          <p>{obj.UomDescription}</p>
                        </div>
                      </div>
                    ) : obj.fieldTypeId === "NUMBER_LONG" ? (
                      <div className="textInputsCont" key={obj.attrDescription}>
                        <p>{obj.attrDescription || obj.attrName}</p>
                        <div className="textInputUnitConst">
                          <input
                            type="text"
                            value={obj.attrValue || ""}
                            onChange={(e) =>
                              validateAssetAttribute(
                                e.target.value,
                                obj.fieldTypeId,
                                index
                              )
                            }
                          />
                          <img
                            src={HorizontalLine}
                            alt="icon"
                            className="horizontalLine"
                          />
                          <p>{obj.UomDescription}</p>
                        </div>
                      </div>
                    ) : obj.fieldTypeId === "TEXTAREA" ? (
                      <div className="textInputsCont" key={obj.attrDescription}>
                        <p>{obj.attrDescription || obj.attrName}</p>
                        <textarea
                          rows={2}
                          className="textArea"
                          value={obj.attrValue || ""}
                          onChange={(e) =>
                            validateAssetAttribute(
                              e.target.value,
                              obj.fieldTypeId,
                              index
                            )
                          }
                        />
                      </div>
                    ) : obj.fieldTypeId === "EMAIL" ? (
                      <div className="textInputsCont" key={obj.attrDescription}>
                        <p>{obj.attrDescription || obj.attrName}</p>
                        <input
                          type={"email"}
                          value={obj.attrValue || ""}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                          onBlur={() => validateEmail(obj.attrValue)}
                          style={{
                            border: `${!isValidEmail ? "1px solid red" : ""}`,
                          }}
                        />
                        {!isValidEmail && (
                          <label className="invalidMessage">
                            {new FormValidator().validateValue(
                              obj.attrValue,
                              /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              30
                            )}
                          </label>
                        )}
                      </div>
                    ) : (
                      <div className="textInputsCont" key={obj.attrDescription}>
                        <p>{obj.attrDescription || obj.attrName}</p>
                        <input
                          type={
                            obj.fieldTypeId === "PASSWORD" ? "password" : "text"
                          }
                          value={obj.attrValue || ""}
                          onChange={(e) =>
                            validateAssetAttribute(
                              e.target.value,
                              obj.fieldTypeId,
                              index
                            )
                          }
                        />
                      </div>
                    )}
                  </>
                ))
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="updateAttributePopupFooter">
          <button
            className="updateAttributeCancelBtn"
            onClick={handleCancelChanges}
          >
            Cancel
          </button>
          <button
            className={`updateAttributeUpdateBtn ${!attributesData || attributesData.length === 0
              ? "disabledButton"
              : ""
              }`}
            style={{
              opacity: isValidEmail ? 1 : 0.3
            }}
            onClick={handleUpdateAttributes}
            disabled={!attributesData || attributesData?.length === 0 || !isValidEmail || assetAttributeStatus.isLoading}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>

      {/* Relocate Popup */}
      <Modal
        show={showRelocate}
        onHide={() => setShowRelocate(false)}
        centered
        className="relocatePopupModal"
        size="md"
      >
        <div>
          <div className="relocateAssetHeaderCont">
            <h4 className="relocateAssetHeader">Relocate Asset</h4>
            <div className="closeIconDiv" onClick={() => setShowRelocate(false)}>
              <img src={IconClose} className="closeIcon" />
            </div>

          </div>
          <div className="selectorTagContainer">
            <input className="selectSearchInput" type="search"
              value={typedValue.value}
              onChange={(e) => {
                setFilteredFacilityList(facilityList.filter((obj) => obj.label.toLowerCase().includes(e.target.value.toLowerCase())))
                setTypedValue((obj) => { return { ...obj, value: e.target.value } })
              }}
              placeholder="Select Facility"
              onClick={() => {
                setShowDropDownList(!showDropDownList);
                setTypedValue((obj) => { return { ...obj, value: "" } })
                setSelectedFacility(false);
              }} />

            {showDropDownList && filteredFacilityList.length !== 0 && <ul className="selectSearchListCont">
              {
                filteredFacilityList.map((el) => <li key={el.value} className={el.label === typedValue.value ? 'selectSearchList selectedList' : 'selectSearchList'}
                  onClick={() => {
                    setTypedValue({ id: el.value, value: el.label });
                    setShowDropDownList(false);
                    setSelectedFacility(true);
                  }}>{el.label}</li>
                )
              }
            </ul>}
          </div>
          <div className="relocateAssetFooterCont">
            <button className="assetCancelBtn" onClick={() => setShowRelocate(false)}>Cancel</button>

            <button className="assetRelocateBtn"
              style={{ opacity: selectedFacility && toastEnabled ? 1 : 0.3 }}
              disabled={!enableRelocateAssetButton || !toastEnabled || !selectedFacility || loading}
              onClick={() => { numberOfClicks.current = numberOfClicks.current + 1; relocateAsset(); }}>
              {loading ? <Lottie options={defaultOptions} height="29px"/> : "Relocate" }
            </button>
          </div>
        </div>
      </Modal>

      {/* Delete Asset Popup */}
      <Modal
        show={assetDelete}
        onHide={() => setAssetDelete(false)}
        centered
        backdrop="static"
        className="deleteAssetPopupModal"
      >
        <Modal.Header className="deleteAssetPopHeaderCont">
          <h4 className="deleteAssetPopHeader">Delete Freezer - 02?</h4>
          <div className="closeIconDiv" onClick={() => setAssetDelete(false)}>
            <img src={IconClose} alt="icon" />
          </div>
        </Modal.Header>
        <Modal.Body className="deleteAssetPopBody">
          <div className="deleteAssetBodyCont">
            <p className="deleteAssetContentTxt">
              This action will permanently delete <b>Freezer-02</b>. Do you to
              continue?
            </p>
            <div className="deleteAssetWarningCont">
              <div className="warningIconCont">
                <img src={IconWarning} alt="icon" />
                <p>
                  <b>Warning</b>
                </p>
              </div>
              <p>
                This action will also permanently delete the association between{" "}
                <b>Freezer-02</b> and <b>IMG1</b>
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="deleteAssetFooter">
          <button
            className="deleteAssetCancelBtn"
            onClick={() => setAssetDelete(false)}
          >
            Cancel
          </button>
          <button
            className="deleteAssetDeleteBtn"
            onClick={() => setAssetDelete(false)}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>

      {/* Edit Asset Popup */}
      {showEditAsset && (
        <AssetSettingsCreateEditModal
          callCreateFacilityFunction={callCreateFacilityFunction}
          data={data}
          type={"Edit"}
          closePopUp={() => setShowEditAsset(false)}
          statusId={statusId}
          statusDiscription={statusDiscription}
          callAssetListApi={callAssetListApi}
          setAssetDetailEmpty={setAssetDetailEmpty}
          showSuccessToast={showSuccessToast}
          showErrorToast={showErrorToast}
        />
      )}
    </>
  );
}

export default AssetSettingsCard;
