import { useEffect, useState } from "react";
import { MediaListType } from "../types/EventListTypes";
import Modal from "react-bootstrap/Modal";
import "./MediaCarousel.scss";
import ControlIcon from "../assets/carouselControlIcon.svg";
import { API_BASE_URL_DOWNLOAD } from "../../../Constants/constants";
import DownloadIcon from "../assets/downloadIcon (2).svg";
import CrossIcon from "../assets/white-cross-icon.svg";

type PropTypes = {
  data: MediaListType[];
  show: boolean;
  onHide: () => void;
  carouselIndex: number;
};

function MediaCarousel(propTypes: PropTypes) {
  const [activeIndex, setActiveIndex] = useState<number>(
    propTypes.carouselIndex
  );
  const [mediaObj, setMediaObj] = useState<MediaListType>({
    dataResourceId: "",
    mimeTypeId: "",
    url: "",
  });

  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % propTypes.data.length);
  };

  const prevSlide = () => {
    setActiveIndex(
      (prevIndex) =>
        (prevIndex - 1 + propTypes.data.length) % propTypes.data.length
    );
  };

  const downloadFile = () => {
    const mimeTypeList = mediaObj.mimeTypeId.split("/");
    const fileName = `${mediaObj.dataResourceId}.${mimeTypeList[1]}`;
    const url = `${API_BASE_URL_DOWNLOAD}${mediaObj.url}`;
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      });
  };

  useEffect(() => {
    setMediaObj(propTypes.data[activeIndex]);
  }, [activeIndex]);

  return (
    <Modal
      show={propTypes.show}
      onHide={propTypes.onHide}
      centered
      backdrop="static"
      className="mediaCarouselPopup"
    >
      <Modal.Header className="mediaCarouselPopUpHeader"></Modal.Header>
      <Modal.Body>
        <div className="mediaCarouselPopUpBody">
          <div className="bodyContentMainDiv">
            <div className="carouselCloseDiv" onClick={propTypes.onHide}>
              <img src={CrossIcon} />
            </div>
            <div className="bodyContentDiv">
              <img
                src={ControlIcon}
                className="controlIcon previousIcon"
                onClick={prevSlide}
              />
              {mediaObj.mimeTypeId.includes("image") ? (
                <img
                  src={`${API_BASE_URL_DOWNLOAD}${mediaObj.url}`}
                  style={{ width: "50vw", height: "45vh", borderRadius: 8 }}
                  loading="lazy"
                />
              ) : (
                <video
                  controls
                  style={{ width: "50vw", height: "45vh", borderRadius: 8 }}
                >
                  <source src={`${API_BASE_URL_DOWNLOAD}${mediaObj.url}`} />
                </video>
              )}

              <img
                src={ControlIcon}
                className="controlIcon"
                onClick={nextSlide}
              />
            </div>
            <div className="downloadMediaDiv" onClick={downloadFile}>
              <img src={DownloadIcon} />
              <p className="downloadIconText">Download</p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default MediaCarousel;
