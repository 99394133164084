import moment from 'moment';

/**
 * Calculate the duration between two date-time values and format it as mm:ss.
 * @param {number | Date} startDateTime - The start date-time value.
 * @param {number | Date} endDateTime - The end date-time value.
 * @returns {string} The formatted duration in mm:ss format.
 */
export const formatDuration = (startDateTime: number | Date, endDateTime: number | Date): string => {
  let start = moment(startDateTime);
  let end = moment(endDateTime);
  let differenceInSeconds = end.diff(start, 'seconds');
  let minutes = Math.floor(differenceInSeconds / 60);
  let seconds = differenceInSeconds % 60;
  return `${minutes}:${seconds.toString().padStart(2, '0')} mins`;
};
