export const initialState = {
  user: null,
  isLoggedIn: null,
  alerts: [],
  assets: [],
  alertSummary: {
    Leak: "0",
    alertCount: "0",
    anomaly: "0",
    bms: "0",
    cylstate: "0",
    load: "0",
    closedAlert: [],
    closedTicket: [],
    openAlert: [],
    openTicket: [],
  },
  alertFetchState: 0,
  soundState: true,
  wsMsg: "",
  dbPortlets: [],
  assetPortlets: [],
  alertPortlets: [],
  darkThemeEnabled: false,
  overlayEnabled: false,
  closedBlockAlert: {},
  pathName: "/",
  ActivityTabArray: {},
  drilledFilters: {},
  getAssets: [],
  getAlertChip: [],
  getTicketChip: [],
  getInsightTab: [],
  loginData: [],

  // Date related states
  dateRange: [],
  dateChip: "Today",

  downloadReportStatus: false,

  // Storing Filtered Related Data Into Redux

  filterRelatedData : {},

  //  end of iocl

  // Aone project dashboard elements
  toDateValue: "MTD",
  aoneLoginData: [],
  updated_Asset_List: [],
  assetTypeList: "TEMP_ENERGY,ENERGY,DG,TEMP",
  dateDisplay: true,
  assetsLengthCount: 0,
  totalAssetCount: 0,

  filterAssets: {},

  // Filters
  assetFilters: {
    facIds: [],
    healthStatusIds: [], //FA_ONLINE, FA_OFFLINE, FA_SLOW
    monitoredAssetTypeIds: [], //SI_FREEZER, SI_CHILLER
    complianceIds: [], //COMPLIANT, NON_COMPLIANT, OFFLINE
    dataLoaded: false,
    status: [
      {
        name: "All",
        id: "All",
        group: "status",
        isSelected: true,
        isVisible: true,
      },
      {
        name: "Online",
        id: "FA_ONLINE",
        group: "status",
        isSelected: false,
        isVisible: true,
      },
      {
        name: "Offline",
        id: "FA_OFFLINE",
        group: "status",
        isSelected: false,
        isVisible: true,
      },
      {
        name: "Slow",
        id: "FA_SLOW",
        group: "status",
        isSelected: false,
        isVisible: true,
      },
      {
        name: "Active",
        id: "FA_ACTIVE",
        group: "status",
        isSelected: false,
        isVisible: true,
      },
      {
        name: "Inactive",
        id: "FA_IN_ACTIVE",
        group: "status",
        isSelected: false,
        isVisible: true,
      },
      {
        name: "Lost",
        id: "FA_LOST",
        group: "status",
        isSelected: false,
        isVisible: true,
      },
      {
        name: "Maintenance",
        id: "FA_MAINT",
        group: "status",
        isSelected: false,
        isVisible: true,
      },

    ],
    monitoredAssetType: [],
    compliance: [
      {
        name: "All",
        id: "All",
        group: "status",
        isSelected: true,
        isVisible: true,
      },
      {
        name: "Compliance",
        id: "COMPLIANT",
        group: "compliance",
        isSelected: false,
        isVisible: true,
      },
      {
        name: "Non-Compliance",
        id: "NON-COMPLIANT",
        group: "compliance",
        isSelected: false,
        isVisible: true,
      },
      {
        name: "Offline",
        id: "OFFLINE",
        group: "compliance",
        isSelected: false,
        isVisible: true,
      },
    ],
    facility: [],
    region: [],
    city: [],
    cluster: [],
    state: [
      {
        name: "All",
        id: "All",
        group: "last",
        isSelected: true,
        isVisible: true,
      },
      {
        name: "compliance",
        id: "COMPLIANCE",
        group: "last",
        isSelected: false,
        isVisible: true,
      },
      {
        name: "Non compliance",
        id: "NON_COMPLIANCE",
        group: "last",
        isSelected: false,
        isVisible: true,
      },
      {
        name: "offline",
        id: "OFFLINE",
        group: "last",
        isSelected: false,
        isVisible: true,
      },
    ],
  },
  dashboardFilters: [],


  //Forgot Password Varibles
  forgotPassword: {
    user: '',
    userInfo: {},
    selectedOption: '',
    type: ''
  },

  /*

    { categ: "city", id: "SI_CHENNAI" },
    { categ: "city", id: "SI_BANGALORE" },
    { categ: "assetType", id: "SI_FREEZER" }, */
  searchText: "",
  triggerDownload: [],
  graphData: {
    data: [],
    triggerType: null,
  },
  currAssetId: "",
  dwnLoadData: {
    "Asset Summary": {},
  },
  settingsFilterPreferences: [],
  settingsFilterAllKeyValueList: [],
  settingsPaginationCount: "",
  showAssetCount: false,
  assetLists: [],
  assetFilterDatas: new Map(),
  filterTitlesData: {},
  scrollLeftDistance: "",
  consumptionFlowData: {
    "selectedParentId": "",
    "assetChildData": [],
    "selectedConsumptionName": "",
    "selectedEnergyAssetCategory": "",
    "distanceFromLeft": "",
    "discSize": "",
    "showGroupDatas": true,
    "selectedEnergyAsset": "",
    "discId": ""
  },
  reRenderConsumptionChild: 0,
  utilUserAssets: [],
  lastSelectedActivityCenterTab: String,


  //path location variable
  locationPath:'',
};

export const actionTypes = {
  SET_FILTERED_RELATED_DATA : "SET_FILTERED_RELATED_DATA",
  SET_USER: "SET_USER",
  SET_ALERTS: "SET_ALERTS",
  SET_ALERTS_SUMMARY: "SET_ALERTS_SUMMARY",
  SET_ASSETS: "SET_ASSETS",
  UPDATE_ALERTS: "UPDATE_ALERTS",
  UPDATE_ASSETS: "UPDATE_ASSETS",
  SET_LOGGED_IN: "SET_LOGGED_IN",
  SET_ALERT_FETCH_STATE: "SET_ALERT_FETCH_STATE",
  SET_SOUND_STATE: "SET_SOUND_STATE",
  SET_WS_MSG: "SET_WS_MSG",
  DB_PORTLETS: "DB_PORTLETS",
  ALERT_PORTLETS: "ALERT_PORTLETS",
  ASSET_PORTLETS: "ASSET_PORTLETS",
  UPDATE_ALERT_TICKET: "UPDATE_ALERT_TICKET",
  TOGGLE_DARK_THEME: "TOGGLE_DARK_THEME",
  TOGGLE_OVERLAY: "TOGGLE_OVERLAY",
  CLOSED_BLOCK_ALERTS: "CLOSED_BLOCK_ALERTS",
  PATH_NAME: "PATH_NAME",
  ACTIVITY_TAB_ARRAY: "ACTIVITY_TAB_ARRAY",
  FILTER_ASSETS: "FILTER_ASSETS",
  DRILLED_FILTER: "DRILLED_FILTER",
  GET_ASSETS: "GET_ASSETS",
  GET_ALERT_CHIP: "GET_ALERT_CHIP",
  GET_TICKET_CHIP: "GET_TICKET_CHIP",
  GET_INSIGHT_TAB: "GET_INSIGHT_TAB",
  LOGIN_DATA: "LOGIN_DATA",
  //  END OF IOCL
  //Update location Path
  LOCATION_PATH_UPDATE: "LOCATION_PATH_UPDATE",

  // AONE ACTION TYPE ELEMENTS
  AONE_LOGIN_DATA: "AONE_LOGIN_DATA",
  DATE_RANGE: "DATE_RANGE",
  TO_DATE_VALUE: "TO_DATE_VALUE",
  DATE_DISPLAY: "DATE_DISPLAY",
  GET_ASSETS_LENGTH: "GET_ASSETS_LENGTH",
  SET_DATE_CHIP: "SET_DATE_CHIP",

  UPDATED_ASSET_LIST: "UPDATED_ASSET_LIST",

  // Filters
  UPDATE_ASSET_FILTERS: "UPDATE_ASSET_FILTERS",
  SET_DASHBOARD_FILTERS: "SET_DASHBOARD_FILTERS",

  // Search Text
  SET_SEARCH_TEXT: "SET_SEARCH_TEXT",

  //Report Status
  SET_REPORT_STATUS: "SET_REPORT_STATUS",

  SET_TRIGGER_DOWNLOAD: "SET_TRIGGER_DOWNLOAD",

  SET_GRAPH_DATA: "SET_GRAPH_DATA",
  SET_CURRENT_ASSET_ID: "SET_CURRENT_ASSET_ID",
  SET_TOTAL_ASSET_COUNT: "SET_TOTAL_ASSET_COUNT",

  SET_DWN_DATA: "SET_DWN_DATA",
  SET_SETTINGS_FILTER_PREFERENCES: "SET_SETTINGS_FILTER_PREFERENCES",
  SET_SETTINGS_FILTER_SELECTED_LIST_FOR_KEY: "SET_SETTINGS_FILTER_SELECTED_LIST_FOR_KEY",
  SET_SETTINGS_FILTER_PAGINATION_DETAILS: "SET_SETTINGS_FILTER_PAGINATION_DETAILS",
  SHOW_ASSET_COUNT: "SHOW_ASSET_COUNT",

  //Forgot Password
  UPDATE_FORGOTPASSWORD_VARIABLES:"UPDATE_FORGOTPASSWORD_VARIABLES",
  SET_ASSET_LIST: "SET_ASSET_LIST",
  UPDATED_ASSET_LISTS: "UPDATED_ASSET_LISTS",
  ASSET_FILTER_DATA: "ASSET_FILTER_DATA",
  FILTER_TITLES: "FILTER_TITLES",
  SCROLL_LEFT_DISTANCE: "SCROLL_LEFT_DISTANCE",
  CONSUMPTION_FLOW: "CONSUMPTION_FLOW",
  RERENDER_CONSUMPTION_CHILD: "RERENDER_CONSUMPTION_CHILD",
  UTIL_USER_ASSETS: "UTIL_USER_ASSETS",
  SELECTED_ACTIVITY_TAB: "SELECTED_ACTIVITY_TAB"
};

const reducer = (state, action) => {
  switch (action.type) {

    case actionTypes.CONSUMPTION_FLOW:
      return {
        ...state,
        consumptionFlowData: action.consumptionFlowData
      }

    case actionTypes.SCROLL_LEFT_DISTANCE:
      return {
        ...state,
        scrollLeftDistance: action.scrollLeftDistance,
      }

    case actionTypes.SET_FILTERED_RELATED_DATA:
      return {
        ...state,
        filterRelatedData: action.filterRelatedData,
      };

    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.user,
      };

    case actionTypes.SET_ALERTS:
      return {
        ...state,
        alerts: action.alerts,
      };

    case actionTypes.SET_ALERT_FETCH_STATE:
      return {
        ...state,
        alertFetchState: action.alertFetchState,
      };

    case actionTypes.SET_ALERTS_SUMMARY:
      return {
        ...state,
        alertsSummary: action.summary,
      };

    case actionTypes.SET_ASSETS:
      return {
        ...state,
        assets: action.assets,
      };

    case actionTypes.SET_SOUND_STATE:
      return {
        ...state,
        soundState: action.soundState,
      };

    case actionTypes.SET_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
      };

    case actionTypes.SET_WS_MSG:
      return {
        ...state,
        wsMsg: action.wsMsg,
      };

    case actionTypes.DB_PORTLETS:
      return {
        ...state,
        dbPortlets: action.dbPortlets,
      };

    case actionTypes.ALERT_PORTLETS:
      return {
        ...state,
        alertPortlets: action.alertPortlets,
      };

    case actionTypes.ASSET_PORTLETS:
      return {
        ...state,
        assetPortlets: action.assetPortlets,
      };
    case actionTypes.UPDATE_ALERT_TICKET:
      return {
        ...state,
        alerts: updateAlert(state.alerts, action.alertPayload),
      };
    case actionTypes.TOGGLE_DARK_THEME:
      return {
        ...state,
        darkThemeEnabled: !state.darkThemeEnabled,
      };
    case actionTypes.TOGGLE_OVERLAY:
      return {
        ...state,
        overlayEnabled: !state.overlayEnabled,
      };
    case actionTypes.CLOSED_BLOCK_ALERTS:
      return {
        ...state,
        closedBlockAlert: {
          ...state.closedBlockAlert,
          ...action.closedBlockAlert,
        },
      };
    case actionTypes.PATH_NAME:
      return {
        ...state,
        pathName: action.pathName,
      };

    case actionTypes.ACTIVITY_TAB_ARRAY:
      return {
        ...state,
        ActivityTabArray: action.ActivityTabArray,
      };

    case actionTypes.FILTER_ASSETS:
      return {
        ...state,
        filterAssets: action.filterAssets,
      };

    case actionTypes.DRILLED_FILTER:
      return {
        ...state,
        drilledFilters: action.drilledFilters,
      };

    case actionTypes.GET_ASSETS:
      return {
        ...state,
        getAssets: action.getAssets,
      };

    case actionTypes.GET_ALERT_CHIP:
      return {
        ...state,
        getAlertChip: action.getAlertChip,
      };

    case actionTypes.GET_TICKET_CHIP:
      return {
        ...state,
        getTicketChip: action.getTicketChip,
      };
    case actionTypes.GET_INSIGHT_TAB:
      return {
        ...state,
        getInsightTab: action.getInsightTab,
      };

    case actionTypes.LOGIN_DATA:
      return {
        ...state,
        loginData: action.loginData,
      };

    //END OF IOCL

    //START OF AONE COMPONENTS
    case actionTypes.AONE_LOGIN_DATA:
      return {
        ...state,
        aoneLoginData: action.aoneLoginData,
      };

    case actionTypes.DATE_RANGE:
      return {
        ...state,
        dateRange: action.dateRange,
      };

    case actionTypes.TO_DATE_VALUE:
      return {
        ...state,
        toDateValue: action.toDateValue,
      };

    case actionTypes.SET_DATE_CHIP:
      return {
        ...state,
        dateChip: action.dateChip,
      };

    case actionTypes.DATE_DISPLAY:
      return {
        ...state,
        dateDisplay: action.dateDisplay,
      };

    case actionTypes.GET_ASSETS_LENGTH:
      return {
        ...state,
        assetsLengthCount: action.assetsLengthCount,
      };

    case actionTypes.SET_TOTAL_ASSET_COUNT:
      return {
        ...state,
        totalAssetCount: action.totalAssetCount,
      };

    case actionTypes.UPDATED_ASSET_LIST:
      return {
        ...state,
        updated_Asset_List: action.updated_Asset_List,
      };

    // Filters
    case actionTypes.UPDATE_ASSET_FILTERS:
      return {
        ...state,
        assetFilters: action.assetFilters,
      };

    case actionTypes.SET_DASHBOARD_FILTERS:
      return {
        ...state,
        dashboardFilters: action.dashboardFilters,
      };

    // Search
    case actionTypes.SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.searchText,
      };

    //REPORT STATUS
    case actionTypes.SET_REPORT_STATUS:
      return{
        ...state,
        downloadReportStatus: action.downloadReportStatus,
      }

    // GraphData
    case actionTypes.SET_TRIGGER_DOWNLOAD:
      return {
        ...state,
        triggerDownload: action.triggerDownload,
      };

    case actionTypes.SET_GRAPH_DATA:
      return {
        ...state,
        graphData: action.graphData,
      };
    // Current asset ID
    case actionTypes.SET_CURRENT_ASSET_ID:
      return {
        ...state,
        currAssetId: action.currAssetId,
      };

    case actionTypes.SET_DWN_DATA:
      return {
        ...state,
        dwnLoadData: updateDwnData(state.dwnLoadData, action.dwnLoadData),
      };

    case actionTypes.SET_SETTINGS_FILTER_PREFERENCES:
      return {
        ...state,
        settingsFilterPreferences: action.payload,
      };
    case actionTypes.SET_SETTINGS_FILTER_SELECTED_LIST_FOR_KEY:
      return {
        ...state,
        settingsFilterAllKeyValueList: action.selectedFilterList,
      };
    case actionTypes.SET_SETTINGS_FILTER_PAGINATION_DETAILS:
      return {
        ...state,
        settingsPaginationCount: action.settingsPaginationCount,
      };
    case actionTypes.SHOW_ASSET_COUNT:
      return {
        ...state,
        showAssetCount: action.showAssetCount
      }
    //Forgot Password
    case actionTypes.UPDATE_FORGOTPASSWORD_VARIABLES:
      return {
        ...state,
        forgotPassword: action.forgotPassword
      }


    case actionTypes.SET_ASSET_LIST:
      return {
        ...state,
        assetLists: action.assetLists,
      }

    case actionTypes.UPDATED_ASSET_LISTS:
      return {
        ...state,
        assetLists: [...state.assetLists, ...action.assetLists]
      }

    case actionTypes.ASSET_FILTER_DATA:
      return {
        ...state,
        assetFilterDatas: action.assetFilterDatas
      }

    case actionTypes.FILTER_TITLES:
      return {
        ...state,
        filterTitlesData: action.filterTitlesData
      }

    case actionTypes.RERENDER_CONSUMPTION_CHILD:
      return {
        ...state,
        reRenderConsumptionChild: action.reRenderConsumptionChild
      }

      case actionTypes.UTIL_USER_ASSETS:
      return {
        ...state,
        utilUserAssets: action.utilUserAssets
      }
    //window location update

    case actionTypes.LOCATION_PATH_UPDATE:
      return {
        ...state,
        locationPath:action.locationPath
      }

    case actionTypes.SELECTED_ACTIVITY_TAB:
      return {
        ...state,
        lastSelectedActivityCenterTab: action.lastSelectedActivityCenterTab
      }

    default:
      return state;
  }
};

const updateclosedBlockAlertSummary = (state, action) => {
  return {
    ...state,
    closedBlockAlert: [...state.closedBlockAlert, action.cl],
  };
};

const updateDwnData = (dwnData: any, newData: any) => {
  // console.log("updateDwnData::dwnData - ", Object.assign({}, dwnData));

  const key = Object.keys(newData)[0];
  dwnData[key] = newData[key];
  // console.log("updateDwnData::newData - ", Object.assign({}, dwnData));

  return dwnData;
};

const updateAlert = (alerts, action) => {
  const newAlert = alerts.find(
    (singleAlert) => singleAlert.alertId === action.noteId
  );
  newAlert.ticketNumber = action.ticketNumber;
  newAlert.ticketStatus = action.ticketStatus;
  const index = alerts.findIndex(
    (singleAlert) => singleAlert.alertId === action.noteId
  );
  alerts[index] = newAlert;
  // console.log("reducer function call", alerts[index]);
  return alerts;
};

export default reducer;
