/* eslint-disable eqeqeq */
import React, { useState } from "react";

import ExportIcon from "../../assets/icons/ExportIcon.svg";
import IconFilter from "../../assets/icons/IconFilter.svg";
import AddIcon from "../../assets/icons/AddIcon.svg";
import CountryIcon from "../../assets/icons/CountryIcon.svg";
import deleteIcon from "../../assets/icons/addFacDeleteIcon.png";
import UploadImgIcon from "../../assets/icons/UploadImgIcon.svg";
import IconAdd from "../../assets/icons/IconAdd.svg";
import IconClose from "../../assets/icons/IconClose.svg";

import Modal from "react-bootstrap/Modal";

import "./Users.scss";
import UsersCardContainer from "./usersCardContainer/UsersCardContainer";

function Users() {
  const [activeTab, onclickActivePopup] = useState("basic");
  const [showAddUser, setAddUserPopup] = useState(false);

  const [showEmailPurpose, setShowEmailPurpose] = useState(false);
  const emailPurposeData = [
    "Shipping Origin Phone Number",
    "Main Fax Number",
    "Secondary Fax Number",
    "Direct Inward Dialing Phone Number",
    "Main Home Phone Number",
    "Main Mobile Phone Number",
    "Main Work Phone Number",
    "Secondary Work Phone Number",
    "Shipping Destination Phone Number",
  ];
  const [showPhonePurpose, setShowPhonePurpose] = useState(false);
  const phonePurposeData = [
    "Shipping Origin Phone Number",
    "Main Fax Number",
    "Secondary Fax Number",
    "Direct Inward Dialing Phone Number",
    "Main Home Phone Number",
    "Main Mobile Phone Number",
    "Main Work Phone Number",
    "Secondary Work Phone Number",
    "Shipping Destination Phone Number",
  ];

  const [showUserAddAddress, setshowUserAddAddress] = useState(false);
  const addressPurpose = [
    "Shipping Destination Address",
    "Shipping Origin Address",
    "Billing (AP) Address",
    "Payment (AR) Address",
    "General Correspondence Address",
    "Purchase Return Address",
    "Primary Address",
    "Previous Address",
  ];

  const facilitiesData = [
    "",
    "Facility-1",
    "Facility-2",
    "Facility-3",
    "Facility-4",
  ];
  const facilityRoleData = [
    "",
    "Employee",
    "Manager",
    "City Manager",
    "Warehouse Manager",
  ];

  const facilityGroupsData = ["", "Chennai", "Banglore", "Delhi", "Kolkata"];
  const facilityGroupsRoleData = [
    "",
    "Facility Manager",
    "Cluster Manager",
    "City Manager",
    "Warehouse Manager",
  ];

  return (
    <>
      <div className="usersFilterBtnCont">
        <div className="usersBtnsConteiner">
          {/*EXPORT*/}
          {/*<button className="userExportBtn">*/}
          {/*  <img src={ExportIcon} alt="icon" />*/}
          {/*  Export*/}
          {/*</button>*/}
          {/*FILTER PERMISSION*/}
          {/*<button className="usersFilterBtn">*/}
          {/*  <img src={IconFilter} alt="icon" />*/}
          {/*  Filter*/}
          {/*</button>*/}
          <button className="addUserBtn" onClick={() => setAddUserPopup(true)}>
            <img src={AddIcon} alt="icon" />
            Add user
          </button>
        </div>
      </div>
      <div className="usersCardsCont">
        <UsersCardContainer />
      </div>
      {/* Add User Popup */}
      <Modal
        show={showAddUser}
        onHide={() => setAddUserPopup(false)}
        centered
        backdrop="static"
        className="createUserPopupModal"
      >
        <Modal.Header className="createUserPopHeader">
          <h4 className="createUserHeader">Create new user</h4>
          <div className="closeIconDiv" onClick={() => setAddUserPopup(false)}>
            <img src={IconClose} alt="icon" />
          </div>
        </Modal.Header>
        <Modal.Body className="createUserPopBody">
          <div className="createUserBody">
            <div className="createUserHeaderNav">
              <p
                className={
                  activeTab == "basic"
                    ? "activecreateUserNavTxt"
                    : "createUserNavTxt"
                }
                onClick={() => onclickActivePopup("basic")}
              >
                Basic Information
              </p>
              <p
                className={
                  activeTab == "facilities"
                    ? "activecreateUserNavTxt"
                    : "createUserNavTxt"
                }
                onClick={() => onclickActivePopup("facilities")}
              >
                Facilities
              </p>
              <p
                className={
                  activeTab == "groups"
                    ? "activecreateUserNavTxt"
                    : "createUserNavTxt"
                }
                onClick={() => onclickActivePopup("groups")}
              >
                Facility Groups
              </p>
            </div>
            {activeTab == "basic" ? (
              <div className="userBasicInfoCont">
                <div className="basicInfoCont">
                  <div className="basicInfoInputsCont">
                    <div className="textInputsCont">
                      <label>First Name *</label>
                      <input type="text" />
                    </div>

                    <div className="textInputsCont">
                      <label>Last Name *</label>
                      <input type="text" />
                    </div>

                    <div className="selectorInputCont">
                      <label>Role *</label>
                      <select placeholder="Select Role">
                        <option>Select Role</option>
                      </select>
                    </div>

                    <div className="textInputsCont">
                      <label>Address *</label>
                      <input type="text" placeholder="Add address" readOnly />
                      <img
                        className="createUserIcon"
                        src={IconAdd}
                        alt="icon"
                        onClick={() => setshowUserAddAddress(true)}
                      />
                    </div>

                    <div className="phoneNumberInputCont">
                      <label> Phone Number *</label>
                      <div className="selectAndInputCont">
                        <div className="imgAndSelectCont">
                          <img src={CountryIcon} alt="icon" />
                          <select>
                            <option>+91</option>
                          </select>
                        </div>

                        <input type="text" />
                      </div>

                      <p
                        className="setPurpose"
                        onClick={() => setShowPhonePurpose(true)}
                      >
                        Set purpose
                      </p>
                      {showPhonePurpose && (
                        <div className="phoneNumberSetPurpose">
                          <div className="setPurposeHeader">
                            <div className="purposeCloseCont">
                              <img
                                src={IconClose}
                                alt="icon"
                                onClick={() => setShowPhonePurpose(false)}
                              />
                            </div>
                          </div>
                          <div className="setPurposeDataCont">
                            {phonePurposeData.map((el) => (
                              <div className="setPurposeData">
                                <input type="checkbox" />
                                <label>{el}</label>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="textInputsCont">
                      <label>Email *</label>
                      <input type="validateEmail" />
                      <p
                        className="setPurpose"
                        onClick={() => setShowEmailPurpose(true)}
                      >
                        Set purpose
                      </p>
                      {showEmailPurpose && (
                        <div className="emailSetPurpose">
                          <div className="setPurposeHeader">
                            <div className="purposeCloseCont">
                              <img
                                src={IconClose}
                                alt="icon"
                                onClick={() => setShowEmailPurpose(false)}
                              />
                            </div>
                          </div>
                          <div className="setPurposeDataCont">
                            {emailPurposeData.map((el) => (
                              <div className="setPurposeData">
                                <input type="checkbox" />
                                <label>{el}</label>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="credentialsInputsCont">
                    <p className="credentialsHeder">Credentials</p>
                    <div className="credentialsHederBorder"></div>
                    <div className="userNameInputCont">
                      <div className="textInputsCont">
                        <label>Username *</label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="passwordsInputsCont">
                      <div className="textInputsCont">
                        <label>Password *</label>
                        <input type="text" />
                      </div>

                      <div className="textInputsCont">
                        <label>Confirm Password *</label>
                        <input type="text" />
                      </div>
                    </div>
                  </div>

                  <div className="profileImgFormatCont">
                    <div className="profileImgCont">
                      <img src={UploadImgIcon} alt="icon" />
                    </div>
                    <ul className="profileImgDetails">
                      <li className="profileImgHeader">Profile Image</li>
                      <li>Max file size : 2MB. File format : png, jpeg</li>
                      <li>Recommended resolution 300x300</li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : activeTab == "facilities" ? (
              <div className="facilitiesContainer">
                <div className="facilitiesAddBtnCont">
                  <button className="facilitiesAddBtn">
                    <img src={AddIcon} alt="icon" />
                    Add
                  </button>
                </div>
                <div className="facilityAndRoleContainer">
                  <div className="facilityAndRoleHeaders">
                    <p>Facility</p>
                    <p>Role</p>
                  </div>
                  <div className="facilityAndRoleSelectorsCont">
                    <select className="facilityAndRoleSelector">
                      {facilitiesData.map((el) => (
                        <option>{el}</option>
                      ))}
                    </select>
                    <select className="facilityAndRoleSelector">
                      {facilityRoleData.map((el) => (
                        <option>{el}</option>
                      ))}
                    </select>
                    <img src={deleteIcon} alt="icon" />
                  </div>
                </div>
              </div>
            ) : activeTab == "groups" ? (
              <div className="facilityGroupsContainer">
                <div className="facilityGroupsAddBtnCont">
                  <button className="facilityGroupsAddBtn">
                    <img src={AddIcon} alt="icon" />
                    Add
                  </button>
                </div>
                <div className="facilityGroupsAndRoleContainer">
                  <div className="facilityGroupsAndRoleHeaders">
                    <p>Facility Group</p>
                    <p>Role</p>
                  </div>
                  <div className="facilityGroupsAndRoleSelectorsCont">
                    <select className="facilityGroupsAndRoleSelector">
                      {facilityGroupsData.map((el) => (
                        <option>{el}</option>
                      ))}
                    </select>
                    <select className="facilityGroupsAndRoleSelector">
                      {facilityGroupsRoleData.map((el) => (
                        <option>{el}</option>
                      ))}
                    </select>
                    <img src={deleteIcon} alt="icon" />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="createUserPopupFooter">
          <button
            className="userCancelBtn"
            onClick={() => setAddUserPopup(false)}
          >
            Cancel
          </button>
          {activeTab !== "groups" ? (
            <button
              className="userNextBtn"
              onClick={() =>
                onclickActivePopup(
                  activeTab == "basic"
                    ? "facilities"
                    : activeTab === "facilities"
                    ? "groups"
                    : ""
                )
              }
            >
              Next
            </button>
          ) : (
            <button className="userCreateBtn">Create User</button>
          )}
        </Modal.Footer>
      </Modal>

      {/* Add Address Popup */}
      <Modal
        show={showUserAddAddress}
        backdrop="static"
        keyboard={false}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="userAddAddressPopup"
      >
        <Modal.Header className="userAddAddressHeaderCont">
          <h4 className="userAddAddressTxt">Add Address</h4>
          <div
            className="closeIconDiv"
            onClick={() => setshowUserAddAddress(false)}
          >
            <img src={IconClose} alt="icon" />
          </div>
        </Modal.Header>
        <Modal.Body className="userAddAddressBody">
          <div className="userAddAddressBodyCont">
            <div className="userAddAddressInputsCont">
              <div className="addressTextInputsCont">
                <label> To Name *</label>
                <input type="text" />
              </div>

              <div className="addressTextInputsCont">
                <label> Attn Name *</label>
                <input type="text" />
              </div>

              <div className="addressTextInputsCont">
                <label>Address Line 1 *</label>
                <input type="text" />
              </div>

              <div className="addressTextInputsCont">
                <label>Address Line 2 *</label>
                <input type="text" />
              </div>

              <div className="addressTextInputsCont">
                <label>City *</label>
                <input type="text" />
              </div>

              <div className="addressSelectorCont">
                <label>State/Province *</label>
                <select>
                  <option>TN</option>
                </select>
              </div>

              <div className="addressTextInputsCont">
                <label>Postal Code *</label>
                <input type="text" />
              </div>

              <div className="addressSelectorCont">
                <label>Country</label>
                <select>
                  <option>India</option>
                </select>
              </div>
            </div>

            <div className="userAddAddressPurposeCont">
              {addressPurpose.map((el) => (
                <div className="addressPurposeCont">
                  <input type="checkbox" className="purposeCheckbox" />
                  <label>{el}</label>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="userAddAddressFooter">
          <button
            className="cancelBtn"
            onClick={() => setshowUserAddAddress(false)}
          >
            Cancel
          </button>
          <button
            className="addAddressBtn"
            onClick={() => setshowUserAddAddress(false)}
          >
            Add Address
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Users;
