import React, { useState } from "react";
import loader from "../../a1Components/lotties/round-loader-white.json";
import LoginSubLayout from "./LoginSubLayout";
import "../LoginPage/LoginPageStyles.scss";
import PasswordOtpSelectionCard from "./PasswordOtpSelectionCard";
import OtpScreen from "./OtpScreen";
import UserInputForChangePassword from "./UserInputForChangePassword";
import NewPasswordScreen from "./NewPasswordScreen";



function ForgotPassword() {
  const [componentId, setComponentId] = useState(0);

  const submitHandler = async (e:any) => {
    e.preventDefault();
  };

  const components = [
    <UserInputForChangePassword onSumbitData={() => setComponentId(1)} />,
    <PasswordOtpSelectionCard onSumbitData={() => setComponentId(2)} />,
    <OtpScreen onSumbitData={() => setComponentId(3)} />,
    <NewPasswordScreen />
    ];


  return (
      <div className="container">
        <form onSubmit={submitHandler}>
          <div className="row">
            <div className="subclassOne col-xs-12 col-lg-4">
              <div className="selectClassname">
                <select id="selectId">
                  <option value="English">English - US</option>
                </select>
              </div>
              {components[componentId]}
              <p className="atsuyaClass">© {new Date().getFullYear()} Atsuya Technologies Pvt. Ltd.</p>
            </div>
            <LoginSubLayout />
          </div>
        </form>
      </div>
  );
}

export default ForgotPassword;