import React from "react";
// import the core library.
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { TitleComponent, TooltipComponent } from "echarts/components";
import { PieChart } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TitleComponent,
  TooltipComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
]);

function DonutChart({ donutData, total, totalText }: any) {
  const data = [
    { name: "Online", value: 468 },
    { name: "Maintenance", value: 0 },
    { name: "Offline", value: 80 },
    { name: "Inactive", value: 0 },
    { name: "Total", value: 569 },
    { name: "Slow", value: 21 },
    { name: "Lost", value: 0 },
  ];
  console.log("the donutData", donutData);
  echarts.use([TooltipComponent, PieChart, CanvasRenderer, LabelLayout]);

  const colorMap = {
    ONLINE: "#4CAF50",
    MAINTENANCE: "#FF8B00",
    OFFLINE: "#F44336",
    INACTIVE: "#A0A0A0",
    SLOW: "#E9BE03",
    LOST: "#445B94",
    ACTIVE: "#37B5C6",
  };

  const assignColorsToData = (data) => {
    console.log("the donutData_", data);
    const filteredData = data.filter((item) => item.name !== "TOTAL");
    return filteredData.map((item) => ({
      ...item,
      color: colorMap[item.name] || "#000", // Default to black if name not found in colorMap
    }));
  };

  let keys = Object.keys(donutData);
  let values = Object.values(donutData);
  let list = keys.map((ele, index) => {
    return {
      name: ele.toUpperCase(),
      value: values[index],
    };
  });
  const coloredData = assignColorsToData(list);

  let legendData = coloredData.map((item) => ({
    name: item.name + " " + item.value, // Concatenate the name and value
    icon: "circle",
  }));
  console.log("coloredDatacoloredData", coloredData, legendData);
  var option = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical", // Show the legend vertically on the right
      right: 0, // Adjust the right margin as needed
      top: "center", // Center the legend vertically
      itemHeight: 10, // Set the height of legend items
      icon: "circle",
      textStyle: {
        fontSize: 14, // Adjust the legend item text size
      },
      data: coloredData.map((item) => item.name), // Use the data names for legend labels
      selectedMode: true,
      formatter: function (name) {
        // Custom legend formatter to include both name and value
        const item = coloredData.find((dataItem) => dataItem.name === name);
        return `${name} (${item.value})`;
      },
    },
    series: [
      {
        name: "",
        type: "pie",
        radius: ["45%", "55%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: false,
            fontSize: 40,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: coloredData.map((item) => ({
          name: item.name,
          value: item.value,
          itemStyle: {
            color: item.color,
          },
        })),
      },
    ],
  };
  const totalValue = total;
  option.graphic = [
    {
      type: "text",
      left: "center",
      top: "center",
      style: {
        text: `${"Total"} \n ${totalValue} `,
        fontSize: 16,
        fontWeight: "bold",
      },
    },
  ];

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={option}
      notMerge={true}
      lazyUpdate={true}
      theme={"theme_name"}
    />
  );
}

export default DonutChart;