import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import IconAdd from "../../../assets/icons/IconAdd.svg";
import IconClose from "../../../assets/icons/IconClose.svg";
import "./FacilitypopUpScreen.scss";
import FacilityAddressPopUpScreen from "./FacilityAddressPopUpScreen";
import EmailInputComponent from "../../emailInputComponent/EmailInputComponent";
import PhoneNumberComponent from "../../phoneNumberComponent/PhoneNumberComponent";
import { SettingsApiServices } from "../../../services/settingsPageServices/SettingsApiServices";
import * as R from "ramda";
import CustomSwitch from "../../Users/usersCardContainer/customSwitch/CustomSwitch";
import Select from 'react-select'

const FacilitypopUpScreen = ({ closePopUpScreen, type, propsData }: any) => {
  const [activeTab, onclickActivePopup] = useState("basic");
  const [nextButtonText, setNextButtonText] = useState("Next");
  const [facilityNameInput, setFacilityNameInput] = useState("");
  const [loader, setLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [responseText, setResponseText] = useState("");
  const [response, setResponse] = useState("Please Wait ...");
  //Adress pop-up screen state
  const [addressPopUpscreen, setAddressPopUpscreen] = useState(false);
  //address obj
  let addFacility =
    type === "add"
      ? {
          facilityName: "",
          facilityTypeId: "",
          facilityBrandName: "",
          ownerPartyId: localStorage.getItem("@partyID"),
          countryGeoId: "",
          emailAddress: "",
          emailPurpose: "",
          phonePurpose: "",
          phoneNumberPrefix: "+91",
          phoneNumber: "",
          externalId: "",
          addressPurpose: "",
          toName: "",
          attnName: "",
          address1: "",
          address2: "",
          city: "",
          stateGeoCodeId: "",
          postalCode: "",
          facilitySizeUomId: "",
          facilitySize: "",
          latitude: "",
          longitude: "",
        }
      : propsData;

  const [addressObject, setAddressObject] = useState(
    Object.assign({}, addFacility)
  );

  const onChangeAddress = (e: any, key: any) => {
    let tempObj = { ...addressObject };
    tempObj[key] = e.target.value;
    tempObj.facilityName = facilityNameInput;
    tempObj.facilityBrandName = facilityNameInput;
    setAddressObject(tempObj);
    //console.log("dataToken", tempObj);
  };


  useEffect(()=>{
    if (activeTab === "users"){
      setNextButtonText("Create Facility")
    } else {
      setNextButtonText("Next")
    }

  },[activeTab])

  const onAddressPopUpClose = () => {
    setAddressPopUpscreen(false);
  };

  const createPostData = async () => {
    //onclickActivePopup("attrib");
    setLoader(true);
    let tokenID = localStorage.getItem("@tokenID");
    const fetchData = new SettingsApiServices();
    const createFacility = await fetchData.CreateFacility(
      tokenID,
      addressObject
    );
    if (createFacility?.reponseCode === 200) {
      setResponseText("");
      setResponse(
        `facilityId : ${createFacility?.reponse?.facilityId} Created`
      );
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setLoader(false);
        closePopUpScreen();
      }, 2500);
    } else {
      //alert("Failed to Create Facility");
      setResponseText("Failed to Create Facility");
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 2000);
      setLoader(false);
    }
  };

  const [facilityStatistics, setFacilityStatistics] = useState({
    EmailPuprposeList: [],
    PhonePurposeList: [],
    facilityTypesList: [],
    AreaMeasures: [],
    CountrysList: [],
    ContactPurposeList: [],
  });

  async function FacilityStatistics() {
    let tokenId = localStorage.getItem("@tokenID");
    const fetchData = new SettingsApiServices();
    const data = await fetchData.FacilityStatistics(tokenId);
    if (!R.isEmpty(data)) {
      setFacilityStatistics({
        ...facilityStatistics,
        facilityTypesList: data?.facilityTypes,
        EmailPuprposeList: data?.contactPurposes?.EMAIL_ADDRESS,
        PhonePurposeList: data?.contactPurposes?.TELECOM_NUMBER,
        AreaMeasures: data?.unitsOfMeasures?.AREA_MEASURE,
        CountrysList: data?.countries,
        ContactPurposeList: data?.contactPurposes?.WEB_ADDRESS,
      });
    } else {
      //console.log("@data", data);
    }
  }

  useEffect(() => {
    FacilityStatistics();
  }, []);

  const AddressDataUpdata = (e: any) => {
    let tempObj = { ...addressObject, ...e };
    setAddressObject(tempObj);
    //console.log("@address", tempObj);
  };

  const updatePhoneNumberPurposeListUpdate = (keyId: any, dataList: any) => {
    let tempObj = { ...addressObject };
    let out = "[";
    let n = dataList.length;
    for (let i = 0; i < n; i++) {
      let item = dataList[i];
      out += item;
      if (i < n - 1) {
        out += ", ";
      }
    }
    out += "]";
    tempObj[keyId] = out;
    setAddressObject(tempObj);
  };

  const onChangeNumberInput = (e: any, key: any) => {
    let tempObj = { ...addressObject };
    tempObj[key] = Number(e.target.value);
    setAddressObject(tempObj);
  };

  const [callBack, setCallBack] = useState(true);

  const facilityGroupData = [
    { value: 'Chennai', label: 'Chennai' },
    { value: 'Chennai Zone 2', label: 'Chennai Zone 2' },
    { value: 'Bangalore', label: 'Bangalore' },
    { value: 'Pune', label: 'Pune' },
    { value: 'Hyderabad', label: 'Hyderabad' },
    { value: 'Kerala', label: 'Kerala' },
   ]

  return (
    <>
      {
        <Modal
          backdrop="static"
          keyboard={false}
          show={true}
          onHide={closePopUpScreen}
          centered
          className="createAndEditFacilityPopup"
        >
          <Modal.Header className="createAndEditFacilityHeaderCont">
            <h1 className="createAndEditFacilityHeader">
              {type === "add" ? "Create new " : "Edit "}facility
            </h1>

            <div
              className="toastTextContainer"
              style={{ opacity: visible ? 1 : 0 }}
            >
              <h6 className="toastText">{responseText}</h6>
            </div>

            <img
              src={IconClose}
              alt="icon"
              onClick={closePopUpScreen}
              style={{ cursor: "pointer" }}
            />
          </Modal.Header>
          <Modal.Body>
            {loader ? (
              <div className="spinnerContainer">
                <div>
                  {response && <h5 className="respone">{response}</h5>}
                  {response === "Please Wait ..." ? (
                    <div className="spinner"></div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="createFacilityBody">
                <div className="createFacilityNavbar">
                  <p
                    className={
                      activeTab == "basic"
                        ? "activeCreateFacilityNavTxt"
                        : "createFacilityNavTxt"
                    }
                    onClick={() => onclickActivePopup("basic")}
                  >
                    Basic Information
                  </p>
                  <p
                    className={
                      activeTab == "attrib"
                        ? "activeCreateFacilityNavTxt"
                        : "createFacilityNavTxt"
                    }
                    onClick={() => onclickActivePopup("attrib")}
                  >
                    Attributes
                  </p>
                  <p
                    className={
                      activeTab == "users"
                        ? "activeCreateFacilityNavTxt"
                        : "createFacilityNavTxt"
                    }
                    onClick={() => onclickActivePopup("users")}
                  >
                    Users
                  </p>
                </div>
                {activeTab == "basic" ? (
                  <div className="createFacilityBasicInfoCont">
                    <div className="createFacilityInputs">
                      <div className="facilityInputs">
                        <div className="createFacilityInputsleft">
                          <div className="inputsCont">
                            <p>Name *</p>
                            <input
                              value={addressObject.facilityName}
                              type="text"
                              onChange={(e) => {
                                setFacilityNameInput(e.target.value);
                              }}
                              className="facilityInputs"
                            />
                          </div>

                          <EmailInputComponent
                            onChangeAddress={onChangeAddress}
                            emailAddress={addressObject.emailAddress}
                            popUpScreenData={
                              facilityStatistics.EmailPuprposeList
                            }
                            updataDataListPurposes={
                              updatePhoneNumberPurposeListUpdate
                            }
                          />

                          <PhoneNumberComponent
                            onChangeAddress={onChangeAddress}
                            phoneNumber={addressObject.phoneNumber}
                            popUpScreenData={
                              facilityStatistics.PhonePurposeList
                            }
                            updataDataListPurposes={
                              updatePhoneNumberPurposeListUpdate
                            }
                          />

                          <div className="inputsCont">
                            <p>External ID</p>
                            <input
                              value={addressObject?.externalId}
                              type="text"
                              onChange={(e) => onChangeAddress(e, "externalId")}
                              className="facilityInputs"
                            />
                          </div>
                        </div>

                        <div className="createFacilityInputsRight">
                          <div className="inputsCont">
                            <p>Facility Type *</p>
                            <select
                              value={addressObject?.facilityTypeId}
                              onChange={(e) =>
                                onChangeAddress(e, "facilityTypeId")
                              }
                              className="facilityTypeSelector"
                            >
                              {facilityStatistics.facilityTypesList.map(
                                (obj, i) => {
                                  return (
                                    <option
                                      key={i}
                                      // onClick={() => alert()}
                                      value={obj?.facilityTypeId}
                                    >
                                      {obj?.description}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>

                          <div className="inputsCont">
                            <p>Address *</p>
                            <input
                              value={
                                addressObject?.city +
                                " " +
                                addressObject?.postalCode
                              }
                              type="text"
                              readOnly
                              placeholder="Add address"
                              className="facilityInputs"
                            />
                            <img
                              onClick={() => setAddressPopUpscreen(true)}
                              src={IconAdd}
                              alt="icon"
                              className="iconAdd"
                            />
                          </div>

                          <div className="inputsCont">
                            <p>Size of facility</p>
                            <div className="sizeInputCont">
                              <input
                                value={addressObject?.facilitySize}
                                onChange={(e) =>
                                  onChangeNumberInput(e, "facilitySize")
                                }
                                className="sizeInput"
                                type="number"
                              />
                              <div className="sizeInputSelectCont">
                                <select
                                  onChange={(e) =>
                                    onChangeAddress(e, "facilitySizeUomId")
                                  }
                                  className="sizeSelector"
                                  value={addressObject?.facilitySizeUomId}
                                >
                                  {facilityStatistics.AreaMeasures.map(
                                    (obj, i) => {
                                      return (
                                        <option key={i} value={obj?.uomId}>
                                          {obj?.abbreviation}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="inputsCont">
                            <p>Facility Group</p>
                            {/* <select
                              //value={addressObject?.facilityGroup}
                              className="facilityGroupSelector"
                              // onChange={(e) =>
                              //   onChangeAddress(e, "facilityGroup")
                              // }
                            >
                              <option value={"Chennai"}>Chennai</option>
                              <option value={"Chennai Zone 2"}>
                                Chennai Zone 2
                              </option>
                              <option value={"Bangalore"}>Bangalore</option>
                              <option value={"Pune"}>Pune</option>
                              <option value={"Hyderabad"}>Hyderabad</option>
                              <option value={"Kerala"}>Kerala</option>
                            </select> */}
                            <Select options={facilityGroupData}    className="facilityGroupSelector" />
                          </div>
                        </div>
                      </div>
                      {/* <div className="mapContainer"></div> */}
                      <div className="dircetionInputsCont">
                        <div className="latitudeInputCont">
                          <div className="inputsCont">
                            <p>Latitude</p>
                            <input
                              value={addressObject?.latitude}
                              onChange={(e) =>
                                onChangeNumberInput(e, "latitude")
                              }
                              type="number"
                            />
                          </div>
                        </div>
                        <div className="longitudeInputCont">
                          <div className="inputsCont">
                            <p>Longitude</p>
                            <input
                              value={addressObject?.longitude}
                              type="number"
                              onChange={(e) =>
                                onChangeNumberInput(e, "longitude")
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="createFacilityPopFooter">
              <div className="editSwitchCont">
                <CustomSwitch callBack={setCallBack} check={callBack} />
                <p>{callBack ? "Disabled" : "Enabled"}</p>
              </div>
              <div className="createFacilityFooterBtns">
                <button className="cancelBtn" onClick={closePopUpScreen}>
                  Cancel
                </button>
                <button className="nextBtn" onClick={createPostData}>
                  {nextButtonText}
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      }

      {addressPopUpscreen ? (
        <FacilityAddressPopUpScreen
          updataData={AddressDataUpdata}
          propsData={addressObject}
          type={type}
          closeFacilityAddressPopUpScreen={onAddressPopUpClose}
          countryListData={facilityStatistics.CountrysList}
          contactPurposeListData={facilityStatistics.ContactPurposeList}
        />
      ) : null}
    </>
  );
};

export default FacilitypopUpScreen;
