import React, { useEffect, useState } from "react";
import * as R from "ramda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import filterSolid from "../../assets/image/filter-solid.svg";
import xMark from "../../assets/image/xmark-solid.svg";
import { useStateValue } from "../../redux/StateProvider";
import ThemeButton from "../FormElements/button";
import Switch from "../FormElements/switch";
import "./style.scss";
import SearchInput from "../SearchInput";

const tabData = [
  {
    id: 1,
    value: "Asset Status",
  },
  {
    id: 2,
    value: "Asset Type",
  },
  {
    id: 3,
    value: "Asset Score",
  },
  {
    id: 4,
    value: "Alerts",
  },
];

const score = [
  "Assets score above 80%",
  "Assets score between 60 - 80%",
  "Assets score below 60%",
];

const alertsItem = ["Open Alert", "Repeated Alerts / Tickets"];

function FilterPopup(props:any) {
  const [{ filterAssets, assetsLengthCount, totalAssetCount, showAssetCount }, dispatch] =
    useStateValue();

  const [open, setOpen] = useState(false);
  const [onlineState, setOnline] = useState(false);
  const [offlineState, setOffline] = useState(false);
  const [slowState, setSlow] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [statusAcc, setStatusAcc] = useState(true);
  const [typeAcc, setTypeAcc] = useState(true);
  const [scoreAcc, setScoreAcc] = useState(true);
  const [alertsAcc, setAlertsAcc] = useState(true);
  const [selectedTags, setSelectedTags] = useState<any>([]);
  const [scoreRange, setScoreRange] = useState<any>([]);
  const [alertState, setAlerts] = useState<any>([]);
  const [tabArr, setTabArr] = useState(tabData);
  const [width, setWidth] = useState(window.innerWidth);
  const [tags, setTags] = useState<any>(
    JSON.parse(localStorage.getItem("@assetTypes")) || []
  );

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    async function tagsHandle() {
      let assetTypes:any = localStorage.getItem("@assetTypes");
      let tagsH:any = await JSON.parse(assetTypes);
      if (!R.isEmpty(tagsH) && !R.isNil(tagsH)) {
        setTags(tagsH);
      }
    }
    tagsHandle();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    let tabArr:any = [];
    tabData.map((el) => {
      if (!props.alerts || props.alerts === undefined) {
        if (el.id !== 4) {
          tabArr.push(el);
        }
      } else {
        tabArr.push(el);
      }
    });
    setTabArr(tabArr);
  }, []);

  const filterCta = () => {
    setOpen(!open);
    dispatch({ type: "TOGGLE_OVERLAY" });
    if (!R.isEmpty(filterAssets)) {
      setOnline(filterAssets?.online);
      setOffline(filterAssets?.offline);
      setSlow(filterAssets?.slow);
      setSelectedTags(filterAssets?.types);
      setScoreRange(filterAssets?.score);
      setAlerts(filterAssets?.alerts);
    } else {
      setOnline(false);
      setOffline(false);
      setSlow(false);
      setSelectedTags([]);
      setScoreRange([]);
      setAlerts([]);
    }
  };
  const tabCta = (id:any) => {
    setActiveTab(id);
  };
  const onlineCta = () => {
    setOnline(!onlineState);
  };
  const offlineCta = () => {
    setOffline(!offlineState);
  };
  const slowCta = () => {
    setSlow(!slowState);
  };
  const tagCta = (t:any) => {
    if (!selectedTags.includes(t)) {
      setSelectedTags([...selectedTags, t]);
    } else {
      let updateArr = selectedTags.filter((el:any) => el !== t);
      setSelectedTags(updateArr);
    }
  };
  const statusCta = () => {
    setStatusAcc(!statusAcc);
  };
  const typeCta = () => {
    setTypeAcc(!typeAcc);
  };
  const scoreCta = (e:any) => {
    setScoreAcc(!scoreAcc);
  };
  const scoreChange = (e:any) => {
    let scoreArr = [...scoreRange, e.target.value];
    setScoreRange(scoreArr);
  };

  const alertsChange = (e:any) => {
    let alertArr = [...alertState, e];
    setAlerts(alertArr);
  };

  const alertCta = () => {
    setAlertsAcc(!alertsAcc);
  };
  const clearCta = () => {
    setOnline(false);
    setOffline(false);
    setSlow(false);
    setSelectedTags([]);
    setScoreRange([]);
    setAlerts([]);
    dispatch({ type: "FILTER_ASSETS", filterAssets: {} });
  };
  const applyCta = () => {
    const filterObj = {
      online: onlineState,
      offline: offlineState,
      slow: slowState,
      types: selectedTags,
      score: scoreRange,
      alerts: alertState,
    };
    const { online, offline, slow, types, score, alerts } = filterObj;
    if (
      online &&
      offline &&
      slow &&
      R.isEmpty(types) &&
      R.isEmpty(score) &&
      R.isEmpty(alerts)
    ) {
      dispatch({ type: "FILTER_ASSETS", filterAssets: {} });
    } else {
      dispatch({ type: "FILTER_ASSETS", filterAssets: filterObj });
    }
    setOpen(!open);
    dispatch({ type: "TOGGLE_OVERLAY" });
  };

  const [assetType, setAssettype] = useState<any>();

  const filterAssetTypeData = (e:any) => {
    setAssettype(e);
  };

  return (
    <div className="filter">
      <div className={`filter-cta ${width < 768 ? "mobile" : ""}`}>
      {showAssetCount && (
        <div className="assetCountScss">
          You are viewing {assetsLengthCount} asset(s) / {totalAssetCount}{" "}
          asset(s)
        </div>
        )}
      </div>
      {open && (
        <div className="filter-modal">
          {width > 767 ? (
            <div>
              <div className="filter-header">
                <h2>Filter by</h2>
                <div onClick={filterCta}>
                  <img src={xMark} />
                </div>
              </div>
              <div className="filter-body">
                <aside>
                  <ul>
                    {tabArr.map((el:any, ind:any) => (
                      <li
                        key={ind}
                        className={`${activeTab === el.id ? "active" : ""}`}
                        onClick={() => tabCta(el.id)}
                      >
                        {el.value}
                      </li>
                    ))}
                  </ul>
                </aside>
                <main>
                  {activeTab === 1 && (
                    <div className="filter-container">
                      <Switch
                        label="Online"
                        id="online"
                        checked={onlineState}
                        onChange={onlineCta}
                        isAlignCenter={true}
                        onToggle={undefined}
                      />
                      <Switch
                        label="Offline"
                        id="offline"
                        checked={offlineState}
                        onChange={offlineCta}
                        isAlignCenter={true}
                        onToggle={undefined}
                      />
                      <Switch
                        label="Slow"
                        id="slow"
                        checked={slowState}
                        onChange={slowCta}
                        isAlignCenter={true}
                        onToggle={undefined}
                      />
                    </div>
                  )}
                  {activeTab === 2 && (
                    <>
                      <SearchInput
                        data={tags}
                        filterAssetTypeData={filterAssetTypeData}
                      />
                      <div className="filter-container">
                        {assetType &&
                          assetType.map((el:any, i:any) => (
                            <div
                              className={`tag ${
                                selectedTags.includes(el) ? "active" : ""
                              }`}
                              key={i}
                              onClick={() => tagCta(el)}
                            >
                              {el}
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                  {activeTab === 3 && (
                    <>
                      <SearchInput
                        data={score}
                        filterAssetTypeData={filterAssetTypeData}
                      />
                      <div className="filter-container">
                        {assetType &&
                          assetType.map((el, i) => (
                            <div className="form-check" key={i}>
                              <input
                                className="form-check-input"
                                onChange={scoreChange}
                                type="checkbox"
                                value={el}
                                id={el}
                              />
                              <label className="form-check-label" for={el}>
                                {el}
                              </label>
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                  {props.alerts && activeTab === 4 && (
                    <>
                      <SearchInput
                        data={alertsItem}
                        filterAssetTypeData={filterAssetTypeData}
                      />
                      <div className="filter-container">
                        {assetType &&
                          assetType.map((el, i) => (
                            <div className="form-check" key={i}>
                              <input
                                className="form-check-input"
                                onChange={() => alertsChange(el)}
                                type="checkbox"
                                value=""
                                id={el}
                              />
                              <label className="form-check-label" for={el}>
                                {el}
                              </label>
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                  <div className="buttonContainer">
                    <ThemeButton
                      label="CLEAR ALL"
                      onClick={clearCta}
                      btnClear={false}
                    />
                    <ThemeButton label="APPLY" onClick={applyCta} />
                  </div>
                </main>
              </div>
            </div>
          ) : (
            <div>
              <div className="filter-header">
                <FontAwesomeIcon icon={faAngleLeft} onClick={filterCta} />
                <h2>Filter by</h2>
                <a href="#" onClick={clearCta}>
                  RESET
                </a>
              </div>
              <div className="filter-body">
                <div className="accordion">
                  <div
                    className={`${statusAcc ? "open" : ""} accordion-header`}
                    onClick={statusCta}
                  >
                    <h4>Asset Status</h4>
                    <FontAwesomeIcon
                      icon={statusAcc ? faAngleUp : faAngleDown}
                    />
                  </div>
                  <div className="accordion-body">
                    <Switch
                      label="Online"
                      id="online"
                      checked={onlineState}
                        onChange={onlineCta}
                        isAlignCenter={true}
                        onToggle={undefined}
                    />
                    <Switch
                      label="Offline"
                      id="offline"
                      checked={offlineState}
                        onChange={offlineCta}
                        isAlignCenter={true}
                        onToggle={undefined}
                    />
                  </div>
                </div>
                <div className="accordion">
                  <div
                    className={`${typeAcc ? "open" : ""} accordion-header`}
                    onClick={typeCta}
                  >
                    <h4>Asset Type</h4>
                    <FontAwesomeIcon icon={typeAcc ? faAngleUp : faAngleDown} />
                  </div>
                  <div className="accordion-body">
                    <div className="tagWrapper">
                      {tags.map((el, i) => (
                        <div
                          className={`tag ${
                            selectedTags.includes(el) ? "active" : ""
                          }`}
                          key={i}
                          onClick={() => tagCta(el)}
                        >
                          {el}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="accordion">
                  <div
                    className={`${scoreAcc ? "open" : ""} accordion-header`}
                    onClick={scoreCta}
                  >
                    <h4>Asset Status</h4>
                    <FontAwesomeIcon
                      icon={scoreAcc ? faAngleUp : faAngleDown}
                    />
                  </div>
                  <div className="accordion-body">
                    {score.map((el, i) => (
                      <div className="form-check" key={i}>
                        <input
                          className="form-check-input"
                          onChange={scoreChange}
                          type="checkbox"
                          value={el}
                          id={el}
                        />
                        <label className="form-check-label" for={el}>
                          {el}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                {props.alerts && (
                  <div className="accordion">
                    <div
                      className={`${alertsAcc ? "open" : ""} accordion-header`}
                      onClick={alertCta}
                    >
                      <h4>Alerts</h4>
                      <FontAwesomeIcon
                        icon={alertsAcc ? faAngleUp : faAngleDown}
                      />
                    </div>
                    <div className="accordion-body">
                      {alertsItem.map((el, i) => (
                        <div className="form-check" key={i}>
                          <input
                            className="form-check-input"
                            onChange={() => alertsChange(el)}
                            type="checkbox"
                            value={el}
                            id={el}
                          />
                          <label className="form-check-label" for={el}>
                            {el}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="theme-button">
                      <ThemeButton label="APPLY" onClick={applyCta} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default FilterPopup;
