/* eslint-disable no-const-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import React, {useEffect, useState} from "react";
import "./AssetSettings.scss";
import Modal from "react-bootstrap/Modal";
import ExportIcon from "../../assets/icons/ExportIcon.svg";
import IconFilter from "../../assets/icons/IconFilter.svg";
import AddIcon from "../../assets/icons/AddIcon.svg";
import ProductAttributeIcon from "../../assets/icons/ProductAttributeIcon.svg";
import IconClose from "../../assets/icons/IconClose.svg";

import AssetSettingsCardContainer from "./assetSettingsCardContainer/AssetSettingsCardContainer";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FilterModal from "../Filter/FilterModal";
import {useStateValue} from "../../redux/StateProvider";
import LocalStorageService from "../../Utils/LocalStorageService";

function AssetSettings(props: any) {
  const [clearFilters, setClearFilters] = useState(false);
  const [{}, dispatch] = useStateValue();
  const {callCreateFacilityFunction, searchString, initSearch, searchKey, incrementInitSearch} = props;
  const [showProductAttributes, setShowProductAttributes] = useState(false);
  const [selectedAssetCategory, setSelectedAssetCategory] = useState<string[]>([]);

  const [assetCreate, setAssetCreate] = useState(false);

  const securityPermissions = localStorage.getItem("securityPermissions") || "";
  const securityPermissionsArray = JSON.parse(securityPermissions)
  // const securityPermissionsArray = securityPermissionsArr.filter(permission => permission !== "FIXEDASSET_CREATE");

  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const data = new Map<string, { key: string; value: string }[]>();

  const setAssetTypeFilterData = async () => {
    let responseString = localStorage.getItem("facility_statistics_cache");
    const response = JSON.parse(responseString)
    // For asset type list
    const assets = response?.data?.assetTypes;
    const result = [];
    assets?.forEach((element: any) => {
      result.push({
        key: element.fixedAssetTypeId,
        value: element.description ? element.description : element.fixedAssetTypeId,
        name: element.description
          ? element.description
          : element.fixedAssetTypeId,
        id: element.fixedAssetTypeId,
        group: "status",
        parentFixedAssetTypeId: element.parentFixedAssetTypeId
      });
    });
    data.set('Asset Type', result);
  };
  const setStatusFilterData = async () => {
    let responseString = localStorage.getItem("facility_statistics_cache");
    const response = JSON.parse(responseString)
    const assetStatus = response?.data?.statusTypes?.FXD_ASSET_STATUS;
    const result = [];
    assetStatus?.forEach((element: any) => {
      result.push({
        key: element.statusCode,
        value: element.description,
      });
    });
    data.set('Status', result);
  }
  const setFacilityFilterData = async () => {
    let localData: any = localStorage.getItem("facilityIds")
    const facilityList = JSON.parse(localData);
    const result = [];
    facilityList.forEach((element: any) => {
      result.push({
        key: element.facilityId,
        value: element.facilityName,
      });
    });
    data.set('Facility', result)
  }

  function setFilterDataFromLocalStorage(localStorageKey: string, labelKey: string) {
    let localData: any = localStorage.getItem(localStorageKey)
    const list = JSON.parse(localData);
    const result = [];
    list.forEach((element: any) => {
      result.push({
        key: element.facilityGroupId,
        value: element.facilityGroupName,
      });
    });
    data.set(labelKey, result)
  }

  function setCategoryFilterData() {

    const assetTypeList: { fixedAssetTypeId: string; description: string }[] = LocalStorageService.getUserSpecificAssetTypeData();

    const result = Array.from(new Set(assetTypeList?.map(type => type.parentFixedAssetTypeId)))
        .map(parentFixedAssetTypeId => {
          const firstMatch = assetTypeList.find(type => type.parentFixedAssetTypeId === parentFixedAssetTypeId);
          return {
            key : parentFixedAssetTypeId,
            value: firstMatch?.parentDescription,
            id : parentFixedAssetTypeId,
            group:  parentFixedAssetTypeId,
          };
        });
        data.set("Asset Category", result)
  }

  setCategoryFilterData()
  setAssetTypeFilterData()
  setStatusFilterData()
  setFacilityFilterData()
  setFilterDataFromLocalStorage("regionIds", "Region")
  setFilterDataFromLocalStorage("clusterIds", "Cluster")
  setFilterDataFromLocalStorage("cityIds", "City")

  const openFilterModal = () => {
    setFilterModalOpen(true);
  };

  const closeFilterModal = () => {
    setFilterModalOpen(false);
  };

  const handleAssetCreate = () => {
    setAssetCreate(false);
  };

  useEffect(()=>{
    dispatch({
      type: 'SET_SETTINGS_FILTER_PREFERENCES',
      payload: [],
    });
    dispatch({
      type: 'SET_SETTINGS_FILTER_SELECTED_LIST_FOR_KEY',
      payload: [],
    });
    incrementInitSearch()
  },[])

  return (
    <>
      {/* <ToastContainer
          position="top-left"
          theme="light"/> */}

      <div className="assetSettingsFilterBtnCont">
        <div className="assetSettingsBtnsConteiner">
          {/*EXPORT*/}
          {/*<button className="assetSettingsExportBtn">*/}
          {/*  <img src={ExportIcon} alt="icon" />*/}
          {/*  Export*/}
          {/*</button>*/}
          {/*FILTER PERMISSION*/}
         
          {securityPermissionsArray.includes("FIXEDASSET_VIEW") && <button className="assetSettingsFilterBtn" onClick={openFilterModal}>
            <img src={IconFilter} alt="icon" />
            Filter
          </button>}
          {securityPermissionsArray.includes("FIXEDASSET_CREATE") && <button className="addAssetBtn" onClick={() => setAssetCreate(true)}>
            <img src={AddIcon} alt="icon" />
            Add asset
          </button>}
        {/*  <button
            className="productAddributesBtn"
            onClick={() => setShowProductAttributes(true)}
          >
            <img src={ProductAttributeIcon} alt="icon" />
            Set product attributes
          </button>*/}
        </div>
      </div>
      <div className="assetSettingsCardsCont">
        <AssetSettingsCardContainer
          setClearFilters={setClearFilters}
          callCreateFacilityFunction={callCreateFacilityFunction}
          assetCreate={assetCreate}
          handleAssetCreate={handleAssetCreate}
          searchString={searchString}
          initSearch={initSearch}
          searchKey={searchKey}
        />
      </div>
      {/* Product Attributes Popup */}
      <Modal
        show={showProductAttributes}
        onHide={() => setShowProductAttributes(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className="productAttributePopupModal"
      >
        <Modal.Header className="productAttributePopupHeader">
          <div className="productAttributeHeaderCont">
            <div className="productAttributeHeader">
              <h4 className="productAttributeHeaderTxt">Product attributes</h4>
              <div
                className="closeIconDiv"
                onClick={() => setShowProductAttributes(false)}
              >
                <img src={IconClose} alt="icon" />
              </div>
            </div>
            <select className="productAttributeHeaderSelect">
              <option></option>
            </select>
          </div>
        </Modal.Header>
        <Modal.Body className="productAttributePopupBody">
          <div className="productAttributeBody">
            <div className="inputTextCont">
              <label>Min Temperature</label>
              <input type="text" />
            </div>

            <div className="inputAndSelectCont">
              <label>Max Temperature</label>
              <div className="inputSelect">
                <input type="text" />
                <select>
                  <option>°C</option>
                </select>
              </div>
            </div>

            <div className="selectInputCont">
              <label>Inventory Category</label>
              <select></select>
            </div>

            <div className="inputTextCont">
              <label>Tube Volume</label>
              <input type="text" />
            </div>

            <div className="inputTextCont">
              <label>Tons of Refrigeration</label>
              <input type="text" />
            </div>

            <div className="selectInputCont">
              <label>Phase</label>
              <select></select>
            </div>

            <div className="inputAndSelectCont">
              <label>Rated Power</label>
              <div className="inputSelect">
                <input type="text" />
                <select>
                  <option>kW</option>
                </select>
              </div>
            </div>

            <div className="inputAndSelectCont">
              <label>Rated Current</label>
              <div className="inputSelect">
                <input type="text" />
                <select>
                  <option>A</option>
                </select>
              </div>
            </div>

            <div className="inputAndSelectCont">
              <label>Rated Voltage</label>
              <div className="inputSelect">
                <input type="text" />
                <select>
                  <option>V</option>
                </select>
              </div>
            </div>

            <div className="inputAndSelectCont">
              <label>Rated Frequency</label>
              <div className="inputSelect">
                <input type="text" />
                <select>
                  <option>Hz</option>
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="productAttributePopupFooter">
          <button
            className="productAttributeCancelBtn"
            onClick={() => setShowProductAttributes(false)}
          >
            Cancel
          </button>
          <button
            className="productAttributeUpdateBtn"
            onClick={() => setShowProductAttributes(false)}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>

      <FilterModal isOpen={filterModalOpen} onClose={closeFilterModal} data={data}
        setClearFilters={setClearFilters}
        clearFilters={clearFilters}
        selectedAssetCategoryMain={selectedAssetCategory}
        setSelectedAssetCategoryMain={setSelectedAssetCategory}
      />

    </>
  );
}

export default AssetSettings;
