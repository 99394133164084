import React, { useEffect, useState } from "react";
import IconClose from "../../assets/icons/IconClose.svg";
import "./EmailInputComponent.scss";
import { HelperFunctions } from "../../Utils/HelperFunctions";
import { FieldValidatorUtil } from "../../Utils/fieldValidation/FieldValidatorUtil";
import FormValidator from "../../Utils/FormValidations/FormValidations";
import ReactTooltip from "react-tooltip";

const EmailInputComponent = ({
  emailAddress,
  popUpScreenData,
  updataDataListPurposes,
  onChangeAddress,
  emailError,
  invalidArr,
  setInvalidArr,
}: any) => {
  const [showEmailPuprpose, setShowEmailPurpose] = useState(false);
  const emailSetPurposeData = [...popUpScreenData];
  const [emailPurposeList, setEmailPurposeList] = useState([]);
  const [purposeText, setPurposeText] = useState("Set purpose");

  const dataCheck = (e: any) => {
    let tempList = [...emailPurposeList];
    if (tempList.includes(e)) {
      let index = tempList.indexOf(e); // get the index of the element to remove
      tempList.splice(index, 1); // remove the element at the specified index
    } else {
      tempList.push(e);
    }
    updataDataListPurposes("emailPurpose", tempList);
    setEmailPurposeList(tempList);
    setPurposeText(HelperFunctions.getPurposeString(tempList));
  };

  const checkName = (name: string, val: string) => {
    const validate = new FieldValidatorUtil();
    const validArr = [
      "validateOrgName",
      "validateRelationShip",
      "validateEmail",
      "validateCity",
      "validatePhoneNumber",
      "validateEmail",
    ];

    if (!validArr.includes(name)) {
      return;
    }

    let isValid = false;

    if (name === "validateOrgName") {
      isValid = validate.validateOrgDetail(val);
    } else if (name === "validateEmail") {
      isValid = validate.validateOrgEmail(val);
    } else {
      isValid = validate.isValidAssetData(val);
    }

    setInvalidArr((prevInvalidArr) => {
      if (isValid) {
        return prevInvalidArr.filter((obj) => obj !== name);
      } else {
        if (!invalidArr.includes(name)) {
          return [...prevInvalidArr, name];
        } else {
          return [...prevInvalidArr];
        }
      }
    });
  };

  return (
    <>
      <div className="inputsCont">
        <h6 className="createOrgHeader">Email *</h6>
        <input
          type="email"
          value={emailAddress}
          onChange={(e) => onChangeAddress(e, "emailAddress")}
          className="facilityInputs"
          style={{
            border: `${
              invalidArr.includes("validateEmail") ? "1px solid red" : ""
            }`,
          }}
          onBlur={() => checkName("validateEmail", emailAddress)}
          data-tip="Email can only contain letters (A-Z, a-z), numbers (0-9) and following special characters: ., _, -, + and @"
          data-for="name-tooltip"
        />

        <ReactTooltip
          id="name-tooltip"
          effect="solid"
          place="bottom"
          className="tooltip"
        />

        {invalidArr.includes("validateEmail") && (
          <p className="invalid-feedback inputFieldError">
            {new FormValidator().validateValue(
              emailAddress,
              /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              255
            )}
          </p>
        )}
        {/* <p
          className="setPurposeTxt"
          onClick={() => {
            setShowEmailPurpose(true);
          }}
        >
          {purposeText}
        </p> */}
      </div>

      {/*Email Set Purpose Div  */}
      {/* {showEmailPuprpose && (
        <div className="emailSetPurposeCont">
          <div className="setPurposeCloseCont">
            <img
              src={IconClose}
              alt="icon"
              onClick={() => {
                setShowEmailPurpose(false);
              }}
            />
          </div>
          <div className="setPurposeDetailsCont">
            {emailSetPurposeData?.length !== 0
              ? emailSetPurposeData?.map((obj, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => dataCheck(obj?.contactMechPurposeTypeId)}
                      className="setPurposeDetails"
                    >
                      <input
                        checked={emailPurposeList.includes(
                          obj?.contactMechPurposeTypeId
                        )}
                        className="setPurposeCheckbox"
                        type="checkbox"
                      />
                      <p className="checkboxTxt">{obj?.description}</p>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      )} */}
    </>
  );
};

export default EmailInputComponent;
