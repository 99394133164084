import React, { useState, useEffect, useRef } from "react";
import "../../App_one.scss";
import styled from "styled-components";
import "./index.scss";
import * as R from "ramda";
import ActivitySubAlert from "../activitySubAlerts";
import right from "../../assets/icons/Iconfeatherchevronright.svg";
import { DashBoardServices } from "../../services/DasboardServices/DashboardService";
import { useStateValue } from "../../redux/StateProvider";
import moment from "moment";
import Lottie from "react-lottie";
import loading from "../../assets/Lotties/activitycentreloading.json";

const Tab = styled.button`
  font-size: 20px;
  padding: 10px 60px;
  cursor: pointer;
  // opacity: 0.6;
  background: #eff2ff;
  border: 0;
  outline: 0;
  border-bottom: 2px solid transparent;
  ${({ active }: any) =>
    active &&
    `
    border-bottom: 3px solid #426BFA;
    border-radius:50px;
    opacity: 1;
    background:white !important;
  `}
`;
const ButtonGroup = styled.div`
  display: flex;
  textalign: left;
`;

function AssetDetailsIndex({
  data,
  lodeStateVal,
  callBackalertPopUp,
  loadState,
  pageIndex,
  acticityImg,
}: any) {


  const defaulterrorLottie = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [itemsToShow, setItemsToShow] = useState(2);
  const [tabDataSet, setTabDataSet] = useState<any>([]);
  const [sendAlertTyle, setSendAlertTyle] = useState();
  const [{ dateRange }] = useStateValue();



  const [active, setActive] = useState("All");
  const dataLength = useRef(0);
  const maxDataLength = useRef(0);

  useEffect(() => {
    let alertsStatus = localStorage.getItem("alertStatus");
    if (alertsStatus === null || alertsStatus === undefined) {
      handleActive("All");
    } else {
      handleActive("Alerts");
    }
    setTempActivityData(data);
    setCurrentPage(1);
    setTabDataSet(data);
    if (!R.isEmpty(data)) {
      data.map((ele: any, id: any) => {
        if (ele.name === active) {
          setActivityAlertData(ele?.value);
        }
      });
      let listLength = data.find((ele: any, id: any) => ele.name === "All");
      maxDataLength.current = listLength.count;
      dataLength.current = listLength.value.data.length;
    }

    //setActivityAlertData(ele?.value);
  }, [data]);

  const [pageRender, setpageRender] = useState("show");
  const [activityAlertData, setActivityAlertData] = useState<any>({ data: [] });

  function handleActive(type: any) {
    setActive(type);
    if (!R.isEmpty(data)) {
      let list = tempActivityData.find(
        (ele: any, id: any) => ele.name === type
      );
      setActivityAlertData(list?.value);
      //let list_2 =
    }
  }
  //setActivityAlertData(ele?.value);

  const [displayHover, setDisplayHover] = useState(false);

  const [backgroundOnSelect, setBackgroundOnSelect] = useState(null);
  const selectedAlertTyle = (ele: any, id: any) => {
    setBackgroundOnSelect(ele);
    setDisplayHover(true);
    setSendAlertTyle(ele);
  };

  const [currentPage, setCurrentPage] = useState(pageIndex);

  let [tempActivityData, setTempActivityData] = useState<any>([...data]);

  const getDataSet = async (val: any) => {
    setIsLoading(true);

    const fromDate = moment(dateRange[0]).startOf("day").toISOString();
    const endDate = moment(dateRange[1]).endOf("day").toISOString();
    const deviceId = localStorage.getItem("assetId");
    const DBservice = new DashBoardServices();
    const fetchData = await DBservice.getActivityCenter(
      moment(fromDate).valueOf(),
      val,
      moment(endDate).valueOf(),
      deviceId
    );
    console.log("fetch-Data", fetchData);
    if (fetchData === "error") {
    } else if (typeof fetchData === "number") {
    } else if (R.isEmpty(fetchData)) {
    } else {
      dataLength.current = [
        ...tempActivityData[0].value.data,
        ...fetchData?.alerts.data,
        ...fetchData?.watchList.data,
        ...fetchData?.tickets.data,
      ].length;
      const newJsonSet = [
        {
          name: "All",
          id: 1,
          count:
            fetchData?.alerts.count +
            fetchData?.watchList.count +
            fetchData?.tickets.count,
          value: {
            data: [
              ...tempActivityData[0].value.data,
              ...fetchData?.alerts.data,
              ...fetchData?.watchList.data,
              ...fetchData?.tickets.data,
            ],
          },
        },
        {
          name: "WatchList",
          id: 2,
          count: fetchData?.watchList.count,
          value: {
            data: [
              ...tempActivityData[1].value.data,
              ...fetchData?.watchList.data,
            ],
          },
        },
        {
          name: "Alerts",
          id: 3,
          count: fetchData?.alerts.count,
          value: {
            data: [
              ...tempActivityData[2].value.data,
              ...fetchData?.alerts.data,
            ],
          },
        },
        {
          name: "Tickets",
          id: 4,
          count: fetchData?.tickets.count,
          value: {
            data: [
              ...tempActivityData[3].value.data,
              ...fetchData?.tickets.data,
            ],
          },
        },
      ];
      setTempActivityData(newJsonSet);
      setTabDataSet(newJsonSet);
      setIsLoading(false);
    }
  };

  const handleNextClick = () => {
    if (maxDataLength.current > dataLength.current) {
      getDataSet(currentPage + 1);
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    handleActive(active);
  }, [tempActivityData]);

  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = containerRef.current;
      const scrollOffset = 800; // Adjust this value as needed

      // Check if the user has reached the bottom of the scroll
      if (
        scrollContainer &&
        scrollContainer.scrollHeight - scrollContainer.scrollTop <=
          scrollContainer.clientHeight + scrollOffset
      ) {
        // To avoid multiple API calls, you can add a loading state flag (isLoading) and check if it's already loading
        if (!isLoading) {
          handleNextClick();
        }
      }
    };
    // Attach the scroll event listener to the specific div
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      // Clean up the event listener on component unmount
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isLoading]);

  return (
    <div className="tab-Class">
      <div className="tabClass-button">
        <ButtonGroup className="tabClass">
          {tabDataSet.map((ele: any, index: any) => (
            <Tab
              key={index}
              active={active === ele.name}
              onClick={() => handleActive(ele.name)}
              className={`${active === ele.name ? "TabBtn active" : "TabBtn"}`}
            >
              <div className="activitySector">
                <div>
                  <img src={ele?.img} />
                  {/* update image it's not getting from tabDataSet */}
                </div>
                <div>
                  {
                    ele.name !== "All" && (
                      <h5>{ele.count}</h5>
                    )
                  }
                  <h6>{ele.name}</h6>
                </div>
              </div>
            </Tab>
          ))}
        </ButtonGroup>
      </div>

      <div
        className="tabBody"
        ref={containerRef}
        style={{overflowY: "auto" , height:"100vh"}}
      >
        
        <h3 className="alertsStyle" style={{"marginRight": "30px"}}>you are viewing {activityAlertData?.data?.length} {active !== "All" ?  activityAlertData?.data[0]?.metadata.toLowerCase() : ""}</h3>
        {activityAlertData.data.map((ele: any, id: any) => {
          return (
            <ActivitySubAlert
              value={ele}
              callBackalertPopUp={callBackalertPopUp}
              name={ele.facilityName}
              type={ele.metadata}
              createdTime={ele.createdDate}
              lastUpdated={ele.createdDate}
              assetName={ele.fixedAssetName}
              alertDes={ele.alertDesc}
              alertTypeDesc={ele.alertTypeDesc}
              id={id}
              escalationLevel={ele.escalationLevel}
              level="assetDetail"
              acticityImg={acticityImg}
            />
          );
        })}
        {isLoading && <div className="lottieLoading">
        <Lottie
          style={{ marginLeft: "0px", marginRight: "0px" }}
          options={defaulterrorLottie}
          height={50}
          width={50}
        />
          </div>}

        {/* <div className="pagination_value">
          <button onClick={handlePreviousClick} disabled={currentPage === 1}>
            <>
              <img style={{ transform: "rotate(180deg)" }} src={right} />
              <span>{currentPage - 1}</span>- Previous{" "}
            </>
          </button>
          <h3 style={{ fontSize: "10px", margin: "0px" }}>
            {" "}
            Current -{currentPage}
          </h3>
          <button onClick={handleNextClick}>
            {" "}
            Next -<span>{currentPage + 1}</span> <img src={right} />
          </button>
        </div> */}
      </div>
    </div>
  );
}

export default AssetDetailsIndex;
