/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./index.scss";

function SnackCard({ label, message, image, isDark }) {
  return (
    <div className="snackCard">
      <div className="column">
        <div
          className="snackCard-details"
          style={{
            borderColor: isDark ? "#DAE2FF" : "#fff",
            backgroundColor: isDark ? "#0C1228" : "#fff",
          }}
        >
          <div className="snackCard-img">
            <img src={image} style={{ width: "25px" }} />
          </div>
          <div
            className="snackCard-cards"
            style={{ color: isDark ? "#fff" : "#000" }}
          >
            <p>
              <b>{label}</b>
            </p>
            <p>{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SnackCard;
