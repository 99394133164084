// @ts-ignore
import React, { useState } from "react";
import { Collapse, Modal } from "react-bootstrap";
// import RangeIdGeneratedLoader from "../loadingShimmer/RangeIdGeneratedLoader";

import "./RangeIDGeneratedModal.scss";

import ChevronIcon from "../assets/icons/BlackRightArrow.svg";
import closeIcon from "../assets/icons/IconClose.svg";

type RangeIDGeneratedModalType = {
  isRangeIdGeneratedModalOpen: boolean;
  handleCloseModal: () => void;
};

function RangeIDGeneratedModal({
  isRangeIdGeneratedModalOpen,
  handleCloseModal,
}: RangeIDGeneratedModalType) {
  const requestResponseData = {
    "PO #": "3298110",
    "QR TAGS COUNT": "10,00,000",
    "ALLOCATED MACHINES": "10",
  };

  const machineData = [
    {
      id: 1,
      name: "Machine #1",
      data: "M33847445",
    },
    {
      id: 2,
      name: "Machine #2",
      data: "M59392745",
    },
    {
      id: 3,
      name: "Machine #3",
      data: "M50219150",
    },
    {
      id: 1,
      name: "Machine #1",
      data: "M33847445",
    },
    {
      id: 1,
      name: "Machine #1",
      data: "M33847445",
    },
    {
      id: 1,
      name: "Machine #1",
      data: "M33847445",
    },
    {
      id: 1,
      name: "Machine #1",
      data: "M33847445",
    },
    {
      id: 1,
      name: "Machine #1",
      data: "M33847445",
    },
    {
      id: 1,
      name: "Machine #1",
      data: "M33847445",
    },
    {
      id: 1,
      name: "Machine #1",
      data: "M33847445",
    },
  ];

  const [isOpen, setIsOpen] = useState(true);

  return (
    <Modal
      show={isRangeIdGeneratedModalOpen}
      onHide={handleCloseModal}
      centered
      backdrop="static"
    >
      <>
        <div className="mainContainer">
          <div className="header-div">
            <p className="title">Range ID Generated</p>
            <div className="popUpCloseButton" onClick={handleCloseModal}>
              <img className="closeIcon" src={closeIcon} alt="close" />
            </div>
          </div>

          <div className="bodyContainer">
            <div className="dataInRows">
              <div className="qrChevron"></div>
              <p className="lightText">PO #</p>
              <p className="blackText">{requestResponseData["PO #"]}</p>
            </div>

            <div className="dataInRows">
              <div className="qrChevron"></div>
              <p className="lightText">QR TAGS COUNT</p>
              <p className="blackText">
                {requestResponseData["QR TAGS COUNT"]}
              </p>
            </div>

            <div
              className="dataInRows changePointer"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <div className="qrChevrons-range">
                <img
                  src={ChevronIcon}
                  className={`chevronImgIcon ${
                    isOpen ? "rotate-90-deg-icon" : "rotate-0-deg-deg-icon"
                  }`}
                  alt="open"
                />
              </div>
              <p className="lightText">ALLOCATED MACHINES</p>
              <p className="blackText">
                {requestResponseData["ALLOCATED MACHINES"]}
              </p>
            </div>

            <Collapse in={isOpen}>
              <div className="animated-div machineDetailsDiv">
                {machineData.map((data: MachineDataType) => (
                  <div className="machineDetailsList" key={data.id}>
                    <p className="machineDetailsText">{data.name}</p>
                    <p className="machineDetailsText">{data.data}</p>
                  </div>
                ))}
              </div>
            </Collapse>
          </div>
        </div>
      </>
    </Modal>
  );
}

export default RangeIDGeneratedModal;
