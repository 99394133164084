/* eslint-disable no-const-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck

import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import FacilityCard from "./facilityCard/FacilityCard";
import DeleteIcon from "../../../assets/icons/Delete-Icon.svg";
import DisableIcon from "../../../assets/icons/Disable-Icon.svg";
import IconClose from "../../../assets/icons/IconClose.svg";
import AddIcon from "../../../assets/icons/AddIcon.svg";
import deleteIcon from "../../../assets/icons/addFacDeleteIcon.png";
import "./FacilitySettingsCards.scss";
import DeletePopUpScreen from "../deletePopUpScreen/DeletePopUpScreen";
import DisableFacilitiesScreen from "../disableFacilitiesScreen/DisableFacilitiesScreen";
import { SettingsApiServices } from "../../../services/settingsPageServices/SettingsApiServices";
import DisplayNote from "../../../a1Components/DisplayNote/DisplayNote";
import LoaderStyle from "../../../a1Components/LoaderStyle/LoaderStyle";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Select from "react-select";

function FacilitySettingsCards(props: any) {
  const { size, setLoader1, setLoadBtn, recall } = props;
  let tokenID = localStorage.getItem("@tokenID");
  console.log(tokenID, "token77::");
  const [facilityList, setFacilityList] = useState([
    {
      phoneVM: [],
      attributes: null,
      addressVM: [],
      facility: {
        lastUpdatedStamp: 1681832373051,
        facilityId: "10141",
        defaultInventoryItemTypeId: null,
        parentFacilityId: null,
        createdTxStamp: 1681832371412,
        createdStamp: 1681832372080,
        description: null,
        lastUpdatedTxStamp: 1681832371267,
        defaultWeightUomId: null,
        primaryFacilityGroupId: null,
        openedDate: 1681832371412,
        ownerPartyId: "Company",
        facilitySize: 14.2,
        facilitySizeUomId: null,
        closedDate: null,
        facilityTypeId: "PLANT",
        defaultDaysToShip: null,
        defaultDimensionUomId: null,
        facilityName: "aeqwe",
        productStoreId: null,
        geoPointId: "10111",
        oldSquareFootage: null,
        facilityTypeDescription: "",
      },
      brand: "aeqwe",
      emailVM: [],
      userCount: 0,
      assetCount: 0,
    },
  ]);
  const [loader, setLoader] = useState(true);
  // const [pageSize, loadMoreFacility] = useState(8);
  useEffect(()=>{
    fetchALlFacilityList();
  },[recall, size])
  const fetchALlFacilityList = async () => {
    let tokenID = localStorage.getItem("@tokenID");
    // console.log(tokenID,"check")
    // setLoader(true);
    setLoader1(true);
    try {
      const options = {
        headers: {
          method: "GET",
          token: tokenID,
        },
      };
      const response = await fetch(
        // `https://dev-tnt.app.aone.ai/api/1.0/facility/list?userPartyId=Company&viewIndex=0&viewSize=100`
        `https://dev-tnt.app.aone.ai/api/1.0/facility/list?userPartyId=Company&viewIndex=${size}&viewSize=12`,
        options
      );
      const responseData = await response.json();
      if (size == 0) {
        setFacilityList(responseData.facilities);
      } else {
        setFacilityList([...facilityList, ...responseData.facilities]);
      }
      setLoadBtn(true);
      setLoader1(false);
      console.log(responseData, "check47");
      setLoader(false);
    } catch (error) {
      console.error(error);
    }
  };

  const [selectedLsit, setSelectedList] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);

  //deletePopUpState
  const [deletePopUpState, setDeletePopUpState] = useState(false);

  //disablePopUpState
  const [disablePopUpState, setDisablePopUpState] = useState(false);

  const addToSelectedList = (facilityName: any) => {
    // alert(facilityName)
    if (!selectedLsit.includes(facilityName)) {
      let val = [...selectedLsit];
      val.push(facilityName);
      setSelectedList(val);
      setSelectedCount(selectedCount + 1);
    } else {
      let removedArr = selectedLsit.filter(
        (element) => element !== facilityName
      );
      setSelectedList(removedArr);
      setSelectedCount(selectedCount - 1);
    }
  };
  const selectAll = () => {
    let count = facilityList.length;
    setSelectedCount(count);
    setSelectedList(facilityList.map((obj) => obj.facility.facilityId));
  };

  const [activeListName, setActiveListName] = useState([]);
  useEffect(() => {
    let activeList = [];
    selectedLsit.forEach((ob) => {
      let name = facilityList.find((n) => n.facility.facilityId == ob)?.facility
        .facilityName;
      activeList.push(name);
      // alert(name)
    });
    setActiveListName(activeList);
    const copyArray = [...mapUsertoFaclityArr];
    // Update the copy array
    copyArray.push({
      id: generateID(4),
      facilityList: [activeListName[activeListName.length - 1]],
      rolesList: ["role-1", "role-2", "role-3", "role-4"],
    });
    setMapUsertoFaclityArr(copyArray);
  }, [selectedLsit]);

  const deselectAll = () => {
    setSelectedCount(0);
    setSelectedList([]);
  };

  const deleteData = () => {
    setDeletePopUpState(!deletePopUpState);
  };

  const disableData = async (key: any) => {
    if (key == "close") {
      setDisablePopUpState(!disablePopUpState);
      return;
    } else {
      disableFacilities();
      setDisablePopUpState(!disablePopUpState);
    }
  };

  // Disable BULK ID's API

  const retainAssets = async () => {
    disableFacilities();
  };

  const DeactivateAsset = async () => {
    if (selectedLsit.length !== 1) {
      // alert("We cannot perform bulk deactivate please select ne facility..");
    } else {
      let tokenID = localStorage.getItem("@tokenID");
      try {
        const token = tokenID;
        const endpoint =
          "https://dev-tnt.app.aone.ai/api/1.0/facility/deactivateAssets";
        const headers = {
          "Content-Type": "application/json",
          token: token,
        };
        const payload = {
          facilityId: selectedLsit[0],
        };
        const response = await fetch(endpoint, {
          method: "DELETE",
          headers: headers,
          body: JSON.stringify(payload),
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const resp = await response.json();
        setShowDeleteFacility(false);
        setDeleteFacility(false);
        // alert(resp.responseMessage);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    }
  };

  //Bulk Deactivated Also done
  const bulkDeactivateAsset = async () => {
    try {
      const token = tokenID;
      const endpoint =
        "https://dev-tnt.app.aone.ai/api/1.0/facility/bulkDeactivatedAssets";
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      const payload = {
        facilityIds: selectedLsit,
      };
      const response = await fetch(endpoint, {
        method: "DELETE",
        headers: headers,
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const resp = await response.json();
      setShowDeleteFacility(false);
      setDeleteFacility(false);
      // alert("success");
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  async function disableFacilities() {
    let tokenID = localStorage.getItem("@tokenID");
    try {
      const token = tokenID;
      const endpoint =
        "https://dev-tnt.app.aone.ai/api/1.0/facility/bulkDisable";
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      const payload = {
        facilityIds: selectedLsit,
      };
      const response = await fetch(endpoint, {
        method: "DELETE",
        headers: headers,
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log(data, "check4777");
      // alert("Request Successfull");
      fetchALlFacilityList();
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }

  // Assign User
  const [showAssignUser, setShowAssignUser] = useState(false);

  function generateID(length: any) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const [mapUsertoFaclityArr, setMapUsertoFaclityArr] = useState([
    {
      id: generateID(4),
      facilityList: [],
      rolesList: ["role-1", "role-2", "role-3", "role-4"],
    },
  ]);

  const addFacilityRole = () => {
    const copyArray = [...mapUsertoFaclityArr];
    // Update the copy array
    copyArray.push({
      id: generateID(4),
      facilityList: [activeListName],
      rolesList: ["role-1", "role-2", "role-3", "role-4"],
    });
    setMapUsertoFaclityArr(copyArray);
  };
  const deleteAddFaciityRole = (id: any) => {
    let copyArray = [...mapUsertoFaclityArr];
    copyArray = copyArray.filter((el) => el.id !== id);
    setMapUsertoFaclityArr(copyArray);
  };

  const defaultSelectedOptions = mapUsertoFaclityArr[0].facilityList
    .slice(0, 2)
    .map((list) => ({
      value: list,
      label: list,
    }));

  return (
    <>
      {deletePopUpState ? (
        <DeletePopUpScreen
          closePopupCall={deleteData}
          retainAssets={retainAssets}
          DeactivateAsset={bulkDeactivateAsset}
        />
      ) : null}

      {disablePopUpState ? (
        <DisableFacilitiesScreen closeDisablePopUpScreen={disableData} />
      ) : null}

      <div className="facilitySettingsCardsCont">
        {selectedCount > 0 ? (
          <div className="facilitySettingsCardsNav">
            <p>{selectedCount} selected</p>
            <div className="facilitySettingsNavBtnsCont">
              <button className="selectAllBtn" onClick={selectAll}>
                Select all
              </button>
              <button className="deselectAllBtn" onClick={deselectAll}>
                Deselect all
              </button>
              <button
                className="addUserBtn"
                onClick={() => setShowAssignUser(true)}
              >
                Assign user
              </button>
              <button
                className="disableBtn"
                onClick={() => disableData("close")}
              >
                <img src={DisableIcon} alt="icon" /> Disable
              </button>
              <button className="deleteBtn" onClick={deleteData}>
                <img src={DeleteIcon} alt="icon" /> Delete
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
        {loader ? (
          <div
            className="facilityCardsCont"
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <LoaderStyle />{" "}
          </div>
        ) : (
          <div className="facilityCardsCont">
            {facilityList &&
              facilityList.map((el) => (
                <FacilityCard
                  selected={selectedLsit.includes(el.facility.facilityId)}
                  key={el.facility.facilityId}
                  data={el}
                  addToSelectedList={addToSelectedList}
                  refreshList={fetchALlFacilityList}
                />
              ))}
          </div>
        )}
      </div>

      <Modal
        show={showAssignUser}
        onHide={() => setShowAssignUser(false)}
        centered
        backdrop="static"
        className="assignUserFacilityPopupModal"
      >
        <Modal.Header className="assignUserFacilityPopHeaderCont">
          <h4 className="assignUserFacilityPopHeader">
            Map facilities to user
          </h4>
          <div
            className="closeIconDiv"
            onClick={() => setShowAssignUser(false)}
          >
            <img
              src={IconClose}
              alt="icon"
              onClick={() => setShowAssignUser(false)}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="assignUserFacilityPopBody">
          <div className="assignUserFacilityPopBodyCont">
            <div className="assignUserBodyHeaderCont">
              <p>Select User</p>
              <select>
                <option>Select User</option>
              </select>
            </div>

            <div className="assignUserFacilityDetailsCont">
              <div className="detailsHeaderCont">
                <p>Facility</p>
                <p>Role</p>
              </div>
              <div className="selectorContDiv">
                {mapUsertoFaclityArr.map((obj) => (
                  <div className="detailsSelectorCont">
                    <select>
                      {obj.facilityList.map((list) => (
                        <option key={list}>{list}</option>
                      ))}
                    </select>

                    <select>
                      <option value="" disabled selected>
                        Select Role
                      </option>
                      {obj.rolesList.map((list) => (
                        <option key={list}>{list}</option>
                      ))}
                    </select>
                    <img
                      src={deleteIcon}
                      alt="delete-icon"
                      onClick={() => deleteAddFaciityRole(obj.id)}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="assignUserFacilityDetailsBtnCont">
              <button
                className="assignUserFacilityDetailsBtn"
                onClick={addFacilityRole}
              >
                <img src={AddIcon} alt="icon" />
                Add
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="assignUserFacilityPopFooterCont">
          <button
            onClick={() => setShowAssignUser(false)}
            className="assignUserFacilityCancelBtn"
          >
            Cancel
          </button>
          <button
            onClick={() => setShowAssignUser(false)}
            className="assignUserFacilityMappingBtn"
          >
            {" "}
            Confirm Mapping
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FacilitySettingsCards;
