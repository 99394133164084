import React, {useState} from 'react';
import machineDetailsIcon from "../assets/icons/icon-machine-details.svg";
import MachineDetailsPopUp from './machineDetails/MachineDetailsPopUp';
import {PartyGroupAddress} from "./Types/PartyGroupAddress";

interface ChildProps {
    poDetails: PartyGroupAddress[];
}

function PoDetailsChildComponent({poDetails}: ChildProps) {
    const [machineModalShow, setMachineModalShow] = useState(false);
    const handleMachineDetailsClick = () => setMachineModalShow(!machineModalShow);

    return (

        <div className="childRow ">
            <div className="tableRow tableHeader">
                <div className="sNo">S.No</div>
                <div className="qrCount">QR Count</div>
                <div className="qrRejected">QC Rejected #</div>
                <div className="allocatedTime">Allocated Time</div>
                <div className="status">Status</div>
                <div className="machineDetails">Machine Details</div>
            </div>

            {poDetails.map((row, index) => (
                <div>
                    <div className="tableRow">
                        <div className="sNo">{index + 1}</div>
                        <div className="qrCount">{row.qrCount}</div>
                        <div className="qrRejected">{row.qcRejected}</div>
                        <div className="allocatedTime">{row.allocatedTime}</div>
                        <div className="status">
                            <div
                                className={`${row.status == "COMPLETED" ? 'statusBg completed' : 'statusBg inProgress'}`}>
                                {row.status}
                            </div>
                        </div>
                        <div className="machineDetails">
                            <img src={machineDetailsIcon} onClick={handleMachineDetailsClick} alt="machineDetails"/>
                        </div>
                    </div>
                    {index != poDetails.length - 1 && <div className="dividerLine width86 marginLeft7"/>}
                </div>
            ))}

            <MachineDetailsPopUp
             show={machineModalShow} 
             close={handleMachineDetailsClick} />

        </div>
    );
}


export default PoDetailsChildComponent;