/* eslint-disable no-const-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import { useEffect, useState, useRef } from "react";
import FacilitySettingsCards from "./facilitySettingsCards/FacilitySettingsCards";
import Modal from "react-bootstrap/Modal";
import AddIcon from "../../assets/icons/AddIcon.svg";
import IconFilter from "../../assets/icons/IconFilter.svg";
import ExportIcon from "../../assets/icons/ExportIcon.svg";
import IconClose from "../../assets/icons/IconClose.svg";
import CountryIcon from "../../assets/icons/CountryIcon.svg";
import IconAdd from "../../assets/icons/IconAdd.svg";
import "./FacilitySettings.scss";
import FacilitypopUpScreen from "./facilitypopUpScreen/FacilitypopUpScreen";
import FacilityAddressPopUpScreen from "../../SettingsComponents/FacilitySettings/facilitypopUpScreen/FacilityAddressPopUpScreen";
import { SettingsApiServices } from "../../services/settingsPageServices/SettingsApiServices";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormValidator from "../../Utils/FormValidations/FormValidations";
import deleteIcon from "../../assets/icons/addFacDeleteIcon.png";
import ReactTooltip from "react-tooltip";

function FacilitySettings(props: any) {
  const { callAddFac, setHeaderButton, callAddFacilityPopup } = props;

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (callAddFac.show) {
      setShowCreateFac();
    }
    // alert("Called")
  }, [callAddFac.number]);

  const closeAddFacility = () => {
    if (callAddFac.show) {
      setHeaderButton("Asset Settings");
      callAddFacilityPopup({ number: generateID(5), show: false });
    }
    setShowCreateFacility(false);
  };

  const closePopUpScreen = () => {
    setShow(false);
  };
  const [loadBtn, setLoadBtn] = useState(false);
  const [size, setPageSize] = useState(0);
  const value = 0.1;
  const maxValue = 1;
  const percentage = 40;
  const [loader, setLoader] = useState(false);

  // Create Facility Popup
  const [showCreateFacility, setShowCreateFacility] = useState(false);
  const [activeTab, onclickActivePopup] = useState("basic");

  // Phone Set Purpose
  // const [showPhonePurpose, setShowPhonePurpose] = useState(false);

  // Email Set Purpose
  // const [showEmailPurpose, setShowEmailPurpose] = useState(false);

  // Address Popup
  const [addressPopUpscreen, setAddressPopUpscreen] = useState(false);

  let [createFacilityObj, setCreateFacilityObj] = useState({
    facilityName: "",
    facilityTypeId: "",
    facilityBrandName: "FacilityTest",
    ownerPartyId: localStorage.getItem("@partyID"),
    countryGeoId: "IND",
    emailAddress: "",
    // emailPurpose: "",
    // "[BILLING_EMAIL]",
    // phonePurpose: "",
    // "[PHONE_BILLING]",
    phoneNumberPrefix: "+91",
    phoneNumber: "",
    externalId: "",
    // addressPurpose: "",
    // "[BILLING_LOCATION]",
    toName: "",
    attnName: "",
    address1: "",
    address2: "",
    city: "",
    stateGeoCodeId: "",
    postalCode: "",
    facilitySizeUomId: "",
    facilitySize: "",
    latitude: "",
    longitude: "",
  });

  const [storeAddressVal, setAddressVal] = useState({});
  const [tempaddressPurpose, setTempAddress] = useState([]);

  const setAddressVisible = () => {
    setShowAddAddress(true);
    let tempAddObj = { ...createFacilityObj };
    setAddressVal(tempAddObj);

    let tempPurp = [...addressPurpose.current];
    setTempAddress(tempPurp);
  };
  // Address Cancel Functionality..

  const setShowAddAddressCancel = () => {
    let stashedObj = { ...storeAddressVal };
    setCreateFacilityObj(stashedObj);
    setShowAddAddress(false);
    addressPurpose.current = tempaddressPurpose;
  };

  const [childRef, setChildRef] = useState("");

  const createFacSettings = async () => {
    let mandatory = new FormValidator();
    if (
      !mandatory.checkMandatoryFields(createFacilityObj, [
        "facilityName",
        "facilityTypeId",
        "emailAddress",
        "toName",
        "attnName",
        "address1",
        "address2",
        "city",
        "stateGeoCodeId",
        "postalCode",
        "phoneNumber",
        "externalId",
      ]) ||
      invalidArr.length !== 0
    ) {
      toast("Please Fill Mandatory Fields");
      onclickActivePopup("basic");
    } else {
      let tokenID = localStorage.getItem("@tokenID");
      console.log(createFacilityObj, "check4444444");
      // setShowAddAddress(fals)
      let resp = await new SettingsApiServices().CreateFacility(
        tokenID,
        createFacilityObj
      );

      console.log(resp, "resp");

      if (resp.reponseCode == 200) {
        toast("Facility Created Successfully");
        facilityAssignUserAPI(resp?.reponse?.facilityId);
        closeAddFacility();
      } else {
        toast("Request Failed");
        let errorArr = await resp.json();
        console.log(errorArr, "apiErrorsResp");
        let invalidErrors = [
          "validateFacilityName",
          "validateEmail",
          "validateMobileNumber",
          "validateFacilitySize",
          "validateLongitude",
          "validateLatitude",
          "validateAttName",
          "validatePostalCode",
          "validateToName",
        ];
        invalidErrors.forEach((el) => {
          let matchedError = errorArr?.apiErrors?.find((error) => {
            if (
              !(
                el.substring(8).substring(0, 4).toLocaleLowerCase() == "faci"
              ) &&
              error.substring(0, 4).toLowerCase() ==
                el.substring(8).substring(0, 4).toLocaleLowerCase()
            ) {
              return true;
            }
            if (
              el.substring(8).substring(0, 4).toLocaleLowerCase() == "faci" &&
              error.substring(0, 10).toLowerCase() ==
                el.substring(8).substring(0, 10).toLocaleLowerCase()
            ) {
              return true;
            }
          });
          if (matchedError !== undefined) {
            console.log("Matched error:", el);
            setInvalidArr((prev) => [...prev, el]);
            // Do something with the matched error
          }
        });
      }

      onclickActivePopup("basic");
    }
  };

  const setFacVal = (e: any, name: string) => {
    if (
      ((name == "phoneNumber" || name == "postalCode") &&
        /[a-zA-Z~`!@#$%^&*()+=\-[\]\\';,./{}|":<>?_]/.test(e.target.value)) ||
      e.target.value.replace(/\D/g, "").length > (name == "postalCode" ? 6 : 10)
    ) {
      return;
    }
    let tempObj = { ...createFacilityObj };
    if (name == "facilitySize") {
      tempObj[name] = e.target.value.replace(/[^0-9.]/g, "");
      const decimalCount = tempObj[name].split(".").length - 1;
      if (decimalCount > 1) {
        tempObj[name] = tempObj[name].slice(0, tempObj[name].lastIndexOf("."));
      }
    } else if (name == "latitude" || name == "longitude") {
      let tempS = parseFloat(e.target.value);
      tempObj[name] = tempS;
    } else {
      tempObj[name] = e.target.value;
    }
    setCreateFacilityObj(tempObj);
  };
  // const [warning, setWarning] = useState("");

  const [invalidArr, setInvalidArr] = useState([]);
  const validate = new FormValidator();
  const checkName = (name, val) => {
    const validArr = [
      "validateFacilityName",
      "validateEmail",
      "validateMobileNumber",
      "validateFacilitySize",
      "validateLongitude",
      "validateLatitude",
      "validateAttName",
      "validatePostalCode",
      "validateToName",
      "address1",
      "address2",
      "city",
      "externalId",
    ];
    if (!validArr.includes(name)) {
      return;
    }

    let functionName =
      name === "validateFacilitySize" ||
      name === "validateLongitude" ||
      name === "validateLatitude"
        ? "validateFloatValue"
        : name;
    functionName =
      name === "validateAttName" || name === "validateToName"
        ? "validateToName"
        : functionName;
    functionName =
      name == "address1" || name == "address2" ? "address1" : functionName;

    console.log(functionName, "check7878787878778");
    const isValid = validate[functionName](val);

    setInvalidArr((prevInvalidArr) => {
      if (isValid) {
        return prevInvalidArr.filter((obj) => obj !== name);
      } else {
        return [...prevInvalidArr, name];
      }
    });

    // console.log(name, val, invalidArr, "Valid7878");
  };

  const [createStats, setCreateStats] = useState({});

  useEffect(async () => {
    let tokenID = localStorage.getItem("@tokenID");
    let stats = await new SettingsApiServices().FacilityStatistics(tokenID);
    console.log(stats, "fac77777777");
    setCreateStats(stats);
    // setEmailActive([]);
    emailActiveList.current = [];
    // setPhoneActiveList([]);
    phoneActiveList.current = [];
  }, []);

  // Add Address Popup
  const [showAddAddress, setShowAddAddress] = useState(false);
  const emailActiveList = useRef([]);
  // setEmailActive
  const setEmailActiveList = (stat) => {
    // alert(stat)
    //ToDO Typo
    if (!emailActiveList.current.includes(stat)) {
      let tempList = emailActiveList.current;
      tempList.push(stat);
      emailActiveList.current = tempList;
      // setEmailActive(tempList);s
    } else {
      let tempList = emailActiveList.current;
      tempList = tempList.filter((obj) => obj !== stat);
      emailActiveList.current = tempList;
      // setEmailActive(tempList);
    }
    console.log(emailActiveList.current, "emailPurposes7878");
    let tempObj = { ...createFacilityObj };
    tempObj["emailPurpose"] = JSON.stringify(emailActiveList.current)
      .replace(/"/g, "")
      .replace(/,/g, ", ");
    setCreateFacilityObj(tempObj);
  };
  // const [phoneActiveList, setPhoneActiveList] = useState([]);
  const phoneActiveList = useRef([]);
  const setPhoneActive = (stat) => {
    // alert(stat)
    // console.log(JSON.stringify(phoneActiveList).replace(/"/g, ""),"check7777")
    // console.log(phoneActiveList, 'check777')
    if (!phoneActiveList.current.includes(stat)) {
      let tempList = [...phoneActiveList.current];
      tempList.push(stat);
      phoneActiveList.current = tempList;
    } else {
      let tempList = [...phoneActiveList.current];
      tempList = tempList.filter((obj) => obj !== stat);
      phoneActiveList.current = tempList;
    }
    console.log(phoneActiveList.current, "@phonePurpose");
    let tempObj = { ...createFacilityObj };
    tempObj["phonePurpose"] = JSON.stringify(phoneActiveList.current)
      .replace(/"/g, "")
      .replace(/,/g, ", ");
    setCreateFacilityObj(tempObj);
  };
  const addressPurpose = useRef([]);
  // setaddressPurpose

  const contactPurpose = (stat) => {
    // console.log(phoneActiveList, 'check777')
    if (!addressPurpose.current.includes(stat)) {
      let tempList = [...addressPurpose.current];
      tempList.push(stat);
      addressPurpose.current = tempList;
    } else {
      let tempList = [...addressPurpose.current];
      tempList = tempList.filter((obj) => obj !== stat);
      // setaddressPurpose.currents(tempList);
      addressPurpose.current = tempList;
    }
    let tempObj = { ...createFacilityObj };
    tempObj["addressPurpose"] = JSON.stringify(addressPurpose.current)
      .replace(/"/g, "")
      .replace(/,/g, ", ");
    setCreateFacilityObj(tempObj);

    console.log(
      JSON.stringify(addressPurpose.current)
        .replace(/"/g, "")
        .replace(/,/g, ", "),
      "dumub8888888"
    );
  };

  const usersRolesList = useRef([]);
  const facilityUsersList = useRef([]);
  const setShowCreateFac = async () => {
    setMapUsertoFaclityArr([
      {
        id: generateID(4),
        facilityList: facilityUsersList.current,
        rolesList: usersRolesList.current,
      },
    ]);
    setSelectedUsers([]);
    let token = localStorage.getItem("@tokenID");
    // call users and roles API..

    const getRolesList = async () => {
      try {
        const getRoles = await new SettingsApiServices().FacilityStatistics(
          token
        );
        console.log(getRoles.roleTypes, "rolesList7878");
        usersRolesList.current = getRoles.roleTypes;
      } catch (error) {
        // alert('User Roles API Error...')
      }
    };
    const getFacilityUsersList = async () => {
      let opt = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      };
      let respObj = await fetch(
        "https://dev-tnt.app.aone.ai/api/1.0/user/list",
        opt
      );
      let finalObj = await respObj.json();
      if (finalObj !== undefined) {
        console.log(finalObj, "usersList7878787");
        facilityUsersList.current = finalObj.data;
      }
    };

    setCreateFacilityObj({
      facilityName: "",
      facilityTypeId: "",
      facilityBrandName: "FacilityTest",
      ownerPartyId: localStorage.getItem("@partyID"),
      countryGeoId: "IND",
      emailAddress: "",
      // emailPurpose: "",
      // "[BILLING_EMAIL]",
      // phonePurpose: "",
      // "[PHONE_BILLING]",
      phoneNumberPrefix: "+91",
      phoneNumber: "",
      externalId: "",
      // addressPurpose: "",
      // "[BILLING_LOCATION]",
      toName: "",
      attnName: "",
      address1: "",
      address2: "",
      city: "",
      stateGeoCodeId: "",
      postalCode: "",
      facilitySizeUomId: "",
      facilitySize: "",
      latitude: "",
      longitude: "",
    });
    addressPurpose.current = [];
    // setEmailActive([]);
    emailActiveList.current = [];
    // setPhoneActiveList([]);
    phoneActiveList.current = [];
    setShowCreateFacility(true);
    setInvalidArr([]);
    getRolesList();
    getFacilityUsersList();
    setMapUsertoFaclityArr([
      {
        id: generateID(4),
        facilityList: facilityUsersList.current,
        rolesList: usersRolesList.current,
      },
    ]);
  };

  const facilityUsersData = ["Chennai", "Banglore", "Delhi", "Kolkata"];
  const facilityUsersRoleData = [
    "Facility Manager",
    "Cluster Manager",
    "City Manager",
    "Warehouse Manager",
  ];

  //Users
  function generateID(length: any) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  useEffect(() => {
    setMapUsertoFaclityArr([
      {
        id: generateID(4),
        facilityList: facilityUsersList.current,
        rolesList: usersRolesList.current,
      },
    ]);
  }, [facilityUsersList.current, usersRolesList.current]);

  const [mapUsertoFaclityArr, setMapUsertoFaclityArr] = useState([
    {
      id: generateID(4),
      facilityList: facilityUsersList.current,
      rolesList: usersRolesList.current,
    },
  ]);
  const addFacilityRole = () => {
    handleAddUser();
    const copyArray = [...mapUsertoFaclityArr];
    // Update the copy array
    copyArray.push({
      id: generateID(4),
      facilityList: facilityUsersList.current,
      rolesList: usersRolesList.current,
    });
    setMapUsertoFaclityArr(copyArray);
  };
  const deleteAddFacilityRole = (id: any, index) => {
    handleDelete(index);
    let copyArray = [...mapUsertoFaclityArr];
    copyArray = copyArray.filter((el) => el.id !== id);
    setMapUsertoFaclityArr(copyArray);
  };

  // AssignUser for facility functionality...

  const [selectedUsers, setSelectedUsers] = useState([]);
  const handleUserSelection = (event, index) => {
    const { value } = event.target;
    const updatedUsers = [...selectedUsers];
    updatedUsers[index] = {
      ...updatedUsers[index],
      partyId: value,
    };
    setSelectedUsers(updatedUsers);
  };
  const handleRoleSelection = (event, index) => {
    const { value } = event.target;
    const updatedUsers = [...selectedUsers];
    updatedUsers[index] = {
      ...updatedUsers[index],
      roleTypeId: value,
    };
    setSelectedUsers(updatedUsers);
  };
  const handleDelete = (index) => {
    const updatedUsers = [...selectedUsers];
    updatedUsers.splice(index, 1);
    setSelectedUsers(updatedUsers);
  };
  const handleAddUser = () => {
    const newUser = {
      partyId: "",
      roleTypeId: "",
    };
    setSelectedUsers([...selectedUsers, newUser]);
    console.log(selectedUsers, "ADDUserFacility7878");
  };

  // Facility Assign user API
  const facilityAssignUserAPI = async (id) => {
    try {
      // activeCardRef.current
      // alert(id);
      let finalArr = [...selectedUsers];
      finalArr = finalArr
        .map((user) => {
          if (user.partyId !== "" && user.roleTypeId !== "") {
            return { ...user, facilityId: id };
          }
          return null;
        })
        .filter((user) => user !== null);

      // Calling API for Assignuser..
      let token = localStorage.getItem("@tokenID");
      const endpoint = `https://dev-tnt.app.aone.ai/api/1.0/assignUser/facility`;
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      const payload = {
        toAdd: finalArr,
        toRemove: [],
      };
      const response = await fetch(endpoint, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      const resp = await response.json();
      // console.log(resp, "finalAddUserArr")
      // console.log(payload, "finalAddUserArr")
      if (resp.statusCode == "OK") {
        toast(`Users Added for Facility ID: ${id} `);
        setChildRef(generateID(5));
      }
      setChildRef(generateID(5));
      // childRef.current = generateID(5)
      // console.log(finalArr, "finalAddUserArr");
    } catch (error) {
      toast("Facility Add User Popup Error");
    }
  };

  const tooltipStyle = {
    fontSize: "2px !important", // Adjust the font size as desired
  };

  return (
    <>
      <div className="settingFilterBtnCont">
        <div className="filterSearchCont">
          {/*<input*/}
          {/*  type="search"*/}
          {/*  placeholder="Search Facilities"*/}
          {/*  className="filterSearchInput"*/}
          {/*/>*/}
        </div>
        <div className="filterBtnConteiner">
          {/*EXPORT*/}
          {/*<button className="facilitySettingsExportBtn">*/}
          {/*  <img src={ExportIcon} alt="icon" />*/}
          {/*  Export*/}
          {/*</button>*/}
          {/*FILTER PERMISSION*/}
          {/*<button className="facilityFilterBtn">*/}
          {/*  <img src={IconFilter} alt="icon" />*/}
          {/*  Filters*/}
          {/*</button>*/}
          <button className="addFacilityBtn" onClick={setShowCreateFac}>
            <img src={AddIcon} alt="icon" />
            Add facility
          </button>
        </div>
      </div>
      <div className="settingCardCont">
        <FacilitySettingsCards
          size={size}
          recall={childRef}
          setLoader1={setLoader}
          setLoadBtn={setLoadBtn}
        />
      </div>
      <ToastContainer position="bottom-left" />
      <div className="facilitySettingsFooter">
        {loadBtn && (
          <>
            {loader ? (
              <button className="loadingBtn">
                Loading
                <div className="loaderAnimation"></div>
              </button>
            ) : (
              <button
                className="loadMoreBtn"
                onClick={() => setPageSize(size + 1)}
              >
                Load more
              </button>
            )}
          </>
        )}
      </div>

      <Modal
        show={showCreateFacility}
        onHide={() => closeAddFacility()}
        centered
        backdrop="static"
        className="createFacilityPopupModal"
        
      
      >
        <Modal.Header className="createFacilityPopHeader">
          <h4 className="createFacilityHeader">Create new facility</h4>
          <div className="closeIconDiv" onClick={() => closeAddFacility()}>
            <img src={IconClose} alt="icon" />
          </div>
        </Modal.Header>
        <Modal.Body className="createFacilityPopBody">
          <div className="createFacilityBody">
            <div className="createFacilityHeaderNav">
              <p
                className={
                  activeTab == "basic"
                    ? "activeCreateFacilityNavTxt"
                    : "createFacilityNavTxt"
                }
                onClick={() => onclickActivePopup("basic")}
              >
                Basic Information
              </p>
              <p
                className={
                  activeTab == "attrib"
                    ? "activeCreateFacilityNavTxt"
                    : "createFacilityNavTxt"
                }
                onClick={() => onclickActivePopup("attrib")}
              >
                Attributes
              </p>
              <p
                className={
                  activeTab == "users"
                    ? "activeCreateFacilityNavTxt"
                    : "createFacilityNavTxt"
                }
                onClick={() => onclickActivePopup("users")}
              >
                Users
              </p>
            </div>
            {activeTab == "basic" ? (
              <div className="facilityBasicInfoCont">
                <div className="basicInfoCont">
                  <div className="basicInfoInputsCont">
                    <div className="textInputsCont">
                      <label>Name *</label>
                      <div className="inputsDivContainer">
                        <input
                          value={createFacilityObj.facilityName}
                          type="text"
                          style={{
                            border: `${
                              invalidArr.includes("validateFacilityName")
                                ? "1px solid red"
                                : ""
                            }`,
                          }}
                          onChange={(e) => setFacVal(e, "facilityName")}
                          onBlur={() =>
                            checkName(
                              "validateFacilityName",
                              createFacilityObj.facilityName
                            )
                          }
                          data-tip="Name can only contain letters (A-Z, a-z), numbers (0-9) and the following special characters: ', _, -, # and whitespace"
                          data-for="name-tooltip"
                        />
                      </div>

                      {invalidArr.includes("validateFacilityName") && (
                        <label style={{ color: "red", fontSize: "8px" }}>
                          {new FormValidator().validateValue(
                            createFacilityObj.facilityName,
                            /^[A-Za-z\s_#]+$/,
                            100
                          )}
                        </label>
                      )}
                      <ReactTooltip
                        id="name-tooltip"
                        effect="solid"
                        place="bottom"
                        className="tooltip"
                      />
                    </div>

                    <div className="selectorInputCont">
                      <label>Facility Type *</label>
                      <select
                        value={createFacilityObj.facilityTypeId}
                        onChange={(e) => setFacVal(e, "facilityTypeId")}
                        placeholder="Select Type"
                      >
                        <option value="" disabled selected>
                          Select Type
                        </option>
                        {createStats?.facilityTypes?.map((el) => (
                          <option
                            key={el.facilityTypeId}
                            value={el.facilityTypeId}
                          >
                            {el.description}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="textInputsCont">
                      <label>Email *</label>
                      <div className="inputsDivContainer">
                        <input
                          style={{
                            border: `${
                              invalidArr.includes("validateEmail")
                                ? "1px solid red"
                                : ""
                            }`,
                          }}
                          value={createFacilityObj.emailAddress}
                          type="validateEmail"
                          onChange={(e) => setFacVal(e, "emailAddress")}
                          onBlur={() =>
                            checkName(
                              "validateEmail",
                              createFacilityObj.emailAddress
                            )
                          }
                          data-tip="Email can only contain letters (A-Z, a-z), numbers (0-9) and following special characters: ., _, -, + and @"
                          data-for="name-tooltip"
                        />
                      </div>

                      <ReactTooltip
                        id="name-tooltip"
                        effect="solid"
                        place="bottom"
                        className="tooltip"
                      />
                      {invalidArr.includes("validateEmail") && (
                        <label style={{ color: "red", fontSize: "8px" }}>
                          {new FormValidator().validateValue(
                            createFacilityObj.emailAddress,
                            /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            255
                          )}
                        </label>
                      )}

                      {/* <p
                        className="setPurpose"
                        onClick={() => setShowEmailPurpose(true)}
                      >
                        {emailActiveList.current.length == 0
                          ? ""
                          : emailActiveList.current.length}{" "}
                        {emailActiveList.current.length == 0
                          ? "Set purpose"
                          : "purposes selected"}
                      </p> */}
                      {/* {showEmailPurpose && (
                        <div className="emailSetPurpose">
                          <div className="setPurposeHeader">
                            <div
                              className="closeIconDev"
                              onClick={() => setShowEmailPurpose(false)}
                            >
                              <img src={IconClose} alt="icon" />
                            </div>
                          </div>
                          <div className="setPurposeDataCont">
                            {createStats?.contactPurposes?.EMAIL_ADDRESS?.map(
                              (el) => (
                                <div
                                  onClick={() =>
                                    setEmailActiveList(
                                      el.contactMechPurposeTypeId
                                    )
                                  }
                                  key={el.contactMechPurposeTypeId}
                                  className="setPurposeData"
                                >
                                  <input
                                    checked={emailActiveList.current.includes(
                                      el.contactMechPurposeTypeId
                                    )}
                                    type="checkbox"
                                  />
                                  <label>{el.description}</label>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )} */}
                    </div>

                    <div className="textInputsCont">
                      <label>Address *</label>
                      <div className="inputsDivContainer">
                        <input
                          type="text"
                          placeholder="Add address"
                          readOnly
                          value={`${createFacilityObj.city}${
                            createFacilityObj.city ? "," : ""
                          } ${createFacilityObj.address1}${
                            createFacilityObj.address2 ? "," : ""
                          } ${createFacilityObj.address2}${
                            createFacilityObj.postalCode ? "-" : ""
                          } ${createFacilityObj.postalCode}`}
                          style={{
                            bottom: `${
                              invalidArr.includes("validateEmail")
                                ? "52px"
                                : "33px"
                            }`,
                          }}
                        />
                        {/* <img
                          className="createFacilityIcon"
                          src={IconAdd}
                          alt="icon"
                          onClick={setAddressVisible}
                        /> */}
                        <div className="createFacilityIconDiv">
                          <img
                            className="createFacilityIcon"
                            src={IconAdd}
                            alt="icon"
                            onClick={setAddressVisible}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="phoneNumberInputCont">
                      <label>Phone Number *</label>
                      <div className="selectAndInputCont">
                        <div className="imgAndSelectCont">
                          <img src={CountryIcon} alt="icon" />
                          <select>
                            <option>+91</option>
                          </select>
                        </div>

                        <input
                          value={createFacilityObj.phoneNumber}
                          type="text"
                          style={{
                            border: `${
                              invalidArr.includes("validateMobileNumber")
                                ? "1px solid red"
                                : ""
                            }`,
                          }}
                          onChange={(e) => setFacVal(e, "phoneNumber")}
                          onBlur={() =>
                            checkName(
                              "validateMobileNumber",
                              createFacilityObj.phoneNumber
                            )
                          }
                        />
                      </div>
                      {invalidArr.includes("validateMobileNumber") && (
                        <label style={{ color: "red", fontSize: "8px" }}>
                          {" "}
                          Enter valid Phone Number*
                        </label>
                      )}
                      {/* <p
                        className="setPurpose"
                        onClick={() => setShowPhonePurpose(true)}
                      >
                        {phoneActiveList.current.length == 0
                          ? ""
                          : phoneActiveList.current.length}{" "}
                        {phoneActiveList.current.length == 0
                          ? "Set purpose"
                          : "purposes selected"}
                      </p> */}
                      {/* {showPhonePurpose && (
                        <div className="phoneNumberSetPurpose">
                          <div className="setPurposeHeader">
                            <img
                              src={IconClose}
                              alt="icon"
                              onClick={() => setShowPhonePurpose(false)}
                            />
                          </div>
                          <div className="setPurposeDataCont">
                            {createStats?.contactPurposes?.TELECOM_NUMBER?.map(
                              (el) => (
                                <div
                                  onClick={() =>
                                    setPhoneActive(el.contactMechPurposeTypeId)
                                  }
                                  key={el.contactMechPurposeTypeId}
                                  className="setPurposeData"
                                >
                                  <input
                                    checked={phoneActiveList.current.includes(
                                      el.contactMechPurposeTypeId
                                    )}
                                    type="checkbox"
                                  />
                                  <label>{el.description}</label>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )} */}
                    </div>

                    <div className="sizeFacilityInputCont">
                      <label>Size of Facility</label>
                      <div className="inputAndSelectCont">
                        <input
                          type="text"
                          style={{
                            border: `${
                              invalidArr.includes("validateFacilitySize")
                                ? "1px solid red"
                                : ""
                            }`,
                          }}
                          value={createFacilityObj.facilitySize}
                          onChange={(e) => setFacVal(e, "facilitySize")}
                          onBlur={() =>
                            checkName(
                              "validateFacilitySize",
                              createFacilityObj.facilitySize
                            )
                          }
                        />

                        <select
                          value={createFacilityObj.facilitySizeUomId}
                          onChange={(e) => setFacVal(e, "facilitySizeUomId")}
                        >
                          {createStats?.unitsOfMeasures?.AREA_MEASURE?.map(
                            (obj) => (
                              <option key={obj.uomId} value={obj.uomId}>
                                {obj.description}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      {invalidArr.includes("validateFacilitySize") && (
                        <label style={{ color: "red", fontSize: "8px" }}>
                          {" "}
                          Enter Float Values Only*
                        </label>
                      )}
                    </div>

                    <div className="textInputsCont">
                      <label>External ID *</label>
                      <div className="inputsDivContainer">
                        <input
                          type="text"
                          style={{
                            border: `${
                              invalidArr.includes("externalId")
                                ? "1px solid red"
                                : ""
                            }`,
                          }}
                          value={createFacilityObj.externalId}
                          onChange={(e) => setFacVal(e, "externalId")}
                          onBlur={() =>
                            checkName(
                              "externalId",
                              createFacilityObj.externalId
                            )
                          }
                          data-tip="External ID can only contain letters (A-Z, a-z), numbers (0-9) and following special character: '"
                          data-for="name-tooltip"
                        />
                      </div>

                      <ReactTooltip
                        id="name-tooltip"
                        effect="solid"
                        place="bottom"
                        className="tooltip"
                      />
                      {invalidArr.includes("externalId") && (
                        <label style={{ color: "red", fontSize: "8px" }}>
                          {new FormValidator().validateValue(
                            createFacilityObj.externalId,
                            /^[A-Za-z']+$/,
                            255
                          )}
                        </label>
                      )}
                    </div>

                    <div className="selectorInputCont">
                      <label>Facility Group</label>
                      <select>
                        <option value="" disabled selected>
                          Select Group
                        </option>
                        <option>Chennai Zone1</option>
                        <option>Chennai Zone 2</option>
                        <option>Bangalore</option>
                      </select>
                    </div>
                  </div>
                  {/* <div className="mapContainer">Map</div> */}
                  <div className="latAndLongInputsCont">
                    <div className="textInputsCont">
                      <label>Latitude</label>
                      <input
                        value={createFacilityObj.latitude}
                        onChange={(e) => setFacVal(e, "latitude")}
                        type="number"
                        style={{
                          border: `${
                            invalidArr.includes("validateLatitude")
                              ? "1px solid red"
                              : ""
                          }`,
                        }}
                        onBlur={() =>
                          checkName(
                            "validateLatitude",
                            createFacilityObj.latitude
                          )
                        }
                      />
                      {invalidArr.includes("validateLatitude") && (
                        <label style={{ color: "red", fontSize: "8px" }}>
                          {" "}
                          Enter Float Values Only*
                        </label>
                      )}
                    </div>

                    <div className="textInputsCont">
                      <label>Longitude</label>
                      <input
                        value={createFacilityObj.longitude}
                        onChange={(e) => setFacVal(e, "longitude")}
                        type="number"
                        style={{
                          border: `${
                            invalidArr.includes("validateLongitude")
                              ? "1px solid red"
                              : ""
                          }`,
                        }}
                        onBlur={() =>
                          checkName(
                            "validateLongitude",
                            createFacilityObj.longitude
                          )
                        }
                      />
                      {invalidArr.includes("validateLongitude") && (
                        <label style={{ color: "red", fontSize: "8px" }}>
                          {" "}
                          Enter Float Values Only*
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : activeTab == "attrib" ? (
              <div className="facilityAttributesCont">
                <div className="textInputsCont">
                  <label>Energy Provider</label>
                  <input type="text" />
                </div>

                <div className="unitOfEnergyInputCont">
                  <label>Cost per unit of energy</label>
                  <div className="selectAndInputCont">
                    <div className="imgAndSelectCont">
                      <select>
                        <option>INR (₹)</option>
                      </select>
                    </div>
                    <input type="number" />
                  </div>
                </div>

                <div className="textInputsCont">
                  <label>Energy Consumer No.</label>
                  <input type="number" />
                </div>

                <div className="selectorInputCont">
                  <label>Unit of energy</label>
                  <select>
                    <option value="" disabled selected>
                      Select Unit
                    </option>
                    <option>kWh</option>
                    <option>KVAH</option>
                  </select>
                </div>

                <div className="contractDemandInputCont">
                  <label>Contract Demand</label>
                  <div className="inputAndSelectCont">
                    <input type="number" />
                    <select>
                      <option>KVA</option>
                    </select>
                  </div>
                </div>

                <div className="selectorInputCont">
                  <label>Is DG present in the facility</label>
                  <select>
                    <option>No</option>
                  </select>
                </div>

                <div className="selectorInputCont">
                  <label>Is DG self owned?</label>
                  <select>
                    <option>No</option>
                  </select>
                </div>

                <div className="selectorInputCont">
                  <label>DG location</label>
                  <select>
                    <option></option>
                  </select>
                </div>
              </div>
            ) : activeTab == "users" ? (
              <div className="facilityUsersCont">
                <div className="facilityUsersAddBtnCont">
                  <button
                    className="facilityUsersAddBtn"
                    onClick={addFacilityRole}
                  >
                    <img src={AddIcon} alt="icon" />
                    Add
                  </button>
                </div>
                <div className="facilityUsersAndRoleContainer">
                  <div className="facilityUsersAndRoleHeaders">
                    <p>User</p>
                    <p>Role</p>
                  </div>
                  {mapUsertoFaclityArr.map((obj, index) => (
                    <div className="facilityUsersAndRoleSelectorsCont">
                      <select
                        className="facilityUsersAndRoleSelector"
                        onChange={(event) => handleUserSelection(event, index)}
                      >
                        <option value="" disabled selected>
                          Select User
                        </option>
                        {obj.facilityList.map((list) => (
                          <option key={list.partyId} value={list.partyId}>
                            {list.firstName}
                          </option>
                        ))}
                      </select>
                      <select
                        className="facilityUsersAndRoleSelector"
                        onChange={(event) => handleRoleSelection(event, index)}
                      >
                        <option value="" disabled selected>
                          Select Role
                        </option>
                        {obj.rolesList.map((list) => (
                          <option key={list.roleTypeId} value={list.roleTypeId}>
                            {list.description}
                          </option>
                        ))}
                      </select>
                      <img
                        src={deleteIcon}
                        alt="icon"
                        onClick={() => {
                          deleteAddFacilityRole(obj.id, index);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="createFacilityPopupFooter">
          <button
            className="facilityCancelBtn"
            onClick={() => closeAddFacility()}
          >
            Cancel
          </button>
          {activeTab !== "users" ? (
            <button
              className="facilityNextBtn"
              onClick={() =>
                onclickActivePopup(
                  activeTab == "basic"
                    ? "attrib"
                    : activeTab === "attrib"
                    ? "users"
                    : ""
                )
              }
            >
              Next
            </button>
          ) : (
            <button
              className="facilityCreateBtn"
              onClick={() => {
                createFacSettings();
                handleAddUser();
              }}
            >
              Create Facility
            </button>
          )}
        </Modal.Footer>
      </Modal>

      {/* Add Address Popup */}
      <Modal
        show={showAddAddress}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="createAddressPopup"
      >
        <Modal.Header className="createAddressHeaderCont">
          <h4 className="createAddressTxt">Add Address</h4>
          <div className="closeIconDiv" onClick={setShowAddAddressCancel}>
            <img src={IconClose} alt="icon" />
          </div>
        </Modal.Header>
        <Modal.Body className="createAddressBody">
          <div className="createAddressBodyCont">
            <div className="createAddressInputsCont">
              <div className="addressTextInputsCont">
                <label>To Name *</label>
                <input
                  type="text"
                  value={createFacilityObj.toName}
                  onChange={(e) => setFacVal(e, "toName")}
                  style={{
                    border: `${
                      invalidArr.includes("validateToName")
                        ? "1px solid red"
                        : ""
                    }`,
                  }}
                  onBlur={() =>
                    checkName("validateToName", createFacilityObj.toName)
                  }
                  data-tip="To Name can only contain letters (A-Z, a-z) and following special characters: ' and whitespace"
                  data-for="name-tooltip"
                />
                <ReactTooltip
                  id="name-tooltip"
                  effect="solid"
                  place="bottom"
                  className="tooltip"
                />
                {invalidArr.includes("validateToName") && (
                  <label style={{ color: "red", fontSize: "8px" }}>
                    {new FormValidator().validateValue(
                      createFacilityObj.toName,
                      /^[A-Za-z ']+$/,
                      100
                    )}
                  </label>
                )}
              </div>

              <div className="addressTextInputsCont">
                <label>Attn Name *</label>
                <input
                  type="text"
                  value={createFacilityObj.attnName}
                  onChange={(e) => setFacVal(e, "attnName")}
                  style={{
                    border: `${
                      invalidArr.includes("validateAttName")
                        ? "1px solid red"
                        : ""
                    }`,
                  }}
                  onBlur={() =>
                    checkName("validateAttName", createFacilityObj.attnName)
                  }
                  data-tip="Attn Name can only contain letters (A-Z, a-z) and following special characters: ' and whitespace"
                  data-for="name-tooltip"
                />
                <ReactTooltip
                  id="name-tooltip"
                  effect="solid"
                  place="bottom"
                  className="tooltip"
                />
                {invalidArr.includes("validateAttName") && (
                  <label style={{ color: "red", fontSize: "8px" }}>
                    {new FormValidator().validateValue(
                      createFacilityObj.attnName,
                      /^[A-Za-z ']+$/,
                      100
                    )}
                  </label>
                )}
              </div>

              <div className="addressTextInputsCont">
                <label>Address Line 1 *</label>
                <input
                  type="text"
                  value={createFacilityObj.address1}
                  style={{
                    border: `${
                      invalidArr.includes("address1") ? "1px solid red" : ""
                    }`,
                  }}
                  onChange={(e) => setFacVal(e, "address1")}
                  onBlur={() =>
                    checkName("address1", createFacilityObj.address1)
                  }
                  data-tip="Address Line 1 can only contain letters (A-Z, a-z), numbers (0-9) and following special characters: #, ., ', -, commas and whitespace"
                  data-for="name-tooltip"
                />
                <ReactTooltip
                  id="name-tooltip"
                  effect="solid"
                  place="bottom"
                  className="tooltip"
                />
                {invalidArr.includes("address1") && (
                  <label style={{ color: "red", fontSize: "8px" }}>
                    {new FormValidator().validateValue(
                      createFacilityObj.address1,
                      /^[\w\s,#-]+$/,
                      255
                    )}
                  </label>
                )}
              </div>

              <div className="addressTextInputsCont">
                <label>Address Line 2 *</label>
                <input
                  type="text"
                  style={{
                    border: `${
                      invalidArr.includes("address2") ? "1px solid red" : ""
                    }`,
                  }}
                  value={createFacilityObj.address2}
                  onChange={(e) => setFacVal(e, "address2")}
                  onBlur={() =>
                    checkName("address2", createFacilityObj.address2)
                  }
                  data-tip="Address Line 2 can only contain letters (A-Z, a-z), numbers (0-9) and following special characters: #, ., ', -, commas and whitespace"
                  data-for="name-tooltip"
                />
                <ReactTooltip
                  id="name-tooltip"
                  effect="solid"
                  place="bottom"
                  className="tooltip"
                />
                {invalidArr.includes("address2") && (
                  <label style={{ color: "red", fontSize: "8px" }}>
                    {new FormValidator().validateValue(
                      createFacilityObj.address2,
                      /^[\w\s,#-]+$/,
                      255
                    )}
                  </label>
                )}
              </div>

              <div className="addressTextInputsCont">
                <label>City *</label>
                <input
                  type="text"
                  style={{
                    border: `${
                      invalidArr.includes("city") ? "1px solid red" : ""
                    }`,
                  }}
                  value={createFacilityObj.city}
                  onChange={(e) => setFacVal(e, "city")}
                  onBlur={() => checkName("city", createFacilityObj.city)}
                  data-tip="City can only contain letters (A-Z, a-z) and following special characters: ' and whitespace"
                  data-for="name-tooltip"
                />
                <ReactTooltip
                  id="name-tooltip"
                  effect="solid"
                  place="bottom"
                  className="tooltip"
                />
                {invalidArr.includes("city") && (
                  <label style={{ color: "red", fontSize: "8px" }}>
                    {new FormValidator().validateValue(
                      createFacilityObj.city,
                      /^[A-Za-z ']+$/,
                      100
                    )}
                  </label>
                )}
              </div>

              <div className="addressSelectorCont">
                <label>State/Province *</label>
                <select
                  value={createFacilityObj.stateGeoCodeId}
                  onChange={(e) => setFacVal(e, "stateGeoCodeId")}
                >
                  {createStats?.countries
                    ?.filter((obj) => obj.geoName == "India")[0]
                    .states.map((stat) => (
                      <option key={stat.geoId} value={stat.geoId}>
                        {stat.geoName
                          .toLowerCase()
                          .split(" ")
                          .map((word) => {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          })
                          .join(" ")}
                      </option>
                    ))}
                </select>
              </div>

              <div className="addressTextInputsCont">
                <label>Postal Code *</label>
                <input
                  type="text"
                  value={createFacilityObj.postalCode}
                  onChange={(e) => setFacVal(e, "postalCode")}
                  style={{
                    border: `${
                      invalidArr.includes("validatePostalCode")
                        ? "1px solid red"
                        : ""
                    }`,
                  }}
                  onBlur={() =>
                    checkName(
                      "validatePostalCode",
                      createFacilityObj.postalCode
                    )
                  }
                />
                {invalidArr.includes("validatePostalCode") && (
                  <label style={{ color: "red", fontSize: "8px" }}>
                    {" "}
                    Enter only six digit code*
                  </label>
                )}
              </div>

              <div className="addressSelectorCont">
                <label>Country</label>
                <select>
                  <option>India</option>
                </select>
              </div>
            </div>

            {/* <div className="createAddressPurposeCont">
              {createStats?.contactPurposes?.POSTAL_ADDRESS?.map((ad) => (
                <div
                  onClick={() => contactPurpose(ad.contactMechPurposeTypeId)}
                  className="addressPurposeCont"
                  key={ad.contactMechPurposeTypeId}
                >
                  <input
                    type="checkbox"
                    checked={addressPurpose.current.includes(
                      ad.contactMechPurposeTypeId
                    )}
                  />
                  <label>{ad.description}</label>
                </div>
              ))}
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="createAddressFooter">
          <button className="cancelBtn" onClick={setShowAddAddressCancel}>
            Cancel
          </button>
          <button
            className="addAddressBtn"
            onClick={() => setShowAddAddress(false)}
          >
            Add Address
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FacilitySettings;
