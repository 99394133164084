import React from "react";

import "./FilterDataComponent.scss";

import FilterIcon from "../../assets/icons/FilterIcon.svg";

type FilterDataType = {
  id: number;
  value: string;
};

type FilterDataComponentType = {
  heading: string;
  filterData: FilterDataType[];
};

function FilterDataComponent({ heading, filterData }: FilterDataComponentType) {
  return (
    <div className="filterDataContainer">
      <div className="filterDataHeading">{heading}</div>
      <div className="filterImgHoverCont">
        <img src={FilterIcon} alt="filter icon" />
        <div className="filterCardHoverCont">
          {filterData.map((item: FilterDataType) => (
            <label key={item.id} className="filterInput">
              <input type="checkbox" />
              <span className="filterDataText">{item.value}</span>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FilterDataComponent;
