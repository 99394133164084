/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import "../../App_one.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowUp,
  faCircleArrowDown,
} from "@fortawesome/free-solid-svg-icons";

function TrendData(props) {
  return (
    <>
      <div>
        <div>
          <span>
            {props.data && props.data.variance && props.data.variance < 0 ? (
              <FontAwesomeIcon
                icon={faCircleArrowDown}
                color="#4CAF50"
                className="trendIcon"
              />
            ) : (
              <FontAwesomeIcon
                icon={faCircleArrowUp}
                color="#FF582C"
                className="trendIcon"
              />
            )}
          </span>
        </div>
        <div>
          {props.data?.variance < 0
            ? props.data?.variance * -1
            : props.data?.variance}

          {props.data?.abbreviation}
        </div>
      </div>
    </>
  );
}

export default TrendData;
