import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import '../DateRangeSelectorInput/DateRangeSelectorInput.scss';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DateFormat from '../DateFormatters/DateFormat';
import { ReportDatePickerData} from "../DataTypes";


const ReportDatePicker = ({ PlaceHolder,state,activeInput, field, title, value, updateDateRange }: ReportDatePickerData) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [calenderPopUpScreen, setCalenderPopupScreen] = useState(false);
  const dateFormatter = new DateFormat();

  const applyAction = () => {
      updateDateRange({
        endDate: "",
        startDate: selectedDate
      });
      setCalenderPopupScreen(false);
  };

  useEffect(() => updateDateRange({ startDate: selectedDate, endDate: ""}), []);

  const handleClick = () => {
    activeInput();
    setCalenderPopupScreen(true);
  }
  
  return (
    <div className="dateInputCardContainer">
      <h4 className="tempInputTitleText">{title}{field === "Y" ? " * " : ""}</h4>
      <div className="styleInp">
        <input type="text" onClick={handleClick} required={true}
          value={value.startDate && moment(value.startDate).format("DD/MM/YYYY")} className="inputTempTextValue" placeholder={PlaceHolder} />
        <div className='calenderIcon'>
          <FontAwesomeIcon onClick={handleClick} icon={faCalendar} />
        </div>

      </div>
      {calenderPopUpScreen && state ? (
        <div className="selectCustomDate">
          <Calendar
            date={selectedDate}
            maxDate={new Date()}
            minDate={dateFormatter.get365DaysAgo()}
            onChange={(date:any) => setSelectedDate(date)}
            showDateDisplay={false}
            showPreview={false}
          />
          <div className="bottonButtonsDiv">
            <button onClick={() => setCalenderPopupScreen(false)} className="cancelButton"> Cancel </button>
            {
              selectedDate ?
                <button onClick={applyAction} className="applyButton"> Apply </button> :
                <button className="applyButton applyButtonOpacity"> Apply </button>
            }
          </div>
        </div>
      ) : null}
    </div>
  );    
 };
  
  export default ReportDatePicker;
  
