import * as R from "ramda";
import moment from "moment";
import { useEffect, useState } from "react";
import temperatureIcon from "../../assets/image/temperature-icon.svg";
import "./style.scss";

function TempEnergy({
  data,
  abbre,
  minTemp,
  maxTemp,
  dailyMeters,
  energyUtil,
  lastCommTime
}: any) {
  const [kwhData, setKwhData] = useState<any>({});
  const [latestTime, setLatestTime] = useState<any>(null);

  useEffect(() => {
    !R.isEmpty(lastCommTime) && !R.isNil(lastCommTime) && lastCommTime !== null && setLatestTime(lastCommTime);
  }, [lastCommTime]);
  useEffect(() => {
    let kwhDataObj = dailyMeters.find((ele: any) => ele.meterTypeId === "KWH");


    if (R.isEmpty(kwhDataObj) || kwhDataObj === undefined) {
    }
    else {
      setKwhData(kwhDataObj);
      console.log("7Jersy", kwhDataObj);
    }

  }, [data]);

  // useEffect(() => {
  //   // console.log("TAG:: data::", data);
  //   // console.log("TAG:: abbre::", abbre);
  //   // console.log("TAG:: minTemp::", minTemp);
  //   // console.log("TAG:: maxTemp::", maxTemp);
  //   // console.log("TAG:: dailyMeters::", dailyMeters);
  //   // console.log("TAG:: energyUtil::", energyUtil);
  // }, [data, abbre, minTemp, maxTemp, dailyMeters, energyUtil]);

  // useEffect(() => {
  //   // console.log("data!!!", data);
  //   data.map((ele:any) => {
  //     ele.meterTypeId.includes("TEMP") && setLatestTime(ele.readingDate);
  //   });
  //   data.map((ele:any) => {
  //     ele.meterTypeId.includes("KWH") && setKwhData(ele);
  //   });
  // }, [data]);

  return (
    <>
      <div className="lastUpdated">
        {!R.isEmpty(latestTime) && !R.isNil(latestTime) ? (
          <h3>Updated {moment(latestTime).fromNow()}</h3>
        ) : (
          <h3>-</h3>
        )}
      </div>
      <div className="assetCard-colum">
        {/* <div className="assetCard-col">
          <p>Energy Consumed</p>
          {!R.isEmpty(kwhData) ? (
            <h2>
              {kwhData?.meterValue.toFixed(2)}{" "}
              <span>{kwhData?.readingUomDesc}</span>
            </h2>
          ) : (
            <h2></h2>
          )}
        </div>

        <div className="assetCard-col">
          <p>Asset Score</p>
          {!R.isEmpty(data) ? (
            data.map((el, index) => {
              el.meterTypeId.includes("Score") && (
                <h2 key={index}>
                  {el.meterValue}%{" "}
                  <span className={`assetCard-col-badge ${el.grade}`}>
                    {el.grade}
                  </span>
                </h2>
              );
            })
          ) : (
            <h2></h2>
          )}
        </div> */}

        {!R.isEmpty(data) ? (
          data.map(
            (el: any, index: any) =>
              el.meterTypeId.includes("TEMP") && (
                <div className="assetCard-col" key={index}>
                  <p>
                    <img src={temperatureIcon} /> Temperature
                  </p>
                  <h2
                    style={{
                      color: el.compliance === false ? "red" : "#000000",
                    }}
                  >
                    {Number(el?.meterValue).toFixed(2)}°{el.readingUomDesc}
                  </h2>
                  <h6>
                    Min {minTemp?.value}°{minTemp?.abbreviation} - Max{" "}
                    {maxTemp?.value}°{maxTemp?.abbreviation}
                  </h6>
                </div>
              )
          )
        ) : (
          <div className="assetCard-col">
            <p>
              <img src={temperatureIcon} /> Temperature
            </p>
            <h2></h2>
          </div>
        )}
        <div className="assetCard-col">
          <p>Energy Consumed</p>
          {
            R.isEmpty(kwhData) ? "" : <h2>
              {Number(kwhData.sum).toFixed(3)}
              <span>  {kwhData?.meterTypeId}</span>
            </h2>
          }

        </div>

        {/* <div className="assetCard-col">
          <p>Asset Score</p>
          {!R.isEmpty(data) ? (
            data.map((el, index) => {
              el.meterTypeId.includes("Score") && (
                <h2 key={index}>
                  {el.meterValue}%{" "}
                  <span className={`assetCard-col-badge ${el.grade}`}>
                    {el.grade}
                  </span>
                </h2>
              );
            })
          ) : (
            <h2></h2>
          )}
        </div> */}

        {/* <div className="assetCard-col">
          <p>Energy Utilization</p>
         
          {!R.isEmpty(energyUtil) && !R.isNil(energyUtil) && energyUtil ? (
            <h2>
              {typeof energyUtil?.value != "string"
                ? Number(energyUtil?.value).toFixed(2)
                : 0}
              {energyUtil.defaultUom}{" "}
             
            </h2>
          ) : (
            <h2></h2>
          )}
        </div> */}
      </div>
    </>
  );
}

export default TempEnergy;
