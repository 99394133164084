import * as R from "ramda";
import LocalStorageService from "./LocalStorageService";

export type AssetFilterDataType = {
  name: string;
  id: string;
  group: string;
  isSelected: boolean;
  isVisible: boolean;
  parentFixedAssetTypeId? : string;
};

export default class AssetFilterUtils {

  setApplyFilter = (
    filterList: Map<string, AssetFilterDataType[]>,
    title: string,
    id: string,
    isSelected: boolean
  ) => {
    const updatedFiltersMap = new Map(filterList);
    const specificObject = updatedFiltersMap
      .get(title)
      .find((item) => item.id === id);
    if (specificObject) {
      specificObject.isSelected = !isSelected;
      updatedFiltersMap.set(title, updatedFiltersMap.get(title));

      const selectedFilterList = updatedFiltersMap
        .get(title)
        ?.filter((item) => item.isSelected === true);
      if (selectedFilterList?.length === 0) {
        const tempSpecificObject = updatedFiltersMap
          .get(title)
          .find((item) => item.id === "All");
        if (tempSpecificObject) {
          tempSpecificObject.isSelected = !tempSpecificObject.isSelected;
          updatedFiltersMap.set(title, updatedFiltersMap.get(title));
        }
      }
    }
    const filterTitleObj = {
      status: "Status",
      assetType: "Asset Type",
      region: "Region",
      city: "City",
      cluster: "Cluster",
      facility: "Facility",
    };
    const obj = {
      filterData: updatedFiltersMap,
      filterTitles: filterTitleObj,
    };
    return obj;
  };

  setAssetTypeFilterData = () => {
    let responseString = localStorage.getItem("user_specific_asset_type_data");
    const response = JSON.parse(responseString);

    // For asset type list
    // const assets = response?.data?.assetTypes;
    const result: AssetFilterDataType[] = [];
    // result.push({
    //   name: "All",
    //   id: "All",
    //   group: "status",
    //   isSelected: true,
    //   isVisible: true,
    // });
    response?.forEach((element: any) => {
      result.push({
        id: element.fixedAssetTypeId,
        name: element.description
          ? element.description
          : element.fixedAssetTypeId,
        group: "status",
        isSelected: true,
        isVisible: true,
        parentFixedAssetTypeId: element.parentFixedAssetTypeId
      });
    });

    return result;
  };

  setStatusFilterData = () => {
    let responseString = localStorage.getItem("facility_statistics_cache");
    const response = JSON.parse(responseString);
    const assetStatus = response?.data?.statusTypes?.FXD_ASSET_STATUS;
    const result: AssetFilterDataType[] = [];
    // result.push({
    //   name: "All",
    //   id: "All",
    //   group: "status",
    //   isSelected: true,
    //   isVisible: true,
    // });
    assetStatus?.forEach((element: any) => {
      result.push({
        id: element.statusCode,
        name: element.description,
        group: element.description,
        isSelected: false,
        isVisible: true,
      });
    });
    return result;
  };

  setFacilityFilterData = () => {
    let localData: any = localStorage.getItem("facilityIds");
    const facilityList = JSON.parse(localData);
    const result: AssetFilterDataType[] = [];
    // result.push({
    //   name: "All",
    //   id: "All",
    //   group: "status",
    //   isSelected: true,
    //   isVisible: true,
    // });
    facilityList.forEach((element: any) => {
      result.push({
        id: element.facilityId,
        name: element.facilityName,
        group: element.facilityName,
        isSelected: false,
        isVisible: true,
      });
    });
    return result;
  };

  setFilterDataFromLocalStorage = (localStorageKey: string) => {
    let localData: any = localStorage.getItem(localStorageKey);
    const list = JSON.parse(localData);
    const result: AssetFilterDataType[] = [];
    // result.push({
    //   name: "All",
    //   id: "All",
    //   group: "status",
    //   isSelected: true,
    //   isVisible: true,
    // });
    list.forEach((element: any) => {
      result.push({
        id: element.facilityGroupId,
        name: element.facilityGroupName,
        group: element.facilityGroupName,
        isSelected: false,
        isVisible: true,
      });
    });
    return result;
  };


  public setCategoryFilterData() {

    const assetTypeList: { fixedAssetTypeId: string; description: string }[] = LocalStorageService.getUserSpecificAssetTypeData();


    return Array.from(new Set(assetTypeList?.map(type => type.parentFixedAssetTypeId)))
        .map(parentFixedAssetTypeId => {
          const firstMatch = assetTypeList.find(type => type.parentFixedAssetTypeId === parentFixedAssetTypeId);
          return {
            id : parentFixedAssetTypeId,
            name : firstMatch?.parentDescription,
            group:  parentFixedAssetTypeId,
            isSelected: false,
            isVisible: true
          };
        });

  }

  getAssetFilterData() {
    const assetTypeList = this.setAssetTypeFilterData();
    const statusList = this.setStatusFilterData();
    const facilityList = this.setFacilityFilterData();
    // const regionList = this.setFilterDataFromLocalStorage("regionIds");
    // const clusterList = this.setFilterDataFromLocalStorage("clusterIds");
    // const cityList = this.setFilterDataFromLocalStorage("cityIds");
    const assetCategoryList = this.setCategoryFilterData()

    const data = new Map<string, AssetFilterDataType[]>();
    data.set("Asset Type", assetTypeList);
    data.set("Status", statusList);
    data.set("Facility", facilityList);
    // data.set("Region", regionList);
    // data.set("Cluster", clusterList);
    // data.set("City", cityList);
    data.set("Asset Category", assetCategoryList);

    const filterTitleObj = {
      status: "statusList",
      assetType: "assetTypeList",
      region: "regionList",
      city: "cityList",
      cluster: "clusterList",
      facility: "facilityList",
      assetCategory: "assetCategoryList"
    };
    const obj = {
      filterData: data,
      filterTitles: filterTitleObj,
    };
    return obj;
  }

}
