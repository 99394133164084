import React from "react";
import "./style.scss";

type SwitchProps = {
  checked: boolean;
  id: string;
  label: string;
  onChange: Function;
  onToggle: Function;
  isAlignCenter: boolean;
  showGroupedData: boolean;
};

function Switch({ checked, id, label, onChange,  onToggle, isAlignCenter, showGroupedData }: SwitchProps) {
  return (
    <div className={`switchBtn ${showGroupedData ? 'grouped' : 'ungrouped'}`} style={{alignItems:isAlignCenter?'center':''}}>
      <h6>{label}</h6>
      <div className="switchBtn-group">
        <input
          type="checkbox"
          hidden="hidden"
          id={id}
          checked={checked}
          onChange={onChange}
          onToggle={ onToggle}
        />
        <label className="switchBtn-label" for={id}></label>
      </div>
    </div>
  );
}

export default Switch;
