import { useEffect, useRef } from "react";
import "./style.scss";
import moment from "moment";
import * as R from "ramda";
import { getCheckAbbrevationValue, getCheckNullValidation } from "../../Utils/utils";
import { compressorLiveCardObj, assetCardDataType } from "../../DataTypes/DataTypes"




function CompressorCard({ meters, lastCommTime}: assetCardDataType) {
  const suctionTemperature1 = useRef<compressorLiveCardObj>({value:"-", setPoint:'-', readingUomDesc:'-'});
  const suctionTemperature2 = useRef<compressorLiveCardObj>({value:"-", setPoint:'-', readingUomDesc:'-'});
  const condensationTemp1 = useRef<compressorLiveCardObj>({value:"-", setPoint:'-', readingUomDesc:'-'});
  const condensationTemp2 = useRef<compressorLiveCardObj>({value:"-", setPoint:'-', readingUomDesc:'-'});
  useEffect(() => {
    if(typeof meters === 'object' && meters.length > 0){
      for (let obj of meters){
          if(obj.meterTypeId === "SUC_TEMP_1") {
            suctionTemperature1.current = ({
                  ...suctionTemperature1.current,
                  value :  getCheckNullValidation(obj.meterValue),
              });
          }
          else if(obj.meterTypeId === "SUC_TEMP_1_SET_PT"){
            suctionTemperature1.current = ({
                  ...suctionTemperature1.current,
                  setPoint : getCheckNullValidation(obj.meterValue),
                  readingUomDesc: getCheckAbbrevationValue(obj.readingUomDesc)
              });
          }
          else if(obj.meterTypeId === "SUC_TEMP_2"){
            suctionTemperature2.current = ({
                  ...suctionTemperature2.current,
                  value : getCheckNullValidation(obj.meterValue),
              });
          }
          else if(obj.meterTypeId === "SUC_TEMP_2_SET_PT"){
            suctionTemperature2.current = ({
                  ...suctionTemperature2.current,
                  setPoint : getCheckNullValidation(obj.meterValue),
                  readingUomDesc: getCheckAbbrevationValue(obj.readingUomDesc)
              });
          }
          else if(obj.meterTypeId === "COND_TEMP_1"){
            condensationTemp1.current = ({
                  ...condensationTemp1.current,
                  value : getCheckNullValidation(obj.meterValue),
              });
          }
          else if(obj.meterTypeId === "COND_TEMP_1_SET_PT"){
            condensationTemp1.current = ({
                  ...condensationTemp1.current,
                  setPoint : getCheckNullValidation(obj.meterValue),
                  readingUomDesc: getCheckAbbrevationValue(obj.readingUomDesc)
              });
          }
          else if(obj.meterTypeId === "COND_TEMP_2"){
            condensationTemp2.current = ({
                  ...condensationTemp2.current,
                  value : getCheckNullValidation(obj.meterValue),
              });
          }
          else if(obj.meterTypeId === "COND_TEMP_2_SET_PT"){
            condensationTemp2.current = ({
                  ...condensationTemp2.current,
                  setPoint : getCheckNullValidation(obj.meterValue),
                  readingUomDesc: getCheckAbbrevationValue(obj.readingUomDesc)
              });
          }
      }
  }
  }, [meters]);

  return (
    <>
      <div className="lastUpdated">
      {!R.isEmpty(lastCommTime) && !R.isNil(lastCommTime) ? (
          <h3>Updated {lastCommTime === null ? "--" : moment(lastCommTime).fromNow()}</h3>
        ) : (
          <h3>&nbsp;</h3>
        )}
      </div>

      <div className="assetCard-colum">
        <div className="assetCard-col">
          <p>Suction Temperature 1</p>
            <h2 className="tempControllerH2">
              {suctionTemperature1.current.value} {suctionTemperature1.current.readingUomDesc}{" "}
            </h2>

          <h6 className="tempControllerH6">
            <b>Set Point</b>{" "}
            {suctionTemperature1.current.setPoint}
            <span>
              {suctionTemperature1.current.readingUomDesc}
            </span>
          </h6>
        </div>
        <div className="assetCard-col">
          <p>Suction Temperature 2</p>
            <h2 className="tempControllerH2">
              {suctionTemperature2.current.value} {suctionTemperature2.current.readingUomDesc}{" "}
            </h2>

          <h6 className="tempControllerH6">
            <b>Set Point</b>{" "}
            {suctionTemperature2.current.setPoint}
            <span>
              {suctionTemperature2.current.readingUomDesc}
            </span>
          </h6>
        </div>
        <div className="assetCard-col">
          <p>Condensation Temp 1</p>
            <h2 className="tempControllerH2">
              {condensationTemp1.current.value} {condensationTemp1.current.readingUomDesc}{" "}
            </h2>

          <h6 className="tempControllerH6">
            <b>Set Point</b>{" "}
            {condensationTemp1.current.setPoint}
            <span>
              {condensationTemp1.current.readingUomDesc}
            </span>
          </h6>
        </div>
        <div className="assetCard-col">
          <p>Condensation Temp 2</p>
            <h2 className="tempControllerH2">
              {condensationTemp2.current.value} {condensationTemp2.current.readingUomDesc}{" "}
            </h2>

          <h6 className="tempControllerH6">
            <b>Set Point</b>{" "}
            {condensationTemp2.current.setPoint}
            <span>
              {condensationTemp2.current.readingUomDesc}
            </span>
          </h6>
        </div>
      </div>
    </>
  );
}

export default CompressorCard