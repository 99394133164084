import { API_BASE_URL } from "../../Constants/constants";

export class AssetDetailsServices {
  async fetchNewLiveData(assetId, startDate, endDate, token, signal) {
    const response = await fetch(`${API_BASE_URL}fixedAsset/assetLiveData`, {
      method: "POST",
      body: JSON.stringify({
        "fixedAssetId": `${assetId}`,
        "fromDate": startDate,
        "thruDate": endDate
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      signal :signal
    });

    if (response.status === 200) {
      return await response.json();
    } else {
      return 'error';
    }
  }
  async getAssetListForAssetDetails(assetId) {
    const tokenID = localStorage.getItem("@tokenID");
    const url = `${API_BASE_URL}assets/assetList`;
    try {
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          isLowDataMode: false,
          isMonitoring: true,
          searchBy: [{ isSearch: true, searchKey: "fixedAssetId", searchValue: [`${assetId}`] }],
          viewIndex: 1,
          viewSize: 1,
          withAssetAttributes: true,
          withCardMeters: false,
          withLiveMeters: true
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenID}`,
        }
      });

      if (!resp.ok) {
        return 'error';
      }

      return await resp.json();
    } catch (error) {
      return error;
    }
  }
}