// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";
import LocalStorageService from "../Utils/LocalStorageService";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

// Messaging service
export const messaging = getMessaging(app);

export const requestForToken = async () => {
    const permission = await Notification.requestPermission();
    if (permission) {

//   navigator.serviceWorker.register("/firebase-messaging-sw.js");

        try {
    const currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_VAPID_KEY,
    });
    if (currentToken) {
      console.log("current token for client: ", currentToken);
      LocalStorageService.setFirebaseTokenData(currentToken);
    } else {
      // Show permission request UI
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
        }
    }else if (permission === "denied") {
        //notifications are blocked
        alert("You denied for the notification");
      }
};
