/* eslint-disable @typescript-eslint/no-unused-vars */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import React from "react";

import atsuyalogo from "../../assets/icons/atsuya-logo.svg";
import SingleBarChart from "../OperationalDashboardComp/SingleBarChart/SingleBarChart";

import "./TempComplianceOpps.scss";

function TempComplianceOpps({ data }) {
  return (
    <div className="TempComplianceOpps">
      {data &&
        data.map((ele, id) => {
          return (
            <div className="tempcompliance-container">
              <div className="areas-details">
                <div className="atsuya-logo">
                  <img src={atsuyalogo} alt="logo" />
                </div>
                <div>
                  <h6>
                    {ele.Client_Batch} - {ele.Asset_name}
                  </h6>
                  <p>
                    <FontAwesomeIcon icon={faLocationDot} /> {ele.locatedAT}
                  </p>
                </div>
              </div>
              <div className="batch-ids">
                <h6>{ele.Asset_Mac_Id}</h6>
              </div>
              <div className="percentage-number">
                <SingleBarChart />
              </div>
              <div className="temperature-values">
                <h6>(0-4'C)</h6>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default TempComplianceOpps;
