import { useEffect, useRef } from "react";
import "./style.scss";
import moment from "moment";
import * as R from "ramda";
import { getCheckAbbrevationValue, getCheckNullValidation } from "../../Utils/utils";
import { compressorLiveCardObj, assetCardDataType } from "../../DataTypes/DataTypes"

export const ACcontroller = ({ meters, lastCommTime, maxValue }: assetCardDataType) => {
    console.log("the ac-contoller value", meters, lastCommTime)
    const temperature = useRef<compressorLiveCardObj>({ value: "-", setPoint: '-', readingUomDesc: '-' });
    const suctionTemperature2 = useRef<compressorLiveCardObj>({ value: "-", setPoint: '-', readingUomDesc: '-' });
    const condensationTemp1 = useRef<compressorLiveCardObj>({ value: "-", setPoint: '-', readingUomDesc: '-' });
    const condensationTemp2 = useRef<compressorLiveCardObj>({ value: "-", setPoint: '-', readingUomDesc: '-' });
    useEffect(() => {
        if (typeof meters === 'object' && meters.length > 0) {
            for (let obj of meters) {
                if (obj.meterTypeId === "TEMP") {
                    temperature.current = ({
                        ...temperature.current,
                        value: getCheckNullValidation(obj.meterValue),
                        readingUomDesc: getCheckAbbrevationValue(obj.readingUomDesc)
                    });
                }
                else if (obj.meterTypeId === "COMP_STATUS") {
                    condensationTemp1.current = ({
                        ...condensationTemp1.current,
                        value: obj.meterValue,
                    });
                }
            }
        }
    }, [meters]);

    return (
        <>
            <div className="lastUpdated">
                {!R.isEmpty(lastCommTime) && !R.isNil(lastCommTime) ? (
                    <h3>Updated {lastCommTime === null ? "--" : moment(lastCommTime).fromNow()}</h3>
                ) : (
                    <h3>&nbsp;</h3>
                )}
            </div>

            <div className="assetCard-colum">
                <div className="assetCard-col">
                    <p>Temperature</p>
                    <h2 className="tempControllerH2">
                        {temperature.current.value} {temperature.current.readingUomDesc}{" "}
                    </h2>

                    <h6 className="tempControllerH6">
                        <b>Set Point </b>
                        {maxValue}
                        <span>
                            {temperature.current.readingUomDesc}
                        </span>
                    </h6>
                </div>
                {/* <div className="assetCard-col">
                    <p>Actual Set Point</p>
                    <h2 className="tempControllerH2">
                        {suctionTemperature2.current.value} {suctionTemperature2.current.readingUomDesc}{" "}
                    </h2>
                </div> */}
                <div className="assetCard-col">
                    <p>Load Status</p>
                    <h2 className="tempControllerH2">
                        {condensationTemp1.current.value === 'N' ? "OFF" : condensationTemp1.current.value === 'Y' ? "ON" : '-'}
                    </h2>
                </div>
            </div>
        </>
    );
}