import React from "react";
import "./DisplayError.scss";
// import errImg from "../../assets/image/error.png";
import errLottie from "../../assets/Lotties/errorLottie.json";
import errorLottie from "../../assets/Lotties/errorLottie.json";
import NoNetwork from "../../assets/Lotties/NoNetwork.json";
import Lottie from "react-lottie";
import somethingWrong from "../../assets/Lotties/Somethingwentwrong.json";
import Nodata from "../../assets/Lotties/Nodata.json";
import somewrongLotti from "../../assets/Lotties/somewrongLotti.json";

function DisplayError({ type }: any) {
  //err-empty
  //err-500/404
  //err-undefined

  // lottie errorLottie
  // lottie NoNetwork

  const defaulterrorLottie = {
    loop: true,
    autoplay: true,
    animationData: errorLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultSomethingWrong = {
    loop: true,
    autoplay: true,
    animationData: somewrongLotti,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultNodata = {
    loop: true,
    autoplay: true,
    animationData: Nodata,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions500 = {
    loop: true,
    autoplay: true,
    animationData: errorLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptionsNetwork = {
    loop: true,
    autoplay: true,
    animationData: NoNetwork,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="display-container">
      {type === "err_1" ? (
        <Lottie
          style={{ marginLeft: "0px", marginRight: "0px" }}
          options={defaulterrorLottie}
          height={50}
          width={50}
        />
      ) : type === "err-empty" || type === "err-undefined" ? (
        <Lottie
          style={{ marginLeft: "0px", marginRight: "0px" }}
          options={defaultNodata}
          height={50}
          width={50}
        />
      ) : type === "err-500/404" ? (
        <Lottie
          style={{ marginLeft: "0px", marginRight: "0px" }}
          options={defaultOptions500}
          height={50}
          width={50}
        />
      ) : (
        <Lottie
          style={{ marginLeft: "0px", marginRight: "10px" }}
          options={defaultSomethingWrong}
          height={55}
          width={50}
        />
      )}

      <li>
        {type === "err_1"
          ? "Oops! There was some error while fetching this data. Please refresh the page."
          : type === "err-empty" || type === "err-undefined"
          ? "No data available to display"
          : type === "err-500/404"
          ? "Sorry, we’re having trouble connecting to the server. Please try reloading the page."
          : "There was some error while processing the data. Please try again later."}
      </li>
    </div>
  );
}

export default DisplayError;
