/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "./ButtonView.scss"
import downArrow from "../Images/arrow-down.svg";
import upArrow from "../Images/arrow-up.svg";

const ButtonView = ({ status,text, callBackFun}) => {
  return (
    <div onClick={callBackFun} className="ButtonContainer">
      <div className="subContainer">
        {status ? (
          <img
            src={downArrow}
            alt="picture"
            className="down-arrow-image"
          />
        ) : (
          <img
            src={upArrow}
            alt="picture"
            className="down-arrow-image"
          />
        )}

        {
          status ? <p>{text}</p> : <p>View Incident Details</p>
        }
        
      </div>
    </div>
  );
};

export default ButtonView;
