/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "./index.scss";

function Loadmore({ showLoadMore, handleLoadMore, showLoadSpin }: any) {
  useEffect(() => {
    // console.log("loadMore -- showLoadSpin::", showLoadSpin);
    // console.log("loadMore -- showLoadMore::", showLoadMore);
  }, [showLoadSpin]);

  return (
    showLoadMore && (
      <button
        id="loadmoreBtn"
        className="btn btn-primary btn-one"
        onClick={handleLoadMore}
      >
        {!showLoadSpin && <>Load more...</>}
        {showLoadSpin && (
          <>
            <div className="d-flex justify-content-center">
              <div
                className="spinner-border"
                role="status"
                style={{ width: "1.3rem", height: " 1.3rem" }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </>
        )}
      </button>
    )
  );
}

export default Loadmore;
