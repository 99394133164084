import React from "react";
import "./DisplayNote.scss";

import searchLottie from "../../assets/Lotties/searchLottie.json";
import Lottie from "react-lottie";

function DisplayNote() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: searchLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="display-container">
      <Lottie
        style={{ marginLeft: "50px", marginRight: "-20px" }}
        options={defaultOptions}
        height={100}
        width={100}
      />
      <li>
        Asset behaviour being observed by our AI engines. We require more data
        to unlock this insight.
      </li>
    </div>
  );
}

export default DisplayNote;
