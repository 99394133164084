/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "./SopViolations.scss";
import ReactEcharts from "echarts-for-react";
import RepeatedSopCard from "../repeatedSopCard/RepeatedSopCard";
import SopCountImg from "../../../assets/icons/SopCountImg.svg";
import { VideoAnalyticsDBServices } from "../videoAnalyticsDbService/videoAnalyticsDbService";
import DisplayNote from "../../../a1Components/DisplayNote/DisplayNote";
import moment from "moment";
import DisplayError from "../../../a1Components/DisplayError/DisplayError";
import { Item } from "semantic-ui-react";
import axios from "axios";

function SopViolations(props: any) {
  const { dateRange } = props;
  const [responseData, setResponsedata] = useState([]);
  const [violationList, setViolationList] = useState([]);
  const [loading, setLoading] = useState(false);

  const violationSummarydbJSON = async () => {
    setLoading(true);
    try {
      const startDate = moment(dateRange[0]).utc().valueOf();
      const endDate = moment(dateRange[1]).utc().valueOf();
      const url = `https://hawkeye.aone.ai/analytics/videoAnalytics/violation/summary?start=${startDate}&end=${endDate}`;
      const response = await axios.get(url);
      if (response.status === 200) {
        setResponsedata(response.data.data);
      } else {
        setResponsedata([]);
      }
      setLoading(false);
    } catch (error) {
      setResponsedata([]);
      setLoading(false);
    }
  };

  const violationListdbJSON = async () => {
    setLoading(true);
    try {
      const startDate = moment(dateRange[0]).utc().valueOf();
      const endDate = moment(dateRange[1]).utc().valueOf();
      const url = `https://hawkeye.aone.ai/analytics/videoAnalytics/violation/list?start=${startDate}&end=${endDate}&page=0&size=5&direction=1`;
      const response = await axios.get(url);
      if (response.status === 200) {
        setViolationList(response.data.data);
      } else {
        setViolationList([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setViolationList([]);
    }
  };

  useEffect(() => {
    violationListdbJSON();
    violationSummarydbJSON();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      height: "90%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      axisLabel: {
        width: "10px",
      },
    },
    yAxis: {
      type: "category",
      data: [
        "Non-availability of fire extinguisher",
        " Issue with Barricade/Fencing",
        "Earthing Faults",
        " Unmanned decantation",
        " Open manholes",
        " Phone usage while decantation",
      ],
      axisLabel: {
        fontSize: "10px",
      },
    },
    series: [
      {
        name: "Direct",
        type: "bar",
        stack: "total",
        label: {
          show: true,
          position: "right",
        },
        emphasis: {
          focus: "series",
        },
        data: [1, 2, 3, 4, 5, 6],
      },
    ],
    color: "#FF7723",
  };

  return (
    <>
      {loading ? (
        <DisplayNote />
      ) : (
        <>
          <DisplayNote />
          {/* <div className="sopViolationsCont">
            <ul className="sopViolationsCountTxtCont">
            
              <li className="sopViolationsCountTxt">
                Total number of SOP Violations
              </li>
              <li className="sopViolationsCount">
                {23}
                <span className="sopAverageCountTxt">
                  <img
                    className="sopCountArrowImg"
                    src={SopCountImg}
                    alt="Increase"
                  />
                  4.24% more than yesterday
               
                </span>
              </li>
              {/* </>
                    );
                  })}
                </> */}
              {/* / )} 
            </ul>
            <div>
              <ReactEcharts option={option} />
            </div>
            <div className="repeatedSopCont">
              <p className="repeatedSopHeader">Repeated SOP Violations</p>
              <RepeatedSopCard />
              <RepeatedSopCard />
              <RepeatedSopCard />
            </div>
          </div> */}
        
        </>
      )}
    </>
  );
}

export default SopViolations;
