import React, { useEffect, useState } from 'react';
import Lottie from "react-lottie";
import loader from "../../a1Components/lotties/round-loader-white.json";
import "../LoginPage/LoginPageStyles.scss";
import { NavLink } from "react-router-dom";
import RadioButton from './RadioButton';
import { DashBoardServices } from '../../services/DasboardServices/DashboardService';
import { useStateValue } from '../../redux/StateProvider';



type dataType = {
  onSumbitData: () => void;
}

const PasswordOtpSelectionCard = ({ onSumbitData }: dataType) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const dbService = new DashBoardServices();
  const [{ forgotPassword }, dispatch] = useStateValue();
  type dataType = { userEmailId: string | null, userPhoneNumber: string | null }

  const [selctionOption, setSelctionOption] = useState("");
  const [liststatus, setListStatus] = useState(false);
  const [optionList, setOptionList] = useState<any>([]);
  const [userData, setUserData] = useState<dataType>();

  const submitOption = async () => {
    setLoading(true);
    seterrorMessage("");
    let selectedOption = selctionOption === "Email" ? userData?.userEmailId : userData?.userPhoneNumber;
    let obj: {
      userLoginId: string,
      contactString: any,
      contactType: string
    } = {
      userLoginId: forgotPassword.user,
      contactString: selectedOption,
      contactType: selctionOption === "SMS" ? "sms" : "email",
    }
    let fetchData: any = await dbService.generateOTP(obj);
    if (fetchData === "error") {
      seterrorMessage("Failed, please try again ");
    }
    else {
      let response = await fetchData.json();
      if (fetchData.status === 200) {
        let element = { ...forgotPassword, type: selctionOption === "SMS" ? "sms" : "email", selectedOption: selectedOption }
        dispatch({ type: "UPDATE_FORGOTPASSWORD_VARIABLES", forgotPassword: element });
        onSumbitData();
      } else {
        seterrorMessage(response.apiErrors[0]);
      }
    }
    setLoading(false);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
  };


  useEffect(() => {
    let list = forgotPassword?.userInfo;
    setUserData(list);
    if (list?.userEmailId === null && list?.userPhoneNumber === null) {
      setListStatus(false);
      setOptionList([]);
    }
    else if (list?.userEmailId === null && list?.userPhoneNumber !== null) {
      setOptionList(["SMS"]);
      setSelctionOption("SMS");
      setListStatus(true);
    }
    else if (list?.userPhoneNumber === null && list?.userEmailId !== null) {
      setOptionList(["Email"]);
      setSelctionOption("Email");
      setListStatus(true);
    }
    else {
      setOptionList(["Email", "SMS"]);
      setListStatus(true);
    }
  }, [])

  function transformEmail(value: any) {
    if (value === null) return ""
    else {
      let item = value.toString();
      const parts = item.split('@');
      if (parts.length === 2) {
        const [localPart, domain] = parts;
        const maskedLocalPart = localPart[0] + '...';
        const transformedEmail = maskedLocalPart + '@' + domain;
        return transformedEmail;
      } else {
        const transformedNumber = item.charAt(0) + item.charAt(1) + item.charAt(2) + item.charAt(3) + '.'.repeat(item.length - 2) + item.charAt(item.length - 2) + item.charAt(item.length - 1);
        return transformedNumber;
      }
    }
  }

  const checkOptionAvailability = (item: string) => {
    if(optionList.length !== 0) {
      if(optionList.includes(item)) setListStatus(true);
      else setListStatus(false);
      setSelctionOption(item);
    }else {
      setListStatus(false);
    }
  }


  return (
    <div className="subContent">
      <div className="loginContent">
        <h6>Reset Password</h6>
        <p>Receive OTP via</p>
        <div className='radioMainContainer'>
          {["Email", "SMS"].map((item: string, index: number) => {
            return <RadioButton onClickState={optionList.length === 0} key={index} value={selctionOption} tittle={item} onClickFunction={() => checkOptionAvailability(item)} />
          })}
        </div>
        {
          liststatus && optionList.length > 0 ?
            <div className='otpTextSelection' style={{ opacity: selctionOption ? 1 : 0 }}>
              <p>Send OTP to   {selctionOption === "Email" ? transformEmail(userData?.userEmailId) : transformEmail(userData?.userPhoneNumber)}</p>
            </div> :
            <div className='emailListContainerMsg'>
              <p>This recovery option is not available for this account. Please contact your administrator.</p>
            </div>
        }

        {selctionOption.length === 0 || !liststatus || userData?.userEmailId === null && userData.userPhoneNumber === null ? <button className="signInbuttonOpacity">Proceed</button> :
          <button onClick={submitOption} className="signInbutton">
            {loading ? <Lottie options={defaultOptions} /> : "Proceed"}
          </button>
        }
        <div className="errorMessageContainer"><p>{errorMessage}</p></div>
        <NavLink to={"/"}>
          <div className="redirectLoginDiv"><p>Back to Login</p></div>
        </NavLink>
      </div>
    </div>
  )
}

export default PasswordOtpSelectionCard