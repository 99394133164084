import React from "react";
import "./AssetCardMeterData.scss";
import ScrollingText from "../../../a1Components/scrollingText/ScrollingText";
import { Attribute, CardMeter, MinAndMaxDataType } from "../types";
import {
  checkIsAssetCompliance,
  commaSeperatedString,
  currentEpoch,
  formatMeterValue,
  getCardAbbreviation,
} from "../../../Utils/utils";
import TempDataScrolling from "../../../a1Components/scrollingText/TempDataScrolling";
import {
  getAssetAttributeData,
  showTempRangeData,
  getTooltipMessageData,
} from "../utils";
import ReactTooltip from "react-tooltip";

type PropTypes = {
  description: string;
  meterValue: number;
  fixedAssetAssocWithTypeId: string;
  minAndMaxData: MinAndMaxDataType;
  meterTypeId: string;
  readingUomDesc: string | undefined;
  cardMeterData: CardMeter;
  attributeList: Attribute[];
  name: string;
};

function AssetCardMeterData({
  description,
  meterValue,
  fixedAssetAssocWithTypeId,
  minAndMaxData,
  meterTypeId,
  readingUomDesc,
  cardMeterData,
  attributeList,
  name,
}: PropTypes) {
  const pointerValue =
    cardMeterData.hasOwnProperty("decimalPrecision") &&
    cardMeterData.decimalPrecision !== null
      ? cardMeterData.decimalPrecision
      : 2;
  const formatValue = formatMeterValue(meterValue, meterTypeId, pointerValue);
  const abbre = getCardAbbreviation(readingUomDesc);

  const attributeData = getAssetAttributeData(cardMeterData, attributeList);
  const rangeData = showTempRangeData(cardMeterData, attributeList, name);
  const tooltipMessageData = getTooltipMessageData(cardMeterData);

  return (
    <div className="cardMeterData">
      <ScrollingText
        classNames="meterDataHeading"
        data={description}
        spanClassNames=""
        spanData=""
        color=""
      />

      <div
        className="meterDataValueView"
        data-html={true}
        data-tip={tooltipMessageData}
        data-for={`individual-${name}-${formatValue}-${currentEpoch()}`}
      >
        <ScrollingText
          classNames={`meterDataValue ${
            checkIsAssetCompliance(
              meterValue,
              fixedAssetAssocWithTypeId,
              minAndMaxData.max,
              minAndMaxData.min,
              meterTypeId
            )
              ? "compliancetext"
              : "nonCompliancetext"
          }`}
          data={formatValue}
          spanClassNames="unitDescription"
          spanData={readingUomDesc}
          color={attributeData.color}
        />

        {rangeData.status &&
        (rangeData.max !== null || rangeData.min !== null) ? (
          <TempDataScrolling
            minValue={rangeData.min}
            unit={rangeData.unit}
            maxValue={rangeData.max}
          />
        ) : attributeData.showRange && !Number.isNaN(attributeData.rangeValue) ? (
            <>
              <div
                className={"limitValueClass"}
                data-html={true}
                data-tip={tooltipMessageData}
                data-for={`individual-${name}-${formatValue}-${currentEpoch()}`}
              >
                <p className="limitText">Limit {" "}</p>
                <ScrollingText
                classNames={"limitValue"}
                data={` ${commaSeperatedString(attributeData.rangeValue.toString())}`}
                spanClassNames={"limitValue"}
                spanData={readingUomDesc}
                color={""}              
              /> 
              </div>
          </>
        ) : (
          <></>
        )}
        {attributeData.showRange && (
        <ReactTooltip
          key={JSON.stringify(
            `individual-${name}-${formatValue}-${currentEpoch()}`
          )}
          id={`individual-${name}-${formatValue}-${currentEpoch()}`}
          effect="solid"
          place={"bottom" || "right" || "top" || "left"}
          className="custom-tooltip"
          />
        )}
      </div>
    </div>
  );
}

export default AssetCardMeterData;
