/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import "../../App_one.scss";

type CardProps = {
  icon: string;
  title: string;
  timeConsumed: string;
  trendVal: string;
};

function TileCard({ icon, title, timeConsumed, trendVal }: CardProps) {
  return (
    <div className="tileIcon-cmp">
      <div className="tileIcon-cmp-img">
        <img src={icon} alt="" className="" />
      </div>
      <div className="cmp-info" style={{ padding: "5px" }}>
        <h5>{title}</h5>
        <h3>
          <b>{timeConsumed} </b>
          {trendVal && (
            <p>
              <FontAwesomeIcon icon={faArrowDown} style={{ color: "#fff" }} />
              {" " + trendVal}
            </p>
          )}
        </h3>
      </div>
    </div>
  );
}

export default TileCard;
