import { useRef } from "react";

import AddIcon from "../../assets/icons/add_image.svg";
import ImageCloseIcon from "../../assets/icons/ImageClose.svg";

import "./ImageSelectorComponent.scss";

type ImageType = {
  image: string | null;
  setImage: (url: string | null) => void;
};

function ImageSelectorComponent({ image, setImage }: ImageType) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: any) => {
    const file: File = event.target.files[0];
    const imageURL = URL.createObjectURL(file);
    setImage(imageURL);
  };

  const cancelImage = () => {
    setImage(null);
  };

  return (
    <div className="file-input-container">
      {image ? (
        <>
          <img className="file-input-image" src={image} alt="Selected" />
          <button className="file-input-button" onClick={cancelImage}>
            <img className="cancel-image" src={ImageCloseIcon} />
          </button>
        </>
      ) : (
        <>
          <button className="file-input-button" onClick={handleClick}>
            {/* Company-logo css as .image */}
            <img className="image" src={AddIcon} />
          </button>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={handleFileChange}
          />
        </>
      )}
    </div>
  );
}

export default ImageSelectorComponent;
