import React from "react";
import "../../App_one.scss";
import Lottie from "react-lottie";
import dot_loader from "../lotties/dots-loader-black";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: dot_loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function ChatBot() {
  return (
    <>
      <div className="chatBot">
        <h6>Have Questions?</h6>
        <Lottie options={defaultOptions} height={30} width={30} />
      </div>
    </>
  );
}

export default ChatBot;
