/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts";
import * as R from "ramda";
import axios from "axios";

var option;
function StackedBarOperational({
  yAxisData,
  col1Data,
  col2Data,
  col3Data,
  data,
  legendBottom,
  legendLeft,
}) {
  const [yourAssetData, setYourAssetData] = useState({});
  useEffect(() => {
    const data = [
      {
        asset: "West",
        utilization: "75%",
        savings: "41kwh",
        stacks: ["EB", "DG"],
        stackData: [232, 232],
      },
      {
        asset: "asset-2",
        utilization: "73%",
        savings: "42kwh",
        stacks: ["EB", "DG"],
        stackData: [231, 231],
      },
      {
        asset: "asset-3",
        utilization: "69%",
        savings: "43kwh",
        stacks: ["EB", "DG", "Solar"],
        stackData: [231, 231, 102],
      },
    ];

    setYourAssetData(data);
    async function getUser() {
      try {
        const response = await axios.get(
          "https://snakeoil-api.atsuyatech.com/utilisation"
        );
        setYourAssetData(response?.data?.yourAssetsDB);
      } catch (error) {
        // console.error(error, "axios");
      }
    }
  }, []);

  const series = [
    {
      name: "West",
      type: "bar",
      stack: "total",
      label: {
        show: true,
      },
      emphasis: {
        focus: "series",
      },

      stacks: ["EB", "DG"],
      stackData: [23, 22],
    },
    {
      name: "",
      type: "bar",
      stack: "total",
      label: {
        show: true,
      },
      emphasis: {
        focus: "series",
      },
      data: col2Data,
    },
    {
      name: "",
      type: "bar",
      stack: "total",
      label: {
        show: true,
      },
      emphasis: {
        focus: "series",
      },
      data: col3Data,
    },
  ];
  option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    color: ["#8481FC", "#F29052", "#3BCE7A"],
    legend: {
      bottom: legendBottom,
      itemGap: 10,
      itemWidth: 10,
      itemHeight: 10,
      left: legendLeft,
    },
    height: 220,
    width: "100%",
    textStyle: {
      fontFamily: "Inter",
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: "400",
      color: "#182F43",
    },
    grid: {
      left: "1%",
      right: "0%",
      top: "1%",
      bottom: "1%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      show: false,
      axisTick: { show: false },
      splitLine: { show: false },
    },
    yAxis: {
      type: "category",
      axisTick: { show: false },
      axisLine: { show: false },
      axisLabel: {
        margin: 17,
        fontSize: 14,
      },
      inverse: "true",
      data: yAxisData,
    },
    series: series,
  };

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={option}
      notMerge={true}
      lazyUpdate={true}
      theme={"theme_name"}
    />
  );
}

export default StackedBarOperational;
