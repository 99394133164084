import React from "react";
import "./AHUStatus.scss";
import StatusOnAHU from "../assets/ic_ahu_on.svg"
import StatusOffAHU from "../assets/ic_ahu_off.svg"

type AHUStatusProps = {
    //this should have been only a boolean
    //since the API doesn't have a defined type for meterValue in meters
    //we are accepting sting, number & undefined
    //added a check for boolean - returning null otherwise
    statusType: string | number | boolean | undefined;
}
export const AhuStatus: React.FC<AHUStatusProps> = (
    {statusType}
) => {

    if (typeof statusType !== 'boolean') {
        return null;
    }
    return (
        <div className={`AHU-status ${statusType} AHU-status-instance`}>
            <img
                className="group"
                alt="status"
                src={statusType ? StatusOnAHU : StatusOffAHU}
            />
            <div className="statusText">
                {statusType ? <>ON</> : <>OFF</>}
            </div>
        </div>
    );
};
