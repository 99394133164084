import React from "react";
import "./FilterTickets.scss";
import ActivitySubAlert from "../activitySubAlerts";
import "react-toastify/dist/ReactToastify.css";

function FilterTicketListPopup({ data, type, callBack }: any) {
  return (
    <div>
      <div>
        {data.map((ele: any, id: any) => {
          return (
            <ActivitySubAlert
              name={ele.facilityName}
              callBackalertPopUp={callBack}
              value={ele}
              type={ele.metadata}
              createdTime={ele.createdDate}
              lastUpdated={ele.createdDate}
              assetName={ele.fixedAssetName}
              alertDes={ele.alertDesc}
              alertTypeDesc={ele.alertTypeDesc}
              id={id}
              escalationLevel={ele.escalationLevel}
            />
          );
        })}
      </div>
    </div>
  );
}

export default FilterTicketListPopup;
