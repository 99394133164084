/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import powerIcon from "../../assets/image/power-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import "./index.scss";
import { keyFormat } from "../../Utils";

function TileTypeOne({ data }) {
  let entryData = Object.entries(data);
  return (
    <div className="tileTypeOne">
      <div className="tileTypeOne-row">
        <div className="tileTypeOne-img">
          <img src={powerIcon} className="cursPointer" />
        </div>
        <div>
          <p className={data.context === "NEGATIVE" ? "less" : "more"}>
            <FontAwesomeIcon
              icon={data.context === "NEGATIVE" ? faArrowDown : faArrowUp}
            />
            {data.variance}
          </p>
        </div>
      </div>
    </div>
  );
}

export default TileTypeOne;
