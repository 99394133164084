/* eslint-disable react/jsx-no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// //@ts-nocheck

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { element } from "prop-types";
import React, { useEffect } from "react";
import useState from "react-usestateref";
import "./Ticketstable.scss";
import NoWrapCard from "../NoWrapCard/NoWrapCard";
import { Carousel } from "react-responsive-carousel";
import TicketsTotal from "../TicketsTotalCard/TicketsTotal";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

function TicketsTable({
  currGeoDist, // region/city/cluster/facility
  data,
  onRowClick,
  onBackPressed,
  showBackButton,
  breadCrumb,
}: any) {
  const [locStates, setLocStates, locStatesRef] = useState({
    data: null, //  Table data
    currGeoDist: null, // region/city/cluster/facility
    showBackButton: false,
    breadCrumb: [],
  });

  const [tableData, setTableData] = useState([]);

  const onBackPressedLoc = () => {
    onBackPressed();
  };

  const onRowClickLoc = (e: any, indexRow: any) => {
    e.preventDefault();
    e.stopPropagation();

    const locStatesObj = Object.assign({}, locStatesRef.current);
    const geoName = locStatesObj.data.tableTitle[indexRow];

    // console.log("locStatesObj::", locStatesObj);
    // console.log("geoName::", geoName);
    onRowClick(locStatesObj.currGeoDist, geoName);
  };

  function reorgTable(tableData: any) {
    // console.log("tableData is::", tableData);

    tableData = tableData.map((rowData: any) => {
      return [0, 0, rowData[1], 0];
    });

    return tableData;
  }

  useEffect(() => {
    const currLocStatesObj = Object.assign({}, locStatesRef.current);

    // console.log("currLocStatesObj::", currLocStatesObj);
    currLocStatesObj.currGeoDist = currGeoDist;
    currLocStatesObj.data = data;

    currLocStatesObj.showBackButton = showBackButton;
    currLocStatesObj.breadCrumb = breadCrumb;

    // console.log(
    //   "data:: tickets - table",
    //   data,
    //   onRowClick,
    //   onBackPressed,
    //   showBackButton,
    //   breadCrumb,
    //   currGeoDist
    // );

    setLocStates(currLocStatesObj);

    const tableTitle = data.tableTitle;
    let tableDataPartial = data.tableData;

    tableDataPartial = reorgTable(tableDataPartial);

    const tableDataTotal = tableTitle.map((geoName, i) => [
      geoName,
      ...tableDataPartial[i],
    ]);

    setTableData(tableDataTotal);
  }, [currGeoDist, data, showBackButton, breadCrumb]);

  return (
    <>
      {locStates.data !== null &&
        locStates.data !== "loading" &&
        locStates.data !== "err" && (
          <Carousel showStatus={false} emulateTouch={true}>
            {locStates.data.carouselData.map((ele:any, id:any) => {
              return <TicketsTotal data={ele} />;
            })}
          </Carousel>
        )}

      <div className="ticketstable">
        {locStates.breadCrumb?.currGeoDist !== "facility" &&
          locStates.showBackButton && (
            <button className="backButton" onClick={() => onBackPressedLoc()}>
              {locStates.breadCrumb[locStates.breadCrumb.length - 1].geoName ? (
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  className="viewall-icon normal"
                />
              ) : (
                ""
              )}
              {locStates.breadCrumb[locStates.breadCrumb.length - 1].geoName}
            </button>
          )}

        {locStates.data !== null &&
          locStates.data !== "loading" &&
          locStates.data !== "err" && (
            <table id="tableStyle" style={{ margin: "10px 0px 10px 0px" }}>
              <thead>
                <tr>
                  {locStates.data.tableHead.map((ele:any, id:any) => {
                    return (
                      <th key={id} scope="col">
                        {ele}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {tableData.map((rowData, indexRow) => {
                  return (
                    <>
                      <div className="divBorder">
                        <div
                          className="mobileHeader"
                          onClick={(e) => onRowClickLoc(e, indexRow)}
                        >
                          {locStates.data.tableTitle[indexRow]}
                          <h3>
                            {locStates.data.tableData[indexRow][4]} Closed
                          </h3>
                        </div>

                        <div className="ticketStatus">
                          <h3>Open</h3>
                          <h3>{locStates.data.tableData[indexRow][3]}</h3>
                        </div>
                        <tr onClick={(e) => onRowClickLoc(e, indexRow)}>
                          {rowData.map((rowItem:any, indexItem:any) => {
                            // console.log(
                            //   "::(()",
                            //   rowItem,
                            //   indexItem,
                            //   "*()",
                            //   locStates.data
                            // );
                            return (
                              <>
                                <td className="headerMobile">
                                  <h3>
                                    {" "}
                                    {locStates.data.tableHead[indexItem]}
                                  </h3>

                                  {typeof rowItem === "string" ? (
                                    <>
                                      <td
                                        onClick={(e) =>
                                          onRowClickLoc(e, indexRow)
                                        }
                                      >
                                        {rowItem}
                                      </td>
                                    </>
                                  ) : (
                                    <td
                                      onClick={(e) =>
                                        onRowClickLoc(e, indexRow)
                                      }
                                      className=" tdStyleScssEng"
                                    >
                                      <div
                                        key={indexItem}
                                        className="mobileViewStyle"
                                      >
                                        <button
                                          className="nonCompliance navClickStyles"
                                          onClick={() =>
                                            console.log(
                                              "the clicked value is",
                                              {
                                                rowItem,
                                              }
                                            )
                                          }
                                        >
                                          {/* {console.log(
                                            indexItem,
                                            indexRow,
                                            "screw"
                                          )} */}
                                          {indexItem !== 4 &&
                                            locStates.data.tableData[indexRow][
                                              indexItem - 1
                                            ]}
                                          {indexItem === 4 &&
                                            locStates.data.tableData[
                                              indexRow
                                            ][3] -
                                              (locStates.data.tableData[
                                                indexRow
                                              ][2] +
                                                locStates.data.tableData[
                                                  indexRow
                                                ][0] +
                                                locStates.data.tableData[
                                                  indexRow
                                                ][1])}
                                        </button>
                                      </div>
                                    </td>
                                  )}
                                </td>
                              </>
                            );
                          })}
                        </tr>
                      </div>

                      {/* =======
                      <div className="divBorder">
                        <div
                          className="mobileHeader"
                          onClick={(e) => onRowClickLoc(e, indexRow)}
                        >
                          {locStates.data.tableTitle[indexRow]}
                        </div>
                        <tr onClick={(e) => onRowClickLoc(e, indexRow)}>
                          {rowData.map((rowItem, indexItem) => {
                            return (
                              <td className="headerMobile">
                                <h3> {locStates.data.tableHead[indexItem]}</h3>

                                {typeof rowItem === "string" ? (
                                  <>
                                    <td
                                      onClick={(e) =>
                                        onRowClickLoc(e, indexRow)
                                      }
                                    >
                                      {rowItem}
                                    </td>
                                  </>
                                ) : (
                                  <td
                                    onClick={(e) => onRowClickLoc(e, indexRow)}
                                    className=" tdStyleScssEng"
                                  >
                                    <div
                                      key={indexItem}
                                      className="mobileViewStyle"
                                    >
                                      <button
                                        className="nonCompliance navClickStyles"
                                        onClick={() =>
                                          console.log("the clicked value is", {
                                            rowItem,
                                          })
                                        }
                                      >
                                        {rowItem
                                          .toFixed(2)
                                          .replace(/[.,]00$/, "")}
                                      </button>
                                    </div>
                                  </td>
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      </div> */}
                    </>
                  );
                })}
              </tbody>
            </table>
          )}
      </div>
      {/* <Carousel showStatus={false} emulateTouch={true}> emulateTouch={true}>
        <NoWrapCard />
        <NoWrapCard />
        <NoWrapCard />
      </Carousel> */}
    </>
  );
}

export default TicketsTable;
