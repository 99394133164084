/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/alt-text */
import moment from "moment";
import * as R from "ramda";
import { useState, useEffect } from "react";
import "./style.scss";

function Pressure({
    data,
    abbre,
    minTemp,
    maxTemp,
    dailyMeters,
    energyUtil,
    lastCommTime,
}: any) {
    const [latestTime, setLatestTime] = useState();
    useEffect(() => {
        !R.isEmpty(lastCommTime) &&
            !R.isNil(lastCommTime) &&
            lastCommTime !== null &&
            setLatestTime(lastCommTime);
    }, [lastCommTime]);

    const [pvPressure, setPvPressure] = useState();
    useEffect(() => {
        console.log("data", data)
        let pressure = data.find(
            (obj: { meterTypeId: string }) => obj.meterTypeId === "PRESSURE"
        );
        setPvPressure(pressure);

    }, [data]);



    return (
        <>
            <div className="lastUpdated">
                {!R.isEmpty(latestTime) && !R.isNil(latestTime) ? (
                    <h3>Updated {moment(latestTime).fromNow()}</h3>
                ) : (
                    <h3></h3>
                )}
            </div>
            <div className="assetCard-colum">
                <div className="assetCard-col">
                    <p>Pressure</p>
                    {!R.isEmpty(pvPressure?.meterValue) &&
                        !R.isNil(pvPressure?.meterValue) &&
                        pvPressure?.meterValue !== undefined ? (
                        <h2 className="tempControllerH2">
                            {pvPressure?.meterValue.toFixed(2)} {pvPressure?.readingUomDesc}{" "}
                        </h2>
                    ) : (
                        "--"
                    )}
                </div>


            </div>
        </>
    );
}

export default Pressure;
