import * as R from "ramda";
import DisplayError from "../DisplayError/DisplayError";
import "./AnoviEventSummary.scss";
import DefrostCycleTable from "./DefrostCycleTable";
import CompressorTable from "./CompressorTable";
import DoorOpenTable from "./DoorOpenTable";
import { useEffect, useState } from "react";

function AnoviEventSummary({data, type}:any) {

  const [activeData,setActiveData] = useState(data)
  console.log("anoviData",activeData)

  useEffect(() => {
  if(data !== undefined){
    setActiveData(data)
  }
  }, [data])
  

  return (
    <>
      {activeData === "error" ? (
        <DisplayError type={"err_1"} />
      ) : typeof activeData === "number" ? (
        <DisplayError type={"err-500/404"} />
      ) : typeof activeData === "object" ? (
        activeData.length == 0 ? (
          <DisplayError type={"err-empty"} />
        ) : type === "DEFROST_CYCLE" ? (
          <DefrostCycleTable data={activeData}/>
        ) : type === "CMPRSR_ON,CMPRSR_OFF" ? (
          <CompressorTable data={activeData}/>
        ) : (
          <DoorOpenTable data={activeData}/>
        )
      ) : (
        <DisplayError />
      )}
    </>
  );
}

export default AnoviEventSummary;
