/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import * as R from "ramda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import energyAssetIcon from "../../assets/image/energy-asset-icon.svg";
import lpgAssetIcon from "../../assets/image/gas-asset-icon.svg";
import waterAsseticon from "../../assets/image/water-asset-icon.svg";
import repeatedIcon from "../../assets/image/repeated-alerts-icon.svg";
import activitywatchlisticon from "../../assets/image/watchlist-icon.svg";
import activityalertsicon from "../../assets/image/alerts-icon.svg";
import temperatureIcon from "../../assets/image/temperature-icon.svg";
import energytempicon from "../../assets/image/energy-temp-icon.png";
import dgicon from "../../assets/image/dg-icon.png";
import assetcard_img from "../../assets/icons/icon-info-assetcard.svg";
import AirAssetIcon from "../../assets/icons/AirAssetIcon.svg";
import WaterPulseIcon from "../../assets/icons/WaterPulseIcon.svg";
// import waterAsseticon from "../../assets/icons/waterAsseticon.svg"
import fuelAsseticon from "../../assets/icons/fuelAsseticon.svg";
import iconairquality from "../../assets/icons/iconairquality.svg";
import TempControllerIcon from "../../assets/icons/TempControllerIcon.svg";
import temperatureCompressor from "../../assets/icons/Asset-Icon-compressor.svg";
import pressureMonitoring from '../../assets/icons/icon-assets-pressure.svg';
import "./style.scss";
import Temp from "./Temp";
import TempEnergy from "./TempEnergy";
import DG from "./DG";
import Energy from "./Energy";
import { useStateValue } from "../../redux/StateProvider";
import { actionTypes } from "../../redux/reducer";
import moment from "moment";
import AIR from "./AIR";
import Water from "./Water";
import Fuel from "./Fuel";
import IAQ from "./IAQ";
import TempController from "./TempController";
import CompressorCard from "./CompressorCard";
import Pressure from "./Pressure";
import { AirControllerCard } from "./AirControllerCard";
import airControllerAsset from '../../assets/icons/air-controller-asset.svg';
import { ACcontroller } from "./ACcontroller";
import WaterPulse from "./WaterPulse";

function AssetCard(props: any) {
  const [score, setScore] = useState("");
  const [assetIcon, setAssetIcon] = useState(energyAssetIcon);
  const [assetTypeGrid, setAssetTypeGrid] = useState(
    <Energy
      data={props.data.meters}
      abbre={props.data}
      dailyMeters={props.data.dailyMeters}
      monthlyMeters={props?.data?.monthlyMeters}
    />
  );

  const [statusDG, setStatusDG] = useState();
  const [loadDG, setLoadDG] = useState();

  const [{ }, dispatch] = useStateValue();

  const location = useLocation();
  const [locateAsset, setLocateAsset] = useState([]);
  let grids;
  const navigate = useNavigate();
  const handleOnClick = useCallback(
    () => navigate(`${currPath}${props.data.assetName}`, { replace: true }),
    [navigate]
  );
  const handleData = () => {
    localStorage.setItem("assetId", props.data.assetId);
    dispatch({
      type: actionTypes.SET_CURRENT_ASSET_ID,
      currAssetId: props.data.assetId,
    });
    //to reduce the load in the local storage
    let dataTypeCheck = {
      fixedAssetAssocWithTypeDesc: props.data.fixedAssetAssocWithTypeDesc,
      assetId: props.data.assetId,
      fixedAssetAssocWithTypeId: props.data.fixedAssetAssocWithTypeId,
      assetName: props.data.assetName,
      assetTypeDesc: props.data.assetTypeDesc,
      data: props.data,
    };
    localStorage.setItem("selectedAsset", JSON.stringify(dataTypeCheck));
    localStorage.setItem("singleAssetCardDetails", JSON.stringify(locateAsset));
    navigate(`/asset-list/${props.data.assetName}`);
  };
  useEffect(() => {
    //  localStorage.setItem("myassets", JSON.stringify(props.data))
    if (!R.isEmpty(props.data.meters)) {
      props.data.meters.map((el: any) => {
        if (el?.meterTypeId.includes("Score")) {
          setScore(el.grade);
        }
      });
    }

    switch (props.data?.fixedAssetAssocWithTypeId) {
      case "TEMP":
        setAssetIcon(temperatureIcon);
        grids = (
          <Temp
            dailyMeters={props?.data?.dailyMeters}
            data={props.data.meters}
            abbre={props.data}
          />
        );
        break;
      case "TEMP_ENERGY":
        setAssetIcon(energytempicon);
        grids = (
          <TempEnergy
            dailyMeters={props?.data?.dailyMeters}
            data={props.data.meters}
            abbre={props.data}
          />
        );
        break;
      case "DG":
        setAssetIcon(dgicon);
        grids = (
          <DG
            dailyMeters={props?.data?.dailyMeters}
            data={props.data.meters}
            abbre={props.data}
          />
        );
        break;
      //assetType is AIR
      case "AIR":
        setAssetIcon(AirAssetIcon);
        grids = (
          <AIR
            dailyMeters={props?.data?.dailyMeters}
            data={props.data.meters}
            abbre={props.data}
          />
        );
        break;
      
      // assetType is water pulse
      case "WATER_PULSE":
        setAssetIcon(WaterPulseIcon);
        grids = (
          <WaterPulse
            dailyMeters={props?.data?.dailyMeters}
            data={props.data.meters}
            abbre={props.data}
          />
        );
        break;

      //assetType is water
      case "WATER":
        setAssetIcon(waterAsseticon);
        grids = (
          <Water
            dailyMeters={props?.data?.dailyMeters}
            data={props.data.meters}
            abbre={props.data}
          />
        );
        break;
      //assetType is fuel
      case "FUEL":
        setAssetIcon(fuelAsseticon);
        grids = (
          <Fuel
            dailyMeters={props?.data?.dailyMeters}
            data={props.data.meters}
            abbre={props.data}
          />
        );
        break;

      //assetType is fuel
      case "IAQ":
        setAssetIcon(iconairquality);
        grids = (
          <IAQ
            dailyMeters={props?.data?.dailyMeters}
            data={props.data.meters}
            abbre={props.data}
          />
        );
        break;

      //TempController
      case "TEMPCONTROLLER":
        setAssetIcon(TempControllerIcon);
        grids = (
          <TempController
            dailyMeters={props?.data?.dailyMeters}
            data={props.data.meters}
            abbre={props.data}
          />
        );
        break;
      case "PRESSURE":
        setAssetIcon(pressureMonitoring);
        grids = (
          <Pressure
            dailyMeters={props?.data?.dailyMeters}
            data={props.data.meters}
            abbre={props.data}
          />
        );
        break;

      default:
        setAssetIcon(energyAssetIcon);
        grids = (
          <Energy
            dailyMeters={props?.data?.dailyMeters}
            data={props.data.meters}
            abbre={props.data}
            monthlyMeters={props?.data?.monthlyMeters}
          />
        );
    }

    if (props.data?.fixedAssetAssocWithTypeId === "DG") {
      props.data?.meters.map((ele: any) => {
        ele.meterTypeId.includes("STATUS_DG") && setStatusDG(ele.meterValue);
      });
      props.data?.meters.map((ele: any) => {
        ele.meterTypeId.includes("LOAD_DG") && setLoadDG(ele.meterValue);
      });
    }
  }, [props]);

  useEffect(() => {
    let localData: any = localStorage.getItem("facilityIds");
    const facilityIds = JSON.parse(localData);
    const dataByFacility = facilityIds.filter(
      (el: any) => el.facilityId === props.data.locatedAtFacility.facilityId
    );
    setLocateAsset(dataByFacility);
  }, [props.data]);

  const currPath =
    location.pathname === "/"
      ? "asset-list" + location.pathname
      : location.pathname + "/";
  return (
    <div
      // reloadDocument
      // to={`/asset-list/${props.data.assetName}`}
      onClick={handleData}
      style={{ textDecoration: "none" }}
      className={props.data?.status === "FA_INACTIVE" && "disable"}
    >
      <div
        className={`assetCard ${score}`}
        style={
          props.data?.status === "FA_OFFLINE"
            ? { borderBottom: "4px solid #F44336" }
            : props.data?.status === "FA_IN_ACTIVE"
              ? { borderBottom: "4px solid #F44747" }
              : props.data?.status === "FA_SLOW"
                ? { borderBottom: "4px solid #FFC107" }
                : props.data?.status === "FA_LOST"
                  ? { borderBottom: "4px solid #DF4320" }
                  : props.data?.status === "FA_MAINT"
                    ? { borderBottom: "4px solid #E9BE03" }
                    : props.data?.status === "FA_ACTIVE"
                      ? { borderBottom: "4px solid #5AA15D" }
                      : { borderBottom: "4px solid #4CAF50" }
        }
      >
        <div className="assetCard-header">
          <section>
            {props.data?.fixedAssetAssocWithTypeId === "TEMP" ? (
              <>
                <img src={temperatureIcon} />
              </>
            ) : props.data?.fixedAssetAssocWithTypeId === "TEMP_ENERGY" ? (
              <>
                <img src={energytempicon} />
              </>
            ) : props.data?.fixedAssetAssocWithTypeId === "AIR" ? (
              <>
                <img src={AirAssetIcon} />
              </>
            ) : props.data?.fixedAssetAssocWithTypeId === "WATER" ? (
              <>
                <img src={waterAsseticon} />
              </>
            ) : props.data?.fixedAssetAssocWithTypeId === "FUEL" ? (
              <>
                <img src={fuelAsseticon} />
              </>
            ) : props.data?.fixedAssetAssocWithTypeId === "IAQ" ? (
              <>
                <img src={iconairquality} />
              </>
            ) : props.data?.fixedAssetAssocWithTypeId === "PRESSURE" ? (
              <>
                <img src={pressureMonitoring} />
              </>
            ) : props.data?.fixedAssetAssocWithTypeId === "WATER_PULSE" ? (
              <>
                <img src={WaterPulseIcon} />
              </>
            ) : props.data?.fixedAssetAssocWithTypeId === "COMPRESSOR" ? (
              <>
                <img alt="icon" loading="lazy" src={temperatureCompressor} />
              </>
            ) : props.data?.fixedAssetAssocWithTypeId === "AC_CONTROLLER" ? <img alt="icon" loading="lazy" src={airControllerAsset} />
              : props.data?.fixedAssetAssocWithTypeId === "TEMPCONTROLLER" ? (
                <>
                  <img src={TempControllerIcon}
                    style={{
                      filter:
                        statusDG === "N"
                          ? " brightness(0) saturate(100%) invert(32%) sepia(63%) saturate(5053%) hue-rotate(345deg) brightness(110%) contrast(92%)"
                          : "",
                    }}
                  />
                </>) : props.data?.fixedAssetAssocWithTypeId === "ENERGY" ? <img src={energyAssetIcon} loading="lazy" alt="icon" /> : null}
            <div className="header_styleProp">
              <h6>{props.data.assetName}</h6>
              <p>{props.data.assetTypeDesc}</p>
            </div>
          </section>
          <div className="assetCard-info-extend">
            <ul>
              <li>
                <img src={activityalertsicon} />
                <p>
                  <span>
                    {R.isEmpty(props.data.Alert_Count)
                      ? "0"
                      : props.data.Alert_Count}{" "}
                    Alerts
                  </span>
                </p>
              </li>
              <li>
                <img src={activitywatchlisticon} />
                <p>
                  <span>
                    {R.isEmpty(props.data.Alert_Watched)
                      ? "0"
                      : props.data.Alert_Watched}{" "}
                    Watching
                  </span>
                </p>
              </li>
              <li>
                <img src={repeatedIcon} />
                <p>
                  <span>
                    {R.isEmpty(props.data.Alert_Repeated)
                      ? "0"
                      : props.data.Alert_Repeated}{" "}
                    Repeated
                  </span>
                </p>
              </li>
            </ul>
          </div>
        </div>

        {props.data?.fixedAssetAssocWithTypeId === "TEMP" ? (
          <>
            <Temp
              data={props?.data?.meters}
              abbre={props?.data}
              minTemp={props?.data?.temp_min}
              maxTemp={props?.data?.temp_max}
              dailyMeters={props?.data?.dailyMeters}
              energyUtil={props?.data?.energyUtilisation}
              lastCommTime={props?.data?.lastCommTime}
            />
          </>
        ) : props.data?.fixedAssetAssocWithTypeId === "TEMP_ENERGY" ? (
          <>
            <TempEnergy
              data={props?.data?.meters}
              abbre={props?.data}
              minTemp={props?.data?.temp_min}
              maxTemp={props?.data?.temp_max}
              dailyMeters={props?.data?.dailyMeters}
              energyUtil={props?.data?.energyUtilisation}
              lastCommTime={props?.data?.lastCommTime}
            />
          </>
        ) : props.data?.fixedAssetAssocWithTypeId === "DG" ? (
          <>
            <DG
              data={props?.data?.meters}
              abbre={props?.data}
              dailyMeters={props?.data?.dailyMeters}
              load={props?.data?.load?.value}
              lastCommTime={props?.data?.lastCommTime}
            />
          </>
        ) : props.data?.fixedAssetAssocWithTypeId === "AIR" ? (
          <>
            <AIR
              data={props?.data?.meters}
              abbre={props?.data}
              dailyMeters={props?.data?.dailyMeters}
              load={props?.data?.load?.value}
              monthlyMeters={props?.data?.monthlyMeters}
              lastCommTime={props?.data?.lastCommTime}
            />
          </>
        ) : props.data?.fixedAssetAssocWithTypeId === "WATER" ? (
          <>
            <Water
              data={props?.data?.meters}
              abbre={props?.data}
              dailyMeters={props?.data?.dailyMeters}
              load={props?.data?.load?.value}
              lastCommTime={props?.data?.lastCommTime}
            />
          </>
        ) : props.data?.fixedAssetAssocWithTypeId === "FUEL" ? (
          <>
            <Fuel
              data={props?.data?.meters}
              abbre={props?.data}
              dailyMeters={props?.data?.dailyMeters}
              load={props?.data?.load?.value}
              lastCommTime={props?.data?.lastCommTime}
            />
          </>
        ) : props.data?.fixedAssetAssocWithTypeId === "IAQ" ? (
          <>
            <IAQ
              data={props?.data?.meters}
              abbre={props?.data}
              dailyMeters={props?.data?.dailyMeters}
              load={props?.data?.load?.value}
              lastCommTime={props?.data?.lastCommTime}
            />
          </>
        ) : props.data?.fixedAssetAssocWithTypeId === "WATER_PULSE" ? (
          <>
            <WaterPulse
              data={props?.data?.meters}
              abbre={props?.data}
              dailyMeters={props?.data?.dailyMeters}
              load={props?.data?.load?.value}
              lastCommTime={props?.data?.lastCommTime}
              monthlyMeters={props?.data?.monthlyMeters}
            />
          </>
        ) : props.data?.fixedAssetAssocWithTypeId === "TEMPCONTROLLER" ? (
          <>
            <TempController
              data={props?.data?.meters}
              abbre={props?.data}
              dailyMeters={props?.data?.dailyMeters}
              load={props?.data?.load?.value}
              lastCommTime={props?.data?.lastCommTime}
            />
          </>
        ) :
          props.data?.fixedAssetAssocWithTypeId === "COMPRESSOR" ? (
            <>
              <CompressorCard
                meters={props?.data?.meters === null ? [] : props?.data?.meters}
                lastCommTime={props?.data?.lastCommTime}
                maxValue={props.data?.temp_max?.value}

              />
            </>) :
            props.data?.fixedAssetAssocWithTypeId === "AC_CONTROLLER" ? (
              <>
                <ACcontroller
                  meters={props?.data?.meters === null ? [] : props?.data?.meters}
                  lastCommTime={props?.data?.lastCommTime}
                  maxValue={props.data?.temp_max?.value}
                />
              </>)
              : props.data?.fixedAssetAssocWithTypeId === "PRESSURE" ? (
                <>
                  <Pressure
                    data={props?.data?.meters}
                    abbre={props?.data}
                    dailyMeters={props?.data?.dailyMeters}
                    load={props?.data?.load?.value}
                    lastCommTime={props?.data?.lastCommTime}
                  />
                </>
              )
                : (
                  <>
                    <Energy
                      data={props?.data?.meters}
                      abbre={props?.data}
                      dailyMeters={props?.data?.dailyMeters}
                      monthlyMeters={props?.data?.monthlyMeters}
                      lastCommTime={props?.data?.lastCommTime}
                    />
                  </>
                )}
        {/* <div className="assetCard-colum">
          <div className="assetCard-col">
            <p>Energy Consumed</p>
            {!R.isEmpty(props.data.meters) ? props.data.meters.map(el => {
                el.meterTypeId.includes("Consumed") && <h2>{el?.meterValue} - <span>{el?.readingUomDesc}</span></h2>
              }):<h2></h2>
            }
          </div>
          
          <div className="assetCard-col">
            <p>Asset Score</p>
            {!R.isEmpty(props.data.meters) ? props.data.meters.map(el => {
                el.meterTypeId.includes("Score") && <h2>{el.meterValue}% <span className={`assetCard-col-badge ${el.grade}`}>{el.grade}</span></h2>
              }):<h2></h2>
            }
          </div>

          {!R.isEmpty(props.data.meters) ? props.data.meters.map(el =>
            el.meterTypeId.includes("TEMP") &&
            <div className="assetCard-col">
              <p><img src={temperatureIcon} /> Temperature</p>
              <h2>{el.meterValue.toFixed(2)}°{el.readingUomDesc}</h2>
              <h6>Min {props.data?.temp_min?.value}°{props.data?.temp_min?.abbreviation} - Max {props.data?.temp_max?.value}°{props.data?.temp_max?.abbreviation}</h6>
            </div>):<div className="assetCard-col">
              <p><img src={temperatureIcon} /> Temperature</p>
              <h2></h2>
            </div>
          }

          <div className="assetCard-col">
            <p>Energy Utilization</p>
            {!R.isEmpty(props.data.meters) ? props.data.meters.map(el => {
                el.meterTypeId.includes("Utilization") && <h2>{el.meterValue}{el.readingUomDesc} <span className={`assetCard-col-badge ${el.grade}`}>{el.grade}</span></h2>
              }):<h2></h2>
            }
          </div>
          
        </div> */}
        <div className="assetCard-footer">
          <p>
            <FontAwesomeIcon icon={faLocationDot} />
            {locateAsset[0]?.facilityName !== undefined && ` ${locateAsset[0]?.facilityName}`}
            {locateAsset[0]?.address?.city !== undefined && `, ${locateAsset[0]?.address?.city}`}
            {locateAsset[0]?.address?.stateProvinceGeoId !== undefined && `, ${locateAsset[0]?.address?.stateProvinceGeoId}`}
          </p>
          <div className="lastUpdatedTime">
            <div
              className={
                props.data?.status === "FA_OFFLINE"
                  ? "offlineBadge"
                  : props.data?.status === "FA_IN_ACTIVE"
                    ? "inActiveBadge"
                    : props.data?.status === "FA_SLOW"
                      ? "slowBadge"
                      : props.data?.status === "FA_LOST"
                        ? "lostBadge"
                        : props.data?.status === "FA_MAINT"
                          ? "maintenanceBadge"
                          : props.data?.status === "FA_ACTIVE"
                            ? "activeBadge"
                            : "onlineBadge"
              }
            >
              <span className="imgInfoAssetCard">
                <img src={assetcard_img} />
                {/* <FontAwesomeIcon icon={faCircleInfo} /> */}
                {props.data?.status === "FA_OFFLINE"
                  ? "Offline"
                  : props.data?.status === "FA_SLOW"
                    ? "Slow"
                    : props.data?.status === "FA_ONLINE"
                      ? "Online"
                      : props.data?.status === "FA_IN_ACTIVE"
                        ? "Inactive"
                        : props.data?.status === "FA_MAINT"
                          ? "Maintenance"
                          : props.data?.status === "FA_LOST"
                            ? "Lost"
                            : "Active"}
              </span>
            </div>
            {/* // const [statusDG, setStatusDG] = useState()
    // const [loadDG, setLoadDG] = useState() */}

            {/* {  props.data?.fixedAssetAssocWithTypeId === "DG"  ? <div
              className={
                statusDG === "N"
                  ? "in-activeBadge":
                  statusDG === "Y" ?  "activeBadge" :""
                  
              }
            >
              <span>
                <FontAwesomeIcon icon={faCircleInfo} />
                { statusDG === "N"
                  ? "DG Off"
                  :statusDG === "Y" ?  "DG On":""}
              </span>
            </div> : ""} */}

            <div className="showTime">
              {/* // dont remove this is for development use */}
              {/* {console.log("the meter value",props.data?.meters)}  */}
              {/* {!R.isEmpty(props.data?.fixedAssetAssocWithTypeId) &&
              props.data?.fixedAssetAssocWithTypeId === "TEMP"
                ? props?.data?.meters.map((ele) => {
                   return ele?.meterTypeId?.includes("TEMP") && (
                      <h3>zxxzzx
                        {moment(ele.readingDate).startOf("hour").fromNow()}
                      </h3>
                    );
                  })
                : props?.data?.fixedAssetAssocWithTypeId === "TEMP_ENERGY"
                ? props?.data?.meters.map((ele) => {
                    ele.meterTypeId.includes("TEMP") && (
                      <h3>zszs
                        {moment(ele.readingDate).startOf("hour").fromNow()}
                      </h3>
                    );
                  })
                : props.data?.fixedAssetAssocWithTypeId === "DG"
                ? props.data?.meters.map((ele) => {
                    ele.meterTypeId.includes("TW") && (
                      <h3>zssz
                        {moment(ele.readingDate).startOf("hour").fromNow()}
                      </h3>
                    );
                  })
                : props.data?.meters.map((ele) => {
                    ele.meterTypeId.includes("TW") && (
                      <h3>zss
                        {moment(ele.readingDate).startOf("hour").fromNow()}
                      </h3>
                    );
                  })} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssetCard;