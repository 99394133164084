import Modal from "react-bootstrap/Modal";
import IconClose from "../../../assets/icons/IconClose.svg";
import "./DisableFacilitiesScreen.scss";

const DisableFacilitiesScreen = ({ closeDisablePopUpScreen }: any) => {
  return (
    <Modal
      show={true}
      onHide={() => closeDisablePopUpScreen("close")}
      centered
      backdrop="static"
      keyboard={false}
      className="bulkDisableFacilityPopup"
    >
      <Modal.Header className="bulkDisableFacilityHeader">
        <h4 className="bulkDisableHeaderTxt">Disable Facilities?</h4>
        <div className="closeIconDiv" onClick={closeDisablePopUpScreen}>
          <img src={IconClose} alt="icon"/>
        </div>
      </Modal.Header>
      <Modal.Body className="bulkDisableFacilityBody">
        <ul className="bulkDisableTxtCont">
          <li>
            This action will disable the selected facilities. Please choose an
            appropriate option from the dropdown to handle the assets.
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer className="bulkDisableFooter">
        <button
          onClick={() => closeDisablePopUpScreen("close")}
          className="bulkCancelBtn"
        >
          Cancel
        </button>
        <button
          className="bulkDisableBtn"
          onClick={() => closeDisablePopUpScreen("delete")}
        >
          Disable Facilities
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DisableFacilitiesScreen;
