// import { QueryClient, QueryClientProvider } from 'react-query';
import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, NavLink, Navigate } from "react-router-dom";
import * as R from "ramda";
// Absolute Paths
import { useStateValue } from "./redux/StateProvider";
import { theme } from "./theme";
import "./App_one.scss";
// Components
import Header from "./a1Components/header";
import SideNavBar from "./a1Components/sideNavBar";
import RightToggle from "./a1Components/rightToggle";
import RightToggleButton from "./a1Components/rightToggle/button";
import Searchbar from "./a1Components/header/demoGrid/Searchbar/Searchbar";
// Pages
import Assets from "./a1Pages/Assets";
import Facilities from "./a1Pages/Facility";
import { useLocation } from "react-router-dom";

// temprory
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import TabsCardSwitch from "./a1Components/multiTabComp/TabsCardSwitch";
import FooterAone from "./a1Components/FooterAone";
import atsuyalogo from "./atsuyalogo.svg";
import iconactivitycentre from "./assets/icons/icon-activity-centre.svg";
import DateBtnRow from "./a1Components/header/DatePicker";
import AssetDetailPage from "./a1Pages/AssetDetailPage/AssetDetailPage";
import Breadcrumbs from "./a1Components/breadcrumbs";
import ClusterDB from "./a1Pages/dashBoard/ClusterDB";
import CityDashboard from "./a1Pages/dashBoard/CityDashboard";
import RegionDashboard from "./a1Pages/dashBoard/RegionDashboard";
import ErrorPage from "./a1Pages/ErrorPage/ErrorPage";
import Connectionerror from "./a1Pages/ErrorPage/Connectionerror";
import WronPage from "./a1Pages/ErrorPage/WronPage";
import LoginPage from "./a1Pages/LoginPage/LoginPage";
import CityPage from "./a1Pages/CityPage/CityPage";
import ClusterPage from "./a1Pages/ClusterPage/ClusterPage";
import RegionPage from "./a1Pages/RegionPage/RegionPage";
import OperationalDashboard from "./a1Pages/dashBoard/OperationDashboard/OperationalDashboard";
import PanIndiaDB from "./a1Pages/dashBoard/PanIndiaDB";
import DashBoard from "./a1Pages/dashBoard";
import avatarImg from "./assets/image/avatar2.png";
import LoaderEffect from "./a1Components/LoaderEffects/LoaderEffect";
import UserViewDasbaoard from "./a1Pages/dashBoard/UserViewDasbaoard";
import AssetDataUtil from "./Utils/AssetDataUtil";
import { getBuildDate } from "./Utils/utils";
import withClearCache from "./ClearCache";
import packageJson from "../package.json";
import AssetFilterUtil from "./Utils/AssetFilterUtil";
import DevMethodUtil from "./Utils/DevMethodUtil";
import RedirectComp from "./components/RedirectComp";
import PasswordPopup from "./a1Components/ForgotpasswordPopup/PasswordPopup";
import LogoutIcon from "./assets/icons/icon-logout.svg";
import ConditionMonitoringPage from "./a1Pages/dashBoard/ConditionMonitoringPage";
import IncidentLoadMorePage from "./a1Pages/dashBoard/IncidentLoadMorePage";
import VideoAnalyticsPage from "./a1Pages/dashBoard/VideoAnalyticsPage";
import AppAbout from "./AppAbout";
import AoneTrolley from "./AoneTrolley";
import CorporateDashboard2 from "./CorporateComponent/CorporateDashboard2/CorporateDashboard2";
import TrackTraceDashboard from "./a1Pages/dashBoard/trackntraceDashboard/TracktraceDashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import ProfilePopUpScreen from "./SettingsComponents/profilePopUpScreen/ProfilePopUpScreen";
import CompanyInformation from "./SettingsComponents/companyInformation/CompanyInformation";
import QrGenerationComponent from "./QrGeneration/QrGenerationComponent";
import UserViewDasbaoardAnovi from "./a1Pages/dashBoard/UserViewDasbaoardAnovi";
import UserViewDasbaoardRealestate from "./a1Pages/dashBoard/UserViewDasbaoardRealestate";
import FilterPopup from "./a1Components/filterPopup";
import { HelperFunctions } from "./Utils/HelperFunctions";
import Lottie from "react-lottie";
import download_lottie from "./assets/Lotties/animation_downloadingHeader.json";
import DownloadList from "./components/Download_List/DownloadList";
import ViewReport from "./a1Components/viewReport/ViewReport";
import ForgotPassword from "./a1Pages/Forgot Password/ForgotPassword";
import UserInputForChangePassword from "./a1Pages/Forgot Password/UserInputForChangePassword";
import PasswordOtpSelectionCard from "./a1Pages/Forgot Password/PasswordOtpSelectionCard";
import OtpScreen from "./a1Pages/Forgot Password/OtpScreen";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { Vector } from "html2canvas/dist/types/render/vector";
import Vectoravatar from "./assets/image/Vector_avatar.svg"
import downloadIcon from "./assets/icons/icon-downloads.svg"

import { DashBoardServices } from "./services/DasboardServices/DashboardService";
import CommonDashboard from './a1Pages/dashBoard/CommonDashboard';
import { QueryClient, QueryClientProvider } from "react-query";
import { setAuthTokenInterceptor } from "./authInterceptor";

import './assets/fonts/LiquidCrystal-Normal.woff'
import AssetList from "./features/assetList/components/AssetList";
import EventListDashboard from "./features/eventList/components/dashboard/EventListDashboard";
import NewAssetDetailComponent from "./features/NewAssetDetailPage/NewAssetDetailComponent";
import OldAssetDetailPage from "./a1Pages/AssetDetailPage/OldAssetDetailPage";
import LocalStorageService from "./Utils/LocalStorageService";
import {API_BASE_URL} from "./Constants/constants";

const ClearCacheComponent = withClearCache(MainApp);

const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ClearCacheComponent />
    </QueryClientProvider>

  );
}

function MainApp(props: any) {
  const currentPathArr = window.location.pathname.split("/");
  const [{ darkThemeEnabled, pathName, overlayEnabled, assetFilters, locationPath, downloadReportStatus }, dispatch] = useStateValue();
  const isDark = darkThemeEnabled;
  const bodyBg = isDark
    ? { backgroundColor: theme.dark.colors.bodyBg }
    : { backgroundColor: theme.light.colors.bodyBg };
  const pageBorderColor = isDark ? "#edeef8" : "#F0F3FF";
  // const pageBgColor = isDark ? "#0C1228" : pathName === "/"?"#E1E3F0":"#F0F3FF";
  const pageBgColor = pathName === "/" ? "#e1e3f0 " : "#edeef8";
  const [rightOpen, setRightOpen] = useState(false);
  const [toggleBtn, setToggleBtn] = useState(true);
  const [isLogin, setIslogin] = useState<any>(null);
  const [pages, setPages] = useState<any>([]);
  const [sideNavBarId, setSideNavId] = useState([]);

  const [firstNameVal, setFirstNameVal] = useState<any>();
  const [lastNameVal, setLastNameVal] = useState<any>();

  const [logoUrl, setLogoUrl] = useState<any>("./atsuyalogo.svg");

  const [error, setError] = useState("");

  //console.log("the security group aoi", sideNavBarId);
  //login change password popup
  const [popupState, setPopupState] = useState(false);

  const [pathName1, setPathName1] = useState("")

  let permission: any = localStorage.getItem("securityPermissions");
  let permissionsList: any = JSON.parse(permission);

  setAuthTokenInterceptor();

  const openModalHandler = () => {
    setPopupState(true);
  };

  const closeModalHandler = () => {
    setPopupState(false);
  };

  //profilepopupScreen variables
  const [profilePopUpState, setProfilePopUpState] = useState(false);

  const openProfilePopUpScreen = () => {
    setProfilePopUpState(true);
  };

  const closeProfilePopUpScreen = () => {
    setProfilePopUpState(false);
  };

  const redirectToLoginPage = () => {
    logOut();
  };

  const startTimestampCheck = () => {
    const timestamp: any = localStorage.getItem("timestamp");
    setInterval(() => {
      const currentTime = new Date().getTime();
      console.log("currentTime", currentTime, parseInt(timestamp));
      if (currentTime >= parseInt(timestamp)) {
        // redirectToLoginPage();
      }
    }, 60 * 1000);
  };

  useEffect(() => {
    const timestamp: any = localStorage.getItem("timestamp");
    const currentTime = new Date().getTime();
    if (currentTime >= parseInt(timestamp)) {
      // redirectToLoginPage();
    } else {
      // startTimestampCheck();
    }
  }, [isLogin]);


  const updatePathName = (newPathName: any) => {
    setPathName1(newPathName);
  };


  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const firstName = localStorage.getItem("firstNameH");
    const lastName = localStorage.getItem("lastNameH");
    const logoPath = localStorage.getItem("logourl");
    let logo = `https://aone.atsuyatech.com${logoPath}`
    setLogoUrl(logo);
    setFirstNameVal(firstName);
    setLastNameVal(lastName);
    var loginStateValue = localStorage.getItem("loginState");

    let cacheUpdateValue = getBuildDate(packageJson.buildDate);

    localStorage.setItem("cachestate", cacheUpdateValue);

    if (isLogin === null) {
      if (loginStateValue === "true") {
        setIslogin(true);
      } else {
        setIslogin(false);
      }
    }
    if (isLogin) {
      const localStorageLoginData = await localStorage.getItem("aoneLoginData");
      if (
        !R.isEmpty(localStorageLoginData) &&
        !R.isNil(localStorageLoginData)
      ) {
        let jsonParse = JSON.parse(localStorageLoginData);
        let firstNameLg = jsonParse?.data?.loginDetails[0]?.firstName;
        let lastNameLg = jsonParse?.data?.loginDetails[0]?.lastName;

        let availablePages = jsonParse?.loginDetails[0]?.portalDetails.map(
          (page: any) => page.portalPageId
        );
        jsonParse?.loginDetails[0]?.securityGroup.forEach((obj: any) => {
          obj.permissionId.forEach((el: any) => {
            availablePages.push(el);
          })
        })
        //ToDo VooDoo
        if (HelperFunctions.isTnt(localStorage.getItem("userName"))) {
          availablePages.push("TRACK_AND_TRACE");
        }
        setPages(availablePages);

        var setPageID = localStorage.getItem("securityPermissions")!;
        var parsePageId = JSON.parse(setPageID);
        setSideNavId(parsePageId);
      }


    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);

  function renderDevMethods() {
    const devMethdUtil = new DevMethodUtil();
    devMethdUtil.runDevMethods();
  }

  useEffect(() => {
    const firstName = localStorage.getItem("firstNameH");
    const lastName = localStorage.getItem("lastNameH");
    setFirstNameVal(firstName);
    setLastNameVal(lastName);
    renderDevMethods();
  }, []);

  const [dateBtnStatus, setDateBtnStatus] = useState(true);

  useEffect(() => {
    if ((window.location.pathname !== "/asset-list" && pathEnd !== "asset-list") && (window.location.pathname !== "/settings" && pathEnd !== "settings") && (window.location.pathname !== "/reports" && pathEnd !== "reports")) {
      setDateBtnStatus(true);
    } else {
      setDateBtnStatus(false);
    }
  }, [window.location.pathname, locationPath]);

  function handleRightOpen() {
    setRightOpen(!rightOpen);
  }

  const toggleSideNav = () => {
    if (toggleBtn === false) {
      setToggleBtn(true);
    } else {
      setToggleBtn(false);
    }
  };
  const setErrorMsg = (errMsg: any) => {
    setError(errMsg);
  };

  const LoginProp = async (details: any) => {
    if (details.username === "" && details.password === "") {
      // console.log("The username or password is empty");
      setErrorMsg("Both the Fields are Empty!");
    } else if (details.username !== "" && details.password === "") {
      // console.log("The username or password is empty");
      setErrorMsg("Password is Empty!");
    } else if (details.username === "" && details.password !== "") {
      // console.log("The username or password is empty");
      setErrorMsg("Email is Empty!");
    } else {
      getStorage();
      // setIslogin(true);
    }
  };

  const getStorage = async () => {
    const localStorageLoginData = await localStorage.getItem("aoneLoginData");
    if (!R.isEmpty(localStorageLoginData) && !R.isNil(localStorageLoginData)) {
      let jsonParse = JSON.parse(localStorageLoginData);
      let availablePages = jsonParse?.loginDetails[0]?.portalDetails.map(
        (page: any) => page.portalPageId
      );
      console.log(availablePages, "to do show", jsonParse);
      setPages(availablePages);

      var setPageID: any = localStorage.getItem("securityPermissions");
      var parsePageId: any = JSON.parse(setPageID);
      setSideNavId(parsePageId);

      // Removed the forced reload in the dashboard.
      // The reload was triggered because the local storage read was empty for the pushed data.
      // Ideally, all local storage operations should be handled by a service, and the use of 'await' should have been employed.
      // This temporary one-second timeout allows the local storage write to complete.
      // It's not a recommended or elegant solution, but it serves as a temporary fix.
      setTimeout(function () {
        setIslogin(true);
        const loginState = "true";
        localStorage.setItem("loginState", loginState);
      }, 1000);

    }
  };

  const Download_Lottie = {
    loop: true,
    autoplay: true,
    animationData: download_lottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  window.addEventListener("beforeunload", () => {
    const timestamp: any = localStorage.getItem("timestamp");
    const currentTime = new Date().getTime();
    if (timestamp === null) {
    } else {
      if (currentTime >= parseInt(timestamp)) {
        // redirectToLoginPage();
      } else {
        // startTimestampCheck();
      }
    }
  });

  window.addEventListener("load", () => {
    const timestamp: any = localStorage.getItem("timestamp");
    const currentTime = new Date().getTime();
    if (timestamp === null) {
    } else {
      if (currentTime >= parseInt(timestamp)) {
        // redirectToLoginPage();
      } else {
        // startTimestampCheck();
      }
    }
  });

  // Check token expiration on page load
  window.addEventListener("unload", () => {
    const timestamp: any = localStorage.getItem("timestamp");
    const currentTime = new Date().getTime();
    if (timestamp === null) {
    } else {
      if (currentTime >= parseInt(timestamp)) {
        // redirectToLoginPage();
      } else {
        // startTimestampCheck();
      }
    }
  });

  useEffect(() => {
    // Check if one hour has passed since the last trigger
    const lastTriggerTime: any = localStorage.getItem("timestamp");
    const currentTime = new Date().getTime();
    if (lastTriggerTime === null) {
    } else {
      if (currentTime >= parseInt(lastTriggerTime)) {
        // Trigger the event and update the last trigger time

        // Add event listener for the beforeunload event
        const beforeUnloadHandler = () => {
          // redirectToLoginPage();
        };

        window.addEventListener("beforeunload", beforeUnloadHandler);

        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener("beforeunload", beforeUnloadHandler);
        };
      }
    }
  }, [isLogin]); // Empty dependency array to run the effect only once

  async function serverLogOut() {
    try {

      const tokenID = LocalStorageService.getToken();
      const response = await fetch(`${API_BASE_URL}auth/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (response.status === 200 || response.status === 400) {
        localStorage.clear();
        localStorage.setItem("loginState", "false");
        window.location.href = "/";
      }
    } catch (e) {
      localStorage.clear();
      localStorage.setItem("loginState", "false");
      window.location.href = "/";
    }
    finally {
      setIslogin(false);
    }
  }

  const logOut = async () => {
    // const assetFilterUtil = new AssetFilterUtil();
    // const emptyFilters = await assetFilterUtil.getClearAssetFilters(
    //   Object.assign({}, assetFilters)
    // );
    // emptyFilters.dataLoaded = false;
    // dispatch({ type: "UPDATE_ASSET_FILTERS", assetFilters: emptyFilters });
    // console.log("Logout was clicked");
    // console.log("assetFilters::", emptyFilters);
    // var logOutval = "false";
    // localStorage.clear();
    // localStorage.setItem("loginState", logOutval);
    // window.location.href = "/";
    // setIslogin(false);
    await serverLogOut();
  };

  const changePasswordUpdate = (message: string) => {
    setPopupState(false);
    toast.success(message, {
      position: "bottom-right",
      autoClose: 2500, // Close the toast after 5 seconds
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }



  // const location = useLocation();
  const [layOutClassName, setlayOutClassName] = useState(
    "page-Layout layout-in removeOverFlow"
  );
  useEffect(() => {
    // setlayOutClassName('page-Layout layout-in removeOverFlow')
    if (window.location.pathname.split("/").length == 2) {
      setRightOpen(false)
      if (
        (window.location.pathname.split("/")[1] == "settings" && ((!R.isEmpty(permissionsList) && permissionsList.includes("SETTINGS_VIEW")))) ||
        window.location.pathname.split("/")[1] == "qrgeneration" ||
        window.location.pathname.split("/")[1] == "download-list"
      ) {
        setlayOutClassName("page-Layout layout-in removeOverFlow");
      } else if (window.location.pathname.split("/")[1] == "asset-list") {
        setlayOutClassName("page-Layout layout-in hide-overflow");
      } else {
        setlayOutClassName("page-Layout");
      }
    } else {
      setlayOutClassName("page-Layout");
    }
  }, [window.location.pathname]);
  // let layOutClassName =  currentPathArr.includes("asset-list") || currentPathArr.includes("download-list")  ? "page-Layout layout-Out" : "page-Layout layout-in" ;

  // let layOutClassName =
  //     pathName === "/" ? "page-Layout layout-in" : "page-Layout";

  const [incidentLoadMoreCard, setIncidentloadMoreCard] = useState(true);
  // alert(pathName);
  //let layOutClassName =
  // pathName === "/" ? "page-Layout layout-in" : pathName === 'settings' ? 'removeOverFlow' : 'page-Layout'
  //"page-Layout";
  const pathArr = window.location.pathname.split("/");
  const pathEnd = pathArr[pathArr.length - 1];
  // console.log(pages, "pages")
  return !isLogin ? (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage Login={LoginProp} error={error} setIslogin={setIslogin} setLoginMsg={setErrorMsg} />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
      </Routes>
    </BrowserRouter>
  ) : (
    <BrowserRouter>
      <div style={bodyBg} className={overlayEnabled ? "overlayEnabled" : ""}>
        <Modal show={popupState} backdrop="static" centered className="passwordPopup">
          <PasswordPopup responseCallBack={changePasswordUpdate} closeCallBackFunction={closeModalHandler} />
        </Modal>

        <ToastContainer position="bottom-right" />

        <ProfilePopUpScreen
          className="modal"
          show={profilePopUpState}
          close={closeProfilePopUpScreen}
        />
        
          {window.location.pathname === "/" && (
            <>
              <RightToggleButton isDark={isDark} handleRightOpen={handleRightOpen} />
            </>
          )}
        {rightOpen ? (
          <RightToggle
            rightOpen={rightOpen}
            isDark={isDark}
            handleRightOpen={handleRightOpen}
          >
            <div className="col-lg-12">
              <TabsCardSwitch />
            </div>
          </RightToggle>
        ) : null}
        <div className="container-fluid  pageFixScss">
          <div className="row">
            <div
              className={
                rightOpen
                  ? "col-xl-8 col-12"
                  : toggleBtn
                    ? "col-12 pl-6"
                    : "col-12 pl-0"
              }
            >
              <Header isDark={isDark} open={rightOpen}>
                <div className="header-row">
                  <div className="col-1 logoCol">
                    <div className="atsuyaLogo">
                      <img src={logoUrl} alt="logo" loading="lazy" />
                    </div>
                    <button
                      className="toggle-FontAwesome"
                      onClick={toggleSideNav}
                    >
                      {toggleBtn ? (
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                      ) : (
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      )}
                    </button>
                  </div>
                  {/* <div
                    className={
                      rightOpen
                        ? "col-3 pl-0 header-badges-rightOpen"
                        : "header-badges col-3 pl-0"
                    }
                  >
                    <Badge data={userScore} isDark={isDark} />
                  </div> */}
                  <div
                    className={
                      rightOpen
                        ? "col-5 searchAndDate searchShrink"
                        : "col-6 searchAndDate"
                    }
                  >
                    {/* search for search bar in dashboard page  */}
                    {/*{((window.location.pathname != "/asset-list" &&*/}
                    {/*  pathEnd != "asset-list")*/}
                    {/*  && (window.location.pathname != "/settings" &&*/}
                    {/*    pathEnd != "settings")) && (*/}
                    {/*    <Searchbar*/}
                    {/*      label="All"*/}
                    {/*      label1="Assets"*/}
                    {/*      label2="Alerts"*/}
                    {/*      historyList="How many assets are under-utilized"*/}
                    {/*      searchPlaceholder="Ask AOne"*/}
                    {/*      slot={true}*/}
                    {/*      slot2={false}*/}
                    {/*    />*/}
                    {/*  )}*/}
                    {/* for the asset list page search list */}
                    {window.location.pathname == "/asset-list" &&
                      pathEnd == "asset-list" && (
                        <Searchbar
                          label="All"
                          label1="Assets"
                          label2="Alerts"
                          historyList="How many assets are under-utilized"
                          searchPlaceholder="Ask AOne"
                          slot={false}
                          slot2={true}
                        />
                      )}
                    {dateBtnStatus ? <DateBtnRow /> : null}
                  </div>
                  <div
                    className={
                      rightOpen
                        ? "col-3 d-flex justify-content-end align-items-start"
                        : "col-2 d-flex justify-content-end align-items-start"
                    }
                  >
                    <div
                      className={
                        rightOpen
                          ? "header-right-cta rightOpen"
                          : "header-right-cta"
                      }
                    >
                      {/* <ToggleTheme /> */}
                      {/* //commented for development purpose
                      this is developed for the next sprint  */}
                      <NavLink
                        to={"/download-list"}
                        className="chatCta d-flex justify-content-center align-items-center"
                        // onClick={navigateDownloadList}
                        style={{ backgroundColor: "#DFE6FF" }}
                      >
                        {
                          downloadReportStatus ?
                            <Lottie options={Download_Lottie} height={23} width={20} /> :
                            <img src={downloadIcon} alt="icon" style={{ marginTop: "2px" }} />
                        }
                      </NavLink>

                      <div
                        className="chatCta d-flex justify-content-center align-items-center"
                        onClick={handleRightOpen}
                      >
                        <img
                          src={iconactivitycentre}
                          alt="chart"
                          style={{ marginTop: "2px" }}
                        />
                      </div>

                      <div className="profileDp">
                        <button className="dropbtn profileName">
                          <img src={Vectoravatar} alt="dp" />
                          <p>
                            {firstNameVal} {lastNameVal}
                          </p>
                        </button>
                        <div className="dropdown-content">
                          {/*<a onClick={openProfilePopUpScreen}>Profile</a>*/}
                          {/*<a href="/companyinformation">Company information</a>*/}
                          <a onClick={openModalHandler}>Change Password</a>
                          <a onClick={logOut}>
                            Log Out{" "}
                            <span>
                              {" "}
                              <img src={LogoutIcon} />
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {rightOpen && (
                  <div className="dateBtnRow">
                    <DateBtnRow />
                  </div>
                )} */}
              </Header>
            </div>
          </div>
          <div className="row xs-col-reverse pageFixScssRight">
            <div
              className={
                toggleBtn
                  ? "col-xl-1 col-12 navCol pr-0"
                  : "col-xl-1 col-12 navCol pr-0 width-2"
              }
            >
              <SideNavBar pages={sideNavBarId} updatePathName={updatePathName} />

            </div>
            <div
              style={{
                borderColor: pageBorderColor,
                backgroundColor: pageBgColor,
              }}
              className={
                rightOpen
                  ? `${toggleBtn
                    ? `${layOutClassName} col-xl-7 col-12 pt-10`
                    : `${layOutClassName} col-xl-8 col-12 pt-10 width-98`
                  }`
                  : `${toggleBtn
                    ? `${layOutClassName} col-xl-11 col-12 pt-10`
                    : `${layOutClassName} col-xl-12 col-12 pt-10 pl-40`
                  }`
              }
            >
              {window.location.pathname != "/" &&
                pathName1 != "/download-list" &&
                  pathEnd != "settings" &&
                  pathEnd != "asset-list" ? (
                <div className="breadCrumbsFilter">
                  <div className="CrumbsChips">
                    <div className="filterPopsScss">
                      {currentPathArr[currentPathArr?.length - 1] ===
                        "asset-list" && <FilterPopup alerts />}
                      {/* commited for the development purpose this is used in asset page for filtering */}
                    </div>
                  </div>
                  {(window.location.pathname != "/asset-list" &&
                    pathEnd != "asset-list") && <div className="filterBtn">
                      <div>
                        <Breadcrumbs />
                      </div>
                    </div>}
                </div>
                // <div style={{padding:'10px', backgroundColor: "#E0E3F0"}}> </div>
              ) : (
                <></>
              )}

              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/companyinformation" element={<CompanyInformation />} />
                <Route path="/download-list" element={<DownloadList />} />
                <Route path="/reports" element={<ViewReport />} />
                <Route path="*" element={<ErrorPage errorStatus={500} />} />
                {/* {!R.isEmpty(pages) && pages.includes("PAN_INDIA_DB") && (
                  <Route path="/"
                    element={
                      <PanIndiaDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )} */}

                {/* ujwal vedio analytics */}
                {/* {!R.isEmpty(pages) &&
                  pages.includes("VIDEO_ANALYTICS_PAGE") && (
                    <Route
                      path="/"
                      element={
                        <CorporateDashboard2
                          isDark={isDark}
                          theme={theme}
                          rightOpen={rightOpen}
                          toggleBtn={toggleBtn}
                        />
                      }
                    />
                  )} */}
                  {pages.includes("DB_VIEW") && (
                    <Route path="/event-list" element={<EventListDashboard />} />
                    )}
                {!R.isEmpty(pages) &&
                  pages.includes("VIDEO_ANALYTICS_PAGE") && (
                    <Route path="/smartTrolley" element={<AoneTrolley />} />
                  )}
                {!R.isEmpty(pages) &&
                  pages.includes("VIDEO_ANALYTICS_PAGE") && (
                    <Route path="/cyltag" element={<AppAbout />} />
                  )}
                {!R.isEmpty(pages) &&
                  pages.includes("VIDEO_ANALYTICS_PAGE") && (
                    <Route
                      path="/VideoAnalytics"
                      element={
                        incidentLoadMoreCard ? (
                          // want to check VideoAnalyticsPage change component name with this below
                          <VideoAnalyticsPage
                            isDark={isDark}
                            theme={theme}
                            rightOpen={rightOpen}
                            toggleBtn={toggleBtn}
                            displayIncident={setIncidentloadMoreCard}
                          />
                        ) : (
                          <IncidentLoadMorePage
                            isDark={isDark}
                            theme={theme}
                            rightOpen={rightOpen}
                            toggleBtn={toggleBtn}
                            hideDisplayIncidents={setIncidentloadMoreCard}
                          />
                        )
                      }
                    />
                  )}
                {!R.isEmpty(pages) &&
                  pages.includes("VIDEO_ANALYTICS_PAGE") && (
                    <Route
                      path="/ConditionMonitor"
                      element={
                        // want to check VideoAnalyticsPage change component name with this below
                        <ConditionMonitoringPage
                          isDark={isDark}
                          theme={theme}
                          rightOpen={rightOpen}
                          toggleBtn={toggleBtn}
                          displayIncident={setIncidentloadMoreCard}
                        />
                      }
                    />
                  )}
                {/* <ConditionMonitoringPage
                          isDark={isDark}
                          theme={theme}
                          rightOpen={rightOpen}
                          toggleBtn={toggleBtn}
                          displayIncident={setIncidentloadMoreCard}
                        /> */}

                {/* page Eng */}
                {/* {!R.isEmpty(pages) && pages.includes("TRACK_AND_TRACE") && (
                  <Route
                    path="/"
                    element={
                      <TrackTraceDashboard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )} */}

                <Route
                  path="/settings"
                  element={(!R.isEmpty(permissionsList) && permissionsList?.includes("SETTINGS_VIEW")) ?
                    <TrackTraceDashboard
                      isDark={isDark}
                      theme={theme}
                      rightOpen={rightOpen}
                      toggleBtn={toggleBtn}
                    /> : <Navigate to="/*" />
                  }
                />

                <Route
                  path="/qrgeneration"
                  element={
                    <QrGenerationComponent
                      isDark={isDark}
                      theme={theme}
                      rightOpen={rightOpen}
                      toggleBtn={toggleBtn}
                    />
                  }
                />
                {/* {!R.isEmpty(pages) && pages.includes("AONE_BASIC_DB") && (
                  <Route
                    path="/"
                    element={
                      <UserViewDasbaoard
                        logOut={logOut}
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )} */}
                {console.log("the pages in aoneDb", pages)}
                {!R.isEmpty(pages) &&
                  // (pages.includes("TEST_AONE_DB"))&&
                  (
                    <Route
                      path="/"
                      element={
                        <CommonDashboard logOut rightOpen={rightOpen} toggleBtn={toggleBtn} isDark={isDark} theme={theme} handleRightOpen={handleRightOpen} dbType={'TEST_AONE_DB'} />
                        // <UserViewDasbaoardAnovi
                        //   logOut={logOut}
                        //   dbType={'TEST_AONE_DB'}
                        //   isDark={isDark}
                        //   theme={theme}
                        //   rightOpen={rightOpen}
                        //   toggleBtn={toggleBtn}
                        //   handleRightOpen={handleRightOpen}
                        // />
                      }
                    />
                  )}

                {/* {!R.isEmpty(pages) && pages.includes("REALESTATE_FM_DB") && (
                  <Route
                    path="/"
                    element={
                      <UserViewDasbaoardRealestate
                        logOut={logOut}
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                        handleRightOpen={handleRightOpen}
                      />
                    }
                  />
                )} */}

                {/* <LoaderEffect/> */}

                {/* {!R.isEmpty(pages) &&
                  !pages.includes("PAN_INDIA_DB") &&
                  pages.includes("REGION_DB") && (
                    <Route
                      path="/"
                      element={
                        <RegionDashboard
                          isDark={isDark}
                          theme={theme}
                          rightOpen={rightOpen}
                          toggleBtn={toggleBtn}
                        />
                      }
                    />
                  )} */}

                {/* {!R.isEmpty(pages) &&
                  !pages.includes("PAN_INDIA_DB") &&
                  !pages.includes("REGION_DB") &&
                  pages.includes("CITY_DB") && (
                    <Route
                      path="/"
                      element={
                        <CityDashboard
                          isDark={isDark}
                          theme={theme}
                          rightOpen={rightOpen}
                          toggleBtn={toggleBtn}
                        />
                      }
                    />
                  )} */}

                {/* {!R.isEmpty(pages) &&
                  !pages.includes("PAN_INDIA_DB") &&
                  !pages.includes("REGION_DB") &&
                  !pages.includes("CITY_DB") &&
                  pages.includes("CLUSTER_DB") && (
                    <Route
                      path="/"
                      element={
                        <ClusterDB
                          isDark={isDark}
                          theme={theme}
                          rightOpen={rightOpen}
                          toggleBtn={toggleBtn}
                        />
                      }
                    />
                  )} */}

                {/* {!R.isEmpty(pages) &&
                  !pages.includes("PAN_INDIA_DB") &&
                  !pages.includes("REGION_DB") &&
                  !pages.includes("CITY_DB") &&
                  !pages.includes("CLUSTER_DB") &&
                  pages.includes("FACILITY_DB") && (
                    <Route
                      path="/"
                      element={
                        <DashBoard
                          isDark={isDark}
                          theme={theme}
                          rightOpen={rightOpen}
                          toggleBtn={toggleBtn}
                        />
                      }
                    />
                  )} */}

                {/* {!R.isEmpty(pages) &&
                  !pages.includes("PAN_INDIA_DB") &&
                  !pages.includes("REGION_DB") &&
                  !pages.includes("CITY_DB") &&
                  !pages.includes("FACILITY_DB") &&
                  pages.includes("FIXEDASSET_VIEW") && 
                  (
                    <Route
                      path="/"
                      element={
                        <Assets
                          isDark={isDark}
                          theme={theme}
                          rightOpen={rightOpen}
                          toggleBtn={toggleBtn}
                        />
                      }
                    />
                  )} */}

                {!R.isEmpty(pages) && pages.includes("CLUSTER_DB") && (
                  <Route
                    path="/cluster-db"
                    element={
                      <ClusterDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CITY_DB") && (
                  <Route
                    path="/city-db"
                    element={
                      <CityDashboard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) &&
                  !pages.includes("PAN_INDIA_DB") &&
                  !pages.includes("REGION_DB") &&
                  !pages.includes("CITY_DB") &&
                  !pages.includes("CLUSTER_DB") &&
                  pages.includes("FACILITY_DB") && (
                    <Route
                      path="/facility-db"
                      element={
                        <DashBoard
                          isDark={isDark}
                          theme={theme}
                          rightOpen={rightOpen}
                          toggleBtn={toggleBtn}
                        />
                      }
                    />
                  )}

                {/* **** Pan india drill down start *** */}
                {!R.isEmpty(pages) && pages.includes("PAN_INDIA_DB") && (
                  <Route
                    path="/Pan-India-db"
                    element={
                      <PanIndiaDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("REGION_ASTPG") && (
                  <Route
                    path="/Pan-India-db/region-list"
                    element={
                      <RegionPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("REGION_DB") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId"
                    element={
                      <RegionDashboard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("REGION_DB") && (
                  <Route
                    path="/Pan-India-db/:regionId"
                    element={
                      <RegionDashboard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("CITY_ASTPG") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/city-list"
                    element={
                      <CityPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("CITY_ASTPG") && (
                  <Route
                    path="/Pan-India-db/:regionId/city-list"
                    element={
                      <CityPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("CITY_DB") && (
                  <Route
                    path="/Pan-India-db/:regionId/:cityId"
                    element={
                      <CityDashboard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CITY_DB") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/city-list/:cityId"
                    element={
                      <CityDashboard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CITY_DB") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/:cityId"
                    element={
                      <CityDashboard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CITY_DB") && (
                  <Route
                    path="/Pan-India-db/:regionId/city-list/:cityId"
                    element={
                      <CityDashboard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("CLUSTER_ASTPG") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/city-list/:cityId/cluster-list"
                    element={
                      <ClusterPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_ASTPG") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/:cityId/cluster-list"
                    element={
                      <ClusterPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_ASTPG") && (
                  <Route
                    path="/Pan-India-db/:regionId/city-list/:cityId/cluster-list"
                    element={
                      <ClusterPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_ASTPG") && (
                  <Route
                    path="/Pan-India-db/:regionId/:cityId/cluster-list"
                    element={
                      <ClusterPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("CLUSTER_DB") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/city-list/:cityId/cluster-list/:clusterId"
                    element={
                      <ClusterDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_DB") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/:cityId/cluster-list/:clusterId"
                    element={
                      <ClusterDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_DB") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/:cityId/:clusterId"
                    element={
                      <ClusterDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_DB") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/city-list/:cityId/:clusterId"
                    element={
                      <ClusterDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_DB") && (
                  <Route
                    path="/Pan-India-db/:regionId/city-list/:cityId/cluster-list/:clusterId"
                    element={
                      <ClusterDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_DB") && (
                  <Route
                    path="/Pan-India-db/:regionId/:cityId/cluster-list/:clusterId"
                    element={
                      <ClusterDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_DB") && (
                  <Route
                    path="/Pan-India-db/:regionId/:cityId/:clusterId"
                    element={
                      <ClusterDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_DB") && (
                  <Route
                    path="/Pan-India-db/:regionId/city-list/:cityId/:clusterId"
                    element={
                      <ClusterDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("FACILITY_ASTPG") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/city-list/:cityId/cluster-list/:clusterId/facility-list"
                    element={
                      <Facilities
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_ASTPG") && (
                  <Route
                    path="/Pan-India-db/:regionId/:cityId/:clusterId/facility-list"
                    element={
                      <Facilities
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_ASTPG") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/:cityId/cluster-list/:clusterId/facility-list"
                    element={
                      <Facilities
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_ASTPG") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/city-list/:cityId/:clusterId/facility-list"
                    element={
                      <Facilities
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_ASTPG") && (
                  <Route
                    path="/Pan-India-db/:regionId/city-list/:cityId/cluster-list/:clusterId/facility-list"
                    element={
                      <Facilities
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_ASTPG") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/:cityId/:clusterId/facility-list"
                    element={
                      <Facilities
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_ASTPG") && (
                  <Route
                    path="/Pan-India-db/:regionId/city-list/:cityId/:clusterId/facility-list"
                    element={
                      <Facilities
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/city-list/:cityId/cluster-list/:clusterId/facility-list/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/Pan-India-db/:regionId/:cityId/:clusterId/facility-list/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/:cityId/cluster-list/:clusterId/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/:cityId/:clusterId/facility-list/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/city-list/:cityId/:clusterId/facility-list/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/city-list/:cityId/:clusterId/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/:cityId/:clusterId/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/Pan-India-db/:regionId/city-list/:cityId/cluster-list/:clusterId/facility-list/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/Pan-India-db/:regionId/city-list/:cityId/cluster-list/:clusterId/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/Pan-India-db/:regionId/:cityId/cluster-list/:clusterId/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/Pan-India-db/:regionId/city-list/:cityId/:clusterId/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/Pan-India-db/:regionId/city-list/:cityId/:clusterId/facility-list/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/city-list/:cityId/cluster-list/:clusterId/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/Pan-India-db/:regionId/:cityId/:clusterId/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/city-list/:cityId/cluster-list/:clusterId/facility-list/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/city-list/:cityId/cluster-list/:clusterId/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/:regionId/:cityId/:clusterId/facility-list/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/:cityId/:clusterId/facility-list/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/:cityId/cluster-list/:clusterId/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/:cityId/:clusterId/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/:regionId/city-list/:cityId/cluster-list/:clusterId/facility-list/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/:regionId/:cityId/cluster-list/:clusterId/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/:regionId/city-list/:cityId/cluster-list/:clusterId/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/:regionId/city-list/:cityId/:clusterId/facility-list/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/:regionId/city-list/:cityId/:clusterId/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/:regionId/:cityId/:clusterId/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/city-list/:cityId/cluster-list/:clusterId/facility-list/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/:regionId/:cityId/:clusterId/facility-list/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/city-list/:cityId/cluster-list/:clusterId/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/:cityId/:clusterId/facility-list/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/:regionId/city-list/:cityId/:clusterId/facility-list/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/:regionId/city-list/:cityId/:clusterId/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/:cityId/:clusterId/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/region-list/:regionId/:cityId/cluster-list/:clusterId/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/:regionId/city-list/:cityId/cluster-list/:clusterId/facility-list/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/:regionId/city-list/:cityId/cluster-list/:clusterId/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/:regionId/:cityId/cluster-list/:clusterId/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/Pan-India-db/:regionId/:cityId/:clusterId/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {/* *** Pan india drill down end *** */}

                {/*
                 *** Region drill down start ***
                 */}
                {!R.isEmpty(pages) && pages.includes("REGION_DB") && (
                  <Route
                    path="/:regionId"
                    element={
                      <RegionDashboard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("REGION_DB") && (
                  <Route
                    path="/region-db"
                    element={
                      <RegionDashboard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("CITY_ASTPG") && (
                  <Route
                    path="/:regionId/city-list"
                    element={
                      <CityPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("CITY_DB") && (
                  <Route
                    path="/:regionId/:cityId"
                    element={
                      <CityDashboard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CITY_DB") && (
                  <Route
                    path="/:regionId/city-list/:cityId"
                    element={
                      <CityDashboard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("CLUSTER_ASTPG") && (
                  <Route
                    path="/:regionId/city-list/:cityId/cluster-list"
                    element={
                      <ClusterPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_ASTPG") && (
                  <Route
                    path="/:regionId/:cityId/cluster-list"
                    element={
                      <ClusterPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("CLUSTER_DB") && (
                  <Route
                    path="/:regionId/city-list/:cityId/cluster-list/:clusterId"
                    element={
                      <ClusterDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_DB") && (
                  <Route
                    path="/:regionId/:cityId/cluster-list/:clusterId"
                    element={
                      <ClusterDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_DB") && (
                  <Route
                    path="/:regionId/:cityId/:clusterId"
                    element={
                      <ClusterDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_DB") && (
                  <Route
                    path="/:regionId/city-list/:cityId/:clusterId"
                    element={
                      <ClusterDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("FACILITY_ASTPG") && (
                  <Route
                    path="/:regionId/city-list/:cityId/cluster-list/:clusterId/facility-list"
                    element={
                      <Facilities
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_ASTPG") && (
                  <Route
                    path="/:regionId/:cityId/cluster-list/:clusterId/facility-list"
                    element={
                      <Facilities
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_ASTPG") && (
                  <Route
                    path="/:regionId/city-list/:cityId/:clusterId/facility-list"
                    element={
                      <Facilities
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_ASTPG") && (
                  <Route
                    path="/:regionId/:cityId/:clusterId/facility-list"
                    element={
                      <Facilities
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/:regionId/city-list/:cityId/cluster-list/:clusterId/facility-list/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/:regionId/:cityId/cluster-list/:clusterId/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/:regionId/:cityId/:clusterId/facility-list/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/:regionId/city-list/:cityId/:clusterId/facility-list/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/:regionId/city-list/:cityId/:clusterId/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/:regionId/:cityId/:clusterId/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/:regionId/city-list/:cityId/cluster-list/:clusterId/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/:regionId/:cityId/cluster-list/:clusterId/facility-list/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/:regionId/city-list/:cityId/cluster-list/:clusterId/facility-list/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/:regionId/:cityId/:clusterId/facility-list/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/:regionId/:cityId/cluster-list/:clusterId/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/:regionId/city-list/:cityId/cluster-list/:clusterId/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/:regionId/city-list/:cityId/:clusterId/facility-list/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/:regionId/city-list/:cityId/:clusterId/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/:regionId/:cityId/cluster-list/:clusterId/facility-list/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/:regionId/:cityId/:clusterId/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/:regionId/city-list/:cityId/:clusterId/facility-list/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/:regionId/city-list/:cityId/:clusterId/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/:regionId/city-list/:cityId/cluster-list/:clusterId/facility-list/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/:regionId/:cityId/cluster-list/:clusterId/facility-list/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/:regionId/city-list/:cityId/cluster-list/:clusterId/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/:regionId/:cityId/cluster-list/:clusterId/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/:regionId/:cityId/:clusterId/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {/*
                 **** Region drill down end ***
                 */}

                {/*
                 *** City drill down start ***
                 */}

                {!R.isEmpty(pages) && pages.includes("CITY_DB") && (
                  <Route
                    path="/city-db"
                    element={
                      <CityDashboard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CITY_DB") && (
                  <Route
                    path="/city-db"
                    element={
                      <CityDashboard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("CLUSTER_ASTPG") && (
                  <Route
                    path="/city-db/cluster-list"
                    element={
                      <ClusterPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_ASTPG") && (
                  <Route
                    path="/city-db/cluster-list"
                    element={
                      <ClusterPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("CLUSTER_DB") && (
                  <Route
                    path="/city-db/cluster-list/:clusterId"
                    element={
                      <ClusterDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_DB") && (
                  <Route
                    path="/city-db/cluster-list/:clusterId"
                    element={
                      <ClusterDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_DB") && (
                  <Route
                    path="/city-db/:clusterId"
                    element={
                      <ClusterDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_DB") && (
                  <Route
                    path="/city-db/:clusterId"
                    element={
                      <ClusterDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_DB") && (
                  <Route
                    path="/city-db/cluster-list/:clusterId"
                    element={
                      <ClusterDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_DB") && (
                  <Route
                    path="/city-db/cluster-list/:clusterId"
                    element={
                      <ClusterDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_DB") && (
                  <Route
                    path="/city-db/:clusterId"
                    element={
                      <ClusterDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_DB") && (
                  <Route
                    path="/city-db/:clusterId"
                    element={
                      <ClusterDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("FACILITY_ASTPG") && (
                  <Route
                    path="/city-db/cluster-list/:clusterId/facility-list"
                    element={
                      <Facilities
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_ASTPG") && (
                  <Route
                    path="/city-db/cluster-list/:clusterId/facility-list"
                    element={
                      <Facilities
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_ASTPG") && (
                  <Route
                    path="/city-db/:clusterId/facility-list"
                    element={
                      <Facilities
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_ASTPG") && (
                  <Route
                    path="/city-db/:clusterId/facility-list"
                    element={
                      <Facilities
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/city-db/cluster-list/:clusterId/facility-list/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/city-db/cluster-list/:clusterId/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/city-db/:clusterId/facility-list/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/city-db/:clusterId/facility-list/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/city-db/:clusterId/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/city-db/:clusterId/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/city-db/cluster-list/:clusterId/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/city-db/cluster-list/:clusterId/facility-list/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/city-db/cluster-list/:clusterId/facility-list/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/city-db/:clusterId/facility-list/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/city-db/cluster-list/:clusterId/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/city-db/cluster-list/:clusterId/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/city-db/:clusterId/facility-list/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/city-db/:clusterId/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/city-db/:clusterId/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/city-db/:clusterId/facility-list/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/city-db/:clusterId/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/city-db/cluster-list/:clusterId/facility-list/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/city-db/cluster-list/:clusterId/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/city-db/cluster-list/:clusterId/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/city-db/:clusterId/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {/* City drill down end */}

                {/*
                 *** Cluster drill down start ***
                 */}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_DB") && (
                  <Route
                    path="/cluster-db"
                    element={
                      <ClusterDB
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("FACILITY_ASTPG") && (
                  <Route
                    path="/cluster-db/facility-list"
                    element={
                      <Facilities
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/cluster-db/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/cluster-db/facility-list/:facilityId"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/cluster-db/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/cluster-db/facility-list/:facilityId/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/cluster-db/facility-list/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/cluster-db/:facilityId/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {/* Cluster drill down end */}

                {/*
                 *** Facility drill down start ***
                 */}

                {!R.isEmpty(pages) && pages.includes("FACILITY_DB") && (
                  <Route
                    path="/facility-db"
                    element={
                      <DashBoard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/facility-db/asset-list"
                    element={
                      <Assets
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                  <Route
                    path="/facility-db/asset-list/:assetId"
                    element={
                      <AssetDetailPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) &&
                  pages.includes("FIXEDASSET_VIEW") &&
                  (
                    <Route
                      path="/asset-list"
                      element={
                        <AssetList
                          isDark={isDark}
                          theme={theme}
                          rightOpen={rightOpen}
                          toggleBtn={toggleBtn}
                        />
                      }
                    >
                      {!R.isEmpty(pages) && pages.includes("FIXEDASSET_VIEW") && (
                        <Route
                          path=":assetId"
                          element={
                            <AssetDetailPage
                              isDark={isDark}
                              theme={theme}
                              rightOpen={rightOpen}
                              toggleBtn={toggleBtn}
                            />
                            // <NewAssetDetailComponent />
                          }
                        />)}
                    </Route>
                  )}
                <Route
                  path="/ErrorPage"
                  element={<ErrorPage errorStatus={500} />}
                />
                <Route path="/Connectionerror" element={<Connectionerror />} />
                <Route path="/WronPage" element={<WronPage />} />
                {!R.isEmpty(pages) && pages.includes("FACILITY_ASTPG") && (
                  <Route
                    path="/facility-list"
                    element={
                      <Facilities
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CITY_ASTPG") && (
                  <Route
                    path="/city-list"
                    element={
                      <CityPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("CLUSTER_ASTPG") && (
                  <Route
                    path="/cluster-list"
                    element={
                      <ClusterPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}
                {!R.isEmpty(pages) && pages.includes("REGION_ASTPG") && (
                  <Route
                    path="/region-list"
                    element={
                      <RegionPage
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                {!R.isEmpty(pages) && pages.includes("OperationalViewDB") && (
                  <Route
                    path="/operationalDashboard"
                    element={
                      <OperationalDashboard
                        isDark={isDark}
                        theme={theme}
                        rightOpen={rightOpen}
                        toggleBtn={toggleBtn}
                      />
                    }
                  />
                )}

                <Route path="/s/:fixedAssetId" element={<RedirectComp />} />
              </Routes>
            </div>
          </div>
        </div>
        <FooterAone />
      </div>
    </BrowserRouter>


  );
}

export default App;
