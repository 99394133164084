export enum DropdownType {
  SINGLE = "single",
  MULTIPLE = "multiple",
  }

export enum FileFormat {
  Excel = "Excel",
  PDF = "PDF",
}

export enum Color {
  BLACK = "rgba(27, 27, 27, 1)",
  AMBER = "rgba(255, 140, 0, 1)",
  GREEN = "rgba(76, 175, 80, 1)",
  RED = "rgba(244, 67, 54, 1)",
}

export enum ControllerId {
    TIMER= "202",
    TEMPERATURE = "203",
    UI_MODE = "1000",
}