import React from "react";
import "./FaultSummaryBtn.scss";

import FaultArrow from "../../../assets/icons/SopCountImg.svg";

function FaultSummaryBtn({ data, callBack, state, key }) {
  return (
    <>
      {state === data.name ? (
        <div
          onClick={() => callBack(data.name)}
          className="faultSummaryBtnCont"
        >
          <li className="faultSummaryBtnTxt">{data.name}</li>
          <li className="faultSummaryBtnCount">
            {data.count}
            <span className="faultSummaryBtnCountSpan">
              <img className="faultArrow" src={FaultArrow} alt="faultArrow" />
              {data.delta}%
            </span>
          </li>
        </div>
      ) : (
        <div
          onClick={() => callBack(data.name)}
          style={{ opacity: 0.5}}
          className="faultSummaryBtnCont"
        >
          <li className="faultSummaryBtnTxt">{data.name}</li>
          <li className="faultSummaryBtnCount">
            {data.count}
            <span className="faultSummaryBtnCountSpan">
              <img className="faultArrow" src={FaultArrow} alt="faultArrow" />
              {data.delta}%
            </span>
          </li>
        </div>
      )}
    </>
  );
}

export default FaultSummaryBtn;
