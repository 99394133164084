export class AssetHelperFunction {
  getFacilityStateData(countryList: any, countryId: string, stateId: string) {
    if (
      countryId === undefined ||
      stateId === undefined ||
      countryList === undefined
    ) {
      return "";
    }

    let stateName = "";

    countryList.map((ele: any) => {
      if (ele.geoId === countryId) {
        const stateList = ele.states;

        stateList.map((item: any) => {
          if (item.geoId === stateId) {
            stateName = item.geoName;
          }
        });
      }
    });

    return stateName;
  }
}
