import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import '../DateTimeRangeSelectorInput/DateTimeRangeSelectorInput.scss';
import {ReportDatePickerData} from '../DataTypes';
import { Modal } from 'react-bootstrap';
import CalenderPopUpScreen from '../../ModelPopupcomp/DateTimeRangeInputComponent/CalenderPopUpScreen';
import { exptectedDateFormat, get365DaysAgo, getMinMaxDate } from '../../ModelPopupcomp/DateTimeRangeInputComponent/Utils';

const ReportDateTimePicker = ({PlaceHolder,field,state,activeInput, title, value, updateDateRange}: ReportDatePickerData) => {

  const handleClick = () => {
    activeInput();
    setPopUpState(true);
  }

  const [popUpState, setPopUpState] = useState(false);
  const closeCalenderPopUpScreen = () => setPopUpState(false);
  const [inputValue, setInputValue] = useState<any>("");
  const [tempselectedDate, setTempSelectedDate] = useState<any>(new Date());

  useEffect(() => {
    let selectedDate = new Date();
    let formattedDate = exptectedDateFormat(selectedDate.toLocaleString('en-US', { timeZone: 'UTC' }));
    selectedDate.setHours(selectedDate.getHours() - 5);
    selectedDate.setMinutes(selectedDate.getMinutes() - 30);
    setTempSelectedDate(() => selectedDate);
    updateDateRange(selectedDate.getTime());
    setInputValue(() => formattedDate);
    closeCalenderPopUpScreen();
  }, [])

  const updateInputValue = (e: any) => {
    let selectedDate = new Date(e);
    let formattedDate = exptectedDateFormat(selectedDate.toLocaleString('en-US', { timeZone: 'UTC' }));
    selectedDate.setHours(selectedDate.getHours() - 5);
    selectedDate.setMinutes(selectedDate.getMinutes() - 30);
    setTempSelectedDate(() => selectedDate);
    updateDateRange(selectedDate.getTime());
    setInputValue(() => formattedDate);
    closeCalenderPopUpScreen();
  }

  return (
    <div className="dateTimeInputCardContainer">
      <h4 className="tempInputTitleText">{title}{field === "Y" ? " *" : ""}</h4>
      <div className="styleInp">
        <input type="text" onClick={handleClick} required={true} 
         value={inputValue}  className="inputTempTextValue" placeholder={PlaceHolder} />
        <div className='calenderIcon'>
          <FontAwesomeIcon onClick={handleClick} icon={faCalendar} />
        </div>    
      </div>
      <Modal show={popUpState && state} centered backdrop={"static"} className='dateSpecific'>
        <CalenderPopUpScreen keyName={"StartDate"} selectedDate={tempselectedDate} min={get365DaysAgo()} max={getMinMaxDate("")} updateInput={updateInputValue} closePopUp={closeCalenderPopUpScreen} />
      </Modal>
    </div>
  );
}

export default ReportDateTimePicker