/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useStateValue } from "../../redux/StateProvider";
import FilterChip from "../activityCard/filterChip.tsx";
import "./ServiceTicketsCards.scss";
import atsuyalogo from "../../assets/icons/atsuya-logo.svg";

function ServiceTicketsCards({ data }) {
  const [{ getAlertChip }, dispatch] = useStateValue();
  const [selectedChips, setSelectedChips] = useState(getAlertChip || []);
  function selectedChipsCta(chip) {
    setSelectedChips([...getAlertChip, chip]);
    dispatch({ type: "GET_ALERT_CHIP", getAlertChip: [...getAlertChip, chip] });
  }

  return (
    <div className="serviceTicketsCards">
      <h3>Recent Tickets</h3>

      {data &&
        data.map((ele, id) => {
          return (
            <div className="ticketCards">
              <div className="ticketCards_header">
                <div className="ticketCards_header_name">
                  <h3>
                    SN:{ele.id}
                    {ele.serial_no}|{ele.mac_Id}
                  </h3>
                </div>
                <div className="ticketCards_header_time">
                  <h3>{ele.timeZone}</h3>
                </div>
              </div>

              <div className="ticketCards_watchListLeft">
                <div className="ticketCards_watchListIcon">
                  <div className="ticketCards_atsuyaLogo">
                    <img src={atsuyalogo} alt="logo" />
                  </div>
                  <div className="watchListData">
                    <div className="alertNotif">
                      <h3>Probe failure</h3>
                      <FilterChip
                        data={ele}
                        filterChipName={"repeated"}
                        selectedChips={selectedChipsCta}
                      />
                      <FilterChip
                        data={ele}
                        filterChipName={"Assigned"}
                        selectedChips={selectedChipsCta}
                      />
                    </div>

                    <div className="ticketAssigned">
                      <h5 className="borderRight">{ele.Client_Batch}</h5>
                      <h6 className="borderRight">Gas Meter-01</h6>
                      <p>
                        <FontAwesomeIcon icon={faLocationDot} /> {ele.locatedAT}
                      </p>
                    </div>
                    {Object.keys(ele).includes("repeated") ? (
                      <>
                        <div className="repeatedMsg">
                          {ele?.repeated?.message}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default ServiceTicketsCards;
