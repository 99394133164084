/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import "./FaultTicketsCard.scss";
import moment from "moment";

import TicketLocationIcon from "../../../assets/icons/SopLocationIcon.svg";

function FaultTicketsCard(props: any) {
  const { data } = props;
  return (
    <div className="faultTicketsCardCont">
      <div className="faultTicketsIdAndTime">
        <p className="faultTicketsId">ID: {data.id}</p>
        <p className="faultTicketsTime">
          {moment(new Date()).diff(data.time, "days")} mins ago
        </p>
      </div>
      <div className="faultTicketsIncormationCont">
        <div className="faultTicketsAddressInfoCont">
          <li className="faultTicketsAddressInfoTxt">
            {data.name}
            {data.isRepeated === true ? (
              <span className="faultRepeat">REPEATED</span>
            ) : (
              ""
            )}
          </li>
          <li className="faultTicketsAddressInfo">
            <img
              className="faultTicketsLocation"
              src={TicketLocationIcon}
              alt="locationImg"
            />
            {data.location.full}
          </li>
        </div>
        <div className="faultTicketsImportance">
          <li className="faultTicketsImportanceTxt">{data.severity}</li>
        </div>
      </div>
    </div>
  );
}

export default FaultTicketsCard;
