import React from "react";
import "./IncidentSummaryBtn.scss";
import IncidentArrow from "../../../assets/icons/SopCountImg.svg";

function IncidentSummaryBtn({ data, callBack, state, key }: any) {
  return (
    <>
      {state === data.name ? (
        <div
          onClick={() => callBack(data.name)}
          className="incidentSummaryBtnCont"
        >
          <li className="incidentSummaryBtnTxt">{data.name}</li>
          <li className="incidentSummaryBtnCount">
            {data.count}
            <span className="incidentSummaryBtnCountSpan">
              <img
                className="incidentArrow"
                src={IncidentArrow}
                alt="incidentArrow"
              />
              {data.delta}%
            </span>
          </li>
        </div>
      ) : (
        <div
          onClick={() => callBack(data.name)}
          style={{ opacity: 0.5 }}
          className="incidentSummaryBtnCont"
        >
          <li className="incidentSummaryBtnTxt">{data.name}</li>
          <li className="incidentSummaryBtnCount">
            {data.count}
            <span className="incidentSummaryBtnCountSpan">
              <img
                className="incidentArrow"
                src={IncidentArrow}
                alt="incidentArrow"
              />
              {data.delta}%
            </span>
          </li>
        </div>
      )}
    </>
  );
}

export default IncidentSummaryBtn;
