import React, { useEffect, useState } from 'react';
import Lottie from "react-lottie";
import loader from "../../a1Components/lotties/round-loader-white.json";
import sucessTick from '../../assets/icons/sucessTick.svg';
import {NavLink, useLocation } from "react-router-dom";
import PasswordInputField from './PasswordInputField';
import { DashBoardServices } from '../../services/DasboardServices/DashboardService';
import { useStateValue } from '../../redux/StateProvider';
import "../LoginPage/LoginPageStyles.scss";


const NewPasswordScreen = () => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, seterrorMessage] = useState("");
    const [message, setMessage] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [redirectLoginPage, setRedirectLoginPage] = useState(false);
    const [{ forgotPassword }, dispatch] = useStateValue();
    const dbService = new DashBoardServices();
    let navigate = useLocation();

    const submitHandler = async () => {
      setLoading(true);
      seterrorMessage("");
      let obj = { userLoginId : forgotPassword.user, newPassword : newPassword, newPasswordVerify : confirmPassword };
      let fetchData:any = await dbService.getUpdateNewPassword(obj);
      if (newPassword === confirmPassword) {
        if(fetchData === "error") {
          seterrorMessage("Failed, please try again ");
        }else {
          let response = await fetchData.json();
          if(fetchData.status === 200){
            let msg = response.successMessage;
            setMessage(msg);
            setRedirectLoginPage(true);
            setTimeout(() => {
              window.location.pathname = "./"
            }, 2 * 1000 );

          }
          else {
            let msg = response.errorMessageList;
            seterrorMessage(msg);
          }
        }
      }
      else {
        seterrorMessage("Passwords are not matching")
      }
      setLoading(false);
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loader,
      };

  return (
    <div className="subContent">
      {
        redirectLoginPage ?  <div className="redirectLoginPage">
        <div className='msgDicContainer'>
          <img src={sucessTick} alt="icon" loading='lazy' />
          <p>{message}</p>
        </div>
        <div className='msgDicContainerOne'>
          <p>Please wait while we redirect you...</p>
        </div>
      </div> : 
       <div className="loginContent">
       <h6>Reset Password</h6>
       <p>Please enter your new password to continue.</p>
       <div className='passwordInputPageContainer'>
         <PasswordInputField onChangeInput={(e) => setNewPassword(e)} value={newPassword} title={"New Password"} />
         <PasswordInputField onChangeInput={(e) => setConfirmPassword(e)} value={confirmPassword} title={"Confirm Password"} />
       </div>
     
       {newPassword.length > 0 && confirmPassword.length > 0 ? 
         <button onClick={submitHandler} className="signInbutton">
           {loading ? <Lottie options={defaultOptions} /> : "Update password" }
         </button> : 
         <button className="signInbuttonOpacity">Update password</button>
       }
       <div className="errorMessageContainer"><p>{errorMessage}</p></div>
       <NavLink to={"/"}>
        <div className="redirectLoginDiv"><p>Back to Login</p></div>  
       </NavLink>            
     </div>
      }
  </div>
  )
}

export default NewPasswordScreen