/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  faAnglesRight,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import TrendData from "../trendData";
import { useStateValue } from "../../redux/StateProvider";

async function fetchSummary() {
  const url = "https://snakeoil-api.atsuyatech.com/data";
  const resp = await fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return resp.json();
}
function Emissions(props) {
  const [{ darkThemeEnabled }] = useStateValue();
  const isDark = darkThemeEnabled;
  const [activityData, setActivityData] = useState([]);

  useEffect(async () => {
    fetchSummary().then((result) => {
      if (result) {
        setActivityData(result.emissions);
      }
    });
  }, []);
  return (
    <>
      <div
        className="emissions"
        style={{
          backgroundColor: isDark ? "#0C1228" : "",
        }}
      >
        <div className="emissions-data-left">
          <h2>
            <b>{activityData.emission && activityData.emission}</b> kg CO
            <sub style={{ fontSize: "0.4" }}>2</sub>{" "}
          </h2>
          <div className="trendData">
            <TrendData />
            <p style={{ color: isDark ? "#fff" : "#000" }}>
              <b style={{ fontSize: "11px" }}>
                {activityData.variance && activityData.variance}%{" "}
                {activityData.trend && activityData.trend}
              </b>
            </p>
          </div>
        </div>
        <div>
          <FontAwesomeIcon
            className="faarrowrightlong"
            icon={faArrowRightLong}
            style={{
              color: "#16A085",
              fontSize: "25px",
              paddingBottom: "20px",
            }}
          />
        </div>

        <div
          className="emissions-data-right"
          style={{ color: isDark ? "#fff" : "#000" }}
        >
          <h6>Equivalent to</h6>

          <h2>{activityData.equivalent_number}</h2>
          <p>Tree seedlings grown for {activityData.timeperiod} </p>

          <NavLink children={undefined} to={""} className="nav-link">
            <p>
              {props.Contributing}
              <FontAwesomeIcon
                icon={faAnglesRight}
                style={{
                  fontSize: "small",
                  paddingTop: "4px",
                  paddingLeft: "6px",
                }}
              />
            </p>
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default Emissions;
