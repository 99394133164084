/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import GaugeChart from "../../a1Components/Gauge";
import { DataFetching } from "../../a1Components/Servies/DataFetching";
import SnackCard from "../../a1Components/SnackCards";
import { useStateValue } from "../../redux/StateProvider";
import healthscoreicongood from "../../assets/icons/healthscore-icon-good.svg";
import healthscoreiconexcellent from "../../assets/icons/healthscore-icon-excellent.svg";
import healthscoreiconbad from "../../assets/icons/healthscore-icon-bad.svg";
import "./CityPage.scss";
import { AssetServices } from "../../services/AssetServices/AssetServices";
import axios from "axios";
import LoaderStyle from "../../a1Components/LoaderStyle/LoaderStyle";

async function getFacility() {
  const fetchDataFH = new AssetServices();
  const data = fetchDataFH.getFacilityAS();
  return data;
}

function CityPage({ isDark, theme, rightOpen, toggleBtn, themeColors }:any) {
  const [{}, dispatch] = useStateValue();
  let { regionId } = useParams();
  const location = useLocation();
  const [facility, setFacility] = useState([]);
  const [snackCard, setSnackCard] = useState([]);
  const [loaderState, setLoaderState] = useState(true);
  useEffect(() => {
    dispatch({
      type: "PATH_NAME",
      pathName: location.pathname,
    });
  }, []);
  useEffect(() => {
    async function getCityList() {
      const userName = await localStorage.getItem("userName");
      const password = await localStorage.getItem("password");
      const loginData = await localStorage.getItem("aoneLoginData");
      const loginDataParse = JSON.parse(loginData);
      const facilities = loginDataParse.data?.loginDetails[0]?.facilities;
      const regionIds = facilities?.regionIds;
      const rId =
        regionId !== undefined && !regionId.includes("-")
          ? regionId
          : regionIds[0];
      try {
        setLoaderState(true);
        const response = await axios.get(
          `https://aone.atsuyatech.com//api/1.0/fixedAsset/healthScoreList?username=${userName}&password=${password}&filterBy=${rId}&view=REGION`
        );
        if (response.status === 200) {
          setFacility(response?.data?.health);
          setLoaderState(false);
        }
      } catch (error) {
        // console.error(error, "getCityList");
      }
    }
    getCityList();
  }, []);

  return (
    <div className="row">
      {loaderState && (
        <div className="lottieContainer">
          <div className="d-flex justify-content-center">
            <LoaderStyle />
          </div>
        </div>
      )}
      <div
        className={
          !rightOpen
            ? "col-12"
            : !rightOpen && toggleBtn
            ? "col-12"
            : toggleBtn
            ? "col-12"
            : "col-8"
        }
      >
        <div className="row">
          {facility.map((item) => (
            <div
              className={
                rightOpen
                  ? "col-6"
                  : "faciltyMobile col-12 col-lg-4 col-md-6 mt-1"
              }
            >
              <Link to={location.pathname + "/" + item?.groupId}>
                <div className={`facilityCard ${item.grade}`}>
                  <div className="facilityCard-header">
                    <div>
                      <h5>{item?.groupName}</h5>
                      <p>{item?.facilityDetails?.address?.city}</p>
                    </div>
                    <h6>{item?.grade}</h6>
                  </div>
                  <div className="facilityCard-body">
                    <div className="facilityCard-chart">
                      <GaugeChart
                        data={{
                          value: item.scoreValue,
                          abbreviation: item.scoreAbbreviation,
                          label: item.scoreLabel,
                        }}
                      />
                    </div>
                    <div className="snackItem">
                      {item?.snackCard.map((snack) => (
                        <div className="snackItem-details">
                          <img
                            src={
                              snack?.grade === "Excellent"
                                ? healthscoreiconexcellent
                                : snack?.grade === "Good"
                                ? healthscoreicongood
                                : healthscoreiconbad
                            }
                          />
                          <div className="msg">
                            <p>
                              <b>{snack?.label}</b>
                            </p>
                            <p>{snack?.message}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="facilityCard-footer">
                    <div className="">
                      <p>{item?.message}</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CityPage;
