/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
// import the core library.
import ReactEChartsCore from "echarts-for-react/lib/core";
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from "echarts/core";
// Import charts, all with Chart suffix
import {
  // LineChart,
  BarChart,
  // PieChart,
  // ScatterChart,
  // RadarChart,
  // MapChart,
  // TreeChart,
  // TreemapChart,
  // GraphChart,
  GaugeChart,

  // FunnelChart,
  // ParallelChart,
  // SankeyChart,
  // BoxplotChart,
  // CandlestickChart,
  // EffectScatterChart,
  // LinesChart,
  HeatmapChart,
  // PictorialBarChart,
  // ThemeRiverChart,
  // SunburstChart,
  // CustomChart,
} from "echarts/charts";
// import components, all suffixed with Component
import {
  // GridSimpleComponent,
  GridComponent,
  // PolarComponent,
  // RadarComponent,
  // GeoComponent,
  // SingleAxisComponent,
  // ParallelComponent,
  // CalendarComponent,
  // GraphicComponent,
  // ToolboxComponent,
  TooltipComponent,
  // AxisPointerComponent,
  // BrushComponent,
  TitleComponent,
  // TimelineComponent,
  // MarkPointComponent,
  // MarkLineComponent,
  // MarkAreaComponent,
  // LegendComponent,
  // LegendScrollComponent,
  // LegendPlainComponent,
  // DataZoomComponent,
  // DataZoomInsideComponent,
  // DataZoomSliderComponent,
  // VisualMapComponent,
  // VisualMapContinuousComponent,
  // VisualMapPiecewiseComponent,
  // AriaComponent,
  // TransformComponent,
  DatasetComponent,
  CalendarComponent,
  VisualMapComponent,
} from "echarts/components";
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
  CanvasRenderer,
  // SVGRenderer,
} from "echarts/renderers";
// import DisplayNote from '../../DisplayNote/DisplayNote';

// Register the required components

function Heatmap(props) {
  echarts.use([
    TitleComponent,
    CalendarComponent,
    TooltipComponent,
    VisualMapComponent,
    HeatmapChart,
    CanvasRenderer,
  ]);

  const cities = ["Chennai"];
  // prettier-ignore
  const parameters = [
    'Savings', 'Compliance',
    'Energy Utilization','Emissions'
];

  const legendFormatter = function (val) {
    if (val === "0-3") {
      return "Poor";
    } else if (val === "3-6") {
      return "Good";
    } else if (val === "6-9") {
      return "Excellent";
    } else {
      return val;
    }
  };

  const data = [
    [0, 0, 0],
    [1, 0, 0],
    [2, 0, 0],
    [3, 0, 0],
  ].map(function (item) {
    return [item[1], item[0], item[2] || "-"];
  });
  var option = {
    tooltip: {
      position: "top",
    },
    grid: {
      height: "50%",
      top: "15%",
      left: "20%",
    },
    xAxis: {
      type: "category",
      data: cities,
      splitArea: {
        show: true,
      },
    },
    yAxis: {
      type: "category",
      data: parameters,
      splitArea: {
        show: true,
      },
    },
    visualMap: {
      min: 0,
      max: 9,
      splitNumber: 3,
      orient: "horizontal",
      left: "center",
      bottom: "10%",

      inRange: {
        color: ["#F44336", "#FFC107", "#4CAF50"], //From smaller to bigger value ->
      },
      type: "piecewise",
      formatter: function (value, value2) {
        return legendFormatter(`${value}-${value2}`);
      },
    },
    series: [
      {
        name: "City-wise performance",
        type: "heatmap",
        data: data,
        label: {
          show: false,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  return (
    <>
      <ReactEChartsCore
        echarts={echarts}
        option={option}
        notMerge={true}
        lazyUpdate={true}
        theme={"theme_name"}
      />
    </>
  );
}

export default Heatmap;
